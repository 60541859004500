/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  TemplateDto,
} from '../models/index';
import {
    TemplateDtoFromJSON,
    TemplateDtoToJSON,
} from '../models/index';

export interface TemplateDeleteRequest {
    id: number;
}

export interface TemplateInsertRequest {
    templateDto: TemplateDto;
}

export interface TemplateSelectByIdRequest {
    id: number;
}

export interface TemplateSelectByPkRequest {
    eventType: TemplateSelectByPkEventTypeEnum;
    channelType: TemplateSelectByPkChannelTypeEnum;
    language: string;
}

export interface TemplateUpdateRequest {
    templateDto: TemplateDto;
}

/**
 * 
 */
export class TemplateManagementApi extends runtime.BaseAPI {

    /**
     * Deletes a template by its ID.
     * Delete a template
     */
    async templateDeleteRaw(requestParameters: TemplateDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling templateDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/template/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Deletes a template by its ID.
     * Delete a template
     */
    async templateDelete(requestParameters: TemplateDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.templateDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates a new template with the given details and returns the created template.
     * Create a new template
     */
    async templateInsertRaw(requestParameters: TemplateInsertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TemplateDto>> {
        if (requestParameters['templateDto'] == null) {
            throw new runtime.RequiredError(
                'templateDto',
                'Required parameter "templateDto" was null or undefined when calling templateInsert().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/template`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TemplateDtoToJSON(requestParameters['templateDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TemplateDtoFromJSON(jsonValue));
    }

    /**
     * Creates a new template with the given details and returns the created template.
     * Create a new template
     */
    async templateInsert(requestParameters: TemplateInsertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TemplateDto> {
        const response = await this.templateInsertRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a template by its ID.
     * Get template by ID
     */
    async templateSelectByIdRaw(requestParameters: TemplateSelectByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TemplateDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling templateSelectById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/template/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TemplateDtoFromJSON(jsonValue));
    }

    /**
     * Retrieves a template by its ID.
     * Get template by ID
     */
    async templateSelectById(requestParameters: TemplateSelectByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TemplateDto> {
        const response = await this.templateSelectByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves templates by event type, channel type, and language.
     * Get templates by primary key
     */
    async templateSelectByPkRaw(requestParameters: TemplateSelectByPkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TemplateDto>>> {
        if (requestParameters['eventType'] == null) {
            throw new runtime.RequiredError(
                'eventType',
                'Required parameter "eventType" was null or undefined when calling templateSelectByPk().'
            );
        }

        if (requestParameters['channelType'] == null) {
            throw new runtime.RequiredError(
                'channelType',
                'Required parameter "channelType" was null or undefined when calling templateSelectByPk().'
            );
        }

        if (requestParameters['language'] == null) {
            throw new runtime.RequiredError(
                'language',
                'Required parameter "language" was null or undefined when calling templateSelectByPk().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['eventType'] != null) {
            queryParameters['eventType'] = requestParameters['eventType'];
        }

        if (requestParameters['channelType'] != null) {
            queryParameters['channelType'] = requestParameters['channelType'];
        }

        if (requestParameters['language'] != null) {
            queryParameters['language'] = requestParameters['language'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/template/by-pk`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TemplateDtoFromJSON));
    }

    /**
     * Retrieves templates by event type, channel type, and language.
     * Get templates by primary key
     */
    async templateSelectByPk(requestParameters: TemplateSelectByPkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TemplateDto>> {
        const response = await this.templateSelectByPkRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates a template by its primary key.
     * Update an existing template
     */
    async templateUpdateRaw(requestParameters: TemplateUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['templateDto'] == null) {
            throw new runtime.RequiredError(
                'templateDto',
                'Required parameter "templateDto" was null or undefined when calling templateUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/template`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: TemplateDtoToJSON(requestParameters['templateDto']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Updates a template by its primary key.
     * Update an existing template
     */
    async templateUpdate(requestParameters: TemplateUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.templateUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const TemplateSelectByPkEventTypeEnum = {
    Invitation: 'INVITATION',
    SignupSuccessful: 'SIGNUP_SUCCESSFUL',
    ResetPassword: 'RESET_PASSWORD',
    Otp: 'OTP',
    Welcome: 'WELCOME',
    Info: 'INFO',
    Alert: 'ALERT'
} as const;
export type TemplateSelectByPkEventTypeEnum = typeof TemplateSelectByPkEventTypeEnum[keyof typeof TemplateSelectByPkEventTypeEnum];
/**
 * @export
 */
export const TemplateSelectByPkChannelTypeEnum = {
    Email: 'EMAIL',
    Sms: 'SMS',
    Api: 'API'
} as const;
export type TemplateSelectByPkChannelTypeEnum = typeof TemplateSelectByPkChannelTypeEnum[keyof typeof TemplateSelectByPkChannelTypeEnum];
