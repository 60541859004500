import {PermissionManagementApi} from './generated-react-client/src/apis/PermissionManagementApi';
import {
    PermissionDeleteRequest,
    PermissionInsertRequest,
    PermissionSelectByAliasRequest,
    PermissionSelectByIdRequest,
    PermissionUpdateRequest
} from './generated-react-client/src/apis';
import config from './clientConfig';
import {apiCallWrapper} from '../utils/apiWrapper';

const api = new PermissionManagementApi(config);

export const permissionInsert = async (requestParameters: PermissionInsertRequest) => {
    return apiCallWrapper(() => api.permissionInsert(requestParameters));
};
export const permissionSelect = async () => {
    return apiCallWrapper(() => api.permissionSelect());
};

export const permissionUpdate = async (requestParameters: PermissionUpdateRequest) => {
    return apiCallWrapper(() => api.permissionUpdate(requestParameters));
};

export const permissionDelete = async (requestParameters: PermissionDeleteRequest) => {
    return apiCallWrapper(() => api.permissionDelete(requestParameters));
};

export const permissionSelectByAlias = async (requestParameters: PermissionSelectByAliasRequest) => {
    return apiCallWrapper(() => api.permissionSelectByAlias(requestParameters));
};

export const permissionSelectById = async (requestParameters: PermissionSelectByIdRequest) => {
    return apiCallWrapper(() => api.permissionSelectById(requestParameters));
};
