/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Trace schema metadata data to update an existing record
 * @export
 * @interface TraceSchemaMetadataDto
 */
export interface TraceSchemaMetadataDto {
    /**
     * Unique identifier for the trace schema.
     * @type {number}
     * @memberof TraceSchemaMetadataDto
     */
    tschemaId?: number;
    /**
     * Unique string identifier for the trace schema metadata.
     * @type {string}
     * @memberof TraceSchemaMetadataDto
     */
    id?: string;
    /**
     * Order index of the metadata field.
     * @type {number}
     * @memberof TraceSchemaMetadataDto
     */
    orderIndex?: number;
    /**
     * The default value of the field if no value is provided.
     * @type {string}
     * @memberof TraceSchemaMetadataDto
     */
    defaultValue?: string;
    /**
     * Validation rules associated with the field.
     * @type {string}
     * @memberof TraceSchemaMetadataDto
     */
    validationRule?: string;
    /**
     * List of possible values for the field.
     * @type {string}
     * @memberof TraceSchemaMetadataDto
     */
    valueList?: string;
    /**
     * Indicates if the field is mandatory (true) or optional (false).
     * @type {boolean}
     * @memberof TraceSchemaMetadataDto
     */
    mandatory?: boolean;
    /**
     * Enumerates the various types of data that a field can hold, providing specific context for the data handling requirements.
     * @type {string}
     * @memberof TraceSchemaMetadataDto
     */
    dataType?: TraceSchemaMetadataDtoDataTypeEnum;
}


/**
 * @export
 */
export const TraceSchemaMetadataDtoDataTypeEnum = {
    Boolean: 'BOOLEAN',
    Integer: 'INTEGER',
    String: 'STRING',
    Memo: 'MEMO',
    Double: 'DOUBLE',
    Date: 'DATE',
    Time: 'TIME',
    Datetime: 'DATETIME',
    List: 'LIST',
    Json: 'JSON',
    Xml: 'XML'
} as const;
export type TraceSchemaMetadataDtoDataTypeEnum = typeof TraceSchemaMetadataDtoDataTypeEnum[keyof typeof TraceSchemaMetadataDtoDataTypeEnum];


/**
 * Check if a given object implements the TraceSchemaMetadataDto interface.
 */
export function instanceOfTraceSchemaMetadataDto(value: object): boolean {
    return true;
}

export function TraceSchemaMetadataDtoFromJSON(json: any): TraceSchemaMetadataDto {
    return TraceSchemaMetadataDtoFromJSONTyped(json, false);
}

export function TraceSchemaMetadataDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TraceSchemaMetadataDto {
    if (json == null) {
        return json;
    }
    return {
        
        'tschemaId': json['tschemaId'] == null ? undefined : json['tschemaId'],
        'id': json['id'] == null ? undefined : json['id'],
        'orderIndex': json['orderIndex'] == null ? undefined : json['orderIndex'],
        'defaultValue': json['defaultValue'] == null ? undefined : json['defaultValue'],
        'validationRule': json['validationRule'] == null ? undefined : json['validationRule'],
        'valueList': json['valueList'] == null ? undefined : json['valueList'],
        'mandatory': json['mandatory'] == null ? undefined : json['mandatory'],
        'dataType': json['dataType'] == null ? undefined : json['dataType'],
    };
}

export function TraceSchemaMetadataDtoToJSON(value?: TraceSchemaMetadataDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'tschemaId': value['tschemaId'],
        'id': value['id'],
        'orderIndex': value['orderIndex'],
        'defaultValue': value['defaultValue'],
        'validationRule': value['validationRule'],
        'valueList': value['valueList'],
        'mandatory': value['mandatory'],
        'dataType': value['dataType'],
    };
}

