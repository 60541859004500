import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {ShareDto, TeamDto} from "../../../api/generated-react-client/src";
import {
    shareDelete,
    shareInsert,
    shareSelectByPschema,
    shareTeamDelete,
    shareTeamInsert,
    shareTeamSelectByShare,
    shareUpdate
} from "../../../api/ShareService";
import {MdOutlineCreate, MdOutlineDeleteForever, MdOutlineGroup} from "react-icons/md";
import Modal from "../../common/Modal";
import {teamSelect} from "../../../api/TeamService";

const ShareForm: React.FC = () => {

    const {pschemaId} = useParams<{ pschemaId: string }>();
    const [shares, setShares] = useState<ShareDto[]>([]);
    const [createFormData, setCreateFormData] = useState({alias: '', title: ''});
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [showPopup, setShowPopup] = useState(false);

    const [teams, setTeams] = useState<TeamDto[]>([]);
    const [isTeamsModalOpen, setIsTeamsModalOpen] = useState<boolean>(false);
    const [selectedTeams, setSelectedTeams] = useState<Record<number, boolean>>({});
    const [tempSelectedTeams, setTempSelectedTeams] = useState<Record<number, boolean>>({});
    const [currentShareId, setCurrentShareId] = useState<number | null>(null);

    const handleError = (error: unknown) => {
        setError((error as Error).message);
        setShowPopup(true);
    };

    useEffect(() => {
        const fetchShares = async () => {
            try {
                const fetchedShares = await shareSelectByPschema({pschemaId: Number(pschemaId)});
                setShares(fetchedShares);
            } catch (error) {
                handleError(error);
            }
        };
        fetchShares();
    }, [pschemaId]);

    const handleCreate = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const shareDto: ShareDto = {
            alias: createFormData.alias,
            title: createFormData.title,
            pschemaId: Number(pschemaId)
        };

        try {
            await shareInsert({shareDto});
            setIsCreateModalOpen(false);
            const updatedShares = await shareSelectByPschema({pschemaId: Number(pschemaId)});
            setShares(updatedShares);
        } catch (error) {
            handleError(error);
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setCreateFormData(prevFormData => ({...prevFormData, [name]: value}));
    };

    const handleEditClick = async (share: ShareDto, field: keyof ShareDto) => {
        const newValue = prompt(`Edit ${field}`, share[field] as string);
        if (newValue !== null) {
            try {
                await shareUpdate({shareDto: {...share, [field]: newValue}});
                setShares(shares.map(b => (b.id === share.id ? {...b, [field]: newValue} : b)));
            } catch (error) {
                handleError(error);
            }
        }
    };

    const handleDelete = async (shareId: number) => {
        try {
            await shareDelete({shareId});
            setShares(shares.filter(share => share.id !== shareId));
        } catch (error) {
            handleError(error);
        }
    };

    const handleShowTeam = async (shareId: number) => {
        setCurrentShareId(shareId);

        try {
            const teams = await teamSelect();
            setTeams(teams);

            const selectedTeamsForShare: Record<number, boolean> = {};
            const linkedTeams = await shareTeamSelectByShare({shareId});
            linkedTeams.forEach(linkedTeam => {
                selectedTeamsForShare[linkedTeam.teamId!] = true;
            });

            setSelectedTeams(selectedTeamsForShare);
            setTempSelectedTeams(selectedTeamsForShare);
            setIsTeamsModalOpen(true);
        } catch (error) {
            handleError(error);
        }
    };

    const handleTeamCheckboxChange = async (teamId: number, isChecked: boolean) => {
        setTempSelectedTeams(prev => ({...prev, [teamId]: isChecked}));

        if (currentShareId !== null) {
            try {
                if (isChecked) {
                    await shareTeamInsert({shareTeamDto: {shareId: currentShareId, teamId}});
                } else {
                    await shareTeamDelete({shareId: currentShareId, teamId});
                }

                setSelectedTeams(prev => ({...prev, [teamId]: isChecked}));
            } catch (error) {
                handleError(error);
            }
        }
    };

    const handleCloseTeamsModal = () => {
        setIsTeamsModalOpen(false);
        setTempSelectedTeams({...selectedTeams});
    };

    return (
        <div className="formList">
            <div className="headerContainer">
                <b>Share list</b>
                <div className="formGroup">
                    <button onClick={() => setIsCreateModalOpen(true)}>Create</button>
                </div>
            </div>

            <Modal isOpen={isCreateModalOpen} onClose={() => setIsCreateModalOpen(false)}>
                <form onSubmit={handleCreate}>
                    <div className="formGroup">
                        <label>Alias</label>
                        <input
                            type="text"
                            name="alias"
                            value={createFormData.alias}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="formGroup">
                        <label>Title</label>
                        <input
                            type="text"
                            name="title"
                            value={createFormData.title}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="horizontalButtonContainer">
                        <button type="submit">Create</button>
                    </div>
                </form>
            </Modal>

            <table>
                <thead>
                <tr>
                    <th style={{width: '40px', textAlign: 'center'}}>ID</th>
                    <th>Alias</th>
                    <th>Title</th>
                    <th style={{width: '100px'}}>Action</th>
                </tr>
                </thead>
                <tbody>
                {shares.map((share, index) => (
                    <tr key={share.id} className={index % 2 === 0 ? 'even' : 'odd'}>
                        <td style={{width: '40px', textAlign: 'center'}}>{share.id}</td>
                        <td>
                            <div className="td-vertical">
                                {share.alias}
                                <span className="editIcon" onClick={() => handleEditClick(share, 'alias')}>
                                    <MdOutlineCreate/>
                                </span>
                            </div>
                        </td>
                        <td>
                            <div className="td-vertical">
                                {share.title}
                                <span className="editIcon" onClick={() => handleEditClick(share, 'title')}>
                                    <MdOutlineCreate/>
                                </span>
                            </div>
                        </td>
                        <td style={{width: '100px'}}>
                            <div className="icon-container">
                                <span onClick={() => handleShowTeam(share.id!)}
                                      className="notifyIcon"
                                      title="Manage teams"><MdOutlineGroup/></span>
                                <span onClick={() => handleDelete(share.id!)} className="deleteIcon">
                                    <MdOutlineDeleteForever/>
                                </span>
                            </div>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>

            <Modal isOpen={isTeamsModalOpen} onClose={handleCloseTeamsModal}>
                <div>
                    {teams.map(team => (
                        <div key={team.id}>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={tempSelectedTeams[team.id!] || false}
                                    onChange={e => handleTeamCheckboxChange(team.id!, e.target.checked)}
                                />
                                {team.alias}
                            </label>
                        </div>
                    ))}
                </div>
            </Modal>
        </div>
    );
};

export default ShareForm;