import {
    UploadCSVFileRequest,
    UserDeleteRequest,
    UserManagementApi,
    UserSearchRequest,
    UserUpdateRequest,
} from './generated-react-client/src/apis/UserManagementApi';
import {UserInsertRequest} from './generated-react-client/src/apis';
import config from './clientConfig';
import {apiCallWrapper} from '../utils/apiWrapper'; // Import apiCallWrapper

const api = new UserManagementApi(config);

export const userInsert = async (requestParameters: UserInsertRequest) => {
    return apiCallWrapper(() => api.userInsert(requestParameters));
};
export const userSelect = async () => {
    return apiCallWrapper(() => api.userSelect());
};

export const userUpdate = async (requestParameters: UserUpdateRequest) => {
    console.log(requestParameters);
    return apiCallWrapper(() => api.userUpdate(requestParameters));
};

export const userDelete = async (requestParameters: UserDeleteRequest) => {
    return apiCallWrapper(() => api.userDelete(requestParameters));
};

export const userUploadCSVFile = async (requestParameters: UploadCSVFileRequest) => {
    return apiCallWrapper(() => api.uploadCSVFile(requestParameters));
};

export const userSearch = async (requestParameters: UserSearchRequest) => {
    return apiCallWrapper(() => api.userSearch(requestParameters));
};