/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * The instance to delete
 * @export
 * @interface TeamMemberDto
 */
export interface TeamMemberDto {
    /**
     * The ID of the team
     * @type {number}
     * @memberof TeamMemberDto
     */
    teamId?: number;
    /**
     * Type of a member in the system.
     * @type {string}
     * @memberof TeamMemberDto
     */
    memberType?: TeamMemberDtoMemberTypeEnum;
    /**
     * The ID of the member
     * @type {number}
     * @memberof TeamMemberDto
     */
    memberId?: number;
}


/**
 * @export
 */
export const TeamMemberDtoMemberTypeEnum = {
    User: 'USER',
    Entity: 'ENTITY',
    Tenant: 'TENANT',
    Team: 'TEAM'
} as const;
export type TeamMemberDtoMemberTypeEnum = typeof TeamMemberDtoMemberTypeEnum[keyof typeof TeamMemberDtoMemberTypeEnum];


/**
 * Check if a given object implements the TeamMemberDto interface.
 */
export function instanceOfTeamMemberDto(value: object): boolean {
    return true;
}

export function TeamMemberDtoFromJSON(json: any): TeamMemberDto {
    return TeamMemberDtoFromJSONTyped(json, false);
}

export function TeamMemberDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamMemberDto {
    if (json == null) {
        return json;
    }
    return {
        
        'teamId': json['teamId'] == null ? undefined : json['teamId'],
        'memberType': json['memberType'] == null ? undefined : json['memberType'],
        'memberId': json['memberId'] == null ? undefined : json['memberId'],
    };
}

export function TeamMemberDtoToJSON(value?: TeamMemberDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'teamId': value['teamId'],
        'memberType': value['memberType'],
        'memberId': value['memberId'],
    };
}

