/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * The new attributes for the cron.
 * @export
 * @interface CronDto
 */
export interface CronDto {
    /**
     * Unique identifier for the cron task.
     * @type {number}
     * @memberof CronDto
     */
    id?: number;
    /**
     * Identifier for the node where the task is scheduled to run.
     * @type {string}
     * @memberof CronDto
     */
    host?: string;
    /**
     * Indicates whether the task is currently running.
     * @type {boolean}
     * @memberof CronDto
     */
    running?: boolean;
    /**
     * Timestamp of creation of the cron.
     * @type {Date}
     * @memberof CronDto
     */
    createdAt?: Date;
    /**
     * Timestamp when the task is scheduled to run.
     * @type {Date}
     * @memberof CronDto
     */
    executeAt?: Date;
    /**
     * Identifier of the user under whose access rights the task will be executed.
     * @type {number}
     * @memberof CronDto
     */
    userId?: number;
    /**
     * Timestamp when the task was actually executed.
     * @type {Date}
     * @memberof CronDto
     */
    executedAt?: Date;
    /**
     * Number of attempts made to execute the task.
     * @type {number}
     * @memberof CronDto
     */
    retryCount?: number;
    /**
     * Identifier of the source task, used in case of retry or reschedule.
     * @type {number}
     * @memberof CronDto
     */
    sourceId?: number;
    /**
     * Error message encountered upon task exit, if any.
     * @type {string}
     * @memberof CronDto
     */
    error?: string;
    /**
     * Fully qualified class name of the handler responsible for task execution.
     * @type {string}
     * @memberof CronDto
     */
    handler?: string;
    /**
     * Level of concurrency for the task execution.
     * @type {string}
     * @memberof CronDto
     */
    parallelism?: string;
    /**
     * Maximum number of retry attempts allowed for the task.
     * @type {number}
     * @memberof CronDto
     */
    retryMaxCount?: number;
    /**
     * Time in milliseconds to wait before retrying the task execution.
     * @type {number}
     * @memberof CronDto
     */
    retryWaitTime?: number;
    /**
     * Maximum allowable runtime for the task in milliseconds. Default is 10 minutes.
     * @type {number}
     * @memberof CronDto
     */
    runMaxTime?: number;
    /**
     * Identifier for the tenant to which the task belongs.
     * @type {number}
     * @memberof CronDto
     */
    tenantId?: number;
    /**
     * Identifier for the related resource.
     * @type {number}
     * @memberof CronDto
     */
    resourceId?: number;
    /**
     * JSON payload related to the task, if any.
     * @type {string}
     * @memberof CronDto
     */
    jsonPayload?: string;
}

/**
 * Check if a given object implements the CronDto interface.
 */
export function instanceOfCronDto(value: object): boolean {
    return true;
}

export function CronDtoFromJSON(json: any): CronDto {
    return CronDtoFromJSONTyped(json, false);
}

export function CronDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CronDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'host': json['host'] == null ? undefined : json['host'],
        'running': json['running'] == null ? undefined : json['running'],
        'createdAt': json['createdAt'] == null ? undefined : (new Date(json['createdAt'])),
        'executeAt': json['executeAt'] == null ? undefined : (new Date(json['executeAt'])),
        'userId': json['userId'] == null ? undefined : json['userId'],
        'executedAt': json['executedAt'] == null ? undefined : (new Date(json['executedAt'])),
        'retryCount': json['retryCount'] == null ? undefined : json['retryCount'],
        'sourceId': json['sourceId'] == null ? undefined : json['sourceId'],
        'error': json['error'] == null ? undefined : json['error'],
        'handler': json['handler'] == null ? undefined : json['handler'],
        'parallelism': json['parallelism'] == null ? undefined : json['parallelism'],
        'retryMaxCount': json['retryMaxCount'] == null ? undefined : json['retryMaxCount'],
        'retryWaitTime': json['retryWaitTime'] == null ? undefined : json['retryWaitTime'],
        'runMaxTime': json['runMaxTime'] == null ? undefined : json['runMaxTime'],
        'tenantId': json['tenantId'] == null ? undefined : json['tenantId'],
        'resourceId': json['resourceId'] == null ? undefined : json['resourceId'],
        'jsonPayload': json['jsonPayload'] == null ? undefined : json['jsonPayload'],
    };
}

export function CronDtoToJSON(value?: CronDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'host': value['host'],
        'running': value['running'],
        'createdAt': value['createdAt'] == null ? undefined : ((value['createdAt']).toISOString()),
        'executeAt': value['executeAt'] == null ? undefined : ((value['executeAt']).toISOString()),
        'userId': value['userId'],
        'executedAt': value['executedAt'] == null ? undefined : ((value['executedAt']).toISOString()),
        'retryCount': value['retryCount'],
        'sourceId': value['sourceId'],
        'error': value['error'],
        'handler': value['handler'],
        'parallelism': value['parallelism'],
        'retryMaxCount': value['retryMaxCount'],
        'retryWaitTime': value['retryWaitTime'],
        'runMaxTime': value['runMaxTime'],
        'tenantId': value['tenantId'],
        'resourceId': value['resourceId'],
        'jsonPayload': value['jsonPayload'],
    };
}

