/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * The new attributes of a hash algorithm
 * @export
 * @interface HalgorithmDto
 */
export interface HalgorithmDto {
    /**
     * The id of the hash algorithm
     * @type {number}
     * @memberof HalgorithmDto
     */
    id?: number;
    /**
     * The value of the hash algorithm
     * @type {string}
     * @memberof HalgorithmDto
     */
    alias?: string;
    /**
     * Indicates whether the hash algorithm is locked. When locked, the algorithm cannot be deleted and must be unlocked first.
     * @type {boolean}
     * @memberof HalgorithmDto
     */
    locked?: boolean;
}

/**
 * Check if a given object implements the HalgorithmDto interface.
 */
export function instanceOfHalgorithmDto(value: object): boolean {
    return true;
}

export function HalgorithmDtoFromJSON(json: any): HalgorithmDto {
    return HalgorithmDtoFromJSONTyped(json, false);
}

export function HalgorithmDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): HalgorithmDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'alias': json['alias'] == null ? undefined : json['alias'],
        'locked': json['locked'] == null ? undefined : json['locked'],
    };
}

export function HalgorithmDtoToJSON(value?: HalgorithmDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'alias': value['alias'],
        'locked': value['locked'],
    };
}

