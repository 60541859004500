import {
    TokenManagementApi,
    TokenSetupInsertRequest,
    TokenSetupSelectByAliasRequest,
    TokenSetupSelectByIdRequest,
    TokenSetupUpdateRequest
} from './generated-react-client/src/apis';
import config from './clientConfig';
import {apiCallWrapper} from '../utils/apiWrapper';

const api = new TokenManagementApi(config);

export const tokenInsert = async (requestParameters: TokenSetupInsertRequest) => {
    return apiCallWrapper(() => api.tokenSetupInsert(requestParameters));
};

export const tokenSelect = async () => {
    return apiCallWrapper(() => api.tokenSetupSelect());
};

export const tokenUpdate = async (requestParameters: TokenSetupUpdateRequest) => {
    console.log(requestParameters);
    return apiCallWrapper(() => api.tokenSetupUpdate(requestParameters));
};

export const tokenDelete = async (tokenId: number) => {
    return apiCallWrapper(() => api.tokenSetupDelete({tokenId}));
};

export const tokenSelectByAlias = async (requestParameters: TokenSetupSelectByAliasRequest) => {
    return apiCallWrapper(() => api.tokenSetupSelectByAlias(requestParameters));
};

export const tokenSelectById = async (requestParameters: TokenSetupSelectByIdRequest) => {
    return apiCallWrapper(() => api.tokenSetupSelectById(requestParameters));
};