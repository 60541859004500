/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UserDto } from './UserDto';
import {
    UserDtoFromJSON,
    UserDtoFromJSONTyped,
    UserDtoToJSON,
} from './UserDto';

/**
 * Authenticated user details
 * @export
 * @interface AuthUserDto
 */
export interface AuthUserDto {
    /**
     * 
     * @type {string}
     * @memberof AuthUserDto
     */
    token?: string;
    /**
     * 
     * @type {Date}
     * @memberof AuthUserDto
     */
    expiresAt?: Date;
    /**
     * 
     * @type {Array<UserDto>}
     * @memberof AuthUserDto
     */
    userList?: Array<UserDto>;
}

/**
 * Check if a given object implements the AuthUserDto interface.
 */
export function instanceOfAuthUserDto(value: object): boolean {
    return true;
}

export function AuthUserDtoFromJSON(json: any): AuthUserDto {
    return AuthUserDtoFromJSONTyped(json, false);
}

export function AuthUserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthUserDto {
    if (json == null) {
        return json;
    }
    return {
        
        'token': json['token'] == null ? undefined : json['token'],
        'expiresAt': json['expiresAt'] == null ? undefined : (new Date(json['expiresAt'])),
        'userList': json['userList'] == null ? undefined : ((json['userList'] as Array<any>).map(UserDtoFromJSON)),
    };
}

export function AuthUserDtoToJSON(value?: AuthUserDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'token': value['token'],
        'expiresAt': value['expiresAt'] == null ? undefined : ((value['expiresAt']).toISOString()),
        'userList': value['userList'] == null ? undefined : ((value['userList'] as Array<any>).map(UserDtoToJSON)),
    };
}

