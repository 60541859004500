/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PipelineUploadRequest
 */
export interface PipelineUploadRequest {
    /**
     * The file to be uploaded.
     * @type {Blob}
     * @memberof PipelineUploadRequest
     */
    file: Blob;
}

/**
 * Check if a given object implements the PipelineUploadRequest interface.
 */
export function instanceOfPipelineUploadRequest(value: object): boolean {
    if (!('file' in value)) return false;
    return true;
}

export function PipelineUploadRequestFromJSON(json: any): PipelineUploadRequest {
    return PipelineUploadRequestFromJSONTyped(json, false);
}

export function PipelineUploadRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PipelineUploadRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'file': json['file'],
    };
}

export function PipelineUploadRequestToJSON(value?: PipelineUploadRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'file': value['file'],
    };
}

