/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  TokenSetupDto,
} from '../models/index';
import {
    TokenSetupDtoFromJSON,
    TokenSetupDtoToJSON,
} from '../models/index';

export interface TokenSetupDeleteRequest {
    tokenId: number;
}

export interface TokenSetupInsertRequest {
    tokenSetupDto: TokenSetupDto;
}

export interface TokenSetupSelectByAliasRequest {
    alias: string;
}

export interface TokenSetupSelectByIdRequest {
    tokenId: number;
}

export interface TokenSetupUpdateRequest {
    tokenSetupDto: TokenSetupDto;
}

/**
 * 
 */
export class TokenManagementApi extends runtime.BaseAPI {

    /**
     * Deletes a security token by their unique identifier (ID) and returns the count of deleted records.
     * Delete a security token
     */
    async tokenSetupDeleteRaw(requestParameters: TokenSetupDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['tokenId'] == null) {
            throw new runtime.RequiredError(
                'tokenId',
                'Required parameter "tokenId" was null or undefined when calling tokenSetupDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/token-setup/{tokenId}`.replace(`{${"tokenId"}}`, encodeURIComponent(String(requestParameters['tokenId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Deletes a security token by their unique identifier (ID) and returns the count of deleted records.
     * Delete a security token
     */
    async tokenSetupDelete(requestParameters: TokenSetupDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.tokenSetupDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates a new security token with the given details and returns the created token information.
     * Create a new security token
     */
    async tokenSetupInsertRaw(requestParameters: TokenSetupInsertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TokenSetupDto>> {
        if (requestParameters['tokenSetupDto'] == null) {
            throw new runtime.RequiredError(
                'tokenSetupDto',
                'Required parameter "tokenSetupDto" was null or undefined when calling tokenSetupInsert().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/token-setup`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TokenSetupDtoToJSON(requestParameters['tokenSetupDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenSetupDtoFromJSON(jsonValue));
    }

    /**
     * Creates a new security token with the given details and returns the created token information.
     * Create a new security token
     */
    async tokenSetupInsert(requestParameters: TokenSetupInsertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TokenSetupDto> {
        const response = await this.tokenSetupInsertRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a list of all security tokens in the system.
     * Get all security tokens
     */
    async tokenSetupSelectRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TokenSetupDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/token-setup`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TokenSetupDtoFromJSON));
    }

    /**
     * Retrieves a list of all security tokens in the system.
     * Get all security tokens
     */
    async tokenSetupSelect(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TokenSetupDto>> {
        const response = await this.tokenSetupSelectRaw(initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a single security token by their alias.
     * Get token by alias
     */
    async tokenSetupSelectByAliasRaw(requestParameters: TokenSetupSelectByAliasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TokenSetupDto>> {
        if (requestParameters['alias'] == null) {
            throw new runtime.RequiredError(
                'alias',
                'Required parameter "alias" was null or undefined when calling tokenSetupSelectByAlias().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/token-setup/by-alias/{alias}`.replace(`{${"alias"}}`, encodeURIComponent(String(requestParameters['alias']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenSetupDtoFromJSON(jsonValue));
    }

    /**
     * Retrieves a single security token by their alias.
     * Get token by alias
     */
    async tokenSetupSelectByAlias(requestParameters: TokenSetupSelectByAliasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TokenSetupDto> {
        const response = await this.tokenSetupSelectByAliasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a single security token by their unique identifier (ID).
     * Get token by ID
     */
    async tokenSetupSelectByIdRaw(requestParameters: TokenSetupSelectByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TokenSetupDto>> {
        if (requestParameters['tokenId'] == null) {
            throw new runtime.RequiredError(
                'tokenId',
                'Required parameter "tokenId" was null or undefined when calling tokenSetupSelectById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/token-setup/{tokenId}`.replace(`{${"tokenId"}}`, encodeURIComponent(String(requestParameters['tokenId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenSetupDtoFromJSON(jsonValue));
    }

    /**
     * Retrieves a single security token by their unique identifier (ID).
     * Get token by ID
     */
    async tokenSetupSelectById(requestParameters: TokenSetupSelectByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TokenSetupDto> {
        const response = await this.tokenSetupSelectByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates an existing security token\'s details based on the provided information and returns the updated token information.
     * Update an existing security token
     */
    async tokenSetupUpdateRaw(requestParameters: TokenSetupUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TokenSetupDto>> {
        if (requestParameters['tokenSetupDto'] == null) {
            throw new runtime.RequiredError(
                'tokenSetupDto',
                'Required parameter "tokenSetupDto" was null or undefined when calling tokenSetupUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/token-setup`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: TokenSetupDtoToJSON(requestParameters['tokenSetupDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenSetupDtoFromJSON(jsonValue));
    }

    /**
     * Updates an existing security token\'s details based on the provided information and returns the updated token information.
     * Update an existing security token
     */
    async tokenSetupUpdate(requestParameters: TokenSetupUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TokenSetupDto> {
        const response = await this.tokenSetupUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
