/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Information for creating a new Share Bundle-Team link
 * @export
 * @interface ShareTeamDto
 */
export interface ShareTeamDto {
    /**
     * The unique identifier of the share bundle.
     * @type {number}
     * @memberof ShareTeamDto
     */
    shareId?: number;
    /**
     * The unique identifier of the team associated with the share bundle.
     * @type {number}
     * @memberof ShareTeamDto
     */
    teamId?: number;
}

/**
 * Check if a given object implements the ShareTeamDto interface.
 */
export function instanceOfShareTeamDto(value: object): boolean {
    return true;
}

export function ShareTeamDtoFromJSON(json: any): ShareTeamDto {
    return ShareTeamDtoFromJSONTyped(json, false);
}

export function ShareTeamDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShareTeamDto {
    if (json == null) {
        return json;
    }
    return {
        
        'shareId': json['shareId'] == null ? undefined : json['shareId'],
        'teamId': json['teamId'] == null ? undefined : json['teamId'],
    };
}

export function ShareTeamDtoToJSON(value?: ShareTeamDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'shareId': value['shareId'],
        'teamId': value['teamId'],
    };
}

