import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(Backend) // load translations using http (default public/assets/locals/en/translations)
    .use(LanguageDetector) // detect user language
    .use(initReactI18next) // pass the i18n instance to react-i18next.
    .init({
        fallbackLng: 'en', // use en if detected lng is not available
        debug: true,
        load: 'languageOnly', // Load base language only (e.g., 'en' instead of 'en-GB')
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        backend: {
            // translation file path
            loadPath: '/i18n/{{lng}}.json'
        }
    });

export default i18n;