import {
    EntityDeleteRequest,
    EntityInsertRequest,
    EntityManagementApi,
    EntityUpdateRequest,
} from './generated-react-client/src/apis/EntityManagementApi';
import config from './clientConfig';
import {apiCallWrapper} from '../utils/apiWrapper';

const api = new EntityManagementApi(config);

export const entityInsert = async (requestParameters: EntityInsertRequest) => {
    return apiCallWrapper(() => api.entityInsert(requestParameters));
};

export const entitySelect = async () => {
    return apiCallWrapper(() => api.entitySelect());
};

export const entityUpdate = async (requestParameters: EntityUpdateRequest) => {
    return apiCallWrapper(() => api.entityUpdate(requestParameters));
};

export const entityDelete = async (requestParameters: EntityDeleteRequest) => {
    return apiCallWrapper(() => api.entityDelete(requestParameters));
};