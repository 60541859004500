/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  TraceSchemaMetadataDto,
} from '../models/index';
import {
    TraceSchemaMetadataDtoFromJSON,
    TraceSchemaMetadataDtoToJSON,
} from '../models/index';

export interface TraceSchemaMetadataDeleteRequest {
    tschemaId: number;
    id: string;
}

export interface TraceSchemaMetadataInsertRequest {
    traceSchemaMetadataDto: TraceSchemaMetadataDto;
}

export interface TraceSchemaMetadataSelectByIdRequest {
    tschemaId: number;
    id: string;
}

export interface TraceSchemaMetadataSelectByOrderIndexRequest {
    tschemaId: number;
    orderIndex: number;
}

export interface TraceSchemaMetadataSelectByTschemaIdRequest {
    tschemaId: number;
}

export interface TraceSchemaMetadataUpdateRequest {
    id: string;
    traceSchemaMetadataDto: TraceSchemaMetadataDto;
}

/**
 * 
 */
export class TraceSchemaMetadataManagementApi extends runtime.BaseAPI {

    /**
     * Deletes a trace schema metadata by their unique identifier (ID) and returns the count of deleted records.
     * Delete a trace schema metadata
     */
    async traceSchemaMetadataDeleteRaw(requestParameters: TraceSchemaMetadataDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['tschemaId'] == null) {
            throw new runtime.RequiredError(
                'tschemaId',
                'Required parameter "tschemaId" was null or undefined when calling traceSchemaMetadataDelete().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling traceSchemaMetadataDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/tschema_metadata/{tschemaId}/{id}`.replace(`{${"tschemaId"}}`, encodeURIComponent(String(requestParameters['tschemaId']))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Deletes a trace schema metadata by their unique identifier (ID) and returns the count of deleted records.
     * Delete a trace schema metadata
     */
    async traceSchemaMetadataDelete(requestParameters: TraceSchemaMetadataDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.traceSchemaMetadataDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates a new trace schema medatadat with the given details and returns the created tschema_metadata.
     * Create a new tschema_metadata
     */
    async traceSchemaMetadataInsertRaw(requestParameters: TraceSchemaMetadataInsertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TraceSchemaMetadataDto>> {
        if (requestParameters['traceSchemaMetadataDto'] == null) {
            throw new runtime.RequiredError(
                'traceSchemaMetadataDto',
                'Required parameter "traceSchemaMetadataDto" was null or undefined when calling traceSchemaMetadataInsert().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/tschema_metadata`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TraceSchemaMetadataDtoToJSON(requestParameters['traceSchemaMetadataDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TraceSchemaMetadataDtoFromJSON(jsonValue));
    }

    /**
     * Creates a new trace schema medatadat with the given details and returns the created tschema_metadata.
     * Create a new tschema_metadata
     */
    async traceSchemaMetadataInsert(requestParameters: TraceSchemaMetadataInsertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TraceSchemaMetadataDto> {
        const response = await this.traceSchemaMetadataInsertRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a single trace schema by its trace schema identifier and identifier.
     * Get trace schema data by trace schema ID and id
     */
    async traceSchemaMetadataSelectByIdRaw(requestParameters: TraceSchemaMetadataSelectByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TraceSchemaMetadataDto>> {
        if (requestParameters['tschemaId'] == null) {
            throw new runtime.RequiredError(
                'tschemaId',
                'Required parameter "tschemaId" was null or undefined when calling traceSchemaMetadataSelectById().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling traceSchemaMetadataSelectById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/tschema_metadata/{tschemaId}/{id}`.replace(`{${"tschemaId"}}`, encodeURIComponent(String(requestParameters['tschemaId']))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TraceSchemaMetadataDtoFromJSON(jsonValue));
    }

    /**
     * Retrieves a single trace schema by its trace schema identifier and identifier.
     * Get trace schema data by trace schema ID and id
     */
    async traceSchemaMetadataSelectById(requestParameters: TraceSchemaMetadataSelectByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TraceSchemaMetadataDto> {
        const response = await this.traceSchemaMetadataSelectByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a single trace schema by its trace schema metadata identifier and urn.
     * Get trace schema data by trace schema ID and orderIndex
     */
    async traceSchemaMetadataSelectByOrderIndexRaw(requestParameters: TraceSchemaMetadataSelectByOrderIndexRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TraceSchemaMetadataDto>> {
        if (requestParameters['tschemaId'] == null) {
            throw new runtime.RequiredError(
                'tschemaId',
                'Required parameter "tschemaId" was null or undefined when calling traceSchemaMetadataSelectByOrderIndex().'
            );
        }

        if (requestParameters['orderIndex'] == null) {
            throw new runtime.RequiredError(
                'orderIndex',
                'Required parameter "orderIndex" was null or undefined when calling traceSchemaMetadataSelectByOrderIndex().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/tschema_metadata/by_order/{tschemaId}/{orderIndex}`.replace(`{${"tschemaId"}}`, encodeURIComponent(String(requestParameters['tschemaId']))).replace(`{${"orderIndex"}}`, encodeURIComponent(String(requestParameters['orderIndex']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TraceSchemaMetadataDtoFromJSON(jsonValue));
    }

    /**
     * Retrieves a single trace schema by its trace schema metadata identifier and urn.
     * Get trace schema data by trace schema ID and orderIndex
     */
    async traceSchemaMetadataSelectByOrderIndex(requestParameters: TraceSchemaMetadataSelectByOrderIndexRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TraceSchemaMetadataDto> {
        const response = await this.traceSchemaMetadataSelectByOrderIndexRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves all trace schema metadata associated with a specific trace schema.
     * Get trace schema metadata by trace Schema Id
     */
    async traceSchemaMetadataSelectByTschemaIdRaw(requestParameters: TraceSchemaMetadataSelectByTschemaIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TraceSchemaMetadataDto>>> {
        if (requestParameters['tschemaId'] == null) {
            throw new runtime.RequiredError(
                'tschemaId',
                'Required parameter "tschemaId" was null or undefined when calling traceSchemaMetadataSelectByTschemaId().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/tschema_metadata/by-tschema/{tschemaId}`.replace(`{${"tschemaId"}}`, encodeURIComponent(String(requestParameters['tschemaId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TraceSchemaMetadataDtoFromJSON));
    }

    /**
     * Retrieves all trace schema metadata associated with a specific trace schema.
     * Get trace schema metadata by trace Schema Id
     */
    async traceSchemaMetadataSelectByTschemaId(requestParameters: TraceSchemaMetadataSelectByTschemaIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TraceSchemaMetadataDto>> {
        const response = await this.traceSchemaMetadataSelectByTschemaIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates an existing trace schema metadata\'s details based on the provided information and returns the updated trace schema metadata information.
     * Update an existing trace schema metadata
     */
    async traceSchemaMetadataUpdateRaw(requestParameters: TraceSchemaMetadataUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling traceSchemaMetadataUpdate().'
            );
        }

        if (requestParameters['traceSchemaMetadataDto'] == null) {
            throw new runtime.RequiredError(
                'traceSchemaMetadataDto',
                'Required parameter "traceSchemaMetadataDto" was null or undefined when calling traceSchemaMetadataUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/tschema_metadata/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: TraceSchemaMetadataDtoToJSON(requestParameters['traceSchemaMetadataDto']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Updates an existing trace schema metadata\'s details based on the provided information and returns the updated trace schema metadata information.
     * Update an existing trace schema metadata
     */
    async traceSchemaMetadataUpdate(requestParameters: TraceSchemaMetadataUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.traceSchemaMetadataUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
