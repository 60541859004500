/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { HashDto } from './HashDto';
import {
    HashDtoFromJSON,
    HashDtoFromJSONTyped,
    HashDtoToJSON,
} from './HashDto';
import type { ShareDto } from './ShareDto';
import {
    ShareDtoFromJSON,
    ShareDtoFromJSONTyped,
    ShareDtoToJSON,
} from './ShareDto';
import type { UserDto } from './UserDto';
import {
    UserDtoFromJSON,
    UserDtoFromJSONTyped,
    UserDtoToJSON,
} from './UserDto';

/**
 * Trace data to create a new trace.
 * @export
 * @interface TraceDto
 */
export interface TraceDto {
    /**
     * The unique identifier of the trace item.
     * @type {number}
     * @memberof TraceDto
     */
    id?: number;
    /**
     * The identifier of the pipeline to which this trace item belongs.
     * @type {number}
     * @memberof TraceDto
     */
    pipelineId?: number;
    /**
     * Uniform Resource Name (URN) of the trace schema, providing a unique reference.
     * @type {string}
     * @memberof TraceDto
     */
    urn?: string;
    /**
     * The alias of te step defined within the trace schema.
     * @type {string}
     * @memberof TraceDto
     */
    batchAlias?: string;
    /**
     * The URL associated with the trace, providing access to further information or related resources.
     * @type {string}
     * @memberof TraceDto
     */
    url?: string;
    /**
     * A specific title of the item, providing contextual or descriptive information about the trace.
     * @type {string}
     * @memberof TraceDto
     */
    title?: string;
    /**
     * Metadata related to the trace, which may include any additional data that supports the trace's purpose and usability.
     * @type {string}
     * @memberof TraceDto
     */
    data?: string;
    /**
     * Creation date
     * @type {Date}
     * @memberof TraceDto
     */
    createdAt?: Date;
    /**
     * 
     * @type {UserDto}
     * @memberof TraceDto
     */
    creator?: UserDto;
    /**
     * 
     * @type {number}
     * @memberof TraceDto
     */
    manifestId?: number;
    /**
     * The status of the trace
     * @type {string}
     * @memberof TraceDto
     */
    status?: TraceDtoStatusEnum;
    /**
     * ???
     * @type {Array<HashDto>}
     * @memberof TraceDto
     */
    hashList?: Array<HashDto>;
    /**
     * A list of share bundles that this item belongs to, which can be empty if the item is not associated with any share bundles.
     * @type {Array<ShareDto>}
     * @memberof TraceDto
     */
    shareList?: Array<ShareDto>;
}


/**
 * @export
 */
export const TraceDtoStatusEnum = {
    Created: 'CREATED',
    Started: 'STARTED',
    Suspended: 'SUSPENDED',
    Canceled: 'CANCELED',
    Eronous: 'ERONOUS',
    Completed: 'COMPLETED',
    Deleted: 'DELETED'
} as const;
export type TraceDtoStatusEnum = typeof TraceDtoStatusEnum[keyof typeof TraceDtoStatusEnum];


/**
 * Check if a given object implements the TraceDto interface.
 */
export function instanceOfTraceDto(value: object): boolean {
    return true;
}

export function TraceDtoFromJSON(json: any): TraceDto {
    return TraceDtoFromJSONTyped(json, false);
}

export function TraceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TraceDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'pipelineId': json['pipelineId'] == null ? undefined : json['pipelineId'],
        'urn': json['urn'] == null ? undefined : json['urn'],
        'batchAlias': json['batchAlias'] == null ? undefined : json['batchAlias'],
        'url': json['url'] == null ? undefined : json['url'],
        'title': json['title'] == null ? undefined : json['title'],
        'data': json['data'] == null ? undefined : json['data'],
        'createdAt': json['createdAt'] == null ? undefined : (new Date(json['createdAt'])),
        'creator': json['creator'] == null ? undefined : UserDtoFromJSON(json['creator']),
        'manifestId': json['manifestId'] == null ? undefined : json['manifestId'],
        'status': json['status'] == null ? undefined : json['status'],
        'hashList': json['hashList'] == null ? undefined : ((json['hashList'] as Array<any>).map(HashDtoFromJSON)),
        'shareList': json['shareList'] == null ? undefined : ((json['shareList'] as Array<any>).map(ShareDtoFromJSON)),
    };
}

export function TraceDtoToJSON(value?: TraceDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'pipelineId': value['pipelineId'],
        'urn': value['urn'],
        'batchAlias': value['batchAlias'],
        'url': value['url'],
        'title': value['title'],
        'data': value['data'],
        'createdAt': value['createdAt'] == null ? undefined : ((value['createdAt']).toISOString()),
        'creator': UserDtoToJSON(value['creator']),
        'manifestId': value['manifestId'],
        'status': value['status'],
        'hashList': value['hashList'] == null ? undefined : ((value['hashList'] as Array<any>).map(HashDtoToJSON)),
        'shareList': value['shareList'] == null ? undefined : ((value['shareList'] as Array<any>).map(ShareDtoToJSON)),
    };
}

