/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Notification details to update an existing record
 * @export
 * @interface NotificationDto
 */
export interface NotificationDto {
    /**
     * The unique identifier of the notification.
     * @type {number}
     * @memberof NotificationDto
     */
    id?: number;
    /**
     * The channel through which the notification is sent, e.g., EMAIL, SMS, API.
     * @type {string}
     * @memberof NotificationDto
     */
    channelType?: NotificationDtoChannelTypeEnum;
    /**
     * The type of event that triggered the notification, e.g., ACCOUNT_CREATION, PASSWORD_RESET.
     * @type {string}
     * @memberof NotificationDto
     */
    eventType?: NotificationDtoEventTypeEnum;
    /**
     * The database identifier of the template used to format this notification's content.
     * @type {number}
     * @memberof NotificationDto
     */
    templateId?: number;
    /**
     * The identifier of the tenant to which this notification belongs.
     * @type {number}
     * @memberof NotificationDto
     */
    tenantId?: number;
    /**
     * The subject line of the notification, typically used in email.
     * @type {string}
     * @memberof NotificationDto
     */
    subject?: string;
    /**
     * The main content or body of the notification, containing detailed information or instructions.
     * @type {string}
     * @memberof NotificationDto
     */
    body?: string;
    /**
     * A JSON string containing additional context data that was used to generate the notification content.
     * @type {string}
     * @memberof NotificationDto
     */
    context?: string;
    /**
     * The user ID of the notification's primary recipient.
     * @type {number}
     * @memberof NotificationDto
     */
    toUserId?: number;
    /**
     * An optional secondary email address that receives a copy of the notification.
     * @type {string}
     * @memberof NotificationDto
     */
    ccUserEmail?: string;
    /**
     * The current status of the notification, indicating whether it's been created, sent, or has failed.
     * @type {string}
     * @memberof NotificationDto
     */
    status?: NotificationDtoStatusEnum;
    /**
     * The timestamp marking when the notification was initially created.
     * @type {Date}
     * @memberof NotificationDto
     */
    createdAt?: Date;
    /**
     * The timestamp of the last activity on the notification, such as a send attempt or status update.
     * @type {Date}
     * @memberof NotificationDto
     */
    lastActivityAt?: Date;
    /**
     * The number of attempts made to send the notification, which may increase following delivery failures.
     * @type {string}
     * @memberof NotificationDto
     */
    trial?: string;
    /**
     * The error message that appears if an exception occurs during the notification sending process.
     * @type {string}
     * @memberof NotificationDto
     */
    error?: string;
}


/**
 * @export
 */
export const NotificationDtoChannelTypeEnum = {
    Email: 'EMAIL',
    Sms: 'SMS',
    Api: 'API'
} as const;
export type NotificationDtoChannelTypeEnum = typeof NotificationDtoChannelTypeEnum[keyof typeof NotificationDtoChannelTypeEnum];

/**
 * @export
 */
export const NotificationDtoEventTypeEnum = {
    Invitation: 'INVITATION',
    SignupSuccessful: 'SIGNUP_SUCCESSFUL',
    ResetPassword: 'RESET_PASSWORD',
    Otp: 'OTP',
    Welcome: 'WELCOME',
    Info: 'INFO',
    Alert: 'ALERT'
} as const;
export type NotificationDtoEventTypeEnum = typeof NotificationDtoEventTypeEnum[keyof typeof NotificationDtoEventTypeEnum];

/**
 * @export
 */
export const NotificationDtoStatusEnum = {
    Created: 'CREATED',
    ToSend: 'TO_SEND',
    Sending: 'SENDING',
    Sent: 'SENT',
    Failed: 'FAILED'
} as const;
export type NotificationDtoStatusEnum = typeof NotificationDtoStatusEnum[keyof typeof NotificationDtoStatusEnum];


/**
 * Check if a given object implements the NotificationDto interface.
 */
export function instanceOfNotificationDto(value: object): boolean {
    return true;
}

export function NotificationDtoFromJSON(json: any): NotificationDto {
    return NotificationDtoFromJSONTyped(json, false);
}

export function NotificationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'channelType': json['channelType'] == null ? undefined : json['channelType'],
        'eventType': json['eventType'] == null ? undefined : json['eventType'],
        'templateId': json['templateId'] == null ? undefined : json['templateId'],
        'tenantId': json['tenantId'] == null ? undefined : json['tenantId'],
        'subject': json['subject'] == null ? undefined : json['subject'],
        'body': json['body'] == null ? undefined : json['body'],
        'context': json['context'] == null ? undefined : json['context'],
        'toUserId': json['toUserId'] == null ? undefined : json['toUserId'],
        'ccUserEmail': json['ccUserEmail'] == null ? undefined : json['ccUserEmail'],
        'status': json['status'] == null ? undefined : json['status'],
        'createdAt': json['createdAt'] == null ? undefined : (new Date(json['createdAt'])),
        'lastActivityAt': json['lastActivityAt'] == null ? undefined : (new Date(json['lastActivityAt'])),
        'trial': json['trial'] == null ? undefined : json['trial'],
        'error': json['error'] == null ? undefined : json['error'],
    };
}

export function NotificationDtoToJSON(value?: NotificationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'channelType': value['channelType'],
        'eventType': value['eventType'],
        'templateId': value['templateId'],
        'tenantId': value['tenantId'],
        'subject': value['subject'],
        'body': value['body'],
        'context': value['context'],
        'toUserId': value['toUserId'],
        'ccUserEmail': value['ccUserEmail'],
        'status': value['status'],
        'createdAt': value['createdAt'] == null ? undefined : ((value['createdAt']).toISOString()),
        'lastActivityAt': value['lastActivityAt'] == null ? undefined : ((value['lastActivityAt']).toISOString()),
        'trial': value['trial'],
        'error': value['error'],
    };
}

