/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  TraceSchemaDto,
  TraceSchemaLinkDto,
} from '../models/index';
import {
    TraceSchemaDtoFromJSON,
    TraceSchemaDtoToJSON,
    TraceSchemaLinkDtoFromJSON,
    TraceSchemaLinkDtoToJSON,
} from '../models/index';

export interface TschemaDeleteRequest {
    pschemaId: number;
    urn: string;
}

export interface TschemaDeleteByPschemaRequest {
    pschemaId: number;
}

export interface TschemaInsertRequest {
    traceSchemaDto: TraceSchemaDto;
}

export interface TschemaLinkDeleteRequest {
    traceSchemaLinkDto: TraceSchemaLinkDto;
}

export interface TschemaLinkInsertRequest {
    traceSchemaLinkDto: TraceSchemaLinkDto;
}

export interface TschemaLinkSelectByPschemaRequest {
    pschemaId: number;
}

export interface TschemaSelectByRequest {
    pschemaId: number;
    urn: string;
}

export interface TschemaSelectByPschemaRequest {
    pschemaId: number;
}

export interface TschemaUpdateRequest {
    traceSchemaDto: TraceSchemaDto;
}

/**
 * 
 */
export class TraceSchemaManagementApi extends runtime.BaseAPI {

    /**
     * Deletes a trace schema by their unique identifier (ID) and returns the count of deleted records.
     * Delete a trace schema
     */
    async tschemaDeleteRaw(requestParameters: TschemaDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['pschemaId'] == null) {
            throw new runtime.RequiredError(
                'pschemaId',
                'Required parameter "pschemaId" was null or undefined when calling tschemaDelete().'
            );
        }

        if (requestParameters['urn'] == null) {
            throw new runtime.RequiredError(
                'urn',
                'Required parameter "urn" was null or undefined when calling tschemaDelete().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['urn'] != null) {
            queryParameters['urn'] = requestParameters['urn'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/tschema/{pschemaId}`.replace(`{${"pschemaId"}}`, encodeURIComponent(String(requestParameters['pschemaId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Deletes a trace schema by their unique identifier (ID) and returns the count of deleted records.
     * Delete a trace schema
     */
    async tschemaDelete(requestParameters: TschemaDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.tschemaDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes a trace schema by their unique identifier (ID) and returns the count of deleted records.
     * Delete a trace schema
     */
    async tschemaDeleteByPschemaRaw(requestParameters: TschemaDeleteByPschemaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['pschemaId'] == null) {
            throw new runtime.RequiredError(
                'pschemaId',
                'Required parameter "pschemaId" was null or undefined when calling tschemaDeleteByPschema().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/tschema/by-pschema/{pschemaId}`.replace(`{${"pschemaId"}}`, encodeURIComponent(String(requestParameters['pschemaId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Deletes a trace schema by their unique identifier (ID) and returns the count of deleted records.
     * Delete a trace schema
     */
    async tschemaDeleteByPschema(requestParameters: TschemaDeleteByPschemaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.tschemaDeleteByPschemaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates a new tschema with the given details and returns the created tschema.
     * Create a new tschema
     */
    async tschemaInsertRaw(requestParameters: TschemaInsertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TraceSchemaDto>> {
        if (requestParameters['traceSchemaDto'] == null) {
            throw new runtime.RequiredError(
                'traceSchemaDto',
                'Required parameter "traceSchemaDto" was null or undefined when calling tschemaInsert().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/tschema`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TraceSchemaDtoToJSON(requestParameters['traceSchemaDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TraceSchemaDtoFromJSON(jsonValue));
    }

    /**
     * Creates a new tschema with the given details and returns the created tschema.
     * Create a new tschema
     */
    async tschemaInsert(requestParameters: TschemaInsertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TraceSchemaDto> {
        const response = await this.tschemaInsertRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes a trace schema link entry from the system.
     * Delete a trace schema link
     */
    async tschemaLinkDeleteRaw(requestParameters: TschemaLinkDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['traceSchemaLinkDto'] == null) {
            throw new runtime.RequiredError(
                'traceSchemaLinkDto',
                'Required parameter "traceSchemaLinkDto" was null or undefined when calling tschemaLinkDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/tschema/tschema`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: TraceSchemaLinkDtoToJSON(requestParameters['traceSchemaLinkDto']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Deletes a trace schema link entry from the system.
     * Delete a trace schema link
     */
    async tschemaLinkDelete(requestParameters: TschemaLinkDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.tschemaLinkDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates a new trace schema link entry in the system.
     * Insert a new trace schema link
     */
    async tschemaLinkInsertRaw(requestParameters: TschemaLinkInsertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TraceSchemaLinkDto>> {
        if (requestParameters['traceSchemaLinkDto'] == null) {
            throw new runtime.RequiredError(
                'traceSchemaLinkDto',
                'Required parameter "traceSchemaLinkDto" was null or undefined when calling tschemaLinkInsert().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/tschema/tschema`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TraceSchemaLinkDtoToJSON(requestParameters['traceSchemaLinkDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TraceSchemaLinkDtoFromJSON(jsonValue));
    }

    /**
     * Creates a new trace schema link entry in the system.
     * Insert a new trace schema link
     */
    async tschemaLinkInsert(requestParameters: TschemaLinkInsertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TraceSchemaLinkDto> {
        const response = await this.tschemaLinkInsertRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves all trace schema links associated with a specific pipeline schema.
     * Get trace schema links by pipeline schema ID
     */
    async tschemaLinkSelectByPschemaRaw(requestParameters: TschemaLinkSelectByPschemaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TraceSchemaLinkDto>>> {
        if (requestParameters['pschemaId'] == null) {
            throw new runtime.RequiredError(
                'pschemaId',
                'Required parameter "pschemaId" was null or undefined when calling tschemaLinkSelectByPschema().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/tschema/tschema/by-pschema/{pschemaId}`.replace(`{${"pschemaId"}}`, encodeURIComponent(String(requestParameters['pschemaId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TraceSchemaLinkDtoFromJSON));
    }

    /**
     * Retrieves all trace schema links associated with a specific pipeline schema.
     * Get trace schema links by pipeline schema ID
     */
    async tschemaLinkSelectByPschema(requestParameters: TschemaLinkSelectByPschemaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TraceSchemaLinkDto>> {
        const response = await this.tschemaLinkSelectByPschemaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a single trace schema by its pipeline schema identifier and urn.
     * Get trace schema by pipeline schema ID and urn
     */
    async tschemaSelectByRaw(requestParameters: TschemaSelectByRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TraceSchemaDto>> {
        if (requestParameters['pschemaId'] == null) {
            throw new runtime.RequiredError(
                'pschemaId',
                'Required parameter "pschemaId" was null or undefined when calling tschemaSelectBy().'
            );
        }

        if (requestParameters['urn'] == null) {
            throw new runtime.RequiredError(
                'urn',
                'Required parameter "urn" was null or undefined when calling tschemaSelectBy().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['urn'] != null) {
            queryParameters['urn'] = requestParameters['urn'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/tschema/{pschemaId}`.replace(`{${"pschemaId"}}`, encodeURIComponent(String(requestParameters['pschemaId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TraceSchemaDtoFromJSON(jsonValue));
    }

    /**
     * Retrieves a single trace schema by its pipeline schema identifier and urn.
     * Get trace schema by pipeline schema ID and urn
     */
    async tschemaSelectBy(requestParameters: TschemaSelectByRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TraceSchemaDto> {
        const response = await this.tschemaSelectByRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a list of all trace schemas in the system.
     * Get all trace schemas related to the specific pipeline schema.
     */
    async tschemaSelectByPschemaRaw(requestParameters: TschemaSelectByPschemaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TraceSchemaDto>>> {
        if (requestParameters['pschemaId'] == null) {
            throw new runtime.RequiredError(
                'pschemaId',
                'Required parameter "pschemaId" was null or undefined when calling tschemaSelectByPschema().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/tschema/by-pschema/{pschemaId}`.replace(`{${"pschemaId"}}`, encodeURIComponent(String(requestParameters['pschemaId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TraceSchemaDtoFromJSON));
    }

    /**
     * Retrieves a list of all trace schemas in the system.
     * Get all trace schemas related to the specific pipeline schema.
     */
    async tschemaSelectByPschema(requestParameters: TschemaSelectByPschemaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TraceSchemaDto>> {
        const response = await this.tschemaSelectByPschemaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates an existing trace schema\'s details based on the provided information and returns the updated trace schema information.
     * Update an existing trace schema
     */
    async tschemaUpdateRaw(requestParameters: TschemaUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TraceSchemaDto>> {
        if (requestParameters['traceSchemaDto'] == null) {
            throw new runtime.RequiredError(
                'traceSchemaDto',
                'Required parameter "traceSchemaDto" was null or undefined when calling tschemaUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/tschema`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: TraceSchemaDtoToJSON(requestParameters['traceSchemaDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TraceSchemaDtoFromJSON(jsonValue));
    }

    /**
     * Updates an existing trace schema\'s details based on the provided information and returns the updated trace schema information.
     * Update an existing trace schema
     */
    async tschemaUpdate(requestParameters: TschemaUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TraceSchemaDto> {
        const response = await this.tschemaUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
