import {BasicAuthenticationManagementApi} from './generated-react-client/src/apis/BasicAuthenticationManagementApi';
import config from './clientConfig';

const api = new BasicAuthenticationManagementApi(config);

export const authenticate = async (email: string, password: string) => {
    const response = await api.authenticate({baRequest: {email, password}});
    console.log('Authentication successful, token:', response);
    localStorage.setItem('expirationIn', '' + response.expiresAt!);
    localStorage.setItem('userId', '' + response.userList![0].id)
    localStorage.setItem('userFullName', response.userList![0].firstName + ' ' + response.userList![0].lastName)
    return localStorage.setItem('authToken', response.token!); // Store the UUID token
};

export const logoff = async () => {
    const response = await api.logoff();
    console.log('Logoff successful, token:', response);
    return localStorage.clear();
};

export const resetPassword = async (email: string) => {
    const requestParams = {
        body: email
    }
    const response = await api.resetPassword(requestParams);
    console.log('Logoff successful, token:', response);
    return localStorage.clear();
};

export const handleResetPassword = async (token: string, password: string) => {

    console.log(token, password);
    const baRequest = {
        password: password
    }

    const requestParams = {
        baRequest: baRequest
    }
    const response = await api.handleResetPasswordRaw(requestParams);
    console.log('Logoff successful, token:', response);
    return localStorage.clear();
};