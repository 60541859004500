/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SetupDto } from './SetupDto';
import {
    SetupDtoFromJSON,
    SetupDtoFromJSONTyped,
    SetupDtoToJSON,
} from './SetupDto';

/**
 * Carries the search results along with pagination details.
 * @export
 * @interface SetupView
 */
export interface SetupView {
    /**
     * The total number of elements available for the query, which may be greater than the number of elements in the list.
     * @type {number}
     * @memberof SetupView
     */
    count?: number;
    /**
     * The position of the first element in the list within the overall query results.
     * @type {number}
     * @memberof SetupView
     */
    offset?: number;
    /**
     * The maximum number of elements that can be returned in a single response.
     * @type {number}
     * @memberof SetupView
     */
    limit?: number;
    /**
     * The list of setups representing the actual data returned for the current page of results.
     * @type {Array<SetupDto>}
     * @memberof SetupView
     */
    list?: Array<SetupDto>;
}

/**
 * Check if a given object implements the SetupView interface.
 */
export function instanceOfSetupView(value: object): boolean {
    return true;
}

export function SetupViewFromJSON(json: any): SetupView {
    return SetupViewFromJSONTyped(json, false);
}

export function SetupViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetupView {
    if (json == null) {
        return json;
    }
    return {
        
        'count': json['count'] == null ? undefined : json['count'],
        'offset': json['offset'] == null ? undefined : json['offset'],
        'limit': json['limit'] == null ? undefined : json['limit'],
        'list': json['list'] == null ? undefined : ((json['list'] as Array<any>).map(SetupDtoFromJSON)),
    };
}

export function SetupViewToJSON(value?: SetupView | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'count': value['count'],
        'offset': value['offset'],
        'limit': value['limit'],
        'list': value['list'] == null ? undefined : ((value['list'] as Array<any>).map(SetupDtoToJSON)),
    };
}

