import React, {ChangeEvent, useEffect, useState} from 'react';
import {
    exportCSVFile,
    translationDelete,
    translationInsert,
    translationSelectWithTenant0,
    translationUpdate
} from '../../../api/TranslationService';
import {
    ExportCSVFileRequest,
    TranslationDeleteRequest,
    TranslationDto,
    TranslationDtoCategoryEnum,
    TranslationDtoLanguageEnum,
    TranslationInsertRequest,
    TranslationUpdateRequest
} from '../../../api/generated-react-client/src';
import Modal from '../../common/Modal';
import styles from './TranslationForm.module.css';
import {MdOutlineCreate, MdOutlineDeleteForever, MdOutlinePlaylistAdd} from 'react-icons/md';
import Flag from 'react-world-flags';

const languageToCountryCode: Record<TranslationDtoLanguageEnum, string> = {
    [TranslationDtoLanguageEnum.En]: 'GB',
    [TranslationDtoLanguageEnum.Fr]: 'FR',
    [TranslationDtoLanguageEnum.De]: 'DE',
    [TranslationDtoLanguageEnum.Pl]: 'PL',
    [TranslationDtoLanguageEnum.Sv]: 'SV',
    // Add other languages and their corresponding icons
};

const TranslationForm: React.FC = () => {
    const [translations, setTranslations] = useState<ExtendedTranslationDto[]>([]);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState<TranslationDtoCategoryEnum>(TranslationDtoCategoryEnum.Anomaly);
    const [selectedLanguage, setSelectedLanguage] = useState<TranslationDtoLanguageEnum>(TranslationDtoLanguageEnum.En);
    const [referenceLanguage, setReferenceLanguage] = useState<TranslationDtoLanguageEnum | null>(null);
    const [filter, setFilter] = useState<'all' | 'default' | 'specific'>('all');
    const [createTranslationFormData, setCreateTranslationFormData] = useState<CreateTranslationFormData>({
        tenantId: 0,
        category: TranslationDtoCategoryEnum.Anomaly,
        language: TranslationDtoLanguageEnum.En,
        labelId: 0,
        label: ''
    });
    const [error, setError] = useState<string | null>(null);
    const [showPopup, setShowPopup] = useState<boolean>(false);

    useEffect(() => {
        fetchTranslations();
    }, [selectedCategory, selectedLanguage, referenceLanguage]);

    const fetchTranslations = async () => {
        try {
            const response = await translationSelectWithTenant0({
                category: selectedCategory,
                language: selectedLanguage
            });
            let translationsWithReference: ExtendedTranslationDto[] = response as ExtendedTranslationDto[];

            if (referenceLanguage) {
                const referenceTranslations = await fetchReferenceTranslations(referenceLanguage);
                translationsWithReference = translationsWithReference.map(translation => {
                    const referenceTranslation = referenceTranslations.find(ref => ref.labelId === translation.labelId);
                    return {
                        ...translation,
                        referenceLabel: referenceTranslation ? referenceTranslation.label : null
                    };
                });
            }
            setTranslations(translationsWithReference);
        } catch (error) {
            setError('Failed to fetch translations');
            console.error(error);
        }
    };

    const fetchReferenceTranslations = async (referenceLanguage: TranslationDtoLanguageEnum) => {
        try {
            const response = await translationSelectWithTenant0({
                category: selectedCategory,
                language: referenceLanguage
            });
            return response;
        } catch (error) {
            setError('Failed to fetch reference translations');
            console.error(error);
            return [];
        }
    };

    const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {name, value} = event.currentTarget;
        setCreateTranslationFormData(prevFormData => ({...prevFormData, [name]: value}));
    };

    const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const {name, value} = event.target;
        setCreateTranslationFormData(prevFormData => ({...prevFormData, [name]: value}));
    };

    const handleCreate = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const requestParameters: TranslationInsertRequest = {translationDto: {...createTranslationFormData}};
        setError(null);
        try {
            await translationInsert(requestParameters);
            await fetchTranslations();
            setIsCreateModalOpen(false);
        } catch (error) {
            setError((error as Error).message);
            setShowPopup(true);
        }
    };

    const handleEditClick = async (translation: TranslationDto, field: keyof TranslationDto) => {
        const currentValue = translation[field]?.toString() || '';
        const newValue = prompt(`Edit ${field}`, currentValue);
        if (newValue !== null) {
            const updatedTranslation = {...translation, [field]: newValue};
            const requestParameters: TranslationUpdateRequest = {translationDto: updatedTranslation};
            await translationUpdate(requestParameters);
            setTranslations(translations.map(t => (t.labelId === translation.labelId ? updatedTranslation : t)));
        }
    };

    const handleDelete = async (translation: TranslationDto) => {
        if (window.confirm('Are you sure you want to delete this translation?')) {
            try {
                const requestParameters: TranslationDeleteRequest = {
                    category: translation.category!,
                    language: translation.language!,
                    labelId: translation.labelId!
                };
                await translationDelete(requestParameters);
                await fetchTranslations();
            } catch (error) {
                setError('Failed to delete translation');
                console.error(error);
            }
        }
    };

    const handleCreateForTenant = async (translation: TranslationDto) => {
        const requestParameters: TranslationInsertRequest = {translationDto: {...translation, tenantId: 1}};

        try {
            await translationInsert(requestParameters);
            await fetchTranslations();
        } catch (error) {
            setError('Error inserting translation');
            console.error(error);
        }
    };

    const handleExportCSV = async () => {
        try {
            const requestParameters: ExportCSVFileRequest = {
                templateAlias: "",
                languageId: selectedLanguage
            };
            const csvBlob = await exportCSVFile(requestParameters);
            const url = window.URL.createObjectURL(new Blob([csvBlob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `translation_${selectedLanguage}.json`); // fixme: add tenant_id
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            setError('Failed to export CSV');
            console.error(error);
        }
    };

    const filteredTranslations = translations.filter(translation => {
        if (filter === 'all') return true;
        if (filter === 'default') return translation.tenantId === 0;
        if (filter === 'specific') return translation.tenantId !== 0;
        return true;
    });

    return (
        <div className="formList">
            <div className="headerContainer">
                <div className={styles.headerLeft}>
                    <b>Translation list</b>
                    <div className={styles.spacer}></div>
                    <div className="formGroup">
                        <label>Filter:</label>
                        <select value={filter}
                                onChange={(e) => setFilter(e.target.value as 'all' | 'default' | 'specific')}>
                            <option value="all">All</option>
                            <option value="default">Default</option>
                            <option value="specific">Specific</option>
                        </select>
                    </div>
                </div>
                <div className="formGroup">
                    <select className={styles.select} value={selectedCategory}
                            onChange={(e) => setSelectedCategory(e.target.value as TranslationDtoCategoryEnum)}>
                        {Object.values(TranslationDtoCategoryEnum).map(category => (
                            <option key={category} value={category}>{category}</option>
                        ))}
                    </select>
                    <select className={styles.select} value={selectedLanguage}
                            onChange={(e) => setSelectedLanguage(e.target.value as TranslationDtoLanguageEnum)}>
                        {Object.values(TranslationDtoLanguageEnum).map(language => (
                            <option key={language} value={language}>{language}</option>
                        ))}
                    </select>
                    <select className={styles.select} value={referenceLanguage || ''}
                            onChange={(e) => setReferenceLanguage(e.target.value as TranslationDtoLanguageEnum !== selectedLanguage ? e.target.value as TranslationDtoLanguageEnum : null)}>
                        <option value="">Select reference language</option>
                        {Object.values(TranslationDtoLanguageEnum).map(language => (
                            <option key={language} value={language}
                                    disabled={language === selectedLanguage}>{language}</option>
                        ))}
                    </select>
                    <button onClick={() => setIsCreateModalOpen(true)}>Create</button>
                    <button onClick={handleExportCSV}>Export as CSV</button>
                </div>
                <Modal isOpen={isCreateModalOpen} onClose={() => setIsCreateModalOpen(false)}>
                    <div>
                        <form onSubmit={handleCreate}>
                            <div className="formGroup">
                                <label>Category</label>
                                <select className={styles.select} name="category"
                                        value={createTranslationFormData.category} onChange={handleSelectChange}>
                                    {Object.values(TranslationDtoCategoryEnum).map(category => (
                                        <option key={category} value={category}>{category}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="formGroup">
                                <label>Language</label>
                                <select className={styles.select} name="language"
                                        value={createTranslationFormData.language} onChange={handleSelectChange}>
                                    {Object.values(TranslationDtoLanguageEnum).map(language => (
                                        <option key={language} value={language}>{language}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="formGroup">
                                <label>Label ID</label>
                                <input type="number" name="labelId" value={createTranslationFormData.labelId}
                                       onChange={handleInputChange}/>
                            </div>
                            <div className="formGroup">
                                <label>Label</label>
                                <input type="text" name="label" value={createTranslationFormData.label}
                                       onChange={handleInputChange}/>
                            </div>
                            <div className="horizontalButtonContainer">
                                <button type="submit">Create</button>
                            </div>
                        </form>
                    </div>
                </Modal>
            </div>
            {showPopup && error !== null && (
                <div className="errorModal">
                    <div className="errorModalContent">
                        <p>Error: {error}</p>
                        <button onClick={() => setShowPopup(false)}>Close</button>
                    </div>
                </div>
            )}
            <div className={styles.tableContainer}>
                <table>
                    <thead>
                    <tr>
                        <th className={styles.centered} style={{width: '100px'}}>Tenant ID</th>
                        <th className={styles.centered} style={{width: '100px'}}>Label ID</th>
                        <th className={styles.iconContainer}>Label
                            <Flag code={languageToCountryCode[selectedLanguage] || 'UN'}
                                  style={{width: '24px', height: '24px', marginLeft: '8px', marginRight: '8px'}}/>
                            {referenceLanguage && (
                                <>
                                    (<Flag code={languageToCountryCode[referenceLanguage] || 'UN'} style={{
                                    width: '24px',
                                    height: '24px',
                                    marginLeft: '4px',
                                    marginRight: '4px'
                                }}/>)
                                </>
                            )}
                        </th>
                        <th className={styles.centered} style={{width: '100px'}}>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {filteredTranslations.map((translation, index) => (
                        <tr key={translation.labelId} className={`row ${index % 2 === 0 ? 'even' : 'odd'}`}>
                            <td className={styles.centered}
                                style={{width: '100px'}}>{translation.tenantId === 0 ? 'default' : 'specific'}</td>
                            <td className={styles.centered} style={{width: '100px'}}>{translation.labelId}</td>
                            <td>
                                <div className="td-vertical">
                                    {translation.label}
                                    <span className="editIcon"
                                          onClick={() => handleEditClick(translation, 'label')}><MdOutlineCreate/></span>
                                    {translation.referenceLabel && (
                                        <div style={{
                                            fontStyle: 'italic',
                                            color: 'gray'
                                        }}>{translation.referenceLabel}</div>

                                    )}
                                </div>
                            </td>
                            <td className={styles.centered} style={{width: '100px'}}>
                                {translation.tenantId === 0 && (
                                    <span className="notifyIcon"
                                          onClick={() => handleCreateForTenant(translation)}><MdOutlinePlaylistAdd/></span>
                                )}
                                {translation.tenantId !== 0 && (
                                    <span onClick={() => handleDelete(translation)}
                                          className="deleteIcon"><MdOutlineDeleteForever/></span>
                                )}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

interface ExtendedTranslationDto extends TranslationDto {
    referenceLabel?: string | null;
}

interface CreateTranslationFormData {
    tenantId: number;
    category: TranslationDtoCategoryEnum;
    language: TranslationDtoLanguageEnum;
    labelId: number;
    label: string;
}

export default TranslationForm;
