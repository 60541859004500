/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Represents the association between a permission and an access right, specifying the rights to access a specific endpoint.
 * @export
 * @interface PermissionAccessDto
 */
export interface PermissionAccessDto {
    /**
     * The unique identifier of the permission.
     * @type {number}
     * @memberof PermissionAccessDto
     */
    permissionId?: number;
    /**
     * The identifier of a specific right to access an endpoint.
     * @type {number}
     * @memberof PermissionAccessDto
     */
    accessId?: number;
}

/**
 * Check if a given object implements the PermissionAccessDto interface.
 */
export function instanceOfPermissionAccessDto(value: object): boolean {
    return true;
}

export function PermissionAccessDtoFromJSON(json: any): PermissionAccessDto {
    return PermissionAccessDtoFromJSONTyped(json, false);
}

export function PermissionAccessDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PermissionAccessDto {
    if (json == null) {
        return json;
    }
    return {
        
        'permissionId': json['permissionId'] == null ? undefined : json['permissionId'],
        'accessId': json['accessId'] == null ? undefined : json['accessId'],
    };
}

export function PermissionAccessDtoToJSON(value?: PermissionAccessDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'permissionId': value['permissionId'],
        'accessId': value['accessId'],
    };
}

