/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * The new attributes for the batch schema
 * @export
 * @interface BatchSchemaDto
 */
export interface BatchSchemaDto {
    /**
     * The unique identifier of the step schema.
     * @type {number}
     * @memberof BatchSchemaDto
     */
    id?: number;
    /**
     * The unique identifier of the parent schema to which the step belongs.
     * @type {number}
     * @memberof BatchSchemaDto
     */
    pschemaId?: number;
    /**
     * A short, human-readable name or alias for the step schema.
     * @type {string}
     * @memberof BatchSchemaDto
     */
    alias?: string;
    /**
     * A descriptive title for the step schema.
     * @type {string}
     * @memberof BatchSchemaDto
     */
    title?: string;
}

/**
 * Check if a given object implements the BatchSchemaDto interface.
 */
export function instanceOfBatchSchemaDto(value: object): boolean {
    return true;
}

export function BatchSchemaDtoFromJSON(json: any): BatchSchemaDto {
    return BatchSchemaDtoFromJSONTyped(json, false);
}

export function BatchSchemaDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BatchSchemaDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'pschemaId': json['pschemaId'] == null ? undefined : json['pschemaId'],
        'alias': json['alias'] == null ? undefined : json['alias'],
        'title': json['title'] == null ? undefined : json['title'],
    };
}

export function BatchSchemaDtoToJSON(value?: BatchSchemaDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'pschemaId': value['pschemaId'],
        'alias': value['alias'],
        'title': value['title'],
    };
}

