import React, {ChangeEvent, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {urnSelect} from "../../../api/UrnService";
import {
    SetupDtoDataTypeEnum,
    TraceSchemaMetadataDeleteRequest,
    TraceSchemaMetadataDto,
    TraceSchemaMetadataSelectByTschemaIdRequest,
    TraceSchemaMetadataUpdateRequest,
    UrnSelectOrThrowRequest
} from "../../../api/generated-react-client/src";
import {
    traceSchemaMetadataDelete,
    traceSchemaMetadataInsert,
    traceSchemaMetadataSelectByTSchema,
    traceSchemaMetadataUpdate
} from "../../../api/TraceSchemaMetadataService";
import {MdOutlineCreate, MdOutlineDeleteForever} from "react-icons/md";
import Modal from "../../common/Modal";
import {pschemaSelectById} from "../../../api/PipelineSchemaService";

const TraceSchemaMetadataForm: React.FC = () => {
    const {pschemaId} = useParams<{ pschemaId: string }>();
    const {urn} = useParams<{ urn: string }>();
    const [tschemaId, setTschemaId] = useState<number>(0);
    const [metadatas, setMetadatas] = useState<Array<TraceSchemaMetadataDto>>([]);
    const [error, setError] = useState<string | null>(null);
    const [showErrorPopup, setShowErrorPopup] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isPSchemaDraft, setIsPSchemaDraft] = useState<boolean>(false);
    const [formData, setFormData] = useState<Partial<TraceSchemaMetadataDto>>({
        tschemaId: 0,
        id: '',
        defaultValue: '',
        validationRule: '',
        valueList: '',
        mandatory: true,
        dataType: SetupDtoDataTypeEnum.Boolean
    })

    const fetchMetadata = async () => {
        if (formData.tschemaId === undefined) {
            console.error('tschemaId is not defined');
            return; // Exit early if tschemaId is not set
        }

        const request: TraceSchemaMetadataSelectByTschemaIdRequest = {
            tschemaId: formData.tschemaId
        };

        try {
            const list = await traceSchemaMetadataSelectByTSchema(request);
            setMetadatas(list);
        } catch (error) {
            console.error('Error fetching metadata:', error);
            // Handle error as needed
        }
    };

    const resetForm = () => {
        setFormData({
            tschemaId: Number(tschemaId),
            id: '',
            defaultValue: '',
            validationRule: '',
            valueList: '',
            mandatory: true,
            dataType: SetupDtoDataTypeEnum.Boolean
        });
    };

    useEffect(() => {
        const fetchTSchemaAndMetadata = async () => {
            if (!urn) {
                console.error('URN is not defined');
                return;
            }

            try {
                // Fetch TSchema
                const request: UrnSelectOrThrowRequest = {
                    pschemaId: Number(pschemaId),
                    urnValue: urn
                };
                const urnDto = await urnSelect(request);

                setFormData(prevFormData => ({
                    ...prevFormData,
                    tschemaId: urnDto.id,
                }));

                // Fetch Metadata after tschemaId is set
                if (urnDto.id !== undefined) {
                    const metadataRequest: TraceSchemaMetadataSelectByTschemaIdRequest = {
                        tschemaId: urnDto.id
                    };
                    const list = await traceSchemaMetadataSelectByTSchema(metadataRequest);
                    setMetadatas(list);
                    setTschemaId(urnDto.id)
                }
                const pschemaDto = await pschemaSelectById({pschemaId: Number(pschemaId)});
                setIsPSchemaDraft(pschemaDto.status === "DRAFT");
            } catch (error) {
                console.error('Error fetching TSchema or Metadata:', error);
            }
        };

        fetchTSchemaAndMetadata();
    }, [urn, pschemaId])

    const filterValue = async (metadata: TraceSchemaMetadataDto, field: keyof TraceSchemaMetadataDto, value: string): Promise<boolean> => {
        // Check if field is 'defaultValue' to apply validation
        if (field === "defaultValue") {
            if (metadata.dataType === 'INTEGER') {
                // Regular expression to ensure only numbers (no spaces, letters, symbols)
                const isNumeric = /^\d+$/.test(value);

                // If the value is not numeric, show an alert and stop the execution
                if (!isNumeric) {
                    alert("Please enter a valid integer (numbers only).");
                    return false; // Stop execution if input contains any non-numeric characters
                }

                const parsedValue = parseInt(value, 10);
                if (isNaN(parsedValue)) {
                    alert("Please enter a valid integer.");
                    return false; // Stop execution if not a valid integer
                }
            } else if (metadata.dataType === 'DOUBLE') {
                // Regular expression to ensure only numbers and at most one decimal point
                const isValidDouble = /^\d+(\.\d+)?$/.test(value);

                // If the value is not a valid double, show an alert and stop the execution
                if (!isValidDouble) {
                    alert("Please enter a valid decimal number (numbers only, with at most one decimal point).");
                    return false; // Stop execution if input is invalid
                }

                const parsedValue = parseFloat(value);
                if (isNaN(parsedValue)) {
                    alert("Please enter a valid decimal number.");
                    return false; // Stop execution if not a valid double
                }
            } else if (metadata.dataType === 'LIST') {
                // Check if value contains a comma and prevent it
                if (value.includes(',')) {
                    alert("Commas are not allowed in list values.");
                    return false; // Stop execution if a comma is found
                }
            }
        }
        return true; // Continue if valid
    };

    const handleEditClick = async (metadata: TraceSchemaMetadataDto, field: keyof TraceSchemaMetadataDto, idMeta: string) => {
        // Get the current value of the field and convert it to a string
        const currentValue = metadata[field]?.toString() || '';

        // Prompt the user for a new value
        const newValue = prompt(`Edit ${field}`, currentValue);

        if (newValue !== null) {

            const isValid = await filterValue(metadata, field, newValue);
            if (!isValid) {
                // Stop the method if the value is not valid
                return;
            }

            // Clone the metadata object to avoid direct mutation
            const updatedMetadata = {...metadata, [field]: newValue};

            try {
                // Use idMeta for all fields except 'id', where we pass the new value itself
                const idToUse = field === 'id' ? currentValue : idMeta;

                // Call update function, passing the modified metadata
                await updateMetadata(updatedMetadata, idToUse);
                fetchMetadata();
                console.log(`Field '${field}' updated successfully`);
            } catch (error) {
                console.error(`Error updating field '${field}':`, error);
            }
        }
    };


    const handleDelete = async (tschemaId: number, id: string) => {
        if (window.confirm('Are you sure you want to delete this setup?')) {
            try {
                const request: TraceSchemaMetadataDeleteRequest = {
                    tschemaId: tschemaId,
                    id: id
                };
                await traceSchemaMetadataDelete(request);
                fetchMetadata();
            } catch (error) {
                setError((error as Error).message);
                setShowErrorPopup(true);
            }
        }
    }

    const updateMetadata = async (metadata: Partial<TraceSchemaMetadataDto>, id: string) => {
        try {
            const request: TraceSchemaMetadataUpdateRequest = {
                id: id,
                traceSchemaMetadataDto: metadata
            };
            await traceSchemaMetadataUpdate(request);
            fetchMetadata();
        } catch (error) {
            setError((error as Error).message);
            setShowErrorPopup(true);
        }
    }

    const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const {name, value, type} = event.currentTarget;
        if (type === 'checkbox') {
            const {checked} = event.currentTarget as HTMLInputElement;
            if (name === 'defaultValue') {
                setFormData(prevFormData => ({
                    ...prevFormData,
                    [name]: checked ? 'true' : 'false',
                }));
            } else {
                setFormData(prevFormData => ({
                    ...prevFormData,
                    [name]: checked,
                }));
            }
        } else {
            setFormData(prevFormData => ({
                ...prevFormData,
                [name]: value,
            }));
        }
    };

    const handleCreate = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            await traceSchemaMetadataInsert({traceSchemaMetadataDto: formData as TraceSchemaMetadataDto});
            setIsModalOpen(false);
            fetchMetadata();
            resetForm();
        } catch (error) {
            setError((error as Error).message);
            setShowErrorPopup(true);
        }
    };

    return (
        <div className="formList">
            <div className="headerContainer">
                <b>Metadata configuration</b>
                <div className="formGroup">
                    {isPSchemaDraft && (
                        <button onClick={() => setIsModalOpen(true)}>Create</button>
                    )}
                </div>
                <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                    <form onSubmit={handleCreate}>
                        <div className="formGroup">
                            <label>Id</label>
                            <input
                                type="text"
                                name="id"
                                value={formData.id}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="formGroup">
                            <label>Data type</label>
                            <select
                                name="dataType"
                                value={formData.dataType}
                                onChange={handleInputChange}
                            >
                                {Object.values(SetupDtoDataTypeEnum).map((type) => (
                                    <option key={type} value={type}>
                                        {type}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="formGroup">
                            <label>Default Value</label>
                            {formData.dataType === "BOOLEAN" ? (
                                <input
                                    type="checkbox"
                                    name="defaultValue"
                                    checked={formData.defaultValue === 'true'}
                                    value={formData.defaultValue}
                                    onChange={handleInputChange}
                                />
                            ) : formData.dataType === "DATE" ? (
                                <input
                                    type="date" // Use date input for DATE dataType
                                    name="defaultValue"
                                    value={formData.defaultValue}
                                    onChange={(e) => {
                                        const value = e.target.value;

                                        // Validate date format if needed (YYYY-MM-DD)
                                        if (/^\d{4}-\d{2}-\d{2}$/.test(value) || value === '') {
                                            handleInputChange(e);
                                        } else {
                                            alert("Please enter a valid date in YYYY-MM-DD format.");
                                        }
                                    }}
                                />
                            ) : formData.dataType === "TIME" ? (
                                <input
                                    type="time" // Use time input for TIME dataType
                                    name="defaultValue"
                                    value={formData.defaultValue}
                                    onChange={(e) => {
                                        const value = e.target.value;

                                        // Validate time format if needed (HH:MM)
                                        if (/^\d{2}:\d{2}$/.test(value) || value === '') {
                                            handleInputChange(e);
                                        } else {
                                            alert("Please enter a valid time in HH:MM format.");
                                        }
                                    }}
                                />
                            ) : formData.dataType === "DATETIME" ? (
                                <input
                                    type="datetime-local" // Use datetime-local input for DATETIME dataType
                                    name="defaultValue"
                                    value={formData.defaultValue}
                                    onChange={(e) => {
                                        const value = e.target.value;

                                        // Validate datetime format if needed (YYYY-MM-DDTHH:mm)
                                        if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/.test(value) || value === '') {
                                            handleInputChange(e);
                                        } else {
                                            alert("Please enter a valid datetime in YYYY-MM-DDTHH:MM format.");
                                        }
                                    }}
                                />
                            ) : formData.dataType === "LIST" ? (
                                <input
                                    type="text"
                                    name="defaultValue"
                                    value={formData.defaultValue}
                                    onChange={(e) => {
                                        const value = e.target.value;

                                        // Prevent comma input for LIST data type
                                        if (!value.includes(',')) {
                                            handleInputChange(e);
                                        } else {
                                            alert("Commas are not allowed in list values.");
                                        }
                                    }}
                                />
                            ) : (
                                <input
                                    type="text"
                                    name="defaultValue"
                                    value={formData.defaultValue}
                                    onChange={(e) => {
                                        const value = e.target.value;

                                        // Check if the dataType is "INTEGER" and allow only numeric values
                                        if (formData.dataType === "INTEGER") {
                                            if (/^\d*$/.test(value)) { // Allow only digits (or empty string)
                                                handleInputChange(e);
                                            }
                                        } else if (formData.dataType === "DOUBLE") {
                                            // Regular expression to allow numbers and at most one decimal point
                                            if (/^\d*\.?\d*$/.test(value)) {
                                                handleInputChange(e);
                                            }
                                        } else {
                                            handleInputChange(e);
                                        }
                                    }}
                                />
                            )}
                        </div>
                        <div className="formGroup">
                            <label>Validation Rule</label>
                            <input
                                type="text"
                                name="validationRule"
                                value={formData.validationRule}
                                onChange={handleInputChange}
                            />
                        </div>
                        {formData.dataType === "LIST" && (
                            <div className="formGroup">
                                <label>Value List (separator ",")</label>
                                <input
                                    type="text"
                                    name="valueList"
                                    value={formData.valueList}
                                    onChange={handleInputChange}
                                />
                            </div>
                        )}
                        <div className="formGroup">
                            <label>Mandatory</label>
                            <input
                                type="checkbox"
                                name="mandatory"
                                checked={formData.mandatory}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="horizontalButtonContainer">
                            <button type="submit">Create</button>
                        </div>
                    </form>
                </Modal>
            </div>
            {showErrorPopup && error && (
                <div className="errorModal">
                    <div className="errorModalContent">
                        <p>Error: {error}</p>
                        <button onClick={() => setShowErrorPopup(false)}>Close</button>
                    </div>
                </div>
            )}
            <table>
                <thead>
                <tr>
                    <th>Order Index</th>
                    <th>ID</th>
                    <th>Data Type</th>
                    <th>Default Value</th>
                    <th>Validation Rule</th>
                    <th>Value List</th>
                    <th>Mandatory</th>
                    <th>Action</th>
                </tr>
                </thead>
                <tbody>
                {metadatas.map((metadata, index) => (
                    <tr key={`${metadata.orderIndex}`} className={`row ${index % 2 === 0 ? 'even' : 'odd'}`}>
                        <td style={{textAlign: 'center', wordWrap: 'break-word'}}>
                            {metadata.orderIndex}
                            {isPSchemaDraft && (
                                <span
                                    className="editIcon"
                                    onClick={() => handleEditClick(metadata, 'orderIndex', metadata.id!)}
                                >
                                    <MdOutlineCreate/>
                                </span>
                            )}
                        </td>
                        <td style={{textAlign: 'center', wordWrap: 'break-word'}}>
                            {metadata.id}
                            {isPSchemaDraft && (
                                <span className="editIcon"
                                      onClick={() => handleEditClick(metadata, 'id', metadata.id!)}>
                                    <MdOutlineCreate/>
                                </span>
                            )}

                        </td>
                        <td style={{textAlign: 'center', wordWrap: 'break-word'}}>
                            {isPSchemaDraft ? (
                                <select
                                    value={metadata.dataType}
                                    onChange={(e) =>
                                        updateMetadata(
                                            {
                                                tschemaId: tschemaId,
                                                dataType: e.target.value as SetupDtoDataTypeEnum,
                                            },
                                            metadata.id!
                                        )
                                    }
                                >
                                    {Object.values(SetupDtoDataTypeEnum).map((type) => (
                                        <option key={type} value={type}>
                                            {type}
                                        </option>
                                    ))}
                                </select>
                            ) : (
                                <span>{metadata.dataType}</span> // Show the metadata dataType as text
                            )}
                        </td>
                        <td style={{textAlign: 'center', wordWrap: 'break-word'}}>
                            {isPSchemaDraft ? (
                                metadata.dataType === 'BOOLEAN' ? (
                                    <input
                                        type="checkbox"
                                        checked={metadata.defaultValue === 'true'}
                                        onChange={(e) =>
                                            updateMetadata(
                                                {
                                                    tschemaId: metadata.tschemaId,
                                                    defaultValue: e.target.checked ? 'true' : 'false',
                                                },
                                                metadata.id!
                                            )
                                        }
                                    />
                                ) : metadata.dataType === 'DATE' ? (
                                    <input
                                        type="date"
                                        value={metadata.defaultValue}
                                        onChange={(e) =>
                                            updateMetadata(
                                                {
                                                    tschemaId: metadata.tschemaId,
                                                    defaultValue: e.target.value, // Format as 'yyyy-MM-dd'
                                                },
                                                metadata.id!
                                            )
                                        }
                                    />
                                ) : metadata.dataType === 'TIME' ? (
                                    <input
                                        type="time"
                                        value={metadata.defaultValue}
                                        onChange={(e) =>
                                            updateMetadata(
                                                {
                                                    tschemaId: metadata.tschemaId,
                                                    defaultValue: e.target.value, // Format as 'HH:mm'
                                                },
                                                metadata.id!
                                            )
                                        }
                                    />
                                ) : metadata.dataType === 'DATETIME' ? (
                                    <input
                                        type="datetime-local"
                                        value={metadata.defaultValue}
                                        onChange={(e) =>
                                            updateMetadata(
                                                {
                                                    tschemaId: metadata.tschemaId,
                                                    defaultValue: e.target.value, // Format as 'yyyy-MM-ddTHH:mm'
                                                },
                                                metadata.id!
                                            )
                                        }
                                    />
                                ) : (
                                    <>
                                        {metadata.defaultValue}
                                        <span
                                            className="editIcon"
                                            onClick={() => handleEditClick(metadata, 'defaultValue', metadata.id!)}
                                        >
                                            <MdOutlineCreate/>
                                        </span>
                                    </>
                                )
                            ) : (
                                <span>{metadata.defaultValue}</span>
                            )}
                        </td>
                        <td style={{textAlign: 'center', wordWrap: 'break-word'}}>
                            {metadata.validationRule}
                            {isPSchemaDraft && (
                                <span
                                    className="editIcon"
                                    onClick={() => handleEditClick(metadata, 'validationRule', metadata.id!)}
                                >
                                    <MdOutlineCreate/>
                                </span>
                            )}
                        </td>
                        <td style={{textAlign: 'center', wordWrap: 'break-word'}}>
                            {metadata.valueList}
                            {isPSchemaDraft && metadata.dataType === 'LIST' && (
                                <span
                                    className="editIcon"
                                    onClick={() => handleEditClick(metadata, 'valueList', metadata.id!)}
                                >
                                    <MdOutlineCreate/>
                                </span>
                            )}
                        </td>
                        <td style={{textAlign: 'center', wordWrap: 'break-word'}}>
                            {isPSchemaDraft ? (
                                <input
                                    type="checkbox"
                                    checked={metadata.mandatory}
                                    onChange={() =>
                                        updateMetadata(
                                            {
                                                tschemaId: metadata.tschemaId,
                                                mandatory: !metadata.mandatory,
                                            },
                                            metadata.id || ''
                                        )
                                    }
                                />
                            ) : (
                                <span>{metadata.mandatory ? 'true' : 'false'}</span>
                            )}
                        </td>
                        <td style={{textAlign: 'center', wordWrap: 'break-word'}}>
                            {isPSchemaDraft && (
                                <span onClick={() => handleDelete(metadata.tschemaId!, metadata.id!)}
                                      className="deleteIcon">
                                    <MdOutlineDeleteForever/>
                                </span>
                            )}
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>);
}
export default TraceSchemaMetadataForm;