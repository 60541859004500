/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Represents an instance of the relationship between a tenant and a signer.
 * @export
 * @interface TenantSignerDto
 */
export interface TenantSignerDto {
    /**
     * The unique identifier of the tenant.
     * @type {number}
     * @memberof TenantSignerDto
     */
    tenantId?: number;
    /**
     * The unique identifier of the signer.
     * @type {number}
     * @memberof TenantSignerDto
     */
    signerId?: number;
}

/**
 * Check if a given object implements the TenantSignerDto interface.
 */
export function instanceOfTenantSignerDto(value: object): boolean {
    return true;
}

export function TenantSignerDtoFromJSON(json: any): TenantSignerDto {
    return TenantSignerDtoFromJSONTyped(json, false);
}

export function TenantSignerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TenantSignerDto {
    if (json == null) {
        return json;
    }
    return {
        
        'tenantId': json['tenantId'] == null ? undefined : json['tenantId'],
        'signerId': json['signerId'] == null ? undefined : json['signerId'],
    };
}

export function TenantSignerDtoToJSON(value?: TenantSignerDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'tenantId': value['tenantId'],
        'signerId': value['signerId'],
    };
}

