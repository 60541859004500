/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * The new attributes for the Share Bundle.
 * @export
 * @interface ShareDto
 */
export interface ShareDto {
    /**
     * The unique identifier of the share bundle.
     * @type {number}
     * @memberof ShareDto
     */
    id?: number;
    /**
     * The identifier of the pipeline schema to which this share bundle is linked. This indicates the specific pipeline schema whose trace schemas are being grouped for sharing.
     * @type {number}
     * @memberof ShareDto
     */
    pschemaId?: number;
    /**
     * A unique alias for the share bundle, which provides a human-readable identifier. This alias must be unique within the context of the associated pipeline schema, ensuring easy reference.
     * @type {string}
     * @memberof ShareDto
     */
    alias?: string;
    /**
     * A descriptive title for the share bundle, offering context or clarity about the purpose or contents of the bundle.
     * @type {string}
     * @memberof ShareDto
     */
    title?: string;
}

/**
 * Check if a given object implements the ShareDto interface.
 */
export function instanceOfShareDto(value: object): boolean {
    return true;
}

export function ShareDtoFromJSON(json: any): ShareDto {
    return ShareDtoFromJSONTyped(json, false);
}

export function ShareDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShareDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'pschemaId': json['pschemaId'] == null ? undefined : json['pschemaId'],
        'alias': json['alias'] == null ? undefined : json['alias'],
        'title': json['title'] == null ? undefined : json['title'],
    };
}

export function ShareDtoToJSON(value?: ShareDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'pschemaId': value['pschemaId'],
        'alias': value['alias'],
        'title': value['title'],
    };
}

