/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  UrnDto,
} from '../models/index';
import {
    UrnDtoFromJSON,
    UrnDtoToJSON,
} from '../models/index';

export interface UrnDeleteRequest {
    urnId: number;
}

export interface UrnDeleteBySchemaRequest {
    pschemaId: number;
}

export interface UrnInsertRequest {
    urnDto: UrnDto;
}

export interface UrnSelectByIdOrThrowRequest {
    id: number;
}

export interface UrnSelectBySchemaRequest {
    pschemaId: number;
}

export interface UrnSelectOrThrowRequest {
    pschemaId: number;
    urnValue: string;
}

export interface UrnUpdateRequest {
    urnDto: UrnDto;
}

/**
 * 
 */
export class UrnManagementApi extends runtime.BaseAPI {

    /**
     * Deletes a specific urn by its ID.
     * Delete a urn
     */
    async urnDeleteRaw(requestParameters: UrnDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['urnId'] == null) {
            throw new runtime.RequiredError(
                'urnId',
                'Required parameter "urnId" was null or undefined when calling urnDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/urn/{urnId}`.replace(`{${"urnId"}}`, encodeURIComponent(String(requestParameters['urnId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Deletes a specific urn by its ID.
     * Delete a urn
     */
    async urnDelete(requestParameters: UrnDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.urnDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes a specific urn by its ID.
     * Delete a urn
     */
    async urnDeleteBySchemaRaw(requestParameters: UrnDeleteBySchemaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['pschemaId'] == null) {
            throw new runtime.RequiredError(
                'pschemaId',
                'Required parameter "pschemaId" was null or undefined when calling urnDeleteBySchema().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/urn/by-pschema/{pschemaId}`.replace(`{${"pschemaId"}}`, encodeURIComponent(String(requestParameters['pschemaId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Deletes a specific urn by its ID.
     * Delete a urn
     */
    async urnDeleteBySchema(requestParameters: UrnDeleteBySchemaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.urnDeleteBySchemaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates a new URN and returns the created Entity with its ID.
     * Insert a new URN
     */
    async urnInsertRaw(requestParameters: UrnInsertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UrnDto>> {
        if (requestParameters['urnDto'] == null) {
            throw new runtime.RequiredError(
                'urnDto',
                'Required parameter "urnDto" was null or undefined when calling urnInsert().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/urn`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UrnDtoToJSON(requestParameters['urnDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UrnDtoFromJSON(jsonValue));
    }

    /**
     * Creates a new URN and returns the created Entity with its ID.
     * Insert a new URN
     */
    async urnInsert(requestParameters: UrnInsertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UrnDto> {
        const response = await this.urnInsertRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a urn by id.
     * Get urn by id
     */
    async urnSelectByIdOrThrowRaw(requestParameters: UrnSelectByIdOrThrowRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UrnDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling urnSelectByIdOrThrow().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/urn/by-id/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UrnDtoFromJSON(jsonValue));
    }

    /**
     * Retrieves a urn by id.
     * Get urn by id
     */
    async urnSelectByIdOrThrow(requestParameters: UrnSelectByIdOrThrowRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UrnDto> {
        const response = await this.urnSelectByIdOrThrowRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a list of urn by pipeline schema ID.
     * Get a list of urn by pipeline schema
     */
    async urnSelectBySchemaRaw(requestParameters: UrnSelectBySchemaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UrnDto>>> {
        if (requestParameters['pschemaId'] == null) {
            throw new runtime.RequiredError(
                'pschemaId',
                'Required parameter "pschemaId" was null or undefined when calling urnSelectBySchema().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/urn/by-pschema-list/{pschemaId}`.replace(`{${"pschemaId"}}`, encodeURIComponent(String(requestParameters['pschemaId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UrnDtoFromJSON));
    }

    /**
     * Retrieves a list of urn by pipeline schema ID.
     * Get a list of urn by pipeline schema
     */
    async urnSelectBySchema(requestParameters: UrnSelectBySchemaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UrnDto>> {
        const response = await this.urnSelectBySchemaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a urn by its pipeline schema and urn value.
     * Get urn by pipeline schema and value
     */
    async urnSelectOrThrowRaw(requestParameters: UrnSelectOrThrowRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UrnDto>> {
        if (requestParameters['pschemaId'] == null) {
            throw new runtime.RequiredError(
                'pschemaId',
                'Required parameter "pschemaId" was null or undefined when calling urnSelectOrThrow().'
            );
        }

        if (requestParameters['urnValue'] == null) {
            throw new runtime.RequiredError(
                'urnValue',
                'Required parameter "urnValue" was null or undefined when calling urnSelectOrThrow().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/urn/{pschemaId}/{urnValue}`.replace(`{${"pschemaId"}}`, encodeURIComponent(String(requestParameters['pschemaId']))).replace(`{${"urnValue"}}`, encodeURIComponent(String(requestParameters['urnValue']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UrnDtoFromJSON(jsonValue));
    }

    /**
     * Retrieves a urn by its pipeline schema and urn value.
     * Get urn by pipeline schema and value
     */
    async urnSelectOrThrow(requestParameters: UrnSelectOrThrowRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UrnDto> {
        const response = await this.urnSelectOrThrowRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates selected information for a specific tenant.
     * Update tenant\'s attributes
     */
    async urnUpdateRaw(requestParameters: UrnUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['urnDto'] == null) {
            throw new runtime.RequiredError(
                'urnDto',
                'Required parameter "urnDto" was null or undefined when calling urnUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/urn`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UrnDtoToJSON(requestParameters['urnDto']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Updates selected information for a specific tenant.
     * Update tenant\'s attributes
     */
    async urnUpdate(requestParameters: UrnUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.urnUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
