/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CronDto,
  CronFilter,
  CronView,
} from '../models/index';
import {
    CronDtoFromJSON,
    CronDtoToJSON,
    CronFilterFromJSON,
    CronFilterToJSON,
    CronViewFromJSON,
    CronViewToJSON,
} from '../models/index';

export interface CronDeleteRequest {
    cronId: number;
}

export interface CronInsertRequest {
    cronDto: CronDto;
}

export interface CronPurgeRequest {
    purgeTime: Date;
}

export interface CronSearchRequest {
    cronFilter: CronFilter;
}

export interface CronSelectByExecutedRequest {
    min: Date;
    max: Date;
}

export interface CronSelectByIdRequest {
    cronId: number;
}

export interface CronSelectByRunningRequest {
    running: boolean;
}

export interface CronSelectBySourceIdRequest {
    cronId: number;
    sourceId: number;
}

export interface CronUpdateRequest {
    cronDto: CronDto;
}

/**
 * 
 */
export class CronManagementApi extends runtime.BaseAPI {

    /**
     * Deletes a specific cron by its ID.
     * Delete a cron
     */
    async cronDeleteRaw(requestParameters: CronDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['cronId'] == null) {
            throw new runtime.RequiredError(
                'cronId',
                'Required parameter "cronId" was null or undefined when calling cronDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/cron/{cronId}`.replace(`{${"cronId"}}`, encodeURIComponent(String(requestParameters['cronId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Deletes a specific cron by its ID.
     * Delete a cron
     */
    async cronDelete(requestParameters: CronDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.cronDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates a new Cron and returns the created Cron with its ID.
     * Insert a new Cron
     */
    async cronInsertRaw(requestParameters: CronInsertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CronDto>> {
        if (requestParameters['cronDto'] == null) {
            throw new runtime.RequiredError(
                'cronDto',
                'Required parameter "cronDto" was null or undefined when calling cronInsert().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/cron`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CronDtoToJSON(requestParameters['cronDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CronDtoFromJSON(jsonValue));
    }

    /**
     * Creates a new Cron and returns the created Cron with its ID.
     * Insert a new Cron
     */
    async cronInsert(requestParameters: CronInsertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CronDto> {
        const response = await this.cronInsertRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes alls crons created before a specific date.
     * Purge the cron 
     */
    async cronPurgeRaw(requestParameters: CronPurgeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['purgeTime'] == null) {
            throw new runtime.RequiredError(
                'purgeTime',
                'Required parameter "purgeTime" was null or undefined when calling cronPurge().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/cron/purge/{purgeTime}`.replace(`{${"purgeTime"}}`, encodeURIComponent(String(requestParameters['purgeTime']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Deletes alls crons created before a specific date.
     * Purge the cron 
     */
    async cronPurge(requestParameters: CronPurgeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.cronPurgeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Searches for cron records based on a set of filters provided in the request body.
     * Search for cron records
     */
    async cronSearchRaw(requestParameters: CronSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CronView>> {
        if (requestParameters['cronFilter'] == null) {
            throw new runtime.RequiredError(
                'cronFilter',
                'Required parameter "cronFilter" was null or undefined when calling cronSearch().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/cron/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CronFilterToJSON(requestParameters['cronFilter']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CronViewFromJSON(jsonValue));
    }

    /**
     * Searches for cron records based on a set of filters provided in the request body.
     * Search for cron records
     */
    async cronSearch(requestParameters: CronSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CronView> {
        const response = await this.cronSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a list of crons.
     * Get a list of crons between a dateMin and dateMax
     */
    async cronSelectByExecutedRaw(requestParameters: CronSelectByExecutedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CronDto>>> {
        if (requestParameters['min'] == null) {
            throw new runtime.RequiredError(
                'min',
                'Required parameter "min" was null or undefined when calling cronSelectByExecuted().'
            );
        }

        if (requestParameters['max'] == null) {
            throw new runtime.RequiredError(
                'max',
                'Required parameter "max" was null or undefined when calling cronSelectByExecuted().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['min'] != null) {
            queryParameters['min'] = (requestParameters['min'] as any).toISOString();
        }

        if (requestParameters['max'] != null) {
            queryParameters['max'] = (requestParameters['max'] as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/cron`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CronDtoFromJSON));
    }

    /**
     * Retrieves a list of crons.
     * Get a list of crons between a dateMin and dateMax
     */
    async cronSelectByExecuted(requestParameters: CronSelectByExecutedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CronDto>> {
        const response = await this.cronSelectByExecutedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a cron by its ID.
     * Get cron by ID
     */
    async cronSelectByIdRaw(requestParameters: CronSelectByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CronDto>> {
        if (requestParameters['cronId'] == null) {
            throw new runtime.RequiredError(
                'cronId',
                'Required parameter "cronId" was null or undefined when calling cronSelectById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/cron/{cronId}`.replace(`{${"cronId"}}`, encodeURIComponent(String(requestParameters['cronId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CronDtoFromJSON(jsonValue));
    }

    /**
     * Retrieves a cron by its ID.
     * Get cron by ID
     */
    async cronSelectById(requestParameters: CronSelectByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CronDto> {
        const response = await this.cronSelectByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a list of crons.
     * Get a list of crons by running
     */
    async cronSelectByRunningRaw(requestParameters: CronSelectByRunningRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CronDto>>> {
        if (requestParameters['running'] == null) {
            throw new runtime.RequiredError(
                'running',
                'Required parameter "running" was null or undefined when calling cronSelectByRunning().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/cron/by-running/{running}`.replace(`{${"running"}}`, encodeURIComponent(String(requestParameters['running']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CronDtoFromJSON));
    }

    /**
     * Retrieves a list of crons.
     * Get a list of crons by running
     */
    async cronSelectByRunning(requestParameters: CronSelectByRunningRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CronDto>> {
        const response = await this.cronSelectByRunningRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a list of crons.
     * Get a list of crons by id and sourceId
     */
    async cronSelectBySourceIdRaw(requestParameters: CronSelectBySourceIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CronDto>>> {
        if (requestParameters['cronId'] == null) {
            throw new runtime.RequiredError(
                'cronId',
                'Required parameter "cronId" was null or undefined when calling cronSelectBySourceId().'
            );
        }

        if (requestParameters['sourceId'] == null) {
            throw new runtime.RequiredError(
                'sourceId',
                'Required parameter "sourceId" was null or undefined when calling cronSelectBySourceId().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/cron/{cronId}/{sourceId}`.replace(`{${"cronId"}}`, encodeURIComponent(String(requestParameters['cronId']))).replace(`{${"sourceId"}}`, encodeURIComponent(String(requestParameters['sourceId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CronDtoFromJSON));
    }

    /**
     * Retrieves a list of crons.
     * Get a list of crons by id and sourceId
     */
    async cronSelectBySourceId(requestParameters: CronSelectBySourceIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CronDto>> {
        const response = await this.cronSelectBySourceIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a list of crons.
     * Get a list of crons in errors
     */
    async cronSelectInErrorRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CronDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/cron/in-error`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CronDtoFromJSON));
    }

    /**
     * Retrieves a list of crons.
     * Get a list of crons in errors
     */
    async cronSelectInError(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CronDto>> {
        const response = await this.cronSelectInErrorRaw(initOverrides);
        return await response.value();
    }

    /**
     * Updates selected information for a specific cron.
     * Update cron\'s attributes
     */
    async cronUpdateRaw(requestParameters: CronUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['cronDto'] == null) {
            throw new runtime.RequiredError(
                'cronDto',
                'Required parameter "cronDto" was null or undefined when calling cronUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/cron`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: CronDtoToJSON(requestParameters['cronDto']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Updates selected information for a specific cron.
     * Update cron\'s attributes
     */
    async cronUpdate(requestParameters: CronUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.cronUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
