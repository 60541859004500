/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PermissionDto,
} from '../models/index';
import {
    PermissionDtoFromJSON,
    PermissionDtoToJSON,
} from '../models/index';

export interface PermissionDeleteRequest {
    permissionId: number;
}

export interface PermissionInsertRequest {
    permissionDto: PermissionDto;
}

export interface PermissionSelectByAliasRequest {
    alias: string;
}

export interface PermissionSelectByIdRequest {
    permissionId: number;
}

export interface PermissionUpdateRequest {
    permissionDto: PermissionDto;
}

/**
 * 
 */
export class PermissionManagementApi extends runtime.BaseAPI {

    /**
     * Deletes a permission entry from the system
     * Delete a permission
     */
    async permissionDeleteRaw(requestParameters: PermissionDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['permissionId'] == null) {
            throw new runtime.RequiredError(
                'permissionId',
                'Required parameter "permissionId" was null or undefined when calling permissionDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/permission/{permissionId}`.replace(`{${"permissionId"}}`, encodeURIComponent(String(requestParameters['permissionId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Deletes a permission entry from the system
     * Delete a permission
     */
    async permissionDelete(requestParameters: PermissionDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.permissionDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates a new permission entry in the system
     * Insert a new permission
     */
    async permissionInsertRaw(requestParameters: PermissionInsertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PermissionDto>> {
        if (requestParameters['permissionDto'] == null) {
            throw new runtime.RequiredError(
                'permissionDto',
                'Required parameter "permissionDto" was null or undefined when calling permissionInsert().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/permission`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PermissionDtoToJSON(requestParameters['permissionDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PermissionDtoFromJSON(jsonValue));
    }

    /**
     * Creates a new permission entry in the system
     * Insert a new permission
     */
    async permissionInsert(requestParameters: PermissionInsertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PermissionDto> {
        const response = await this.permissionInsertRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetches a list of all permissions available within the tenant\'s context.
     * Retrieve all permissions
     */
    async permissionSelectRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PermissionDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/permission`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PermissionDtoFromJSON));
    }

    /**
     * Fetches a list of all permissions available within the tenant\'s context.
     * Retrieve all permissions
     */
    async permissionSelect(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PermissionDto>> {
        const response = await this.permissionSelectRaw(initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a permission object based on its alias.
     * Get permission by alias
     */
    async permissionSelectByAliasRaw(requestParameters: PermissionSelectByAliasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PermissionDto>> {
        if (requestParameters['alias'] == null) {
            throw new runtime.RequiredError(
                'alias',
                'Required parameter "alias" was null or undefined when calling permissionSelectByAlias().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/permission/by-alias/{alias}`.replace(`{${"alias"}}`, encodeURIComponent(String(requestParameters['alias']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PermissionDtoFromJSON(jsonValue));
    }

    /**
     * Retrieves a permission object based on its alias.
     * Get permission by alias
     */
    async permissionSelectByAlias(requestParameters: PermissionSelectByAliasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PermissionDto> {
        const response = await this.permissionSelectByAliasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetches a permission detail based on the given permission ID. This operation is secure and can only  performed within the context of the tenant to which the permission belongs.
     * Retrieve a specific permission by its ID
     */
    async permissionSelectByIdRaw(requestParameters: PermissionSelectByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PermissionDto>> {
        if (requestParameters['permissionId'] == null) {
            throw new runtime.RequiredError(
                'permissionId',
                'Required parameter "permissionId" was null or undefined when calling permissionSelectById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/permission/{permissionId}`.replace(`{${"permissionId"}}`, encodeURIComponent(String(requestParameters['permissionId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PermissionDtoFromJSON(jsonValue));
    }

    /**
     * Fetches a permission detail based on the given permission ID. This operation is secure and can only  performed within the context of the tenant to which the permission belongs.
     * Retrieve a specific permission by its ID
     */
    async permissionSelectById(requestParameters: PermissionSelectByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PermissionDto> {
        const response = await this.permissionSelectByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates selected information for a specific permission.
     * Update permission\'s attributes
     */
    async permissionUpdateRaw(requestParameters: PermissionUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PermissionDto>> {
        if (requestParameters['permissionDto'] == null) {
            throw new runtime.RequiredError(
                'permissionDto',
                'Required parameter "permissionDto" was null or undefined when calling permissionUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/permission`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PermissionDtoToJSON(requestParameters['permissionDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PermissionDtoFromJSON(jsonValue));
    }

    /**
     * Updates selected information for a specific permission.
     * Update permission\'s attributes
     */
    async permissionUpdate(requestParameters: PermissionUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PermissionDto> {
        const response = await this.permissionUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
