/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  TenantDto,
  TenantHalgorithmDto,
  TenantSignerDto,
} from '../models/index';
import {
    TenantDtoFromJSON,
    TenantDtoToJSON,
    TenantHalgorithmDtoFromJSON,
    TenantHalgorithmDtoToJSON,
    TenantSignerDtoFromJSON,
    TenantSignerDtoToJSON,
} from '../models/index';

export interface TenantDeleteRequest {
    tenantId: number;
}

export interface TenantHalgorithmDeleteRequest {
    tenantHalgorithmDto: TenantHalgorithmDto;
}

export interface TenantHalgorithmInsertRequest {
    tenantHalgorithmDto: TenantHalgorithmDto;
}

export interface TenantInsertRequest {
    tenantDto: TenantDto;
}

export interface TenantLinkSignerRequest {
    signerId: number;
}

export interface TenantSelectByAliasRequest {
    alias: string;
}

export interface TenantSelectByIdRequest {
    tenantId: number;
}

export interface TenantUnlinkSignerRequest {
    signerId: number;
}

export interface TenantUpdateRequest {
    tenantDto: TenantDto;
}

/**
 * 
 */
export class TenantManagementApi extends runtime.BaseAPI {

    /**
     * Deletes a specific tenant by its ID.
     * Delete a tenant
     */
    async tenantDeleteRaw(requestParameters: TenantDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['tenantId'] == null) {
            throw new runtime.RequiredError(
                'tenantId',
                'Required parameter "tenantId" was null or undefined when calling tenantDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/tenant/{tenantId}`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters['tenantId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Deletes a specific tenant by its ID.
     * Delete a tenant
     */
    async tenantDelete(requestParameters: TenantDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.tenantDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes a specific link by its halgorithm ID.
     * Delete a link between tenant and halgorithm
     */
    async tenantHalgorithmDeleteRaw(requestParameters: TenantHalgorithmDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['tenantHalgorithmDto'] == null) {
            throw new runtime.RequiredError(
                'tenantHalgorithmDto',
                'Required parameter "tenantHalgorithmDto" was null or undefined when calling tenantHalgorithmDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/tenant/halgorithm`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: TenantHalgorithmDtoToJSON(requestParameters['tenantHalgorithmDto']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Deletes a specific link by its halgorithm ID.
     * Delete a link between tenant and halgorithm
     */
    async tenantHalgorithmDelete(requestParameters: TenantHalgorithmDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.tenantHalgorithmDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates a new record in the table and return it.
     * Insert a new link between tenant and hash algorithm
     */
    async tenantHalgorithmInsertRaw(requestParameters: TenantHalgorithmInsertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TenantHalgorithmDto>> {
        if (requestParameters['tenantHalgorithmDto'] == null) {
            throw new runtime.RequiredError(
                'tenantHalgorithmDto',
                'Required parameter "tenantHalgorithmDto" was null or undefined when calling tenantHalgorithmInsert().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/tenant/halgorithm`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TenantHalgorithmDtoToJSON(requestParameters['tenantHalgorithmDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TenantHalgorithmDtoFromJSON(jsonValue));
    }

    /**
     * Creates a new record in the table and return it.
     * Insert a new link between tenant and hash algorithm
     */
    async tenantHalgorithmInsert(requestParameters: TenantHalgorithmInsertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TenantHalgorithmDto> {
        const response = await this.tenantHalgorithmInsertRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates a new tenant and returns the created tenant with its ID.
     * Insert a new tenant
     */
    async tenantInsertRaw(requestParameters: TenantInsertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TenantDto>> {
        if (requestParameters['tenantDto'] == null) {
            throw new runtime.RequiredError(
                'tenantDto',
                'Required parameter "tenantDto" was null or undefined when calling tenantInsert().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/tenant`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TenantDtoToJSON(requestParameters['tenantDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TenantDtoFromJSON(jsonValue));
    }

    /**
     * Creates a new tenant and returns the created tenant with its ID.
     * Insert a new tenant
     */
    async tenantInsert(requestParameters: TenantInsertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TenantDto> {
        const response = await this.tenantInsertRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Link the signer to the tenant and return the newly created relation.
     * Link tenant with signer
     */
    async tenantLinkSignerRaw(requestParameters: TenantLinkSignerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TenantSignerDto>> {
        if (requestParameters['signerId'] == null) {
            throw new runtime.RequiredError(
                'signerId',
                'Required parameter "signerId" was null or undefined when calling tenantLinkSigner().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/tenant/link/{signerId}`.replace(`{${"signerId"}}`, encodeURIComponent(String(requestParameters['signerId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TenantSignerDtoFromJSON(jsonValue));
    }

    /**
     * Link the signer to the tenant and return the newly created relation.
     * Link tenant with signer
     */
    async tenantLinkSigner(requestParameters: TenantLinkSignerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TenantSignerDto> {
        const response = await this.tenantLinkSignerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a list of all tenants.
     * Get all tenants
     */
    async tenantSelectRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TenantDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/tenant`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TenantDtoFromJSON));
    }

    /**
     * Retrieves a list of all tenants.
     * Get all tenants
     */
    async tenantSelect(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TenantDto>> {
        const response = await this.tenantSelectRaw(initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a tenant by its alias.
     * Get tenant by name
     */
    async tenantSelectByAliasRaw(requestParameters: TenantSelectByAliasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TenantDto>> {
        if (requestParameters['alias'] == null) {
            throw new runtime.RequiredError(
                'alias',
                'Required parameter "alias" was null or undefined when calling tenantSelectByAlias().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/tenant/by-alias/{alias}`.replace(`{${"alias"}}`, encodeURIComponent(String(requestParameters['alias']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TenantDtoFromJSON(jsonValue));
    }

    /**
     * Retrieves a tenant by its alias.
     * Get tenant by name
     */
    async tenantSelectByAlias(requestParameters: TenantSelectByAliasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TenantDto> {
        const response = await this.tenantSelectByAliasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a tenant by its ID.
     * Get tenant by ID
     */
    async tenantSelectByIdRaw(requestParameters: TenantSelectByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TenantDto>> {
        if (requestParameters['tenantId'] == null) {
            throw new runtime.RequiredError(
                'tenantId',
                'Required parameter "tenantId" was null or undefined when calling tenantSelectById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/tenant/{tenantId}`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters['tenantId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TenantDtoFromJSON(jsonValue));
    }

    /**
     * Retrieves a tenant by its ID.
     * Get tenant by ID
     */
    async tenantSelectById(requestParameters: TenantSelectByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TenantDto> {
        const response = await this.tenantSelectByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes the link between the tenant and the signer and returns the count of deleted records.
     * Unlink tenant and signer
     */
    async tenantUnlinkSignerRaw(requestParameters: TenantUnlinkSignerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['signerId'] == null) {
            throw new runtime.RequiredError(
                'signerId',
                'Required parameter "signerId" was null or undefined when calling tenantUnlinkSigner().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/tenant/unlink/{signerId}`.replace(`{${"signerId"}}`, encodeURIComponent(String(requestParameters['signerId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Deletes the link between the tenant and the signer and returns the count of deleted records.
     * Unlink tenant and signer
     */
    async tenantUnlinkSigner(requestParameters: TenantUnlinkSignerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.tenantUnlinkSignerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates selected information for a specific tenant.
     * Update tenant\'s attributes
     */
    async tenantUpdateRaw(requestParameters: TenantUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TenantDto>> {
        if (requestParameters['tenantDto'] == null) {
            throw new runtime.RequiredError(
                'tenantDto',
                'Required parameter "tenantDto" was null or undefined when calling tenantUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/tenant`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: TenantDtoToJSON(requestParameters['tenantDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TenantDtoFromJSON(jsonValue));
    }

    /**
     * Updates selected information for a specific tenant.
     * Update tenant\'s attributes
     */
    async tenantUpdate(requestParameters: TenantUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TenantDto> {
        const response = await this.tenantUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
