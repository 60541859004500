/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * The filter criteria used to search the cron records
 * @export
 * @interface CronFilter
 */
export interface CronFilter {
    /**
     * The position of the first record to retrieve, used for pagination. It determines the number of initial rows to skip.
     * @type {number}
     * @memberof CronFilter
     */
    offset?: number;
    /**
     * The maximum number of records to return starting from the offset position. This is used to control the size of the result set.
     * @type {number}
     * @memberof CronFilter
     */
    limit?: number;
    /**
     * The search text used to filter crons by their handler name.
     * @type {string}
     * @memberof CronFilter
     */
    handler?: string;
    /**
     * The number used to filter crons by their tenantId.
     * @type {number}
     * @memberof CronFilter
     */
    tenantId?: number;
    /**
     * The number used to filter crons by their userId.
     * @type {number}
     * @memberof CronFilter
     */
    userId?: number;
    /**
     * The boolean used to filter crons by if they are running or not.
     * @type {boolean}
     * @memberof CronFilter
     */
    running?: boolean;
    /**
     * The minimum Instant used to filter crons by the time they should execute at.
     * @type {Date}
     * @memberof CronFilter
     */
    minExecuteAt?: Date;
    /**
     * The maximum Instant used to filter crons by the time they should execute at.
     * @type {Date}
     * @memberof CronFilter
     */
    maxExecuteAt?: Date;
    /**
     * The minimum Instant used to filter crons by the time they have be executed at.
     * @type {Date}
     * @memberof CronFilter
     */
    minExecutedAt?: Date;
    /**
     * The maximum Instant used to filter crons by the time they have be executed at.
     * @type {Date}
     * @memberof CronFilter
     */
    maxExecutedAt?: Date;
}

/**
 * Check if a given object implements the CronFilter interface.
 */
export function instanceOfCronFilter(value: object): boolean {
    return true;
}

export function CronFilterFromJSON(json: any): CronFilter {
    return CronFilterFromJSONTyped(json, false);
}

export function CronFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): CronFilter {
    if (json == null) {
        return json;
    }
    return {
        
        'offset': json['offset'] == null ? undefined : json['offset'],
        'limit': json['limit'] == null ? undefined : json['limit'],
        'handler': json['handler'] == null ? undefined : json['handler'],
        'tenantId': json['tenantId'] == null ? undefined : json['tenantId'],
        'userId': json['userId'] == null ? undefined : json['userId'],
        'running': json['running'] == null ? undefined : json['running'],
        'minExecuteAt': json['minExecuteAt'] == null ? undefined : (new Date(json['minExecuteAt'])),
        'maxExecuteAt': json['maxExecuteAt'] == null ? undefined : (new Date(json['maxExecuteAt'])),
        'minExecutedAt': json['minExecutedAt'] == null ? undefined : (new Date(json['minExecutedAt'])),
        'maxExecutedAt': json['maxExecutedAt'] == null ? undefined : (new Date(json['maxExecutedAt'])),
    };
}

export function CronFilterToJSON(value?: CronFilter | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'offset': value['offset'],
        'limit': value['limit'],
        'handler': value['handler'],
        'tenantId': value['tenantId'],
        'userId': value['userId'],
        'running': value['running'],
        'minExecuteAt': value['minExecuteAt'] == null ? undefined : ((value['minExecuteAt']).toISOString()),
        'maxExecuteAt': value['maxExecuteAt'] == null ? undefined : ((value['maxExecuteAt']).toISOString()),
        'minExecutedAt': value['minExecutedAt'] == null ? undefined : ((value['minExecutedAt']).toISOString()),
        'maxExecutedAt': value['maxExecutedAt'] == null ? undefined : ((value['maxExecutedAt']).toISOString()),
    };
}

