import React, {useEffect, useRef, useState} from 'react';
import Modal from "../../common/Modal";
import {
    PipelineSchemaDto,
    PipelineSchemaDtoStatusEnum,
    PschemaExportRequest,
    PschemaInsertRequest,
    SignerDto
} from "../../../api/generated-react-client/src";
import {
    pschemaDelete,
    pschemaExport,
    pschemaInsert,
    pschemaLinkSigner,
    pschemaSearch,
    pschemaUnlinkSigner,
    pschemaUpdate
} from "../../../api/PipelineSchemaService";
import {useNavigate} from "react-router-dom";
import {
    MdOutlineAccountTree,
    MdOutlineDeleteForever,
    MdOutlineEditNote,
    MdOutlineFolderCopy,
    MdOutlineViewList,
    MdOutlineVisibility
} from "react-icons/md";
import {signerSelect} from "../../../api/SignerService";
import {AgGridReact} from 'ag-grid-react';
import {ColDef, ICellRendererParams} from 'ag-grid-community';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

enum ModalMode {
    CREATE = 'CREATE',
    CLONE = 'CLONE',
    NEW_VERSION = 'NEW_VERSION'
}

const PipelineSchemaForm: React.FC = () => {
    const [pschemas, setPschemas] = useState<PipelineSchemaDto[]>([]);
    const [loading, setLoading] = useState(true);  // Add loading state
    const [currentPschemaId, setCurrentPschemaId] = useState<number | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [showErrorPopup, setShowErrorPopup] = useState<boolean>(false);
    const navigate = useNavigate();

    const [signers, setSigners] = useState<SignerDto[]>([]);
    const [isSignersModalOpen, setIsSignersModalOpen] = useState<boolean>(false);
    const [isExportModalOpen, setIsExportModalOpen] = useState<boolean>(false);
    const [selectedSigners, setSelectedSigners] = useState<Record<number, boolean>>({});
    const [tempSelectedSigners, setTempSelectedSigners] = useState<Record<number, boolean>>({});

    const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
    const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.CREATE);
    const [selectedPschema, setSelectedPschema] = useState<PipelineSchemaDto | null>(null);

    const [isFileUploadModalOpen, setIsFileUploadModalOpen] = useState(false);

    interface FormData {
        id: number;
        alias: string;
        title: string;
        version: string;
        vtitle: string;
        usesExternalIds: boolean;
        status: 'DRAFT' | 'ACTIVE' | 'INACTIVE';
    }

    const [createFormData, setCreateFormData] = useState<FormData>({
        id: 0,
        alias: '',
        title: '',
        version: '',
        vtitle: '',
        usesExternalIds: false,
        status: 'DRAFT'
    });

    const handleUpdate = async (pschema: PipelineSchemaDto) => {
        const requestParameters = {pipelineSchemaDto: pschema};
        try {
            await pschemaUpdate(requestParameters);
            await fetchPschemas();
        } catch (error) {
            setError((error as Error).message);
            setShowErrorPopup(true);
        }
    };

    const gridApi = useRef<any>(null);

    const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

    useEffect(() => {
        fetchPschemas();
        fetchSigners();
        setupColumnDefs();
    }, []);

    const setupColumnDefs = () => {
        setColumnDefs([
            {
                headerName: 'ID',
                field: 'id',
                width: 60,
                editable: false,
                cellStyle: {display: 'flex', alignItems: 'center'},
                cellRenderer: (params: ICellRendererParams) => (
                    <input
                        type="text"
                        value={params.value}
                        readOnly
                        style={{width: '100%', backgroundColor: 'white'}}
                    />
                )
            },
            {
                headerName: 'Alias',
                field: 'alias',
                editable: false,
                cellStyle: {display: 'flex', alignItems: 'center'},
                cellRenderer: (params: ICellRendererParams) => (
                    <EditableCellRenderer {...params} />
                )
            },
            {
                headerName: 'Title',
                field: 'title',
                editable: false,
                cellStyle: {display: 'flex', alignItems: 'center'},
                cellRenderer: (params: ICellRendererParams) => (
                    <EditableCellRenderer {...params} />
                )
            },
            {
                headerName: 'Version',
                field: 'version',
                editable: false,
                cellStyle: {display: 'flex', alignItems: 'center'},
                cellRenderer: (params: ICellRendererParams) => (
                    <EditableCellRenderer {...params} />
                )
            },
            {
                headerName: 'Version Title',
                field: 'vtitle',
                editable: false,
                cellStyle: {display: 'flex', alignItems: 'center'},
                cellRenderer: (params: ICellRendererParams) => (
                    <EditableCellRenderer {...params} />
                )
            },
            {
                headerName: 'Status',
                field: 'status',
                width: 100,
                editable: false,
                cellStyle: {display: 'flex', alignItems: 'center'},
                cellRenderer: (params: ICellRendererParams) => (
                    <SelectCellRenderer {...params} />
                )
            },
            {
                headerName: 'Parent',
                field: 'parent',
                valueGetter: (params) => {
                    // Ensure that pschemas are loaded before proceeding
                    if (!pschemas || pschemas.length === 0) {
                        return 'Loading...';
                    }

                    const parentSchema = pschemas.find(p => p.id === params.data.parentId);

                    if (parentSchema) {
                        return `${parentSchema.alias}|${parentSchema.version}`;
                    } else {
                        return 'No Parent';
                    }
                },
                editable: false,
                cellStyle: {display: 'flex', alignItems: 'center'},
                cellRenderer: (params: ICellRendererParams) => (
                    <input
                        type="text"
                        value={params.value}
                        readOnly
                        style={{width: '100%', backgroundColor: 'white'}}
                    />
                )
            },
            {
                headerName: 'Uses External IDs',
                field: 'usesExternalIds',
                width: 150,
                editable: false,
                cellStyle: {display: 'flex', alignItems: 'center'},
                cellRenderer: (params: ICellRendererParams) => <CheckboxRenderer {...params} />
            },
            {
                headerName: 'Created At',
                field: 'createdAt',
                width: 170,
                valueFormatter: (params) =>
                    params.value ? new Date(params.value as string).toLocaleString() : 'Not available',
                editable: false,
                cellStyle: {display: 'flex', alignItems: 'center'},
                cellRenderer: (params: ICellRendererParams) => (
                    <input
                        type="text"
                        value={params.value}
                        readOnly
                        style={{width: '100%', backgroundColor: 'white'}}
                    />
                )
            },
            {
                headerName: 'Actions',
                field: 'actions',
                width: 200,
                editable: false,
                cellStyle: {display: 'flex', alignItems: 'center'},
                cellRenderer: (params: ICellRendererParams) => <ActionsRenderer {...params} />
            }
        ]);
    };

    const fetchPschemas = async () => {
        try {
            const searchFilter = {pipelineSchemaFilter: {}};
            const result = await pschemaSearch(searchFilter);
            setPschemas(result.list || []);
            setLoading(false);  // Set loading to false after the data is fully loaded

            // if (gridApi.current) {
                gridApi.current.refreshCells();
            // }
        } catch (error) {
            setError((error as Error).message);
            setShowErrorPopup(true);
            setLoading(false);  // Handle error case as well
        }
    };

    const fetchSigners = async () => {
        try {
            const result = await signerSelect();
            setSigners(result || []);
        } catch (error) {
            setError((error as Error).message);
            setShowErrorPopup(true);
        }
    };

    const openExportModal = () => {
        setIsExportModalOpen(true);
    };

    const handleManageSignerClick = (pschema: PipelineSchemaDto) => {
        setCurrentPschemaId(pschema.id!);

        const initialSelectedSigners: Record<number, boolean> = {};
        pschema.signerList?.forEach(signer => {
            if (signer.id !== undefined) {
                initialSelectedSigners[signer.id] = true;
            }
        });

        setSelectedSigners(initialSelectedSigners);
        setTempSelectedSigners(initialSelectedSigners);
        setIsSignersModalOpen(true);
    };

    const handleDelete = async (pschemaId: number) => {
        if (window.confirm('Are you sure you want to delete this pipeline schema?')) {
            try {
                await pschemaDelete({pschemaId});
                setPschemas(pschemas.filter(t => t.id !== pschemaId));
            } catch (error) {
                setError((error as Error).message);
                setShowErrorPopup(true);
            }
        }
    };

    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
    ) => {
        const {name, value} = event.currentTarget;
        setCreateFormData(prevFormData => ({...prevFormData, [name]: value}));
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {checked, name} = event.target;
        setCreateFormData(prevFormData => ({...prevFormData, [name]: checked}));
    };

    const handleCreate = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (
            (modalMode === ModalMode.CREATE ||
                modalMode === ModalMode.CLONE ||
                modalMode === ModalMode.NEW_VERSION) &&
            !createFormData.version
        ) {
            setError('Version is required.');
            setShowErrorPopup(true);
            return;
        }

        if (
            (modalMode === ModalMode.CREATE ||
                modalMode === ModalMode.CLONE ||
                modalMode === ModalMode.NEW_VERSION) &&
            !createFormData.vtitle
        ) {
            setError('Version Title is required.');
            setShowErrorPopup(true);
            return;
        }

        if ((modalMode === ModalMode.CREATE || modalMode === ModalMode.CLONE) && !createFormData.alias) {
            setError('Alias is required.');
            setShowErrorPopup(true);
            return;
        }

        if (modalMode === ModalMode.CREATE && !createFormData.title) {
            setError('Title is required.');
            setShowErrorPopup(true);
            return;
        }

        let pipelineSchemaDto: PipelineSchemaDto;

        if (modalMode === ModalMode.CREATE) {
            pipelineSchemaDto = {
                id: 0,
                tenantId: 0,
                alias: createFormData.alias,
                title: createFormData.title,
                version: createFormData.version,
                vtitle: createFormData.vtitle,
                usesExternalIds: createFormData.usesExternalIds,
                status: createFormData.status as PipelineSchemaDtoStatusEnum
            };
        } else if (modalMode === ModalMode.CLONE) {
            pipelineSchemaDto = {
                id: 0,
                tenantId: selectedPschema?.tenantId || 0,
                alias: createFormData.alias,
                title: selectedPschema?.title || '',
                version: createFormData.version,
                vtitle: createFormData.vtitle,
                usesExternalIds: selectedPschema?.usesExternalIds || false,
                status: 'DRAFT',
                rootId: selectedPschema?.rootId || selectedPschema?.id
            };
        } else if (modalMode === ModalMode.NEW_VERSION) {
            pipelineSchemaDto = {
                id: 0,
                tenantId: selectedPschema?.tenantId || 0,
                alias: selectedPschema?.alias || '',
                title: selectedPschema?.title || '',
                version: createFormData.version,
                vtitle: createFormData.vtitle,
                usesExternalIds: selectedPschema?.usesExternalIds || false,
                status: 'DRAFT',
                rootId: selectedPschema?.rootId || selectedPschema?.id,
                parentId: selectedPschema?.id
            };
        } else {
            setError('Invalid operation mode.');
            setShowErrorPopup(true);
            return;
        }

        const requestParameters: PschemaInsertRequest = {pipelineSchemaDto};

        try {
            await pschemaInsert(requestParameters);
            setIsCreateModalOpen(false);
            setModalMode(ModalMode.CREATE);
            setSelectedPschema(null);
            await fetchPschemas();
        } catch (error) {
            setError((error as Error).message);
            setShowErrorPopup(true);
        }
    };

    const handleSignerCheckboxChange = async (id: number, isChecked: boolean) => {
        setTempSelectedSigners(prev => ({...prev, [id]: isChecked}));

        if (currentPschemaId !== null) {
            try {
                let updatedSignerList: SignerDto[] = [];
                const targetPschema = pschemas.find(pschema => pschema.id === currentPschemaId);

                if (targetPschema) {
                    updatedSignerList = targetPschema.signerList ? [...targetPschema.signerList] : [];

                    if (isChecked) {
                        if (!updatedSignerList.find(signer => signer.id === id)) {
                            updatedSignerList.push({
                                id,
                                alias: signers.find(s => s.id === id)?.alias || ''
                            });
                        }
                        await pschemaLinkSigner({pschemaId: currentPschemaId, body: id});
                    } else {
                        updatedSignerList = updatedSignerList.filter(signer => signer.id !== id);
                        await pschemaUnlinkSigner({pschemaId: currentPschemaId, body: id});
                    }

                    const updatedPschemas = pschemas.map(pschema =>
                        pschema.id === currentPschemaId
                            ? {...pschema, signerList: updatedSignerList}
                            : pschema
                    );

                    setPschemas(updatedPschemas);
                }
            } catch (error) {
                setError((error as Error).message);
                setShowErrorPopup(true);
            }
        }
    };

    const handleSubmitSelectedSigners = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            setSelectedSigners({...tempSelectedSigners});
            setIsSignersModalOpen(false);
        } catch (error) {
            setError((error as Error).message);
            setShowErrorPopup(true);
        }
    };

    const closeFileUploadModal = () => {
        setIsFileUploadModalOpen(false);
        navigate('/pschema');
    };

    const handleCloseModal = () => {
        setIsSignersModalOpen(false);
        setTempSelectedSigners({...selectedSigners});
    };

    const handleSelectPschemaForExport = (pschemaId: number) => {
        setCurrentPschemaId(pschemaId);
    };

    const handleExportJSON = async () => {
        if (currentPschemaId === null) {
            setError('Please select a Pipeline Schema ID to export.');
            setShowErrorPopup(true);
            return;
        }
        try {
            const requestParameters: PschemaExportRequest = {
                pschemaId: currentPschemaId
            };
            const jsonData = await pschemaExport(requestParameters);
            const jsonString = JSON.stringify(jsonData);
            const blob = new Blob([jsonString], {type: 'application/json'});
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `pipelineSchema_${currentPschemaId}.json`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            setError('Failed to export JSON');
            console.error(error);
        } finally {
            setIsExportModalOpen(false);
        }
    };

    const handleShowTraces = (pschemaId: number) => {
        navigate('/trace-schema/' + pschemaId);
    };

    const handleShowBundle = (pschemaId: number) => {
        navigate('/bundle/' + pschemaId);
    };

    const handleCloneSchema = (pschema: PipelineSchemaDto) => {
        setModalMode(ModalMode.CLONE);
        setSelectedPschema(pschema);
        setCreateFormData({
            id: 0,
            alias: '',
            title: '',
            version: '',
            vtitle: '',
            usesExternalIds: false,
            status: 'DRAFT'
        });
        setIsCreateModalOpen(true);
    };

    const handleCreateNewVersion = (pschema: PipelineSchemaDto) => {
        setModalMode(ModalMode.NEW_VERSION);
        setSelectedPschema(pschema);
        setCreateFormData({
            id: 0,
            alias: '',
            title: '',
            version: '',
            vtitle: '',
            usesExternalIds: false,
            status: 'DRAFT'
        });
        setIsCreateModalOpen(true);
    };

    const EditableCellRenderer: React.FC<ICellRendererParams> = (props) => {
        const [value, setValue] = useState(props.value);

        useEffect(() => {
            setValue(props.value);
        }, [props.value]);

        const handleBlur = () => {
            const data = {...props.data};
            const field = props.colDef!.field as keyof PipelineSchemaDto;
            data[field] = value;
            handleUpdate(data);
        };

        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            setValue(e.target.value);
        };

        return (
            <input
                type="text"
                value={value}
                onChange={handleChange}
                onBlur={handleBlur}
                style={{width: '100%', backgroundColor: 'white'}}
            />
        );
    };

    const SelectCellRenderer: React.FC<ICellRendererParams> = (props) => {
        const [value, setValue] = useState(props.value);

        useEffect(() => {
            setValue(props.value);
        }, [props.value]);

        const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
            setValue(e.target.value);
        };

        const handleBlur = () => {
            const data = {...props.data};
            const field = props.colDef!.field as keyof PipelineSchemaDto;
            data[field] = value;
            handleUpdate(data);
        };

        return (
            <select
                value={value}
                onChange={handleChange}
                onBlur={handleBlur}
                style={{width: '100%', backgroundColor: 'white'}}
            >
                <option value="DRAFT">Draft</option>
                <option value="ACTIVE">Active</option>
                <option value="INACTIVE">Inactive</option>
            </select>
        );
    };

    const CheckboxRenderer: React.FC<ICellRendererParams> = (props) => {
        const [checked, setChecked] = useState(!!props.value);

        useEffect(() => {
            setChecked(!!props.value);
        }, [props.value]);

        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setChecked(event.target.checked);
        };

        const handleBlur = () => {
            const data = {...props.data};
            const field = props.colDef!.field as keyof PipelineSchemaDto;
            data[field] = checked;
            handleUpdate(data);
        };

        return (
            <input
                type="checkbox"
                checked={checked}
                onChange={handleChange}
                onBlur={handleBlur}
            />
        );
    };

    const ActionsRenderer: React.FC<ICellRendererParams> = (props) => {
        const pschema = props.data as PipelineSchemaDto;

        return (
            <div className="icon-container">
                <span
                    onClick={() => handleShowTraces(pschema.id!)}
                    className="notifyIcon"
                    title="Manage trace schemas"
                >
                    <MdOutlineVisibility/>
                </span>
                <span
                    onClick={() => handleDelete(pschema.id!)}
                    className="deleteIcon"
                    title="Delete pipeline schema"
                >
                    <MdOutlineDeleteForever/>
                </span>
                <span
                    onClick={() => handleShowBundle(pschema.id!)}
                    className="notifyIcon"
                    title="Manage bundles"
                >
                    <MdOutlineViewList/>
                </span>
                <span
                    onClick={() => handleManageSignerClick(pschema)}
                    className="notifyIcon"
                    title="Add signer"
                >
                    <MdOutlineEditNote/>
                </span>
                <span
                    onClick={() => handleCloneSchema(pschema)}
                    className="notifyIcon"
                    title="Clone Schema"
                >
                    <MdOutlineFolderCopy/>
                </span>
                <span
                    onClick={() => handleCreateNewVersion(pschema)}
                    className="notifyIcon"
                    title="Create New Version"
                >
                    <MdOutlineAccountTree/>
                </span>
            </div>
        );
    };

    return (
        <div className="formList">
            <div className="headerContainer">
                <b>Pipeline Schema List</b>
                <div className="formGroup">
                    <button onClick={() => setIsFileUploadModalOpen(true)}>Upload JSON</button>
                    <button onClick={openExportModal}>Export as JSON</button>
                    <button
                        onClick={() => {
                            setModalMode(ModalMode.CREATE);
                            setSelectedPschema(null);
                            setCreateFormData({
                                id: 0,
                                alias: '',
                                title: '',
                                version: '',
                                vtitle: '',
                                usesExternalIds: false,
                                status: 'DRAFT'
                            });
                            setIsCreateModalOpen(true);
                        }}
                    >
                        Create
                    </button>
                </div>
                <Modal isOpen={isCreateModalOpen} onClose={() => setIsCreateModalOpen(false)}>
                    <div className="formContainer">
                        <h2>
                            {modalMode === ModalMode.CREATE && 'Create Pipeline Schema'}
                            {modalMode === ModalMode.CLONE && 'Clone Pipeline Schema'}
                            {modalMode === ModalMode.NEW_VERSION && 'Create New Version'}
                        </h2>
                        <form onSubmit={handleCreate}>
                            {modalMode === ModalMode.CREATE && (
                                <>
                                    <div className="formGroup">
                                        <label>Alias</label>
                                        <input
                                            type="text"
                                            name="alias"
                                            value={createFormData.alias}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div>
                                    <div className="formGroup">
                                        <label>Title</label>
                                        <input
                                            type="text"
                                            name="title"
                                            value={createFormData.title}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div>
                                    <div className="formGroup">
                                        <label>Version (required)</label>
                                        <input
                                            type="text"
                                            name="version"
                                            value={createFormData.version}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div>
                                    <div className="formGroup">
                                        <label>Version Title</label>
                                        <input
                                            type="text"
                                            name="vtitle"
                                            value={createFormData.vtitle}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div>
                                    <div className="formGroup">
                                        <label>Status</label>
                                        <select
                                            name="status"
                                            value={createFormData.status}
                                            onChange={handleInputChange}
                                            disabled
                                        >
                                            <option value="DRAFT">Draft</option>
                                            <option value="ACTIVE">Active</option>
                                            <option value="INACTIVE">Inactive</option>
                                        </select>
                                    </div>
                                    <div className="formGroup">
                                        <label>Uses External IDs</label>
                                        <input
                                            type="checkbox"
                                            name="usesExternalIds"
                                            checked={createFormData.usesExternalIds}
                                            onChange={handleCheckboxChange}
                                        />
                                    </div>
                                </>
                            )}

                            {modalMode === ModalMode.CLONE && (
                                <>
                                    <div className="formGroup">
                                        <label>New Alias</label>
                                        <input
                                            type="text"
                                            name="alias"
                                            value={createFormData.alias}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div>
                                    <div className="formGroup">
                                        <label>New Version (required)</label>
                                        <input
                                            type="text"
                                            name="version"
                                            value={createFormData.version}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div>
                                    <div className="formGroup">
                                        <label>Version Title</label>
                                        <input
                                            type="text"
                                            name="vtitle"
                                            value={createFormData.vtitle}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div>
                                </>
                            )}

                            {modalMode === ModalMode.NEW_VERSION && (
                                <>
                                    <div className="formGroup">
                                        <label>New Version (required)</label>
                                        <input
                                            type="text"
                                            name="version"
                                            value={createFormData.version}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div>
                                    <div className="formGroup">
                                        <label>Version Title</label>
                                        <input
                                            type="text"
                                            name="vtitle"
                                            value={createFormData.vtitle}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div>
                                </>
                            )}
                            <div className="horizontalButtonContainer">
                                <button type="submit">
                                    {modalMode === ModalMode.CREATE && 'Create'}
                                    {modalMode === ModalMode.CLONE && 'Clone'}
                                    {modalMode === ModalMode.NEW_VERSION && 'Create New Version'}
                                </button>
                            </div>
                        </form>
                    </div>
                </Modal>
                <Modal isOpen={isSignersModalOpen} onClose={handleCloseModal}>
                    <form onSubmit={handleSubmitSelectedSigners}>
                        <div className="modalContent">
                            {signers.map(signer => (
                                <div key={signer.id}>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={tempSelectedSigners[signer.id!] || false}
                                            onChange={(e) => handleSignerCheckboxChange(signer.id!, e.target.checked)}
                                        />
                                        {signer.alias}
                                    </label>
                                </div>
                            ))}
                            <button type="submit">Save</button>
                        </div>
                    </form>
                </Modal>
                <Modal isOpen={isExportModalOpen} onClose={() => setIsExportModalOpen(false)}>
                    <div className="modalContent">
                        <h3>Select Pipeline Schema ID to Export</h3>
                        {pschemas.map((pschema) => (
                            <div key={pschema.id} style={{marginBottom: '10px'}}>
                                <label>
                                    <input
                                        type="radio"
                                        name="pschemaId"
                                        value={pschema.id}
                                        onChange={() => handleSelectPschemaForExport(pschema.id!)}
                                    />
                                    {`ID: ${pschema.id}, Alias: ${pschema.alias}`}
                                </label>
                            </div>
                        ))}
                        <button onClick={handleExportJSON}>Export Selected</button>
                    </div>
                </Modal>
            </div>
            {showErrorPopup && error !== null && (
                <div className="errorModal">
                    <div className="errorModalContent">
                        <p>Error: {error}</p>
                        <button onClick={() => setShowErrorPopup(false)}>Close</button>
                    </div>
                </div>
            )}
            <div className="ag-theme-alpine" style={{height: '500px', width: '100%'}}>
                <AgGridReact
                    columnDefs={columnDefs}
                    rowData={pschemas}
                    defaultColDef={{
                        flex: 1,
                        minWidth: 100,
                        resizable: true,
                        cellStyle: {
                            borderRight: '1px solid #ccc',
                            display: 'flex',
                            alignItems: 'center'
                        }
                    }}
                    onGridReady={(params) => {
                        gridApi.current = params.api;
                    }}
                />
            </div>
        </div>
    );
};

export default PipelineSchemaForm;
