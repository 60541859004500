/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Information for creating a new Share Bundle-Trace Schema association
 * @export
 * @interface ShareTraceSchemaDto
 */
export interface ShareTraceSchemaDto {
    /**
     * The unique identifier of the share bundle.
     * @type {number}
     * @memberof ShareTraceSchemaDto
     */
    shareId?: number;
    /**
     * The unique identifier of the trace schema associated with the share bundle.
     * @type {number}
     * @memberof ShareTraceSchemaDto
     */
    tschemaId?: number;
}

/**
 * Check if a given object implements the ShareTraceSchemaDto interface.
 */
export function instanceOfShareTraceSchemaDto(value: object): boolean {
    return true;
}

export function ShareTraceSchemaDtoFromJSON(json: any): ShareTraceSchemaDto {
    return ShareTraceSchemaDtoFromJSONTyped(json, false);
}

export function ShareTraceSchemaDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShareTraceSchemaDto {
    if (json == null) {
        return json;
    }
    return {
        
        'shareId': json['shareId'] == null ? undefined : json['shareId'],
        'tschemaId': json['tschemaId'] == null ? undefined : json['tschemaId'],
    };
}

export function ShareTraceSchemaDtoToJSON(value?: ShareTraceSchemaDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'shareId': value['shareId'],
        'tschemaId': value['tschemaId'],
    };
}

