/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ManifestDto } from './ManifestDto';
import {
    ManifestDtoFromJSON,
    ManifestDtoFromJSONTyped,
    ManifestDtoToJSON,
} from './ManifestDto';
import type { PipelineDto } from './PipelineDto';
import {
    PipelineDtoFromJSON,
    PipelineDtoFromJSONTyped,
    PipelineDtoToJSON,
} from './PipelineDto';

/**
 * Represents a secured entity with metadata related to the protection of pipeline (business process).
 * @export
 * @interface ProtectedPipeline
 */
export interface ProtectedPipeline {
    /**
     * 
     * @type {PipelineDto}
     * @memberof ProtectedPipeline
     */
    pipeline?: PipelineDto;
    /**
     * A list of manifest files with related digital signatures that contain all items of the pipeline, serving as a comprehensive record.
     * @type {Array<ManifestDto>}
     * @memberof ProtectedPipeline
     */
    manifestList?: Array<ManifestDto>;
}

/**
 * Check if a given object implements the ProtectedPipeline interface.
 */
export function instanceOfProtectedPipeline(value: object): boolean {
    return true;
}

export function ProtectedPipelineFromJSON(json: any): ProtectedPipeline {
    return ProtectedPipelineFromJSONTyped(json, false);
}

export function ProtectedPipelineFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProtectedPipeline {
    if (json == null) {
        return json;
    }
    return {
        
        'pipeline': json['pipeline'] == null ? undefined : PipelineDtoFromJSON(json['pipeline']),
        'manifestList': json['manifestList'] == null ? undefined : ((json['manifestList'] as Array<any>).map(ManifestDtoFromJSON)),
    };
}

export function ProtectedPipelineToJSON(value?: ProtectedPipeline | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'pipeline': PipelineDtoToJSON(value['pipeline']),
        'manifestList': value['manifestList'] == null ? undefined : ((value['manifestList'] as Array<any>).map(ManifestDtoToJSON)),
    };
}

