import React, {useEffect, useState} from 'react';
import {EntityDto} from '../../../api/generated-react-client/src/models/EntityDto';
import {entityDelete, entityInsert, entitySelect, entityUpdate} from "../../../api/EntityService";
import {MdOutlineCreate, MdOutlineDeleteForever} from "react-icons/md";
import Modal from "../../common/Modal";
import {EntityInsertRequest} from "../../../api/generated-react-client/src";

const EntityForm: React.FC = () => {

    const [entities, setEntities] = useState<Array<EntityDto>>([]);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [formData, setFormData] = useState<{ alias: string }>({alias: ''});
    const [error, setError] = useState<string | null>(null);
    const [showPopup, setShowPopup] = useState<boolean>(false);

    useEffect(() => {
        const fetchEntities = async () => {
            try {
                const result = await entitySelect();
                setEntities(result);
            } catch (err) {
                setError((err as Error).message);
            }
        };

        fetchEntities();
    }, []);

    const handleEditClick = async (entity: EntityDto, field: 'alias') => {
        const currentValue = entity[field]?.toString() || '';
        const newValue = prompt(`Edit ${field}`, currentValue);
        setError(null);

        if (newValue && newValue !== currentValue) {
            entity.alias = newValue;

            try {
                await entityUpdate({entityDto: entity});
                setEntities(entities.map(e => e.id === entity.id ? {...e, alias: newValue} : e));
            } catch (error) {
                setError((error as Error).message);
                setShowPopup(true);
            }
        }
    };

    const handleDeleteClick = async (entityId: number) => {
        setError(null);
        if (window.confirm('Are you sure you want to delete this entity?')) {
            try {
                await entityDelete({entityId});
                setEntities(entities.filter(e => e.id !== entityId));
            } catch (error) {
                setError((error as Error).message);
                setShowPopup(true);
            }
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.currentTarget;
        setFormData({...formData, [name]: value});
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const entityDto: EntityDto = {alias: formData.alias};
        const requestParameters: EntityInsertRequest = {entityDto};
        setError(null);

        try {
            await entityInsert(requestParameters);
            setIsModalOpen(false);
            const result = await entitySelect();
            setEntities(result);
        } catch (error) {
            setError((error as Error).message);
            setShowPopup(true);
        }
    };

    return (
        <div className="formList">
            <div className="headerContainer">
                <b>Entity list</b>
                <div className="formGroup">
                    <button onClick={() => setIsModalOpen(true)}>Create</button>
                </div>
                <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                    <div>
                        <form onSubmit={handleSubmit}>
                            <div className="formGroup">
                                <label>Alias</label>
                                <input
                                    type="text"
                                    name="alias"
                                    value={formData.alias}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="horizontalButtonContainer">
                                <button type="submit">Submit</button>
                            </div>
                        </form>
                    </div>
                </Modal>
            </div>
            {showPopup && error !== null && (
                <div className="errorModal">
                    <div className="errorModalContent">
                        <p>Error: {error}</p>
                        <button onClick={() => setShowPopup(false)}>Close</button>
                    </div>
                </div>
            )}
            <table>
                <thead>
                <tr>
                    <th>ID</th>
                    <th>Alias</th>
                    <th>Delete</th>
                </tr>
                </thead>
                <tbody>
                {entities.map((entity, index) => (
                    <tr key={entity.id} className={`row ${index % 2 === 0 ? 'even' : 'odd'}`}>
                        <td>{entity.id}</td>
                        <td>
                            <div className="td-vertical">
                                {entity.alias}
                                <span className="editIcon" onClick={() => handleEditClick(entity, 'alias')}>
                                    <MdOutlineCreate/>
                                </span>
                            </div>
                        </td>
                        <td>
                            <div className="icon-container">
                                <span onClick={() => handleDeleteClick(entity.id!)}
                                      className="deleteIcon"><MdOutlineDeleteForever/></span>
                            </div>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>);
};
export default EntityForm;