/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * The filter criteria used to search the pipeline schemas
 * @export
 * @interface PipelineSchemaFilter
 */
export interface PipelineSchemaFilter {
    /**
     * The position of the first record to retrieve, used for pagination. It determines the number of initial rows to skip.
     * @type {number}
     * @memberof PipelineSchemaFilter
     */
    offset?: number;
    /**
     * The maximum number of records to return starting from the offset position. This is used to control the size of the result set.
     * @type {number}
     * @memberof PipelineSchemaFilter
     */
    limit?: number;
    /**
     * The search text used to filter pipeline schemas by the pipeline title.
     * @type {string}
     * @memberof PipelineSchemaFilter
     */
    alias?: string;
}

/**
 * Check if a given object implements the PipelineSchemaFilter interface.
 */
export function instanceOfPipelineSchemaFilter(value: object): boolean {
    return true;
}

export function PipelineSchemaFilterFromJSON(json: any): PipelineSchemaFilter {
    return PipelineSchemaFilterFromJSONTyped(json, false);
}

export function PipelineSchemaFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): PipelineSchemaFilter {
    if (json == null) {
        return json;
    }
    return {
        
        'offset': json['offset'] == null ? undefined : json['offset'],
        'limit': json['limit'] == null ? undefined : json['limit'],
        'alias': json['alias'] == null ? undefined : json['alias'],
    };
}

export function PipelineSchemaFilterToJSON(value?: PipelineSchemaFilter | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'offset': value['offset'],
        'limit': value['limit'],
        'alias': value['alias'],
    };
}

