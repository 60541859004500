import {
    PipelineGetRequest,
    PipelineManagementApi,
    PipelinePrintRequest,
    PipelineRegisterRequest,
    PipelineSearchRequest,
    PipelineSelectByIdRequest,
    PipelineUpdateStatusRequest,
    PipelineUploadOperationRequest,
    PipelineValidateRequest,
    PipelineExportRequest,
} from './generated-react-client/src/apis/PipelineManagementApi';
import config from './clientConfig';
import {apiCallWrapper} from '../utils/apiWrapper';

const api = new PipelineManagementApi(config);

export const pipelineGet = async (requestParameters: PipelineGetRequest) => {
    return apiCallWrapper(() => api.pipelineGet(requestParameters));
};

export const pipelinePrint = async (requestParameters: PipelinePrintRequest) => {
    const response: any = await apiCallWrapper(() => api.pipelinePrint(requestParameters));
    return response.status;
};

export const pipelineRegister = async (requestParameters: PipelineRegisterRequest) => {
    return apiCallWrapper(() => api.pipelineRegister(requestParameters));
};

export const pipelineSearch = async (requestParameters: PipelineSearchRequest) => {
    return apiCallWrapper(() => api.pipelineSearch(requestParameters));
};

export const pipelineSelectById = async (requestParameters: PipelineSelectByIdRequest) => {
    return apiCallWrapper(() => api.pipelineSelectById(requestParameters));
};

export const pipelineUpdateStatus = async (requestParameters: PipelineUpdateStatusRequest) => {
    return apiCallWrapper(() => api.pipelineUpdateStatus(requestParameters));
};

export const pipelineUpload = async (requestParameters: PipelineUploadOperationRequest) => {
    return apiCallWrapper(() => api.pipelineUpload(requestParameters));
};

export const pipelineValidate = async (requestParameters: PipelineValidateRequest) => {
    return apiCallWrapper(() => api.pipelineValidate(requestParameters));
};

export const pipelineExport = async (requestParameters: PipelineExportRequest) => {
    return apiCallWrapper(() => api.pipelineExport(requestParameters));
};