/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * The member object containing details for deletion.
 * @export
 * @interface PipelineSchemaMemberDto
 */
export interface PipelineSchemaMemberDto {
    /**
     * Identifier of the pipeline schema.
     * @type {number}
     * @memberof PipelineSchemaMemberDto
     */
    pschemaId?: number;
    /**
     * Type of a member in the system.
     * @type {string}
     * @memberof PipelineSchemaMemberDto
     */
    memberType?: PipelineSchemaMemberDtoMemberTypeEnum;
    /**
     * Identifier of the member associated with the schema.
     * @type {number}
     * @memberof PipelineSchemaMemberDto
     */
    memberId?: number;
    /**
     * Role assigned to a member in the system.
     * @type {string}
     * @memberof PipelineSchemaMemberDto
     */
    roleType?: PipelineSchemaMemberDtoRoleTypeEnum;
}


/**
 * @export
 */
export const PipelineSchemaMemberDtoMemberTypeEnum = {
    User: 'USER',
    Entity: 'ENTITY',
    Tenant: 'TENANT',
    Team: 'TEAM'
} as const;
export type PipelineSchemaMemberDtoMemberTypeEnum = typeof PipelineSchemaMemberDtoMemberTypeEnum[keyof typeof PipelineSchemaMemberDtoMemberTypeEnum];

/**
 * @export
 */
export const PipelineSchemaMemberDtoRoleTypeEnum = {
    Viewer: 'VIEWER',
    Manager: 'MANAGER',
    Administrator: 'ADMINISTRATOR'
} as const;
export type PipelineSchemaMemberDtoRoleTypeEnum = typeof PipelineSchemaMemberDtoRoleTypeEnum[keyof typeof PipelineSchemaMemberDtoRoleTypeEnum];


/**
 * Check if a given object implements the PipelineSchemaMemberDto interface.
 */
export function instanceOfPipelineSchemaMemberDto(value: object): boolean {
    return true;
}

export function PipelineSchemaMemberDtoFromJSON(json: any): PipelineSchemaMemberDto {
    return PipelineSchemaMemberDtoFromJSONTyped(json, false);
}

export function PipelineSchemaMemberDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PipelineSchemaMemberDto {
    if (json == null) {
        return json;
    }
    return {
        
        'pschemaId': json['pschemaId'] == null ? undefined : json['pschemaId'],
        'memberType': json['memberType'] == null ? undefined : json['memberType'],
        'memberId': json['memberId'] == null ? undefined : json['memberId'],
        'roleType': json['roleType'] == null ? undefined : json['roleType'],
    };
}

export function PipelineSchemaMemberDtoToJSON(value?: PipelineSchemaMemberDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'pschemaId': value['pschemaId'],
        'memberType': value['memberType'],
        'memberId': value['memberId'],
        'roleType': value['roleType'],
    };
}

