/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SignerDto } from './SignerDto';
import {
    SignerDtoFromJSON,
    SignerDtoFromJSONTyped,
    SignerDtoToJSON,
} from './SignerDto';
import type { TraceSchemaDto } from './TraceSchemaDto';
import {
    TraceSchemaDtoFromJSON,
    TraceSchemaDtoFromJSONTyped,
    TraceSchemaDtoToJSON,
} from './TraceSchemaDto';
import type { TraceSchemaLinkDto } from './TraceSchemaLinkDto';
import {
    TraceSchemaLinkDtoFromJSON,
    TraceSchemaLinkDtoFromJSONTyped,
    TraceSchemaLinkDtoToJSON,
} from './TraceSchemaLinkDto';
import type { UserDto } from './UserDto';
import {
    UserDtoFromJSON,
    UserDtoFromJSONTyped,
    UserDtoToJSON,
} from './UserDto';

/**
 * Pschema data to update an existing pschema
 * @export
 * @interface PipelineSchemaDto
 */
export interface PipelineSchemaDto {
    /**
     * The unique identifier for the schema, used internally to uniquely distinguish each schema within the system.
     * @type {number}
     * @memberof PipelineSchemaDto
     */
    id?: number;
    /**
     * The tenant identifier that this schema belongs to, ensuring that the schema is associated with a specific tenant and maintaining data isolation in a multi-tenant system.
     * @type {number}
     * @memberof PipelineSchemaDto
     */
    tenantId?: number;
    /**
     * A human-readable alias for the schema, used for easier identification and management within the system.
     * @type {string}
     * @memberof PipelineSchemaDto
     */
    alias?: string;
    /**
     * The title of the pipeline schema, providing a human-readable name that describes its purpose or function.
     * @type {string}
     * @memberof PipelineSchemaDto
     */
    title?: string;
    /**
     * A detailed description of what this pipeline schema is or does.
     * @type {string}
     * @memberof PipelineSchemaDto
     */
    description?: string;
    /**
     * Indicates whether the process identifiers within this schema are controlled by SDP or by the external system.
     * @type {boolean}
     * @memberof PipelineSchemaDto
     */
    usesExternalIds?: boolean;
    /**
     * The timestamp marking when the schema was created, providing traceability and helping in tracking the schema's lifecycle.
     * @type {Date}
     * @memberof PipelineSchemaDto
     */
    createdAt?: Date;
    /**
     * 
     * @type {UserDto}
     * @memberof PipelineSchemaDto
     */
    creator?: UserDto;
    /**
     * The parent pipeline schema from which this schema is derived.
     * @type {number}
     * @memberof PipelineSchemaDto
     */
    parentId?: number;
    /**
     * The root pipeline schema from which all versions are derived.
     * @type {number}
     * @memberof PipelineSchemaDto
     */
    rootId?: number;
    /**
     * The alias of the current version of the pipeline schema.
     * @type {string}
     * @memberof PipelineSchemaDto
     */
    version?: string;
    /**
     * The title or description of the current version.
     * @type {string}
     * @memberof PipelineSchemaDto
     */
    vtitle?: string;
    /**
     * Status of the version of a pipeline schema.
     * @type {string}
     * @memberof PipelineSchemaDto
     */
    status?: PipelineSchemaDtoStatusEnum;
    /**
     * 
     * @type {Array<SignerDto>}
     * @memberof PipelineSchemaDto
     */
    signerList?: Array<SignerDto>;
    /**
     * 
     * @type {Array<TraceSchemaDto>}
     * @memberof PipelineSchemaDto
     */
    tschemaList?: Array<TraceSchemaDto>;
    /**
     * 
     * @type {Array<TraceSchemaLinkDto>}
     * @memberof PipelineSchemaDto
     */
    traceLinkList?: Array<TraceSchemaLinkDto>;
}


/**
 * @export
 */
export const PipelineSchemaDtoStatusEnum = {
    Draft: 'DRAFT',
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;
export type PipelineSchemaDtoStatusEnum = typeof PipelineSchemaDtoStatusEnum[keyof typeof PipelineSchemaDtoStatusEnum];


/**
 * Check if a given object implements the PipelineSchemaDto interface.
 */
export function instanceOfPipelineSchemaDto(value: object): boolean {
    return true;
}

export function PipelineSchemaDtoFromJSON(json: any): PipelineSchemaDto {
    return PipelineSchemaDtoFromJSONTyped(json, false);
}

export function PipelineSchemaDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PipelineSchemaDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'tenantId': json['tenantId'] == null ? undefined : json['tenantId'],
        'alias': json['alias'] == null ? undefined : json['alias'],
        'title': json['title'] == null ? undefined : json['title'],
        'description': json['description'] == null ? undefined : json['description'],
        'usesExternalIds': json['usesExternalIds'] == null ? undefined : json['usesExternalIds'],
        'createdAt': json['createdAt'] == null ? undefined : (new Date(json['createdAt'])),
        'creator': json['creator'] == null ? undefined : UserDtoFromJSON(json['creator']),
        'parentId': json['parentId'] == null ? undefined : json['parentId'],
        'rootId': json['rootId'] == null ? undefined : json['rootId'],
        'version': json['version'] == null ? undefined : json['version'],
        'vtitle': json['vtitle'] == null ? undefined : json['vtitle'],
        'status': json['status'] == null ? undefined : json['status'],
        'signerList': json['signerList'] == null ? undefined : ((json['signerList'] as Array<any>).map(SignerDtoFromJSON)),
        'tschemaList': json['tschemaList'] == null ? undefined : ((json['tschemaList'] as Array<any>).map(TraceSchemaDtoFromJSON)),
        'traceLinkList': json['traceLinkList'] == null ? undefined : ((json['traceLinkList'] as Array<any>).map(TraceSchemaLinkDtoFromJSON)),
    };
}

export function PipelineSchemaDtoToJSON(value?: PipelineSchemaDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'tenantId': value['tenantId'],
        'alias': value['alias'],
        'title': value['title'],
        'description': value['description'],
        'usesExternalIds': value['usesExternalIds'],
        'createdAt': value['createdAt'] == null ? undefined : ((value['createdAt']).toISOString()),
        'creator': UserDtoToJSON(value['creator']),
        'parentId': value['parentId'],
        'rootId': value['rootId'],
        'version': value['version'],
        'vtitle': value['vtitle'],
        'status': value['status'],
        'signerList': value['signerList'] == null ? undefined : ((value['signerList'] as Array<any>).map(SignerDtoToJSON)),
        'tschemaList': value['tschemaList'] == null ? undefined : ((value['tschemaList'] as Array<any>).map(TraceSchemaDtoToJSON)),
        'traceLinkList': value['traceLinkList'] == null ? undefined : ((value['traceLinkList'] as Array<any>).map(TraceSchemaLinkDtoToJSON)),
    };
}

