/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EntityDto } from './EntityDto';
import {
    EntityDtoFromJSON,
    EntityDtoFromJSONTyped,
    EntityDtoToJSON,
} from './EntityDto';
import type { UserAttributeDto } from './UserAttributeDto';
import {
    UserAttributeDtoFromJSON,
    UserAttributeDtoFromJSONTyped,
    UserAttributeDtoToJSON,
} from './UserAttributeDto';

/**
 * User data to update an existing user
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * User identifier if has an account, null otherwise
     * @type {number}
     * @memberof UserDto
     */
    id?: number;
    /**
     * Tenant identifier
     * @type {number}
     * @memberof UserDto
     */
    tenantId?: number;
    /**
     * Entitlement identifiers
     * @type {Array<string>}
     * @memberof UserDto
     */
    entitlementNames?: Array<string>;
    /**
     * Whether fully enrolled account
     * @type {boolean}
     * @memberof UserDto
     */
    enrolled?: boolean;
    /**
     * Represents the various statuses a user can have within the system.
     * @type {string}
     * @memberof UserDto
     */
    status?: UserDtoStatusEnum;
    /**
     * Email
     * @type {string}
     * @memberof UserDto
     */
    email?: string;
    /**
     * First name
     * @type {string}
     * @memberof UserDto
     */
    firstName?: string;
    /**
     * Last name
     * @type {string}
     * @memberof UserDto
     */
    lastName?: string;
    /**
     * Phone number
     * @type {string}
     * @memberof UserDto
     */
    phone?: string;
    /**
     * 
     * @type {EntityDto}
     * @memberof UserDto
     */
    entity?: EntityDto;
    /**
     * User attribute map
     * @type {Array<UserAttributeDto>}
     * @memberof UserDto
     */
    attributeList?: Array<UserAttributeDto>;
    /**
     * Country (based on Local)
     * @type {string}
     * @memberof UserDto
     */
    country?: string;
    /**
     * Defines the set of supported languages for translation.
     * @type {string}
     * @memberof UserDto
     */
    language?: UserDtoLanguageEnum;
    /**
     * Creation date
     * @type {Date}
     * @memberof UserDto
     */
    createdAt?: Date;
    /**
     * 
     * @type {UserDto}
     * @memberof UserDto
     */
    creator?: UserDto;
}


/**
 * @export
 */
export const UserDtoStatusEnum = {
    Disabled: 'DISABLED',
    Invited: 'INVITED',
    Refused: 'REFUSED',
    Enabled: 'ENABLED',
    Suspended: 'SUSPENDED',
    Banned: 'BANNED',
    Deleted: 'DELETED'
} as const;
export type UserDtoStatusEnum = typeof UserDtoStatusEnum[keyof typeof UserDtoStatusEnum];

/**
 * @export
 */
export const UserDtoLanguageEnum = {
    En: 'EN',
    Fr: 'FR',
    De: 'DE',
    Pl: 'PL',
    Sv: 'SV'
} as const;
export type UserDtoLanguageEnum = typeof UserDtoLanguageEnum[keyof typeof UserDtoLanguageEnum];


/**
 * Check if a given object implements the UserDto interface.
 */
export function instanceOfUserDto(value: object): boolean {
    return true;
}

export function UserDtoFromJSON(json: any): UserDto {
    return UserDtoFromJSONTyped(json, false);
}

export function UserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'tenantId': json['tenantId'] == null ? undefined : json['tenantId'],
        'entitlementNames': json['entitlementNames'] == null ? undefined : json['entitlementNames'],
        'enrolled': json['enrolled'] == null ? undefined : json['enrolled'],
        'status': json['status'] == null ? undefined : json['status'],
        'email': json['email'] == null ? undefined : json['email'],
        'firstName': json['firstName'] == null ? undefined : json['firstName'],
        'lastName': json['lastName'] == null ? undefined : json['lastName'],
        'phone': json['phone'] == null ? undefined : json['phone'],
        'entity': json['entity'] == null ? undefined : EntityDtoFromJSON(json['entity']),
        'attributeList': json['attributeList'] == null ? undefined : ((json['attributeList'] as Array<any>).map(UserAttributeDtoFromJSON)),
        'country': json['country'] == null ? undefined : json['country'],
        'language': json['language'] == null ? undefined : json['language'],
        'createdAt': json['createdAt'] == null ? undefined : (new Date(json['createdAt'])),
        'creator': json['creator'] == null ? undefined : UserDtoFromJSON(json['creator']),
    };
}

export function UserDtoToJSON(value?: UserDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'tenantId': value['tenantId'],
        'entitlementNames': value['entitlementNames'],
        'enrolled': value['enrolled'],
        'status': value['status'],
        'email': value['email'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'phone': value['phone'],
        'entity': EntityDtoToJSON(value['entity']),
        'attributeList': value['attributeList'] == null ? undefined : ((value['attributeList'] as Array<any>).map(UserAttributeDtoToJSON)),
        'country': value['country'],
        'language': value['language'],
        'createdAt': value['createdAt'] == null ? undefined : ((value['createdAt']).toISOString()),
        'creator': UserDtoToJSON(value['creator']),
    };
}

