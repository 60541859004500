/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  UserPermissionDto,
} from '../models/index';
import {
    UserPermissionDtoFromJSON,
    UserPermissionDtoToJSON,
} from '../models/index';

export interface UserPermissionDeleteRequest {
    userPermissionDto: UserPermissionDto;
}

export interface UserPermissionInsertRequest {
    userPermissionDto: UserPermissionDto;
}

export interface UserPermissionSelectByUserIdRequest {
    userId: number;
}

/**
 * 
 */
export class UserPermissionManagementApi extends runtime.BaseAPI {

    /**
     * Revokes permissions from a user based on the UserPermissionDto provided. If only userId is specified, all permissions linked to that user are deleted. If both userId and permissionId are provided, a specific permission is removed from the user.
     * Revoke user permissions
     */
    async userPermissionDeleteRaw(requestParameters: UserPermissionDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['userPermissionDto'] == null) {
            throw new runtime.RequiredError(
                'userPermissionDto',
                'Required parameter "userPermissionDto" was null or undefined when calling userPermissionDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/user-permission`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: UserPermissionDtoToJSON(requestParameters['userPermissionDto']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Revokes permissions from a user based on the UserPermissionDto provided. If only userId is specified, all permissions linked to that user are deleted. If both userId and permissionId are provided, a specific permission is removed from the user.
     * Revoke user permissions
     */
    async userPermissionDelete(requestParameters: UserPermissionDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.userPermissionDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This endpoint allows for the assignment of a new permission to a user. It requires a UserPermissionDto objectthat specifies the user and the permission to be assigned. This operation ensures that users have the necessary permissions to access various resources or perform specific actions within the application.
     * Assign a new permission to a user
     */
    async userPermissionInsertRaw(requestParameters: UserPermissionInsertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserPermissionDto>> {
        if (requestParameters['userPermissionDto'] == null) {
            throw new runtime.RequiredError(
                'userPermissionDto',
                'Required parameter "userPermissionDto" was null or undefined when calling userPermissionInsert().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/user-permission`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserPermissionDtoToJSON(requestParameters['userPermissionDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserPermissionDtoFromJSON(jsonValue));
    }

    /**
     * This endpoint allows for the assignment of a new permission to a user. It requires a UserPermissionDto objectthat specifies the user and the permission to be assigned. This operation ensures that users have the necessary permissions to access various resources or perform specific actions within the application.
     * Assign a new permission to a user
     */
    async userPermissionInsert(requestParameters: UserPermissionInsertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserPermissionDto> {
        const response = await this.userPermissionInsertRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a list of all permissions assigned to a specific user, identified by their unique user ID. This endpoint facilitates the management and overview of user-specific permissions, aiding in access control and security measures.
     * Get permissions by user
     */
    async userPermissionSelectByUserIdRaw(requestParameters: UserPermissionSelectByUserIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserPermissionDto>>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling userPermissionSelectByUserId().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/user-permission/by-user/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserPermissionDtoFromJSON));
    }

    /**
     * Retrieves a list of all permissions assigned to a specific user, identified by their unique user ID. This endpoint facilitates the management and overview of user-specific permissions, aiding in access control and security measures.
     * Get permissions by user
     */
    async userPermissionSelectByUserId(requestParameters: UserPermissionSelectByUserIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserPermissionDto>> {
        const response = await this.userPermissionSelectByUserIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
