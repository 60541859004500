/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  UserDto,
  UserFilter,
  UserView,
} from '../models/index';
import {
    UserDtoFromJSON,
    UserDtoToJSON,
    UserFilterFromJSON,
    UserFilterToJSON,
    UserViewFromJSON,
    UserViewToJSON,
} from '../models/index';

export interface UploadCSVFileRequest {
    templateAlias: string;
    file: Blob;
}

export interface UserDeleteRequest {
    userId: number;
}

export interface UserInsertRequest {
    userDto: UserDto;
}

export interface UserSearchRequest {
    userFilter: UserFilter;
}

export interface UserSelectByEmailRequest {
    email: string;
}

export interface UserSelectByIdRequest {
    userId: number;
}

export interface UserUpdateRequest {
    userDto: UserDto;
}

/**
 * 
 */
export class UserManagementApi extends runtime.BaseAPI {

    /**
     * Uploads a CSV file for processing. The file must be associated with a specified template alias.
     * Upload a CSV file with users
     */
    async uploadCSVFileRaw(requestParameters: UploadCSVFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['templateAlias'] == null) {
            throw new runtime.RequiredError(
                'templateAlias',
                'Required parameter "templateAlias" was null or undefined when calling uploadCSVFile().'
            );
        }

        if (requestParameters['file'] == null) {
            throw new runtime.RequiredError(
                'file',
                'Required parameter "file" was null or undefined when calling uploadCSVFile().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['templateAlias'] != null) {
            queryParameters['templateAlias'] = requestParameters['templateAlias'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['file'] != null) {
            formParams.append('file', requestParameters['file'] as any);
        }

        const response = await this.request({
            path: `/api/user/csv-import`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Uploads a CSV file for processing. The file must be associated with a specified template alias.
     * Upload a CSV file with users
     */
    async uploadCSVFile(requestParameters: UploadCSVFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.uploadCSVFileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes a user by their unique identifier (ID) and returns the count of deleted records.
     * Delete a user
     */
    async userDeleteRaw(requestParameters: UserDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling userDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/user/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Deletes a user by their unique identifier (ID) and returns the count of deleted records.
     * Delete a user
     */
    async userDelete(requestParameters: UserDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.userDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates a new user with the given details and returns the created user information.
     * Create a new user
     */
    async userInsertRaw(requestParameters: UserInsertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserDto>> {
        if (requestParameters['userDto'] == null) {
            throw new runtime.RequiredError(
                'userDto',
                'Required parameter "userDto" was null or undefined when calling userInsert().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/user`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserDtoToJSON(requestParameters['userDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDtoFromJSON(jsonValue));
    }

    /**
     * Creates a new user with the given details and returns the created user information.
     * Create a new user
     */
    async userInsert(requestParameters: UserInsertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserDto> {
        const response = await this.userInsertRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Searches for users based on a set of filters provided in the request body.
     * Search for users
     */
    async userSearchRaw(requestParameters: UserSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserView>> {
        if (requestParameters['userFilter'] == null) {
            throw new runtime.RequiredError(
                'userFilter',
                'Required parameter "userFilter" was null or undefined when calling userSearch().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/user/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserFilterToJSON(requestParameters['userFilter']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserViewFromJSON(jsonValue));
    }

    /**
     * Searches for users based on a set of filters provided in the request body.
     * Search for users
     */
    async userSearch(requestParameters: UserSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserView> {
        const response = await this.userSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a list of all users in the system.
     * Get all users
     */
    async userSelectRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserDtoFromJSON));
    }

    /**
     * Retrieves a list of all users in the system.
     * Get all users
     */
    async userSelect(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserDto>> {
        const response = await this.userSelectRaw(initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a single user by their email address.
     * Get user by email
     */
    async userSelectByEmailRaw(requestParameters: UserSelectByEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserDto>> {
        if (requestParameters['email'] == null) {
            throw new runtime.RequiredError(
                'email',
                'Required parameter "email" was null or undefined when calling userSelectByEmail().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/user/by-email/{email}`.replace(`{${"email"}}`, encodeURIComponent(String(requestParameters['email']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDtoFromJSON(jsonValue));
    }

    /**
     * Retrieves a single user by their email address.
     * Get user by email
     */
    async userSelectByEmail(requestParameters: UserSelectByEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserDto> {
        const response = await this.userSelectByEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a single user by their unique identifier (ID).
     * Get user by ID
     */
    async userSelectByIdRaw(requestParameters: UserSelectByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserDto>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling userSelectById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/user/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDtoFromJSON(jsonValue));
    }

    /**
     * Retrieves a single user by their unique identifier (ID).
     * Get user by ID
     */
    async userSelectById(requestParameters: UserSelectByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserDto> {
        const response = await this.userSelectByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates an existing user\'s details based on the provided information and returns the updated user information.
     * Update an existing user
     */
    async userUpdateRaw(requestParameters: UserUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserDto>> {
        if (requestParameters['userDto'] == null) {
            throw new runtime.RequiredError(
                'userDto',
                'Required parameter "userDto" was null or undefined when calling userUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/user`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UserDtoToJSON(requestParameters['userDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDtoFromJSON(jsonValue));
    }

    /**
     * Updates an existing user\'s details based on the provided information and returns the updated user information.
     * Update an existing user
     */
    async userUpdate(requestParameters: UserUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserDto> {
        const response = await this.userUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
