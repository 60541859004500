/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * New user information
 * @export
 * @interface SignupRequest
 */
export interface SignupRequest {
    /**
     * Tenant name if known
     * @type {string}
     * @memberof SignupRequest
     */
    tenantName?: string;
    /**
     * Email
     * @type {string}
     * @memberof SignupRequest
     */
    email?: string;
    /**
     * Password
     * @type {string}
     * @memberof SignupRequest
     */
    password?: string;
    /**
     * First name
     * @type {string}
     * @memberof SignupRequest
     */
    firstName?: string;
    /**
     * Last name
     * @type {string}
     * @memberof SignupRequest
     */
    lastName?: string;
    /**
     * Phone number
     * @type {string}
     * @memberof SignupRequest
     */
    phone?: string;
    /**
     * The name of the entity. This is a human-readable alias.
     * @type {string}
     * @memberof SignupRequest
     */
    entityAlias?: string;
    /**
     * A map of attributes related to the user
     * @type {{ [key: string]: string; }}
     * @memberof SignupRequest
     */
    attributeMap?: { [key: string]: string; };
    /**
     * Locale code
     * @type {string}
     * @memberof SignupRequest
     */
    localeCode?: SignupRequestLocaleCodeEnum;
}


/**
 * @export
 */
export const SignupRequestLocaleCodeEnum = {
    En: 'EN',
    Fr: 'FR',
    De: 'DE',
    Pt: 'PT',
    It: 'IT',
    Nl: 'NL',
    Sp: 'SP',
    Lu: 'LU'
} as const;
export type SignupRequestLocaleCodeEnum = typeof SignupRequestLocaleCodeEnum[keyof typeof SignupRequestLocaleCodeEnum];


/**
 * Check if a given object implements the SignupRequest interface.
 */
export function instanceOfSignupRequest(value: object): boolean {
    return true;
}

export function SignupRequestFromJSON(json: any): SignupRequest {
    return SignupRequestFromJSONTyped(json, false);
}

export function SignupRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SignupRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'tenantName': json['tenantName'] == null ? undefined : json['tenantName'],
        'email': json['email'] == null ? undefined : json['email'],
        'password': json['password'] == null ? undefined : json['password'],
        'firstName': json['firstName'] == null ? undefined : json['firstName'],
        'lastName': json['lastName'] == null ? undefined : json['lastName'],
        'phone': json['phone'] == null ? undefined : json['phone'],
        'entityAlias': json['entityAlias'] == null ? undefined : json['entityAlias'],
        'attributeMap': json['attributeMap'] == null ? undefined : json['attributeMap'],
        'localeCode': json['localeCode'] == null ? undefined : json['localeCode'],
    };
}

export function SignupRequestToJSON(value?: SignupRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'tenantName': value['tenantName'],
        'email': value['email'],
        'password': value['password'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'phone': value['phone'],
        'entityAlias': value['entityAlias'],
        'attributeMap': value['attributeMap'],
        'localeCode': value['localeCode'],
    };
}

