/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Represents a log entry.
 * @export
 * @interface LogDto
 */
export interface LogDto {
    /**
     * Timestamp when the log was created.
     * @type {Date}
     * @memberof LogDto
     */
    createdAt?: Date;
    /**
     * Unique identifier (UUID) for the log entry.
     * @type {string}
     * @memberof LogDto
     */
    uuid?: string;
    /**
     * Identifier for the user who generated the log.
     * @type {number}
     * @memberof LogDto
     */
    userId?: number;
    /**
     * Message content of the log.
     * @type {string}
     * @memberof LogDto
     */
    message?: string;
    /**
     * Context information related to the log entry.
     * @type {string}
     * @memberof LogDto
     */
    context?: string;
    /**
     * Stack trace information if applicable.
     * @type {string}
     * @memberof LogDto
     */
    stacktrace?: string;
}

/**
 * Check if a given object implements the LogDto interface.
 */
export function instanceOfLogDto(value: object): boolean {
    return true;
}

export function LogDtoFromJSON(json: any): LogDto {
    return LogDtoFromJSONTyped(json, false);
}

export function LogDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LogDto {
    if (json == null) {
        return json;
    }
    return {
        
        'createdAt': json['createdAt'] == null ? undefined : (new Date(json['createdAt'])),
        'uuid': json['uuid'] == null ? undefined : json['uuid'],
        'userId': json['userId'] == null ? undefined : json['userId'],
        'message': json['message'] == null ? undefined : json['message'],
        'context': json['context'] == null ? undefined : json['context'],
        'stacktrace': json['stacktrace'] == null ? undefined : json['stacktrace'],
    };
}

export function LogDtoToJSON(value?: LogDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'createdAt': value['createdAt'] == null ? undefined : ((value['createdAt']).toISOString()),
        'uuid': value['uuid'],
        'userId': value['userId'],
        'message': value['message'],
        'context': value['context'],
        'stacktrace': value['stacktrace'],
    };
}

