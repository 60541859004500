/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * The new attributes for the host
 * @export
 * @interface HostDto
 */
export interface HostDto {
    /**
     * The alias of the host. This is a user-friendly name or identifier used for easier reference.
     * @type {string}
     * @memberof HostDto
     */
    alias?: string;
    /**
     * The official name of the host as recognized within the network.
     * @type {string}
     * @memberof HostDto
     */
    name?: string;
    /**
     * The IP address of the host, used for network communication. This should be a valid IPv4 or IPv6 address.
     * @type {string}
     * @memberof HostDto
     */
    ip?: string;
    /**
     * The network port number that the host listens on. This is typically associated with the host's main application or service.
     * @type {number}
     * @memberof HostDto
     */
    port?: number;
    /**
     * The current status of the host, indicating whether it is active, inactive, or in a fault state.
     * @type {string}
     * @memberof HostDto
     */
    status?: HostDtoStatusEnum;
    /**
     * The timestamp of when the host node was last (re)started. This is important for tracking uptime and diagnosing issues.
     * @type {Date}
     * @memberof HostDto
     */
    startedAt?: Date;
    /**
     * The timestamp of when the host node was last stopped. This is important for tracking uptime and diagnosing issues.
     * @type {Date}
     * @memberof HostDto
     */
    stoppedAt?: Date;
    /**
     * The timestamp of the last check-in or heartbeat, confirming the node is still active. Used for monitoring host availability.
     * @type {Date}
     * @memberof HostDto
     */
    aliveAt?: Date;
}


/**
 * @export
 */
export const HostDtoStatusEnum = {
    Running: 'RUNNING',
    Stopped: 'STOPPED',
    Failed: 'FAILED'
} as const;
export type HostDtoStatusEnum = typeof HostDtoStatusEnum[keyof typeof HostDtoStatusEnum];


/**
 * Check if a given object implements the HostDto interface.
 */
export function instanceOfHostDto(value: object): boolean {
    return true;
}

export function HostDtoFromJSON(json: any): HostDto {
    return HostDtoFromJSONTyped(json, false);
}

export function HostDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): HostDto {
    if (json == null) {
        return json;
    }
    return {
        
        'alias': json['alias'] == null ? undefined : json['alias'],
        'name': json['name'] == null ? undefined : json['name'],
        'ip': json['ip'] == null ? undefined : json['ip'],
        'port': json['port'] == null ? undefined : json['port'],
        'status': json['status'] == null ? undefined : json['status'],
        'startedAt': json['startedAt'] == null ? undefined : (new Date(json['startedAt'])),
        'stoppedAt': json['stoppedAt'] == null ? undefined : (new Date(json['stoppedAt'])),
        'aliveAt': json['aliveAt'] == null ? undefined : (new Date(json['aliveAt'])),
    };
}

export function HostDtoToJSON(value?: HostDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'alias': value['alias'],
        'name': value['name'],
        'ip': value['ip'],
        'port': value['port'],
        'status': value['status'],
        'startedAt': value['startedAt'] == null ? undefined : ((value['startedAt']).toISOString()),
        'stoppedAt': value['stoppedAt'] == null ? undefined : ((value['stoppedAt']).toISOString()),
        'aliveAt': value['aliveAt'] == null ? undefined : ((value['aliveAt']).toISOString()),
    };
}

