/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { KeyVal } from './KeyVal';
import {
    KeyValFromJSON,
    KeyValFromJSONTyped,
    KeyValToJSON,
} from './KeyVal';

/**
 * ???
 * @export
 * @interface HashDto
 */
export interface HashDto {
    /**
     * 
     * @type {number}
     * @memberof HashDto
     */
    algorithmId?: number;
    /**
     * The hash algorithm used (e.g., SHA, BLAKE), detailing the method of hash calculation.
     * @type {string}
     * @memberof HashDto
     */
    algorithm?: string;
    /**
     * The hash value, represented as a Base64-encoded string, ensuring data integrity and non-repudiation.
     * @type {string}
     * @memberof HashDto
     */
    hash?: string;
    /**
     * 
     * @type {string}
     * @memberof HashDto
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof HashDto
     */
    encoding?: HashDtoEncodingEnum;
    /**
     * 
     * @type {Array<KeyVal>}
     * @memberof HashDto
     */
    fieldList?: Array<KeyVal>;
}


/**
 * @export
 */
export const HashDtoEncodingEnum = {
    Hex: 'HEX',
    Base64: 'BASE64'
} as const;
export type HashDtoEncodingEnum = typeof HashDtoEncodingEnum[keyof typeof HashDtoEncodingEnum];


/**
 * Check if a given object implements the HashDto interface.
 */
export function instanceOfHashDto(value: object): boolean {
    return true;
}

export function HashDtoFromJSON(json: any): HashDto {
    return HashDtoFromJSONTyped(json, false);
}

export function HashDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): HashDto {
    if (json == null) {
        return json;
    }
    return {
        
        'algorithmId': json['algorithmId'] == null ? undefined : json['algorithmId'],
        'algorithm': json['algorithm'] == null ? undefined : json['algorithm'],
        'hash': json['hash'] == null ? undefined : json['hash'],
        'url': json['url'] == null ? undefined : json['url'],
        'encoding': json['encoding'] == null ? undefined : json['encoding'],
        'fieldList': json['fieldList'] == null ? undefined : ((json['fieldList'] as Array<any>).map(KeyValFromJSON)),
    };
}

export function HashDtoToJSON(value?: HashDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'algorithmId': value['algorithmId'],
        'algorithm': value['algorithm'],
        'hash': value['hash'],
        'url': value['url'],
        'encoding': value['encoding'],
        'fieldList': value['fieldList'] == null ? undefined : ((value['fieldList'] as Array<any>).map(KeyValToJSON)),
    };
}

