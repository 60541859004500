/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UserDto } from './UserDto';
import {
    UserDtoFromJSON,
    UserDtoFromJSONTyped,
    UserDtoToJSON,
} from './UserDto';

/**
 * Token data to update an existing security token
 * @export
 * @interface TokenSetupDto
 */
export interface TokenSetupDto {
    /**
     * The unique identifier of the token setup.
     * @type {number}
     * @memberof TokenSetupDto
     */
    id?: number;
    /**
     * The identifier of the tenant that the token setup belongs to, linking it to a specific tenant context within a multi-tenant system.
     * @type {number}
     * @memberof TokenSetupDto
     */
    tenantId?: number;
    /**
     * A human-readable alias to identify the token setup.
     * @type {string}
     * @memberof TokenSetupDto
     */
    alias?: string;
    /**
     * Defines the various purposes that a security token can serve within the system. To access endpoints, the security token must specify a relevant purpose.
     * @type {string}
     * @memberof TokenSetupDto
     */
    purpose?: TokenSetupDtoPurposeEnum;
    /**
     * The lifespan of the token in seconds, after which the token expires.
     * @type {number}
     * @memberof TokenSetupDto
     */
    lifespan?: number;
    /**
     * The property indicates if the token can be refreshed.
     * @type {boolean}
     * @memberof TokenSetupDto
     */
    canRefresh?: boolean;
    /**
     * The timestamp when the token setup was created.
     * @type {Date}
     * @memberof TokenSetupDto
     */
    createdAt?: Date;
    /**
     * 
     * @type {UserDto}
     * @memberof TokenSetupDto
     */
    creator?: UserDto;
}


/**
 * @export
 */
export const TokenSetupDtoPurposeEnum = {
    Login: 'LOGIN',
    Notification: 'NOTIFICATION',
    Signup: 'SIGNUP',
    Reset: 'RESET',
    ApiKey: 'API_KEY'
} as const;
export type TokenSetupDtoPurposeEnum = typeof TokenSetupDtoPurposeEnum[keyof typeof TokenSetupDtoPurposeEnum];


/**
 * Check if a given object implements the TokenSetupDto interface.
 */
export function instanceOfTokenSetupDto(value: object): boolean {
    return true;
}

export function TokenSetupDtoFromJSON(json: any): TokenSetupDto {
    return TokenSetupDtoFromJSONTyped(json, false);
}

export function TokenSetupDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TokenSetupDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'tenantId': json['tenantId'] == null ? undefined : json['tenantId'],
        'alias': json['alias'] == null ? undefined : json['alias'],
        'purpose': json['purpose'] == null ? undefined : json['purpose'],
        'lifespan': json['lifespan'] == null ? undefined : json['lifespan'],
        'canRefresh': json['canRefresh'] == null ? undefined : json['canRefresh'],
        'createdAt': json['createdAt'] == null ? undefined : (new Date(json['createdAt'])),
        'creator': json['creator'] == null ? undefined : UserDtoFromJSON(json['creator']),
    };
}

export function TokenSetupDtoToJSON(value?: TokenSetupDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'tenantId': value['tenantId'],
        'alias': value['alias'],
        'purpose': value['purpose'],
        'lifespan': value['lifespan'],
        'canRefresh': value['canRefresh'],
        'createdAt': value['createdAt'] == null ? undefined : ((value['createdAt']).toISOString()),
        'creator': UserDtoToJSON(value['creator']),
    };
}

