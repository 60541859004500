import React, {ChangeEvent, useEffect, useState} from 'react';
import {userDelete, userInsert, userSearch, userUpdate} from '../../../api/UserService';
import {
    userPermissionDelete,
    userPermissionInsert,
    userPermissionSelectByUserId
} from '../../../api/UserPermissionService';
import {permissionSelect} from "../../../api/PermissionService";
import {entitySelect} from "../../../api/EntityService";
import Modal from "../../common/Modal";
import FileUploadButton from "../../common/FileUploadButton";
import {
    EntityDto,
    PermissionDto,
    UserDto,
    UserDtoStatusEnum,
    UserInsertRequest,
    UserPermissionDto,
    UserUpdateRequest
} from "../../../api/generated-react-client/src";
import {MdOutlineCreate, MdOutlineDeleteForever, MdOutlineLockPerson, MdOutlinePlaylistAdd} from "react-icons/md";
import {TbMailFast} from "react-icons/tb";
import styles from "./UserForm.module.css";
import {resetPassword} from "../../../api/AuthenticationService";

const UserForm: React.FC = () => {
    const [users, setUsers] = useState<UserDto[]>([]);
    const [entities, setEntities] = useState<EntityDto[]>([]);
    const [searchText, setSearchText] = useState('');
    const [isPermissionModalOpen, setIsPermissionModalOpen] = useState(false);
    const [createUserFormData, setCreateUserFormData] = useState<CreateUserFormData>({
        email: '@factiven.io',
        status: UserDtoStatusEnum.Disabled,
        firstName: '',
        lastName: '',
        entityId: 0
    });
    const [isUploadCsvModalOpen, setIsUploadCsvModalOpen] = useState(false);
    const [isEntityModalOpen, setIsEntityModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState<UserDto | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [showPopup, setShowPopup] = useState<boolean>(false);

    const handleError = (error: unknown) => {
        setError((error as Error).message);
        setShowPopup(true);
    };

    const fetchUsers = async () => {
        try {
            const userFilter = {text: searchText, offset: 0, limit: 10};
            const response = await userSearch({userFilter});
            setUsers(response.list || []);
        } catch (error) {
            handleError(error);
        }
    };

    useEffect(() => {
        fetchUsers();
    }, [searchText]);

    useEffect(() => {
        const fetchEntities = async () => {
            try {
                const entitiesFromApi = await entitySelect();
                setEntities(entitiesFromApi);
            } catch (error) {
                handleError(error);
            }
        };

        fetchEntities();
    }, []);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {name, value} = event.currentTarget;
        setCreateUserFormData(prevFormData => ({...prevFormData, [name]: value}));
    };

    const handleSelectChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>) => {
        const {name, value} = event.target;
        setCreateUserFormData(prevFormData => ({...prevFormData, [name]: value}));
    };

    const handleEditClick = async (user: UserDto, field: keyof UserDto) => {
        const currentValue = user[field]?.toString() || '';
        const newValue = prompt(`Edit ${field}`, currentValue);
        if (newValue !== null) {
            try {
                const updatedUser = {...user, [field]: newValue};
                await userUpdate({userDto: updatedUser});
                setUsers(users.map(u => (u.id === user.id ? updatedUser : u)));
            } catch (error) {
                handleError(error);
            }
        }
    };

    const handleNotify = async (userId: number) => {
        if (window.confirm('Are you sure you want to send a notification to this user?')) {
            try {
                // Add your notification logic here
            } catch (error) {
                handleError(error);
            }
        }
    };

    const handleDelete = async (userId: number) => {
        if (window.confirm('Are you sure you want to delete this user?')) {
            try {
                await userDelete({userId});
                setUsers(users.filter(user => user.id !== userId));
            } catch (error) {
                handleError(error);
            }
        }
    };

    const handleResetPassword = async (userMail: string) => {
        try {
            await resetPassword(userMail);
        } catch (error) {
            handleError(error);
        }
    };

    const handleStatusChange = async (user: UserDto, newStatus: UserDtoStatusEnum) => {
        try {
            const updatedUser = {...user, status: newStatus};
            await userUpdate({userDto: updatedUser});
            setUsers(users.map(u => (u.id === user.id ? updatedUser : u)));
        } catch (error) {
            handleError(error);
        }
    };

    const handleCreate = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const userDto: UserDto = {...createUserFormData};
        const requestParameters: UserInsertRequest = {userDto};
        try {
            await userInsert(requestParameters);
            fetchUsers();
            setIsPermissionModalOpen(false);
        } catch (error) {
            handleError(error);
        }
    };

    const handleEntityClick = (user: UserDto) => {
        setSelectedUser(user);
        setIsEntityModalOpen(true);
    };

    const handleEntityChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        if (selectedUser) {
            setSelectedUser({...selectedUser, entity: {id: parseInt(e.target.value)}});
        }
    };

    const handleSubmitEntity = async (e: React.FormEvent) => {
        e.preventDefault();
        if (selectedUser) {
            try {
                const entityDto: EntityDto = {id: selectedUser.entity?.id};
                const userDto: UserDto = {id: selectedUser.id, entity: entityDto};
                const requestParameters: UserUpdateRequest = {userDto};
                await userUpdate(requestParameters);
                setIsEntityModalOpen(false);
            } catch (error) {
                handleError(error);
            }
        }
    };

    const closeFileUploadModal = () => {
        setIsUploadCsvModalOpen(false);
    };

    return (
        <div className="formList">
            <div className="headerContainer">
                <b>User list</b>
                <div className="formGroup">
                    <input
                        type="text"
                        placeholder="Search text..."
                        value={searchText}
                        onChange={e => setSearchText(e.target.value)}
                    />
                    <button onClick={() => setIsUploadCsvModalOpen(true)}>Upload CSV</button>
                    <button onClick={() => setIsPermissionModalOpen(true)}>Create</button>
                </div>
                <Modal isOpen={isUploadCsvModalOpen} onClose={closeFileUploadModal}>
                    <div>
                        <h1>Upload CSV File</h1>
                        <FileUploadButton closeModal={closeFileUploadModal} onUploadSuccess={fetchUsers}/>
                    </div>
                </Modal>
                <Modal isOpen={isPermissionModalOpen} onClose={() => setIsPermissionModalOpen(false)}>
                    <form onSubmit={handleCreate}>
                        <div className="formGroup">
                            <label>Email</label>
                            <input
                                type="text"
                                name="email"
                                value={createUserFormData.email}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="formGroup">
                            <label>Status</label>
                            <select
                                name="status"
                                value={createUserFormData.status}
                                onChange={handleSelectChange}
                            >
                                {Object.values(UserDtoStatusEnum).map(status => (
                                    <option key={status} value={status}>
                                        {status}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="formGroup">
                            <label>First name</label>
                            <input
                                type="text"
                                name="firstName"
                                value={createUserFormData.firstName}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="formGroup">
                            <label>Last name</label>
                            <input
                                type="text"
                                name="lastName"
                                value={createUserFormData.lastName}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="formGroup">
                            <label>Entity</label>
                            <select
                                name="entityId"
                                value={createUserFormData.entityId}
                                onChange={handleSelectChange}
                            >
                                {entities.map(entity => (
                                    <option key={entity.id} value={entity.id}>
                                        {entity.alias}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="horizontalButtonContainer">
                            <button type="submit">Create</button>
                        </div>
                    </form>
                </Modal>
            </div>
            {showPopup && error !== null && (
                <div className="errorModal">
                    <div className="errorModalContent">
                        <p>Error: {error}</p>
                        <button onClick={() => setShowPopup(false)}>Close</button>
                    </div>
                </div>
            )}
            <table>
                <thead>
                <tr>
                    <th style={{width: '40px', textAlign: 'center'}}>ID</th>
                    <th style={{textAlign: 'left'}}>Email</th>
                    <th style={{width: '120px', textAlign: 'center'}}>Status</th>
                    <th style={{textAlign: 'left'}}>First name</th>
                    <th style={{textAlign: 'left'}}>Last name</th>
                    <th style={{width: '120px', textAlign: 'center'}}>Entity</th>
                    <th style={{width: '110px', textAlign: 'center'}}>Permissions</th>
                    <th style={{width: '170px', textAlign: 'center'}}>Created at</th>
                    <th style={{width: '100px', textAlign: 'center'}}>Actions</th>
                </tr>
                </thead>
                <tbody>
                {users.map((user, index) => (
                    <tr key={user.id} className={`row ${index % 2 === 0 ? 'even' : 'odd'}`}>
                        <td style={{width: '40px', textAlign: 'center'}}>{user.id}</td>
                        <td style={{
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                            wordBreak: 'break-all',
                            textAlign: 'left'
                        }}>
                            <div className="td-vertical">
                                {user.email}
                                <span className="editIcon" onClick={() => handleEditClick(user, 'email')}>
                                    <MdOutlineCreate/>
                                </span>
                            </div>
                        </td>
                        <td style={{width: '120px', textAlign: 'center'}}>
                            <select
                                value={user.status}
                                onChange={(e) => handleStatusChange(user, e.target.value as UserDtoStatusEnum)}
                            >
                                {Object.values(UserDtoStatusEnum).map(status => (
                                    <option key={status} value={status}>
                                        {status}
                                    </option>
                                ))}
                            </select>
                        </td>
                        <td style={{textAlign: 'left'}}>
                            <div className="td-vertical">
                                {user.firstName}
                                <span className="editIcon" onClick={() => handleEditClick(user, 'firstName')}>
                                    <MdOutlineCreate/>
                                </span>
                            </div>
                        </td>
                        <td style={{textAlign: 'left'}}>
                            <div className="td-vertical">
                                {user.lastName}
                                <span className="editIcon" onClick={() => handleEditClick(user, 'lastName')}>
                                    <MdOutlineCreate/>
                                </span>
                            </div>
                        </td>
                        <td style={{width: '120px', textAlign: 'center'}}>
                            <div className="td-vertical">
                                {user.entity?.alias}
                                <span className="editIcon" onClick={() => handleEntityClick(user)}>
                                    <MdOutlineCreate/>
                                </span>
                            </div>
                        </td>
                        <PermissionColumn user={user} setError={setError} setShowPopup={setShowPopup}/>
                        <td style={{
                            width: '170px',
                            textAlign: 'center'
                        }}>{user.createdAt?.toLocaleString() ?? 'Not available'}</td>
                        <td style={{width: '100px'}}>
                            <div className="icon-container">
                                <span onClick={() => handleNotify(user.id!)} className="notifyIcon">
                                <TbMailFast/>
                                </span>
                                <span onClick={() => handleDelete(user.id!)} className="deleteIcon">
                                <MdOutlineDeleteForever/>
                                </span>
                                <span onClick={() => handleResetPassword(user.email!)} className="notifyIcon">
                                    <MdOutlineLockPerson/>
                                </span>
                            </div>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
            {selectedUser && (
                <Modal isOpen={isEntityModalOpen} onClose={() => setIsEntityModalOpen(false)}>
                    <form onSubmit={handleSubmitEntity}>
                        <label htmlFor="entitySelector">Select Entity:</label>
                        <select id="entitySelector" value={selectedUser.entity?.id} onChange={handleEntityChange}>
                            {entities.map(entity => (
                                <option key={entity.id} value={entity.id}>{entity.alias}</option>
                            ))}
                        </select>
                        <button type="submit">Create</button>
                    </form>
                </Modal>
            )}
        </div>
    );
};

interface PermissionColumnProps {
    user: UserDto;
    setError: (error: string | null) => void;
    setShowPopup: (show: boolean) => void;
}

const PermissionColumn: React.FC<PermissionColumnProps> = ({user, setError, setShowPopup}) => {
    const [userPermissionList, setUserPermissionList] = useState<UserPermissionDto[]>([]);
    const [isUserPermissionModalOpen, setIsUserPermissionModalOpen] = useState(false);
    const [permissions, setPermissions] = useState<PermissionDto[]>([]);
    const [selectedPermission, setSelectedPermission] = useState<{ [key: string]: boolean }>({});

    useEffect(() => {
        const getAccessRights = async () => {
            try {
                const permissions = await permissionSelect();
                setPermissions(permissions);
            } catch (error) {
                setError((error as Error).message);
                setShowPopup(true);
            }
        };
        getAccessRights();
    }, []);

    useEffect(() => {
        const loadData = async () => {
            try {
                const paList = await userPermissionSelectByUserId({userId: user.id!});
                setUserPermissionList(paList);
            } catch (error) {
                setError((error as Error).message);
                setShowPopup(true);
            }
        };

        loadData();
    }, [user.id]);

    useEffect(() => {
        if (isUserPermissionModalOpen) {
            const userPermissionListIds = new Set(userPermissionList.map(up => String(up.permissionId)));
            const initialSelections = permissions.reduce<{ [key: string]: boolean }>((acc, right) => {
                const key = String(right.id);
                acc[key] = userPermissionListIds.has(key);
                return acc;
            }, {});

            setSelectedPermission(initialSelections);
        }
    }, [isUserPermissionModalOpen, permissions, userPermissionList]);

    const handleCheckboxChange = async (permissionId: number, isChecked: boolean) => {
        setSelectedPermission(prev => ({...prev, [permissionId]: isChecked}));
        const userPermissionDto: UserPermissionDto = {userId: user.id!, permissionId};
        try {
            if (isChecked) {
                await userPermissionInsert({userPermissionDto});
            } else {
                await userPermissionDelete({userPermissionDto});
            }
            const paList = await userPermissionSelectByUserId({userId: user.id!});
            setUserPermissionList(paList);
        } catch (error) {
            setError((error as Error).message);
            setShowPopup(true);
        }
    };

    return (
        <td style={{width: '110px', textAlign: 'center'}}>
            <div className="td-vertical-centred">
                {userPermissionList.map(upDto => upDto.permissionId).join(' | ')}
                <span onClick={() => setIsUserPermissionModalOpen(true)}
                      className="notifyIcon"><MdOutlinePlaylistAdd/>
                </span>
                {isUserPermissionModalOpen && (
                    <Modal isOpen={isUserPermissionModalOpen} onClose={() => setIsUserPermissionModalOpen(false)}>
                        <div className={`${styles.modalContent} ${styles.leftAlign}`}>
                            {permissions.map((right) => (
                                <div key={right.id}>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={!!selectedPermission[right.id!]}
                                            onChange={(e) => handleCheckboxChange(right.id!, e.target.checked)}
                                        />
                                        {right.alias}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </Modal>
                )}
            </div>
        </td>
    );
};

interface TableRowProps {
    user: UserDto;
}

interface CreateUserFormData {
    email: string;
    status: UserDtoStatusEnum;
    firstName: string;
    lastName: string;
    entityId: number;
}

export default UserForm;