import {Configuration} from "./generated-react-client/src";

const getToken = () => localStorage.getItem('authToken'); // Function to retrieve the auth token

const apiConfig = new Configuration({

    basePath: `${window.location.protocol}//${window.location.host}`.replace("/\\/+$/", ""),
    middleware: [{
        pre: (context) => {
            return new Promise((resolve) => {
                const token = getToken();
                if (token) {
                    context.init.headers = {
                        ...context.init.headers,
                        'Authorization': `${token}`, // Append the Authorization header with the token // todo: Bearer
                    };
                }
                console.log('context.url: ', context.url);

                return resolve(context);
            });
        }
    }],
});

export default apiConfig;
