/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * User attributes
 * @export
 * @interface UserAttributeDto
 */
export interface UserAttributeDto {
    /**
     * Attribute key
     * @type {string}
     * @memberof UserAttributeDto
     */
    key?: string;
    /**
     * Attribute value
     * @type {string}
     * @memberof UserAttributeDto
     */
    value?: string;
    /**
     * Attribute type
     * @type {string}
     * @memberof UserAttributeDto
     */
    type?: UserAttributeDtoTypeEnum;
    /**
     * Is the attribute read only
     * @type {boolean}
     * @memberof UserAttributeDto
     */
    readOnly?: boolean;
    /**
     * Is the attribute hidden
     * @type {boolean}
     * @memberof UserAttributeDto
     */
    hidden?: boolean;
}


/**
 * @export
 */
export const UserAttributeDtoTypeEnum = {
    Input: 'INPUT',
    List: 'LIST',
    Bool: 'BOOL',
    Date: 'DATE',
    Datetime: 'DATETIME',
    Period: 'PERIOD',
    Long: 'LONG',
    Memo: 'MEMO',
    Link: 'LINK',
    Rule: 'RULE'
} as const;
export type UserAttributeDtoTypeEnum = typeof UserAttributeDtoTypeEnum[keyof typeof UserAttributeDtoTypeEnum];


/**
 * Check if a given object implements the UserAttributeDto interface.
 */
export function instanceOfUserAttributeDto(value: object): boolean {
    return true;
}

export function UserAttributeDtoFromJSON(json: any): UserAttributeDto {
    return UserAttributeDtoFromJSONTyped(json, false);
}

export function UserAttributeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserAttributeDto {
    if (json == null) {
        return json;
    }
    return {
        
        'key': json['key'] == null ? undefined : json['key'],
        'value': json['value'] == null ? undefined : json['value'],
        'type': json['type'] == null ? undefined : json['type'],
        'readOnly': json['readOnly'] == null ? undefined : json['readOnly'],
        'hidden': json['hidden'] == null ? undefined : json['hidden'],
    };
}

export function UserAttributeDtoToJSON(value?: UserAttributeDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'key': value['key'],
        'value': value['value'],
        'type': value['type'],
        'readOnly': value['readOnly'],
        'hidden': value['hidden'],
    };
}

