import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import styles from './Header.module.css';
import {TbUserShield} from 'react-icons/tb';
import {FaGlobe} from 'react-icons/fa';
import {useTranslation} from 'react-i18next';

type SubmenuVisibility = {
    evidence: boolean;
    platform: boolean;
    admin: boolean;
    language: boolean;
    [key: string]: boolean; // Adding index signature
};

// Define supported language codes
type LanguageCode = 'en' | 'fr' | 'de' | 'pl';

// Map language codes to their readable names
const languageNames: Record<LanguageCode, string> = {
    en: "English",
    fr: "Français",
    de: "Deutsch",
    pl: "Polski"
};

const Header: React.FC = () => {

    const [submenuVisibility, setSubmenuVisibility] = useState<SubmenuVisibility>({
        evidence: false,
        platform: false,
        admin: false,
        language: false
    });

    const {i18n, t} = useTranslation();
    const currentLanguageName = languageNames[i18n.language as LanguageCode] || "Unknown";

    const toggleSubmenu = (menuKey: string) => {
        setSubmenuVisibility(prev => ({
            ...prev,
            [menuKey]: !prev[menuKey] // TypeScript now understands this operation
        }));
    };

    const hideAllSubmenus = () => {
        setSubmenuVisibility({
            evidence: false,
            platform: false,
            admin: false,
            language: false
        });
    };

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
        setSubmenuVisibility(prev => ({
            ...prev,
            language: false // Hide language menu after changing language
        }));
    };

    const getUserFullName = () => localStorage.getItem('userFullName'); // Function to retrieve the auth token

    return (
        <header className={styles.header}>
            <nav className={styles.navbar}>
                <Link to="/dashboard" className={styles.dropbtn}>{t('dashboard')}</Link>

                <div className={styles.dropdown} onMouseLeave={hideAllSubmenus}>
                    <button className={styles.dropbtn} onClick={() => toggleSubmenu('evidence')}>
                        {t('evidence')}
                    </button>
                    {submenuVisibility.evidence && (
                        <div className={`${styles.dropdownContent} ${styles.active}`}>
                            <div className={styles.dropdownItem}><Link
                                to="/pipeline-register">{t('register_evidence')}</Link></div>
                            <div className={styles.dropdownItem}><Link
                                to="/pipeline-search">{t('search_evidence')}</Link></div>
                            <div className={styles.dropdownItem}><Link to="/pipeline/print">{t('show_evidence')}</Link>
                            </div>
                        </div>
                    )}
                </div>

                <div className={styles.dropdown} onMouseLeave={hideAllSubmenus}>
                    <button className={styles.dropbtn} onClick={() => toggleSubmenu('platform')}>
                        {t('platform')}
                    </button>
                    {submenuVisibility.platform && (
                        <div className={`${styles.dropdownContent} ${styles.active}`}>
                            <div className={styles.dropdownItem}><Link to="/tenant">{t('tenants')}</Link></div>
                            <div className={styles.dropdownItem}><Link to="/halgorithm">{t('hash_algorithm')}</Link>
                            </div>
                            <div className={styles.dropdownItem}><Link to="/cache-status">{t('cache_status')}</Link>
                            </div>
                            <div className={styles.dropdownItem}><Link to="/host">{t('hosts')}</Link></div>
                            <div className={styles.dropdownItem}><Link to="/cron">Cron tasks</Link></div>
                            <div className={styles.dropdownItem}><Link to="/log">Logs</Link></div>
                            <div className={styles.dropdownItem}><Link to="/logger">Loggers</Link></div>
                        </div>
                    )}
                </div>
                <div className={styles.dropdown}
                     onMouseLeave={hideAllSubmenus}>
                    <button className={styles.dropbtn}
                            onClick={() => toggleSubmenu('admin')}>
                        {t('admin')}
                    </button>
                    {submenuVisibility.admin && (
                        <div className={`${styles.dropdownContent} ${styles.active}`}>
                            <div className={styles.dropdownItem}><Link to="/tenant-setup">{t('tenant_setup')}</Link>
                            </div>
                            <div className={styles.dropdownItem}><Link to="/permission">{t('permissions')}</Link></div>
                            <div className={styles.dropdownItem}><Link to="/user">{t('users')}</Link></div>
                            <div className={styles.dropdownItem}><Link to="/team">{t('teams')}</Link></div>
                            <div className={styles.dropdownItem}><Link to="/token">{t('token_setup')}</Link></div>
                            <div className={styles.dropdownItem}><Link to="/entity">{t('entities')}</Link></div>
                            <div className={styles.dropdownItem}><Link to="/pschema">{t('pipeline_schemas')}</Link>
                            </div>
                            <div className={styles.dropdownItem}><Link
                                to="/tenant-notification">{t('notifications')}</Link></div>
                            <div className={styles.dropdownItem}><Link to="/translation">{t('translations')}</Link>
                            </div>
                            <div className={styles.dropdownItem}><Link to="/template">Templates</Link>
                            </div>
                        </div>
                    )}
                </div>

                <div className={styles.logoutSection}>
                    <span style={{
                        display: 'flex', alignItems: 'center', color: 'gray', marginRight: '10px'
                    }}><TbUserShield style={{color: 'gray', marginRight: '10px'}}/>{getUserFullName()}</span>

                    <div className={styles.dropdown} onMouseLeave={hideAllSubmenus}>
                        <FaGlobe
                            onClick={() => toggleSubmenu('language')}
                            style={{
                                cursor: 'pointer',
                                marginRight: '10px',
                                filter: 'invert(100%)'
                            }}
                            title={currentLanguageName}
                        />
                        {submenuVisibility.language && (
                            <div className={`${styles.dropdownContent} ${styles.active}`}>
                                <div className={styles.dropdownItem} onClick={() => changeLanguage('en')}>English</div>
                                <div className={styles.dropdownItem} onClick={() => changeLanguage('fr')}>Français</div>
                                <div className={styles.dropdownItem} onClick={() => changeLanguage('de')}>Deutsch</div>
                                <div className={styles.dropdownItem} onClick={() => changeLanguage('pl')}>Polski</div>
                            </div>
                        )}
                    </div>

                    <Link to="/logout">{t('logout')}</Link>
                </div>
            </nav>
        </header>
    );
};

export default Header;