import React, {useEffect, useState} from 'react';
import {templateSelectByPk, templateUpdate} from '../../../api/TemplateService';
import {
    TemplateDto,
    TemplateDtoChannelTypeEnum,
    TemplateDtoEventTypeEnum,
    TemplateSelectByPkRequest
} from '../../../api/generated-react-client/src';
import Modal from '../../common/Modal';

const TemplateForm: React.FC = () => {
    const [templates, setTemplates] = useState<TemplateDto[]>([]);
    const [eventType, setEventType] = useState<string>('');
    const [channelType, setChannelType] = useState<string>('');
    const [language, setLanguage] = useState<string>('en');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState<TemplateDto | null>(null);
    const [subject, setSubject] = useState('');
    const [body, setBody] = useState('');
    const [error, setError] = useState<string | null>(null);
    const [showPopup, setShowPopup] = useState<boolean>(false);

    const fetchTemplates = async () => {
        const requestParameters: TemplateSelectByPkRequest = {
            eventType: eventType as TemplateDtoEventTypeEnum,
            channelType: channelType as TemplateDtoChannelTypeEnum,
            language
        };
        try {
            const response = await templateSelectByPk(requestParameters);
            setTemplates(response);
        } catch (error) {
            setError((error as Error).message);
        }
    };

    useEffect(() => {
        if (eventType && channelType && language) {
            fetchTemplates();
        }
    }, [eventType, channelType, language]);

    const openModal = (template: TemplateDto) => {
        setSelectedTemplate(template);
        setSubject(template.subject || '');
        setBody(template.body || '');
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedTemplate(null);
    };

    const handleUpdate = async () => {
        if (selectedTemplate) {
            const updatedTemplate: TemplateDto = {
                ...selectedTemplate,
                subject,
                body
            };
            try {
                await templateUpdate({templateDto: updatedTemplate});
                setTemplates(templates.map(t => (t.id === updatedTemplate.id ? updatedTemplate : t)));
                closeModal();
            } catch (error) {
                setError((error as Error).message);
            }
        }
    };

    return (
        <div className="formList">
            <div className="headerContainer">
                <b>Templates</b>
                <div className="formGroup">
                    <select value={eventType} onChange={e => setEventType(e.target.value)}>
                        <option value="">Select Event Type</option>
                        {Object.values(TemplateDtoEventTypeEnum).map(event => (
                            <option key={event} value={event}>
                                {event}
                            </option>
                        ))}
                    </select>
                    <select value={channelType} onChange={e => setChannelType(e.target.value)}>
                        <option value="">Select Channel Type</option>
                        {Object.values(TemplateDtoChannelTypeEnum).map(channel => (
                            <option key={channel} value={channel}>
                                {channel}
                            </option>
                        ))}
                    </select>
                    <select value={language} onChange={e => setLanguage(e.target.value)}>
                        <option value="en">English</option>
                        <option value="es">Spanish</option>
                        <option value="fr">French</option>
                        {/* Add other languages as needed */}
                    </select>
                </div>
            </div>
            {showPopup && error !== null && (
                <div className="errorModal">
                    <div className="errorModalContent">
                        <p>Error: {error}</p>
                        <button onClick={() => setShowPopup(false)}>Close</button>
                    </div>
                </div>
            )}
            <table>
                <thead>
                <tr>
                    <th>Event Type</th>
                    <th>Channel Type</th>
                    <th>Language</th>
                    <th>Subject</th>
                    <th>Body</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {templates.map(template => (
                    <tr key={template.id}>
                        <td>{template.eventType}</td>
                        <td>{template.channelType}</td>
                        <td>{template.language}</td>
                        <td>{template.subject}</td>
                        <td>{template.body}</td>
                        <td>
                            <button onClick={() => openModal(template)}>Edit</button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>

            {isModalOpen && selectedTemplate && (
                <Modal isOpen={isModalOpen} onClose={closeModal}>
                    <div>
                        <h3>Edit Template</h3>
                        <div className="formGroup">
                            <label>Subject</label>
                            <input
                                type="text"
                                value={subject}
                                onChange={e => setSubject(e.target.value)}
                            />
                        </div>
                        <div className="formGroup">
                            <label>Body</label>
                            <textarea
                                value={body}
                                onChange={e => setBody(e.target.value)}
                            />
                        </div>
                        <button onClick={handleUpdate}>Save</button>
                        <button onClick={closeModal}>Cancel</button>
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default TemplateForm;