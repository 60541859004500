import React, {useEffect, useState} from 'react';
import Modal from "../../common/Modal";
import {
    BatchSchemaDto,
    HalgorithmDto,
    PipelineSchemaDto,
    ShareDto,
    TraceSchemaDto,
    TraceSchemaDtoVariantEnum
} from "../../../api/generated-react-client/src";
import {tschemaDelete, tschemaInsert, tschemaSelectByPschema, tschemaUpdate} from "../../../api/TraceSchemaService";
import {pschemaSelectById} from "../../../api/PipelineSchemaService";
import {
    batchSchemaDelete,
    batchSchemaInsert,
    batchSchemaSelectByPschemaId,
    batchSchemaUpdate
} from "../../../api/BatchSchemaManagementService";
import {
    shareSelectByPschema,
    shareSelectByTschema,
    shareTraceSchemaDelete,
    shareTraceSchemaInsert
} from "../../../api/ShareService";
import {useNavigate, useParams} from "react-router-dom";
import {
    MdLink,
    MdOutlineCreate,
    MdOutlineDataArray,
    MdOutlineDatasetLinked,
    MdOutlineDeleteForever
} from 'react-icons/md';
import {halgorithmSelect} from "../../../api/HashAlgorithmService";
import styles from './TraceSchemaForm.module.css';
import {AgGridReact} from 'ag-grid-react';
import {CellValueChangedEvent, ColDef, ICellRendererParams} from 'ag-grid-community';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

interface Option {
    key: string;
    value: string;
}

const TraceSchemaForm: React.FC = () => {
    const {pschemaId} = useParams<{ pschemaId: string }>();
    const [traces, setTraces] = useState<Array<TraceSchemaDto>>([]);
    const [pipelineSchema, setPipelineSchema] = useState<PipelineSchemaDto | null>(null);
    const [hashAlgorithms, setHashAlgorithms] = useState<Array<HalgorithmDto>>([]);
    const [batchSchemas, setBatchSchemas] = useState<Array<BatchSchemaDto>>([]);
    const [isBatchModalOpen, setIsBatchModalOpen] = useState<boolean>(false);
    const [isShareModalOpen, setIsShareModalOpen] = useState<boolean>(false);
    const [availableShares, setAvailableShares] = useState<Array<ShareDto>>([]);
    const [linkedShares, setLinkedShares] = useState<Array<number>>([]);
    const [selectedTschemaForShares, setSelectedTschemaForShares] = useState<TraceSchemaDto | null>(null);
    const [currentBatch, setCurrentBatch] = useState<BatchSchemaDto | null>(null);
    const navigate = useNavigate();
    const [isEditingBatch, setIsEditingBatch] = useState<boolean>(false);

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [createFormData, setCreateFormData] = useState<TraceSchemaDto>({
        pschemaId: Number(pschemaId),
        urn: '',
        title: '',
        mutable: false,
        batchId: 0,
        batchAlias: '',
        variant: TraceSchemaDtoVariantEnum.Entity,
        required: false,
        x: 0,
        y: 0,
        hashAlgorithm: ''
    });
    const [error, setError] = useState<string | null>(null);
    const [showPopup, setShowPopup] = useState<boolean>(false);

    const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

    useEffect(() => {
        fetchInitialData();
    }, [pschemaId]);

    const fetchInitialData = async () => {
        try {
            await fetchPipelineSchema();
            await fetchHashAlgorithms();
            await fetchBatchSchemas();
            await fetchTschemas();
            setupColumnDefs();
        } catch (error) {
            setError((error as Error).message);
            setShowPopup(true);
        }
    };

    const setupColumnDefs = () => {
        setColumnDefs([
            {
                headerName: 'URN',
                field: 'urn',
                flex: 1,
                cellStyle: {display: 'flex', alignItems: 'center'},
                cellRenderer: (params: ICellRendererParams) => (
                    <EditableCellRenderer {...params} />
                ),
                editable: false
            },
            {
                headerName: 'Hash algo',
                field: 'hashAlgorithm',
                flex: 1,
                cellStyle: {display: 'flex', alignItems: 'center'},
                cellRenderer: (params: ICellRendererParams) => {
                    const options = [...hashAlgorithms];
                    if (params.value != null && !hashAlgorithms.some(algo => algo.alias === params.value)) {
                        options.push({alias: params.value, id: 0} as HalgorithmDto);
                    }
                    return (
                        <select
                            value={params.value != null ? params.value : ''}
                            onChange={(e) => handleUpdateField(params.data, 'hashAlgorithm', e.target.value)}
                            style={{width: '100%'}}
                        >
                            {options.map(algo => (
                                <option key={algo.alias} value={algo.alias}>{algo.alias}</option>
                            ))}
                        </select>
                    );
                },
                editable: false
            },
            {
                headerName: 'Mutable',
                field: 'mutable',
                minWidth: 80,
                maxWidth: 100,
                cellStyle: {display: 'flex', alignItems: 'center', justifyContent: 'center'},
                cellRenderer: (params: ICellRendererParams) => (
                    <CheckboxRenderer {...params} />
                ),
                editable: false
            },
            {
                headerName: 'Batch',
                field: 'batchId',
                flex: 1,
                cellStyle: {display: 'flex', alignItems: 'center'},
                cellRenderer: (params: ICellRendererParams) => (
                    <BatchCellRenderer {...params} batchSchemas={batchSchemas} onEditBatch={handleBatchModalOpen}/>
                ),
                editable: false
            },
            {
                headerName: 'Variant',
                field: 'variant',
                flex: 1,
                cellStyle: {display: 'flex', alignItems: 'center'},
                cellRenderer: (params: ICellRendererParams) => (
                    <SelectCellRenderer {...params} options={Object.values(TraceSchemaDtoVariantEnum).map(variant => ({
                        key: variant,
                        value: variant
                    }))}/>
                ),
                editable: false
            },
            {
                headerName: 'Title',
                field: 'title',
                flex: 1,
                cellStyle: {display: 'flex', alignItems: 'center'},
                cellRenderer: (params: ICellRendererParams) => (
                    <EditableCellRenderer {...params} />
                ),
                editable: false
            },
            {
                headerName: 'Required',
                field: 'required',
                minWidth: 80,
                maxWidth: 100,
                cellStyle: {display: 'flex', alignItems: 'center', justifyContent: 'center'},
                cellRenderer: (params: ICellRendererParams) => (
                    <CheckboxRenderer {...params} />
                ),
                editable: false
            },
            {
                headerName: 'X',
                field: 'x',
                minWidth: 60,
                maxWidth: 80,
                cellStyle: {display: 'flex', alignItems: 'center', justifyContent: 'center'},
                cellRenderer: (params: ICellRendererParams) => (
                    <NumberCellRenderer {...params} />
                ),
                editable: false
            },
            {
                headerName: 'Y',
                field: 'y',
                minWidth: 60,
                maxWidth: 80,
                cellStyle: {display: 'flex', alignItems: 'center', justifyContent: 'center'},
                cellRenderer: (params: ICellRendererParams) => (
                    <NumberCellRenderer {...params} />
                ),
                editable: false
            },
            {
                headerName: 'Actions',
                field: 'actions',
                flex: 1,
                cellStyle: {display: 'flex', alignItems: 'center'},
                cellRenderer: (params: ICellRendererParams) => (
                    <ActionsRenderer {...params} />
                ),
                editable: false
            }
        ]);
    };

    const fetchPipelineSchema = async () => {
        const schema = await pschemaSelectById({pschemaId: Number(pschemaId)});
        setPipelineSchema(schema);
    };

    const fetchHashAlgorithms = async () => {
        const algoList = await halgorithmSelect();
        setHashAlgorithms([{alias: '', id: 0} as HalgorithmDto, ...algoList]);
    };

    const fetchBatchSchemas = async () => {
        const batchs = await batchSchemaSelectByPschemaId({pschemaId: Number(pschemaId)});
        const defaultBatch = {id: 0, alias: 'default', title: 'Default Batch'} as BatchSchemaDto;
        setBatchSchemas([defaultBatch, ...batchs]);
    };

    const fetchTschemas = async () => {
        const tschemaList = await tschemaSelectByPschema({pschemaId: Number(pschemaId)});
        setTraces(tschemaList);
    };

    const handleUpdate = async (trace: TraceSchemaDto) => {
        const requestParameters = {
            traceSchemaDto: trace
        };
        try {
            await tschemaUpdate(requestParameters);
            fetchTschemas();
        } catch (error) {
            setError((error as Error).message);
            setShowPopup(true);
        }
    };

    const handleUpdateField = async (trace: TraceSchemaDto, field: keyof TraceSchemaDto, value: any) => {
        const updatedTrace = {...trace, [field]: value};
        handleUpdate(updatedTrace);
    };

    const handleDelete = async (pschemaId: number, urn: string) => {
        if (window.confirm('Are you sure you want to delete this trace schema?')) {
            try {
                await tschemaDelete({pschemaId, urn});
                fetchTschemas();
            } catch (error) {
                setError((error as Error).message);
                setShowPopup(true);
            }
        }
    };

    const handleManageLinks = async (pschemaId: number, urn: string) => {
        try {
            navigate(`/manage-links/${pschemaId}/${urn}`);
        } catch (error) {
            setError((error as Error).message);
            setShowPopup(true);
        }
    };

    const handleManageMetadata = async (pschemaId: number, urn: string) => {
        try {
            navigate(`/trace-schema-metadata/${pschemaId}/${urn}`);
        } catch (error) {
            setError((error as Error).message);
            setShowPopup(true);
        }
    };

    const handleCreate = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            const requestParameters = {
                traceSchemaDto: {
                    ...createFormData,
                    batchId: createFormData.batchId,
                    batchAlias: createFormData.batchId === 0 ? createFormData.batchAlias : undefined,
                    hashAlgorithm: createFormData.hashAlgorithm || undefined
                }
            };
            await tschemaInsert(requestParameters);
            setIsModalOpen(false);
            await fetchTschemas();
            await fetchBatchSchemas();
            navigate(`/trace-schema/${pschemaId}`);
        } catch (error) {
            setError((error as Error).message);
            setShowPopup(true);
        }
    };

    const handleBatchModalOpen = () => {
        setIsBatchModalOpen(true);
    };

    const handleBatchModalClose = () => {
        setIsBatchModalOpen(false);
        setCurrentBatch(null);
    };

    const handleShareModalOpen = async (tschema: TraceSchemaDto) => {
        setSelectedTschemaForShares(tschema);
        try {
            const availableShares = await shareSelectByPschema({pschemaId: Number(pschemaId)});
            setAvailableShares(availableShares);
            const linkedShares = await shareSelectByTschema({
                tschemaId: tschema.urnId!
            });
            setLinkedShares(linkedShares.map(share => share.id!));
            setIsShareModalOpen(true);
        } catch (error) {
            setError((error as Error).message);
            setShowPopup(true);
        }
    };

    const handleShareModalClose = () => {
        setIsShareModalOpen(false);
        setSelectedTschemaForShares(null);
    };

    const handleShareLinkChange = async (shareId: number, checked: boolean) => {
        if (checked) {
            try {
                await shareTraceSchemaInsert({
                    shareTraceSchemaDto: {
                        shareId,
                        tschemaId: selectedTschemaForShares!.urnId
                    }
                });
                setLinkedShares([...linkedShares, shareId]);
            } catch (error) {
                setError((error as Error).message);
                setShowPopup(true);
            }
        } else {
            try {
                await shareTraceSchemaDelete({
                    shareId,
                    tschemaId: selectedTschemaForShares!.urnId!
                });
                setLinkedShares(linkedShares.filter(id => id !== shareId));
            } catch (error) {
                setError((error as Error).message);
                setShowPopup(true);
            }
        }
    };

    const handleBatchInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {name, value} = event.currentTarget;
        setCurrentBatch(prevBatch => ({
            ...prevBatch!,
            [name]: value,
        }));
    };

    const handleBatchCreateOrUpdate = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (currentBatch) {
            try {
                if (currentBatch.id) {
                    await batchSchemaUpdate({batchSchemaDto: currentBatch});
                } else {
                    await batchSchemaInsert({batchSchemaDto: {...currentBatch, pschemaId: Number(pschemaId)}});
                }
                fetchBatchSchemas();
                handleBatchModalClose();
            } catch (error) {
                setError((error as Error).message);
                setShowPopup(true);
            }
        }
    };

    const handleBatchDelete = async (batchId: number) => {
        if (window.confirm('Are you sure you want to delete this batch schema?')) {
            try {
                await batchSchemaDelete({sschemaId: batchId});
                fetchBatchSchemas();
            } catch (error) {
                setError((error as Error).message);
                setShowPopup(true);
            }
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {name, value} = event.currentTarget;
        setCreateFormData(prevFormData => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {checked, name} = event.target;
        setCreateFormData(prevFormData => ({
            ...prevFormData,
            [name]: checked,
        }));
    };

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const {name, value} = event.currentTarget;
        if (name === "batchId") {
            setIsEditingBatch(value === "-1");
            setCreateFormData(prevFormData => ({
                ...prevFormData,
                batchId: parseInt(value),
                batchAlias: ""
            }));
        } else {
            setCreateFormData(prevFormData => ({
                ...prevFormData,
                [name]: value
            }));
        }
    };

    const onCellValueChanged = async (params: CellValueChangedEvent) => {
    };

    const hashAlgorithmOptions: Option[] = hashAlgorithms.map(algo => ({
        key: algo.alias || '',
        value: algo.alias || ''
    }));

    const variantOptions: Option[] = Object.values(TraceSchemaDtoVariantEnum).map(variant => ({
        key: variant,
        value: variant
    }));

    const batchOptions: Option[] = [
        {key: "-1", value: "Create a new batch"},
        {key: "0", value: "default"},
        ...batchSchemas.map(batch => ({
            key: batch.id!.toString(),
            value: batch.alias || ''
        }))
    ];

    const EditableCellRenderer: React.FC<ICellRendererParams> = (props) => {
        const [value, setValue] = useState(props.value);

        useEffect(() => {
            setValue(props.value);
        }, [props.value]);

        const handleBlur = () => {
            const data = {...props.data};
            const field = props.colDef!.field as keyof TraceSchemaDto;
            data[field] = value;
            handleUpdate(data);
        };

        return (
            <input
                type="text"
                value={value}
                onChange={(e) => setValue(e.target.value)}
                onBlur={handleBlur}
                style={{width: '100%'}}
            />
        );
    };

    const SelectCellRenderer: React.FC<ICellRendererParams & { options: Option[] }> = (props) => {
        const [value, setValue] = useState(props.value || '');

        useEffect(() => {
            setValue(props.value || '');
        }, [props.value]);

        const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
            setValue(e.target.value);
        };

        const handleBlur = () => {
            const data = {...props.data};
            const field = props.colDef!.field as keyof TraceSchemaDto;
            data[field] = value;
            handleUpdate(data);
        };

        return (
            <select
                value={value}
                onChange={handleChange}
                onBlur={handleBlur}
                style={{width: '100%'}}
            >
                {props.options.map(option => (
                    <option key={option.key} value={option.value}>{option.value}</option>
                ))}
            </select>
        );
    };

    const CheckboxRenderer: React.FC<ICellRendererParams> = (props) => {
        const [checked, setChecked] = useState(!!props.value);

        useEffect(() => {
            setChecked(!!props.value);
        }, [props.value]);

        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const isChecked = event.target.checked;
            setChecked(isChecked);
        };

        const handleBlur = () => {
            const data = {...props.data};
            const field = props.colDef!.field as keyof TraceSchemaDto;
            data[field] = checked;
            handleUpdate(data);
        };

        return (
            <input
                type="checkbox"
                checked={checked}
                onChange={handleChange}
                onBlur={handleBlur}
            />
        );
    };

    const NumberCellRenderer: React.FC<ICellRendererParams> = (props) => {
        const [value, setValue] = useState(props.value);

        useEffect(() => {
            setValue(props.value);
        }, [props.value]);

        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = e.target.value;
            setValue(newValue);
        };

        const handleBlur = () => {
            const data = {...props.data};
            const field = props.colDef!.field as keyof TraceSchemaDto;
            data[field] = parseInt(value);
            handleUpdate(data);
        };

        return (
            <input
                type="number"
                value={value}
                onChange={handleChange}
                onBlur={handleBlur}
                style={{width: '60px'}}
            />
        );
    };

    const BatchCellRenderer: React.FC<ICellRendererParams & {
        batchSchemas: BatchSchemaDto[],
        onEditBatch: () => void
    }> = (props) => {
        const [value, setValue] = useState(props.value || 0);

        useEffect(() => {
            setValue(props.value || 0);
        }, [props.value]);

        const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
            setValue(parseInt(e.target.value));
        };

        const handleBlur = () => {
            const data = {...props.data};
            const field = props.colDef!.field as keyof TraceSchemaDto;
            data[field] = value;
            handleUpdate(data);
        };

        return (
            <div style={{display: 'flex', alignItems: 'center', width: '100%'}}>
                <select
                    value={value}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    style={{width: '100%'}}
                >
                    <option key={0} value={0}>default</option>
                    {props.batchSchemas.map(batch => (
                        <option key={batch.id} value={batch.id}>{batch.alias}</option>
                    ))}
                </select>
                <span className="editIcon" onClick={props.onEditBatch}>
                    <MdOutlineCreate/>
                </span>
            </div>
        );
    };

    const ActionsRenderer: React.FC<ICellRendererParams> = (props) => {
        const tschema = props.data as TraceSchemaDto;

        return (
            <div style={{display: 'flex', alignItems: 'center', width: '100%'}}>
                <span
                    onClick={() => handleShareModalOpen(tschema)}
                    className="deleteIcon"
                    title="Manage shares"
                >
                    <MdLink/>
                </span>
                <span
                    onClick={() => handleManageLinks(tschema.pschemaId!, tschema.urn!)}
                    className="deleteIcon"
                    title="Manage links"
                >
                    <MdOutlineDatasetLinked/>
                </span>
                <span
                    onClick={() => handleManageMetadata(tschema.pschemaId!, tschema.urn!)}
                    className="deleteIcon"
                    title="Manage Metadata"
                >
                    <MdOutlineDataArray/>
                </span>
                <span
                    onClick={() => handleDelete(tschema.pschemaId!, tschema.urn!)}
                    className="deleteIcon"
                    title="Delete trace schema"
                >
                    <MdOutlineDeleteForever/>
                </span>
            </div>
        );
    };

    return (
        <div className="formList">
            <div className="headerContainer">
                <b>
                    Trace schemas for Pipeline Schema
                    {pipelineSchema && `${pipelineSchema.alias} (ID: ${pipelineSchema.id})`}
                </b>
                <div className="formGroup">
                    <button onClick={() => setIsModalOpen(true)}>Create</button>
                    <button onClick={() => navigate(`/pschema/`)}>Return</button>
                </div>
                <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                    <form onSubmit={handleCreate}>
                        <div className="formGroup">
                            <label>URN</label>
                            <input
                                type="text"
                                name="urn"
                                value={createFormData.urn}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="formGroup">
                            <label>Title</label>
                            <input
                                type="text"
                                name="title"
                                value={createFormData.title}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="formGroup">
                            <label>Mutable</label>
                            <input
                                type="checkbox"
                                name="mutable"
                                checked={createFormData.mutable}
                                onChange={handleCheckboxChange}
                            />
                        </div>
                        <div className="formGroup">
                            <label>Hash Algorithm</label>
                            <select
                                name="hashAlgorithm"
                                value={createFormData.hashAlgorithm}
                                onChange={handleSelectChange}
                            >
                                {hashAlgorithmOptions.map(option => (
                                    <option key={option.key} value={option.key}>{option.value}</option>
                                ))}
                            </select>
                        </div>
                        <div className="formGroup">
                            <label>Variant</label>
                            <select
                                name="variant"
                                value={createFormData.variant}
                                onChange={handleSelectChange}
                            >
                                {variantOptions.map(option => (
                                    <option key={option.key} value={option.key}>{option.value}</option>
                                ))}
                            </select>
                        </div>
                        <div className="formGroup">
                            <label>Required</label>
                            <input
                                type="checkbox"
                                name="required"
                                checked={createFormData.required}
                                onChange={handleCheckboxChange}
                            />
                        </div>
                        <div className="formGroup">
                            <label>X</label>
                            <input
                                type="number"
                                name="x"
                                value={createFormData.x}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="formGroup">
                            <label>Y</label>
                            <input
                                type="number"
                                name="y"
                                value={createFormData.y}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="formGroup">
                            <label>Batch</label>
                            <select name="batchId" value={createFormData.batchId} onChange={handleSelectChange}>
                                {batchOptions.map(option => (
                                    <option key={option.key} value={option.key}>{option.value}</option>
                                ))}
                            </select>
                        </div>
                        {isEditingBatch && (
                            <div className="formGroup">
                                <label>New Batch Alias</label>
                                <input
                                    type="text"
                                    name="batchAlias"
                                    value={createFormData.batchAlias}
                                    onChange={handleInputChange}
                                    placeholder="Enter a new name..."
                                />
                            </div>
                        )}
                        <div className="horizontalButtonContainer">
                            <button type="submit">Create</button>
                        </div>
                    </form>
                </Modal>
                <Modal isOpen={isBatchModalOpen} onClose={handleBatchModalClose} className={styles.customModalContent}>
                    <b>Manage Batches</b><br/><br/>
                    <form onSubmit={handleBatchCreateOrUpdate}>
                        <div className="formGroup">
                            <label>Alias</label>
                            <input
                                type="text"
                                name="alias"
                                value={currentBatch?.alias || ''}
                                onChange={handleBatchInputChange}
                            />
                        </div>
                        <div className="formGroup">
                            <label>Title</label>
                            <input
                                type="text"
                                name="title"
                                value={currentBatch?.title || ''}
                                onChange={handleBatchInputChange}
                            />
                        </div>
                        <div className="horizontalButtonContainer">
                            <button type="button" onClick={handleBatchModalClose}>Cancel</button>
                            <button type="submit">Create/Save</button>
                        </div>
                    </form>
                    <h3>Existing Batches</h3>
                    <table>
                        <thead>
                        <tr>
                            <th style={{width: '40px', textAlign: 'center'}}>ID</th>
                            <th>Alias</th>
                            <th>Title</th>
                            <th style={{width: '100px'}}>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {batchSchemas.map((batch, index) => (
                            <tr key={batch.id}>
                                <td style={{width: '40px', textAlign: 'center'}}>{batch.id}</td>
                                <td>{batch.alias}</td>
                                <td>{batch.title}</td>
                                <td style={{width: '100px'}}>
                                    <div className="icon-container">
                                        <span onClick={() => setCurrentBatch(batch)}
                                              className="editIcon"><MdOutlineCreate/></span>
                                        <span onClick={() => handleBatchDelete(batch.id!)}
                                              className="deleteIcon"><MdOutlineDeleteForever/></span>
                                    </div>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </Modal>
                <Modal isOpen={isShareModalOpen} onClose={handleShareModalClose} className={styles.customModalContent}>
                    <b>Manage Shares</b><br/><br/>
                    {availableShares.map(share => (
                        <div key={share.id}>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={linkedShares.includes(share.id!)}
                                    onChange={(e) => handleShareLinkChange(share.id!, e.target.checked)}
                                />
                                {share.title}
                            </label>
                        </div>
                    ))}
                </Modal>
            </div>
            {showPopup && error !== null && (
                <div className="errorModal">
                    <div className="errorModalContent">
                        <p>Error: {error}</p>

                        <button onClick={() => setShowPopup(false)}>Close</button>
                    </div>
                </div>
            )}
            <div className="ag-theme-alpine" style={{height: '500px', width: '100%', borderCollapse: 'collapse'}}>
                <AgGridReact
                    columnDefs={columnDefs}
                    rowData={traces}
                    onCellValueChanged={onCellValueChanged}
                    defaultColDef={{
                        minWidth: 100,
                        resizable: true,
                        cellStyle: {
                            borderRight: '1px solid #ccc',
                            display: 'flex',
                            alignItems: 'center'
                        }
                    }}
                />
            </div>
        </div>
    );
};

export default TraceSchemaForm;
