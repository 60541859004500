import {
    PermissionAccessManagementApi,
    PermissionAccessReplaceRequest,
    PermissionAccessSelectByIdRequest
} from './generated-react-client/src/apis';
import config from './clientConfig';

const api = new PermissionAccessManagementApi(config);

export const permissionAccessSelectById = async (requestParameters: PermissionAccessSelectByIdRequest) => {
    return await api.permissionAccessSelectById(requestParameters);
};
 export const permissionAccessReplace = async (requestParameters: PermissionAccessReplaceRequest) => {
     return await api.permissionAccessReplace(requestParameters);
 };