/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Represents a pipeline schema and signer relation instance.
 * @export
 * @interface PipelineSchemaSignerDto
 */
export interface PipelineSchemaSignerDto {
    /**
     * The ID of the pipeline schema
     * @type {number}
     * @memberof PipelineSchemaSignerDto
     */
    pschemaId?: number;
    /**
     * The ID of the signer
     * @type {number}
     * @memberof PipelineSchemaSignerDto
     */
    signerId?: number;
}

/**
 * Check if a given object implements the PipelineSchemaSignerDto interface.
 */
export function instanceOfPipelineSchemaSignerDto(value: object): boolean {
    return true;
}

export function PipelineSchemaSignerDtoFromJSON(json: any): PipelineSchemaSignerDto {
    return PipelineSchemaSignerDtoFromJSONTyped(json, false);
}

export function PipelineSchemaSignerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PipelineSchemaSignerDto {
    if (json == null) {
        return json;
    }
    return {
        
        'pschemaId': json['pschemaId'] == null ? undefined : json['pschemaId'],
        'signerId': json['signerId'] == null ? undefined : json['signerId'],
    };
}

export function PipelineSchemaSignerDtoToJSON(value?: PipelineSchemaSignerDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'pschemaId': value['pschemaId'],
        'signerId': value['signerId'],
    };
}

