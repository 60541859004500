import React, {useEffect, useState} from 'react';
import {HalgorithmDto} from '../../../api/generated-react-client/src/models/HalgorithmDto';
import {
    halgorithmDelete,
    halgorithmInsert,
    halgorithmSelect,
    halgorithmUpdate
} from "../../../api/HashAlgorithmService";
import {MdOutlineCreate, MdOutlineDeleteForever} from "react-icons/md";
import Modal from "../../common/Modal";
import {HalgorithmInsertRequest} from "../../../api/generated-react-client/src";

const HashAlgorithmForm: React.FC = () => {

    const [hashAlgorithms, setHashAlgorithms] = useState<Array<HalgorithmDto>>([]);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
    const [createFormData, setCreateFormData] = useState<{ alias: string }>({alias: ''});
    const [error, setError] = useState<string | null>(null);
    const [showErrorPopup, setShowErrorPopup] = useState<boolean>(false);

    useEffect(() => {
        const fetchHashAlgorithms = async () => {
            try {
                const result = await halgorithmSelect();
                setHashAlgorithms(result);
            } catch (error) {
                handleError(error);
            }
        };

        fetchHashAlgorithms();
    }, []);

    const handleError = (error: unknown) => {
        setError((error as Error).message);
        setShowErrorPopup(true);
    };

    const handleEditClick = async (hashAlgorithm: HalgorithmDto, field: 'alias') => {
        const currentValue = hashAlgorithm[field]?.toString() || '';
        const newValue = prompt(`Edit ${field}`, currentValue);

        if (newValue && newValue !== currentValue) {
            const updatedAlgorithm = {...hashAlgorithm, alias: newValue};
            try {
                await halgorithmUpdate({halgorithmDto: updatedAlgorithm});
                setHashAlgorithms((prevAlgorithms) =>
                    prevAlgorithms.map(h => h.id === hashAlgorithm.id ? updatedAlgorithm : h)
                );
            } catch (error) {
                handleError(error);
            }
        }
    };

    const handleDeleteClick = async (halgorithmId: number) => {
        if (window.confirm('Are you sure you want to delete this hash algorithm?')) {
            try {
                await halgorithmDelete({halgorithmId});
                setHashAlgorithms((prevAlgorithms) => prevAlgorithms.filter(h => h.id !== halgorithmId));
            } catch (error) {
                handleError(error);
            }
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.currentTarget;
        setCreateFormData({...createFormData, [name]: value});
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const requestParameters: HalgorithmInsertRequest = {halgorithmDto: {alias: createFormData.alias}};

        try {
            await halgorithmInsert(requestParameters);
            setIsCreateModalOpen(false);
            const result = await halgorithmSelect();
            setHashAlgorithms(result);
        } catch (error) {
            handleError(error);
        }
    };

    return (
        <div className="formList">
            <div className="headerContainer">
                <b>All Hash Algorithms</b>
                <div className="formGroup">
                    <button className="createButton" onClick={() => setIsCreateModalOpen(true)}>Create</button>
                </div>
            </div>

            <Modal isOpen={isCreateModalOpen} onClose={() => setIsCreateModalOpen(false)}>
                <form onSubmit={handleSubmit}>
                    <div className="formGroup">
                        <label>Alias</label>
                        <input
                            type="text"
                            name="alias"
                            value={createFormData.alias}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="horizontalButtonContainer">
                        <button type="submit">Submit</button>
                    </div>
                </form>
            </Modal>

            {showErrorPopup && error && (
                <div className="errorModal">
                    <div className="errorModalContent">
                        <p>Error: {error}</p>
                        <button onClick={() => setShowErrorPopup(false)}>Close</button>
                    </div>
                </div>
            )}
            <table>
                <thead>
                <tr>
                    <th>ID</th>
                    <th>Alias</th>
                    <th>Delete</th>
                </tr>
                </thead>
                <tbody>
                {hashAlgorithms.map((hashAlgorithm, index) => (
                    <tr key={hashAlgorithm.id} className={`row ${index % 2 === 0 ? 'even' : 'odd'}`}>
                        <td>{hashAlgorithm.id}</td>
                        <td>
                            <div className="td-vertical">
                                {hashAlgorithm.alias}
                                <span className="editIcon" onClick={() => handleEditClick(hashAlgorithm, 'alias')}>
                                    <MdOutlineCreate/>
                                </span>
                            </div>
                        </td>
                        <td>
                            <div className="icon-container">
                                <span onClick={() => handleDeleteClick(hashAlgorithm.id!)}
                                      className="deleteIcon"><MdOutlineDeleteForever/></span>
                            </div>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default HashAlgorithmForm;