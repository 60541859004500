import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {CacheStatusDto} from '../../../api/generated-react-client/src/models/CacheStatusDto';
import {MdOutlineDeleteForever} from "react-icons/md";
import {cacheStatusSelect} from "../../../api/CacheStatusService";

const CacheForm: React.FC = () => {

    const [caches, setCaches] = useState<Array<CacheStatusDto>>([]);
    const [error, setError] = useState<string | null>(null);
    const [showErrorPopup, setShowErrorPopup] = useState<boolean>(false);

    const handleError = (error: unknown) => {
        setError((error as Error).message);
        setShowErrorPopup(true);
    };

    const handleClearClick = useCallback(async (alias: string) => {
        if (window.confirm('Are you sure you want to clear this cache?')) {
            try {
                // Placeholder for actual cache clear logic await entityDelete({entityId}); // todo
                const updatedCaches = caches.filter(cache => cache.alias !== alias);
                setCaches(updatedCaches);
            } catch (error) {
                handleError(error);
            }
        }
    }, [caches]);

    useEffect(() => {
        const fetchCacheStatus = async () => {
            try {
                const result = await cacheStatusSelect();
                setCaches(result);
            } catch (error) {
                handleError(error);
            }
        };

        fetchCacheStatus();
    }, []);

    const handleRefreshClick = async () => {
        try {
            const result = await cacheStatusSelect();
            setCaches(result);
        } catch (error) {
            handleError(error);
        }
    };

    const tableRows = useMemo(() => {
        return caches.map((cacheStatus, index) => (
            <tr key={cacheStatus.alias} className={`row ${index % 2 === 0 ? 'even' : 'odd'}`}>
                <td>{cacheStatus.alias}</td>
                <td style={{textAlign: 'center'}}>{cacheStatus.count}</td>
                <td>{cacheStatus.objectExpiry}</td>
                <td>{cacheStatus.clearPeriod}</td>
                <td style={{width: '170px', textAlign: 'center'}}>
                    {cacheStatus.clearAt ? new Date(cacheStatus.clearAt).toLocaleString() : 'N/A'}
                </td>
                <td style={{width: '100px'}}>
                    <div className="icon-container">
                        <span onClick={() => handleClearClick(cacheStatus.alias!)} className="deleteIcon">
                            <MdOutlineDeleteForever/>
                        </span>
                    </div>
                </td>
            </tr>
        ));
    }, [caches, handleClearClick]);

    return (
        <div className="formList">
            <div className="headerContainer">
                <b>Cache list</b>
                <div className="formGroup">
                    <button onClick={handleRefreshClick}>Refresh</button>
                </div>
            </div>
            {showErrorPopup && error && (
                <div className="errorModal">
                    <div className="errorModalContent">
                        <p>Error: {error}</p>
                        <button onClick={() => setShowErrorPopup(false)}>Close</button>
                    </div>
                </div>
            )}
            <table>
                <thead>
                <tr>
                    <th>Alias</th>
                    <th style={{textAlign: 'center'}}>Count</th>
                    <th>Expiry</th>
                    <th>Clear period</th>
                    <th style={{width: '170px', textAlign: 'center'}}>Clear at</th>
                    <th style={{width: '100px'}}>Actions</th>
                </tr>
                </thead>
                <tbody>
                {tableRows}
                </tbody>
            </table>
        </div>);
};
export default CacheForm;