import {
    SignerDeleteRequest,
    SignerInsertRequest,
    SignerManagementApi,
    SignerSelectByAliasRequest,
    SignerSelectByIdRequest,
    SignerUpdateRequest,
} from './generated-react-client/src/apis';
import config from './clientConfig';
import {apiCallWrapper} from '../utils/apiWrapper';

const api = new SignerManagementApi(config);

export const signerInsert = async (requestParameters: SignerInsertRequest) => {
    return apiCallWrapper(() => api.signerInsert(requestParameters));
};

export const signerSelect = async () => {
    return apiCallWrapper(() => api.signerSelect());
};

export const signerUpdate = async (requestParameters: SignerUpdateRequest) => {
    return apiCallWrapper(() => api.signerUpdate(requestParameters));
};

export const signerDelete = async (requestParameters: SignerDeleteRequest) => {
    return apiCallWrapper(() => api.signerDelete(requestParameters));
};

export const signerSelectByAlias = async (requestParameters: SignerSelectByAliasRequest) => {
    return apiCallWrapper(() => api.signerSelectByAlias(requestParameters));
};

export const signerSelectById = async (requestParameters: SignerSelectByIdRequest) => {
    return apiCallWrapper(() => api.signerSelectById(requestParameters));
};
