import React from 'react';
import Header from './Header'; // Adjust the import path as needed
import Footer from './Footer'; // Adjust the import path as needed

// Define a type for the props expected by the Layout component
type LayoutProps = {
    children: React.ReactNode; // Use React.ReactNode for the children type
};

const Layout: React.FC<LayoutProps> = ({children}) => {
    return (
        <div className="app-container">
            <Header/>
            <div className="main-content">
                {children} {/* This is where the routed content will be injected */}
            </div>
            <Footer/>
        </div>
    );
};

export default Layout;
