/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KeyVal
 */
export interface KeyVal {
    /**
     * 
     * @type {string}
     * @memberof KeyVal
     */
    key?: string;
    /**
     * 
     * @type {string}
     * @memberof KeyVal
     */
    value?: string;
}

/**
 * Check if a given object implements the KeyVal interface.
 */
export function instanceOfKeyVal(value: object): boolean {
    return true;
}

export function KeyValFromJSON(json: any): KeyVal {
    return KeyValFromJSONTyped(json, false);
}

export function KeyValFromJSONTyped(json: any, ignoreDiscriminator: boolean): KeyVal {
    if (json == null) {
        return json;
    }
    return {
        
        'key': json['key'] == null ? undefined : json['key'],
        'value': json['value'] == null ? undefined : json['value'],
    };
}

export function KeyValToJSON(value?: KeyVal | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'key': value['key'],
        'value': value['value'],
    };
}

