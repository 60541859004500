/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Template data to update
 * @export
 * @interface TemplateDto
 */
export interface TemplateDto {
    /**
     * The id of the template.
     * @type {number}
     * @memberof TemplateDto
     */
    id?: number;
    /**
     * The id of the tenant's template.
     * @type {number}
     * @memberof TemplateDto
     */
    tenantId?: number;
    /**
     * The type of event of the template.
     * @type {string}
     * @memberof TemplateDto
     */
    eventType?: TemplateDtoEventTypeEnum;
    /**
     * The type of channel of the template.
     * @type {string}
     * @memberof TemplateDto
     */
    channelType?: TemplateDtoChannelTypeEnum;
    /**
     * The language of the template.
     * @type {string}
     * @memberof TemplateDto
     */
    language?: string;
    /**
     * The subject of the template.
     * @type {string}
     * @memberof TemplateDto
     */
    subject?: string;
    /**
     * The body of the template.
     * @type {string}
     * @memberof TemplateDto
     */
    body?: string;
}


/**
 * @export
 */
export const TemplateDtoEventTypeEnum = {
    Invitation: 'INVITATION',
    SignupSuccessful: 'SIGNUP_SUCCESSFUL',
    ResetPassword: 'RESET_PASSWORD',
    Otp: 'OTP',
    Welcome: 'WELCOME',
    Info: 'INFO',
    Alert: 'ALERT'
} as const;
export type TemplateDtoEventTypeEnum = typeof TemplateDtoEventTypeEnum[keyof typeof TemplateDtoEventTypeEnum];

/**
 * @export
 */
export const TemplateDtoChannelTypeEnum = {
    Email: 'EMAIL',
    Sms: 'SMS',
    Api: 'API'
} as const;
export type TemplateDtoChannelTypeEnum = typeof TemplateDtoChannelTypeEnum[keyof typeof TemplateDtoChannelTypeEnum];


/**
 * Check if a given object implements the TemplateDto interface.
 */
export function instanceOfTemplateDto(value: object): boolean {
    return true;
}

export function TemplateDtoFromJSON(json: any): TemplateDto {
    return TemplateDtoFromJSONTyped(json, false);
}

export function TemplateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TemplateDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'tenantId': json['tenantId'] == null ? undefined : json['tenantId'],
        'eventType': json['eventType'] == null ? undefined : json['eventType'],
        'channelType': json['channelType'] == null ? undefined : json['channelType'],
        'language': json['language'] == null ? undefined : json['language'],
        'subject': json['subject'] == null ? undefined : json['subject'],
        'body': json['body'] == null ? undefined : json['body'],
    };
}

export function TemplateDtoToJSON(value?: TemplateDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'tenantId': value['tenantId'],
        'eventType': value['eventType'],
        'channelType': value['channelType'],
        'language': value['language'],
        'subject': value['subject'],
        'body': value['body'],
    };
}

