/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * The trace schema link data to delete
 * @export
 * @interface TraceSchemaLinkDto
 */
export interface TraceSchemaLinkDto {
    /**
     * The ID of the schema, used internally.
     * @type {number}
     * @memberof TraceSchemaLinkDto
     */
    pschemaId?: number;
    /**
     * The URN (Uniform Resource Name) of the ancestor entity.
     * @type {string}
     * @memberof TraceSchemaLinkDto
     */
    ancestorUrn?: string;
    /**
     * The URN (Uniform Resource Name) of the descendant entity.
     * @type {string}
     * @memberof TraceSchemaLinkDto
     */
    descendantUrn?: string;
    /**
     * Enum representing different types of links in data traceability.
     * @type {string}
     * @memberof TraceSchemaLinkDto
     */
    link?: TraceSchemaLinkDtoLinkEnum;
    /**
     * 
     * @type {boolean}
     * @memberof TraceSchemaLinkDto
     */
    multi?: boolean;
}


/**
 * @export
 */
export const TraceSchemaLinkDtoLinkEnum = {
    GeneratedBy: 'GENERATED_BY',
    UsedBy: 'USED_BY',
    ProducedBy: 'PRODUCED_BY',
    AnnotatedBy: 'ANNOTATED_BY',
    ActedOnBehalfOf: 'ACTED_ON_BEHALF_OF',
    AssociatedWith: 'ASSOCIATED_WITH',
    AttributedTo: 'ATTRIBUTED_TO',
    DerivedFrom: 'DERIVED_FROM',
    EndedBy: 'ENDED_BY',
    InfluencedBy: 'INFLUENCED_BY',
    InformedBy: 'INFORMED_BY',
    InvalidatedBy: 'INVALIDATED_BY',
    StartedBy: 'STARTED_BY',
    ErasedBy: 'ERASED_BY'
} as const;
export type TraceSchemaLinkDtoLinkEnum = typeof TraceSchemaLinkDtoLinkEnum[keyof typeof TraceSchemaLinkDtoLinkEnum];


/**
 * Check if a given object implements the TraceSchemaLinkDto interface.
 */
export function instanceOfTraceSchemaLinkDto(value: object): boolean {
    return true;
}

export function TraceSchemaLinkDtoFromJSON(json: any): TraceSchemaLinkDto {
    return TraceSchemaLinkDtoFromJSONTyped(json, false);
}

export function TraceSchemaLinkDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TraceSchemaLinkDto {
    if (json == null) {
        return json;
    }
    return {
        
        'pschemaId': json['pschemaId'] == null ? undefined : json['pschemaId'],
        'ancestorUrn': json['ancestorUrn'] == null ? undefined : json['ancestorUrn'],
        'descendantUrn': json['descendantUrn'] == null ? undefined : json['descendantUrn'],
        'link': json['link'] == null ? undefined : json['link'],
        'multi': json['multi'] == null ? undefined : json['multi'],
    };
}

export function TraceSchemaLinkDtoToJSON(value?: TraceSchemaLinkDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'pschemaId': value['pschemaId'],
        'ancestorUrn': value['ancestorUrn'],
        'descendantUrn': value['descendantUrn'],
        'link': value['link'],
        'multi': value['multi'],
    };
}

