/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Represents a manifest within the system, detailing the type of manifest, its associated signature, and relevant metadata for data protection.
 * @export
 * @interface ManifestDto
 */
export interface ManifestDto {
    /**
     * Unique identifier of the manifest.
     * @type {number}
     * @memberof ManifestDto
     */
    id?: number;
    /**
     * Enum representing the different types of manifests in the system.
     * @type {string}
     * @memberof ManifestDto
     */
    type?: ManifestDtoTypeEnum;
    /**
     * Identifier of the signer associated with the manifest, referring to the entity or user responsible for signing.
     * @type {number}
     * @memberof ManifestDto
     */
    signerId?: number;
    /**
     * The core value of the manifest, which can be a hash, content, or any relevant information to be signed or timestamped.
     * @type {string}
     * @memberof ManifestDto
     */
    value?: string;
    /**
     * The digital signature applied to the manifest's value, ensuring integrity and authenticity.
     * @type {string}
     * @memberof ManifestDto
     */
    signature?: string;
    /**
     * The date of creation of the manifest along with its digital signature.
     * @type {Date}
     * @memberof ManifestDto
     */
    createdAt?: Date;
}


/**
 * @export
 */
export const ManifestDtoTypeEnum = {
    Manifest: 'MANIFEST',
    Signature: 'SIGNATURE',
    Timestamp: 'TIMESTAMP'
} as const;
export type ManifestDtoTypeEnum = typeof ManifestDtoTypeEnum[keyof typeof ManifestDtoTypeEnum];


/**
 * Check if a given object implements the ManifestDto interface.
 */
export function instanceOfManifestDto(value: object): boolean {
    return true;
}

export function ManifestDtoFromJSON(json: any): ManifestDto {
    return ManifestDtoFromJSONTyped(json, false);
}

export function ManifestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManifestDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'type': json['type'] == null ? undefined : json['type'],
        'signerId': json['signerId'] == null ? undefined : json['signerId'],
        'value': json['value'] == null ? undefined : json['value'],
        'signature': json['signature'] == null ? undefined : json['signature'],
        'createdAt': json['createdAt'] == null ? undefined : (new Date(json['createdAt'])),
    };
}

export function ManifestDtoToJSON(value?: ManifestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'type': value['type'],
        'signerId': value['signerId'],
        'value': value['value'],
        'signature': value['signature'],
        'createdAt': value['createdAt'] == null ? undefined : ((value['createdAt']).toISOString()),
    };
}

