import {
    CronDeleteRequest,
    CronInsertRequest,
    CronManagementApi,
    CronPurgeRequest,
    CronSearchRequest,
    CronSelectByExecutedRequest,
    CronSelectByIdRequest,
    CronSelectByRunningRequest,
    CronSelectBySourceIdRequest,
    CronUpdateRequest,
} from './generated-react-client/src';
import config from './clientConfig';
import {apiCallWrapper} from '../utils/apiWrapper';

const cronApi = new CronManagementApi(config);

export const cronInsert = async (requestParameters: CronInsertRequest) => {
    return apiCallWrapper(() => cronApi.cronInsert(requestParameters));
};

export const cronDelete = async (requestParameters: CronDeleteRequest) => {
    return apiCallWrapper(() => cronApi.cronDelete(requestParameters));
};

export const cronPurge = async (requestParameters: CronPurgeRequest) => {
    return apiCallWrapper(() => cronApi.cronPurge(requestParameters));
};

export const cronSelectByExecuted = async (requestParameters: CronSelectByExecutedRequest) => {
    return apiCallWrapper(() => cronApi.cronSelectByExecuted(requestParameters));
};

export const cronSelectById = async (requestParameters: CronSelectByIdRequest) => {
    return apiCallWrapper(() => cronApi.cronSelectById(requestParameters));
};

export const cronSelectByRunning = async (requestParameters: CronSelectByRunningRequest) => {
    return apiCallWrapper(() => cronApi.cronSelectByRunning(requestParameters));
};

export const cronSelectBySourceId = async (requestParameters: CronSelectBySourceIdRequest) => {
    return apiCallWrapper(() => cronApi.cronSelectBySourceId(requestParameters));
};

export const cronUpdate = async (requestParameters: CronUpdateRequest) => {
    return apiCallWrapper(() => cronApi.cronUpdate(requestParameters));
};

export const cronSelectInError = async () => {
    return apiCallWrapper(() => cronApi.cronSelectInError());
};

export const cronSearch = async (requestParameters: CronSearchRequest) => {
    return apiCallWrapper(() => cronApi.cronSearch(requestParameters));
};