import {
    TeamDeleteRequest,
    TeamInsertRequest,
    TeamManagementApi,
    TeamSelectByAliasRequest,
    TeamSelectByIdRequest,
    TeamUpdateRequest,
} from './generated-react-client/src/apis/TeamManagementApi';
import config from './clientConfig';
import {apiCallWrapper} from '../utils/apiWrapper';

const api = new TeamManagementApi(config);

export const teamInsert = async (requestParameters: TeamInsertRequest) => {
    return apiCallWrapper(() => api.teamInsert(requestParameters));
};

export const teamSelect = async () => {
    return apiCallWrapper(() => api.teamSelect());
};

export const teamUpdate = async (requestParameters: TeamUpdateRequest) => {
    return apiCallWrapper(() => api.teamUpdate(requestParameters));
};

export const teamDelete = async (requestParameters: TeamDeleteRequest) => {
    return apiCallWrapper(() => api.teamDelete(requestParameters));
};

export const teamSelectByAlias = async (requestParameters: TeamSelectByAliasRequest) => {
    return apiCallWrapper(() => api.teamSelectByAlias(requestParameters));
};

export const teamSelectById = async (requestParameters: TeamSelectByIdRequest) => {
    return apiCallWrapper(() => api.teamSelectById(requestParameters));
};