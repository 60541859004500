/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  SignerDto,
} from '../models/index';
import {
    SignerDtoFromJSON,
    SignerDtoToJSON,
} from '../models/index';

export interface SignerDeleteRequest {
    signerId: number;
}

export interface SignerInsertRequest {
    signerDto: SignerDto;
}

export interface SignerSelectByAliasRequest {
    alias: string;
}

export interface SignerSelectByIdRequest {
    signerId: number;
}

export interface SignerUpdateRequest {
    signerDto: SignerDto;
}

/**
 * 
 */
export class SignerManagementApi extends runtime.BaseAPI {

    /**
     * Deletes a specific signer by its ID.
     * Delete a signer
     */
    async signerDeleteRaw(requestParameters: SignerDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['signerId'] == null) {
            throw new runtime.RequiredError(
                'signerId',
                'Required parameter "signerId" was null or undefined when calling signerDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/signer/{signerId}`.replace(`{${"signerId"}}`, encodeURIComponent(String(requestParameters['signerId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Deletes a specific signer by its ID.
     * Delete a signer
     */
    async signerDelete(requestParameters: SignerDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.signerDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates a new signer and returns the created signer with its ID.
     * Insert a new signer
     */
    async signerInsertRaw(requestParameters: SignerInsertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SignerDto>> {
        if (requestParameters['signerDto'] == null) {
            throw new runtime.RequiredError(
                'signerDto',
                'Required parameter "signerDto" was null or undefined when calling signerInsert().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/signer`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SignerDtoToJSON(requestParameters['signerDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SignerDtoFromJSON(jsonValue));
    }

    /**
     * Creates a new signer and returns the created signer with its ID.
     * Insert a new signer
     */
    async signerInsert(requestParameters: SignerInsertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SignerDto> {
        const response = await this.signerInsertRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a list of all signers.
     * Get all signers
     */
    async signerSelectRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SignerDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/signer`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SignerDtoFromJSON));
    }

    /**
     * Retrieves a list of all signers.
     * Get all signers
     */
    async signerSelect(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SignerDto>> {
        const response = await this.signerSelectRaw(initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a signer by its alias.
     * Get signer by alias
     */
    async signerSelectByAliasRaw(requestParameters: SignerSelectByAliasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SignerDto>> {
        if (requestParameters['alias'] == null) {
            throw new runtime.RequiredError(
                'alias',
                'Required parameter "alias" was null or undefined when calling signerSelectByAlias().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/signer/by-alias/{alias}`.replace(`{${"alias"}}`, encodeURIComponent(String(requestParameters['alias']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SignerDtoFromJSON(jsonValue));
    }

    /**
     * Retrieves a signer by its alias.
     * Get signer by alias
     */
    async signerSelectByAlias(requestParameters: SignerSelectByAliasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SignerDto> {
        const response = await this.signerSelectByAliasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a signer by its ID.
     * Get signer by ID
     */
    async signerSelectByIdRaw(requestParameters: SignerSelectByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SignerDto>> {
        if (requestParameters['signerId'] == null) {
            throw new runtime.RequiredError(
                'signerId',
                'Required parameter "signerId" was null or undefined when calling signerSelectById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/signer/{signerId}`.replace(`{${"signerId"}}`, encodeURIComponent(String(requestParameters['signerId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SignerDtoFromJSON(jsonValue));
    }

    /**
     * Retrieves a signer by its ID.
     * Get signer by ID
     */
    async signerSelectById(requestParameters: SignerSelectByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SignerDto> {
        const response = await this.signerSelectByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates selected information for a specific signer.
     * Update signer\'s attributes
     */
    async signerUpdateRaw(requestParameters: SignerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SignerDto>> {
        if (requestParameters['signerDto'] == null) {
            throw new runtime.RequiredError(
                'signerDto',
                'Required parameter "signerDto" was null or undefined when calling signerUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/signer`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: SignerDtoToJSON(requestParameters['signerDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SignerDtoFromJSON(jsonValue));
    }

    /**
     * Updates selected information for a specific signer.
     * Update signer\'s attributes
     */
    async signerUpdate(requestParameters: SignerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SignerDto> {
        const response = await this.signerUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
