/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BatchSchemaDto,
} from '../models/index';
import {
    BatchSchemaDtoFromJSON,
    BatchSchemaDtoToJSON,
} from '../models/index';

export interface BatchSchemaDeleteRequest {
    sschemaId: number;
}

export interface BatchSchemaInsertRequest {
    batchSchemaDto: BatchSchemaDto;
}

export interface BatchSchemaSelectByIdRequest {
    sschemaId: number;
}

export interface BatchSchemaSelectByPschemaIdRequest {
    pschemaId: number;
}

export interface BatchSchemaUpdateRequest {
    batchSchemaDto: BatchSchemaDto;
}

/**
 * 
 */
export class BatchSchemaManagementApi extends runtime.BaseAPI {

    /**
     * Deletes a specific batch schema by its ID.
     * Delete a batch schema
     */
    async batchSchemaDeleteRaw(requestParameters: BatchSchemaDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['sschemaId'] == null) {
            throw new runtime.RequiredError(
                'sschemaId',
                'Required parameter "sschemaId" was null or undefined when calling batchSchemaDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/sschema/{sschemaId}`.replace(`{${"sschemaId"}}`, encodeURIComponent(String(requestParameters['sschemaId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Deletes a specific batch schema by its ID.
     * Delete a batch schema
     */
    async batchSchemaDelete(requestParameters: BatchSchemaDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.batchSchemaDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates a new batch schema and returns the created batch schema with its ID.
     * Insert a new Batch Schema
     */
    async batchSchemaInsertRaw(requestParameters: BatchSchemaInsertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BatchSchemaDto>> {
        if (requestParameters['batchSchemaDto'] == null) {
            throw new runtime.RequiredError(
                'batchSchemaDto',
                'Required parameter "batchSchemaDto" was null or undefined when calling batchSchemaInsert().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/sschema`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BatchSchemaDtoToJSON(requestParameters['batchSchemaDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BatchSchemaDtoFromJSON(jsonValue));
    }

    /**
     * Creates a new batch schema and returns the created batch schema with its ID.
     * Insert a new Batch Schema
     */
    async batchSchemaInsert(requestParameters: BatchSchemaInsertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BatchSchemaDto> {
        const response = await this.batchSchemaInsertRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a batch schema by its ID.
     * Get a batch schema by ID
     */
    async batchSchemaSelectByIdRaw(requestParameters: BatchSchemaSelectByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BatchSchemaDto>> {
        if (requestParameters['sschemaId'] == null) {
            throw new runtime.RequiredError(
                'sschemaId',
                'Required parameter "sschemaId" was null or undefined when calling batchSchemaSelectById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/sschema/{sschemaId}`.replace(`{${"sschemaId"}}`, encodeURIComponent(String(requestParameters['sschemaId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BatchSchemaDtoFromJSON(jsonValue));
    }

    /**
     * Retrieves a batch schema by its ID.
     * Get a batch schema by ID
     */
    async batchSchemaSelectById(requestParameters: BatchSchemaSelectByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BatchSchemaDto> {
        const response = await this.batchSchemaSelectByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a list of batch schemas by pschemaId.
     * Get a list of batch schemas by pipeline schema ID
     */
    async batchSchemaSelectByPschemaIdRaw(requestParameters: BatchSchemaSelectByPschemaIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<BatchSchemaDto>>> {
        if (requestParameters['pschemaId'] == null) {
            throw new runtime.RequiredError(
                'pschemaId',
                'Required parameter "pschemaId" was null or undefined when calling batchSchemaSelectByPschemaId().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/sschema/by-pschemaId/{pschemaId}`.replace(`{${"pschemaId"}}`, encodeURIComponent(String(requestParameters['pschemaId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BatchSchemaDtoFromJSON));
    }

    /**
     * Retrieves a list of batch schemas by pschemaId.
     * Get a list of batch schemas by pipeline schema ID
     */
    async batchSchemaSelectByPschemaId(requestParameters: BatchSchemaSelectByPschemaIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<BatchSchemaDto>> {
        const response = await this.batchSchemaSelectByPschemaIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates selected information for a specific batch schema.
     * Update batch schema\'s attributes
     */
    async batchSchemaUpdateRaw(requestParameters: BatchSchemaUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['batchSchemaDto'] == null) {
            throw new runtime.RequiredError(
                'batchSchemaDto',
                'Required parameter "batchSchemaDto" was null or undefined when calling batchSchemaUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/sschema`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: BatchSchemaDtoToJSON(requestParameters['batchSchemaDto']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Updates selected information for a specific batch schema.
     * Update batch schema\'s attributes
     */
    async batchSchemaUpdate(requestParameters: BatchSchemaUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.batchSchemaUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
