import React, {useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {handleResetPassword} from "../api/AuthenticationService";
import styles from './ResetPasswordPage.module.css';

const ResetPasswordPage: React.FC = () => {
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [error, setError] = useState<string | null>(null);
    const {token} = useParams<{ token: string }>();
    const navigate = useNavigate();

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (password !== confirmPassword) {
            setError("Passwords do not match.");
            return;
        }

        try {
            console.log(token);
            localStorage.setItem('authToken', token!); // Store the UUID token
            await handleResetPassword(token!, password);
            navigate('/login');
        } catch (apiError) {
            setError("Failed to reset password. Please try again.");
        }
    };

    return (
        <div className={styles.container}>
            <h1 className={styles.title}>Reset Password</h1>
            <form onSubmit={handleSubmit} className={styles.form}>
                <div className={styles.inputGroup}>
                    <label>New Password:</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <div className={styles.inputGroup}>
                    <label>Confirm Password:</label>
                    <input
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    />
                </div>
                {error && <p className={styles.errorMsg}>{error}</p>}
                <button type="submit" className={styles.button}>Reset Password</button>
            </form>
        </div>
    );
};

export default ResetPasswordPage;
