import React, {useEffect, useState} from 'react';
import {loggerSelect, loggerUpdateLevel, loggerUpdatePerformance} from '../../../api/LogService';
import {
    LoggerDto,
    LoggerDtoLevelEnum,
    LoggerUpdateLevelRequest,
    LoggerUpdatePerformanceRequest
} from '../../../api/generated-react-client/src';

const LoggerForm: React.FC = () => {
    const [loggers, setLoggers] = useState<LoggerDto[]>([]);
    const [searchText, setSearchText] = useState('');
    const [error, setError] = useState<string | null>(null);
    const [showPopup, setShowPopup] = useState<boolean>(false);

    const handleError = (error: unknown) => {
        setError((error as Error).message);
        setShowPopup(true);
    };

    const fetchLoggers = async () => {
        try {
            const response = await loggerSelect();
            setLoggers(response.sort((a, b) => a.alias!.localeCompare(b.alias!))); // Sort by alias alphabetically
        } catch (error) {
            handleError(error);
        }
    };

    useEffect(() => {
        fetchLoggers();
    }, []);

    const handleLevelChange = async (logger: LoggerDto, level: string) => {
        const updatedLogger = {
            ...logger,
            level: level as LoggerDtoLevelEnum // Cast the level to LoggerDtoLevelEnum
        };
        const requestParameters: LoggerUpdateLevelRequest = {loggerDto: updatedLogger};

        try {
            await loggerUpdateLevel(requestParameters);
            setLoggers(loggers.map(l => (l.alias === logger.alias ? updatedLogger : l)));
        } catch (error) {
            handleError(error);
        }
    };

    const handlePerformanceChange = async (logger: LoggerDto, isPerformanceEnabled: boolean) => {
        const updatedLogger = {...logger, performance: isPerformanceEnabled}; // Update performance setting
        const requestParameters: LoggerUpdatePerformanceRequest = {loggerDto: updatedLogger};

        try {
            await loggerUpdatePerformance(requestParameters);
            setLoggers(loggers.map(l => (l.alias === logger.alias ? updatedLogger : l)));
        } catch (error) {
            handleError(error);
        }
    };

    const filteredLoggers = loggers.filter(logger =>
        logger.alias!.toLowerCase().includes(searchText.toLowerCase()) // Filter by alias based on search text
    );

    return (
        <div className="formList">
            <div className="headerContainer">
                <b>Logger Management</b>
                <div className="formGroup">
                    <input
                        type="text"
                        placeholder="Search text..."
                        value={searchText}
                        onChange={e => setSearchText(e.target.value)}
                    />
                </div>
            </div>
            {showPopup && error !== null && (
                <div className="errorModal">
                    <div className="errorModalContent">
                        <p>Error: {error}</p>
                        <button onClick={() => setShowPopup(false)}>Close</button>
                    </div>
                </div>
            )}
            <table>
                <thead>
                <tr>
                    <th style={{width: '200px', textAlign: 'left'}}>Alias</th>
                    <th style={{width: '200px', textAlign: 'center'}}>Level</th>
                    <th style={{width: '150px', textAlign: 'center'}}>Performance</th>
                </tr>
                </thead>
                <tbody>
                {filteredLoggers.map((logger, index) => (
                    <tr key={logger.alias} className={`row ${index % 2 === 0 ? 'even' : 'odd'}`}>
                        <td style={{textAlign: 'left'}}>{logger.alias}</td>
                        <td style={{textAlign: 'center'}}>
                            <select
                                value={logger.level}
                                onChange={e => handleLevelChange(logger, e.target.value)}
                            >
                                {Object.values(LoggerDtoLevelEnum).map(level => (
                                    <option key={level} value={level}>
                                        {level}
                                    </option>
                                ))}
                            </select>
                        </td>
                        <td style={{textAlign: 'center'}}>
                            <input
                                type="checkbox"
                                checked={logger.performance || false}
                                onChange={e => handlePerformanceChange(logger, e.target.checked)}
                            />
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default LoggerForm;