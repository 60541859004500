import React, {useEffect, useState} from 'react';
import './NotificationForm.css';
import {
    NotificationDto,
    NotificationDtoChannelTypeEnum,
    NotificationDtoEventTypeEnum
} from '../../../api/generated-react-client/src/models/NotificationDto';
import {notificationDelete, notificationInsert, notificationSelect} from "../../../api/NotificationService";
import {MdOutlineDeleteForever} from "react-icons/md";
import Modal from "../../common/Modal";
import {NotificationInsertRequest} from "../../../api/generated-react-client/src";

const NotificationForm: React.FC = () => {

    const [notifications, setNotifications] = useState<Array<NotificationDto>>([]);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [formData, setFormData] = useState<{
        channelType: NotificationDtoChannelTypeEnum,
        eventType: NotificationDtoEventTypeEnum,
        toUserId: number,
        templateId: number
    }>({
        channelType: NotificationDtoChannelTypeEnum.Email,
        eventType: NotificationDtoEventTypeEnum.Info,
        toUserId: 0,
        templateId: 0
    });
    const [error, setError] = useState<string | null>(null);
    const [showErrorPopup, setShowErrorPopup] = useState<boolean>(false);

    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                const result = await notificationSelect({min: new Date('2000-01-01'), max: new Date()});
                setNotifications(result);
            } catch (error) {
                setError((error as Error).message);
                setShowErrorPopup(true);
            }
        };

        fetchNotifications();
    }, []);

    const handleDeleteClick = async (notificationId: number) => {
        if (window.confirm('Are you sure you want to delete this notification?')) {
            try {
                await notificationDelete({notificationId});
                setNotifications(notifications.filter(n => n.id !== notificationId));
            } catch (error) {
                setError((error as Error).message);
                setShowErrorPopup(true);
            }
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const {name, value} = event.currentTarget;
        setFormData({...formData, [name]: value});
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const notificationDto: NotificationDto = {
            channelType: formData.channelType,
            eventType: formData.eventType,
            toUserId: formData.toUserId,
            templateId: formData.templateId
        };
        const requestParameters: NotificationInsertRequest = {notificationDto};

        try {
            await notificationInsert(requestParameters);
            setIsModalOpen(false);
            const result = await notificationSelect({min: new Date('2000-01-01'), max: new Date()});
            setNotifications(result);
        } catch (error) {
            setError((error as Error).message);
            setShowErrorPopup(true);
        }
    };

    return (
        <div className="formList">
            <div className="headerContainer">
                <b>Notification list</b>
                <div className="formGroup">
                    <button onClick={() => setIsModalOpen(true)}>Create</button>
                </div>
                <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                    <div className="formContainer">
                        <form onSubmit={handleSubmit} className="notificationForm">
                            <div className="formGroup">
                                <label>Channel Type</label>
                                <select
                                    name="channelType"
                                    value={formData.channelType}
                                    onChange={handleInputChange}
                                >
                                    {Object.values(NotificationDtoChannelTypeEnum).map(channelType => (
                                        <option key={channelType} value={channelType}>{channelType}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="formGroup">
                                <label>Event Type</label>
                                <select
                                    name="eventType"
                                    value={formData.eventType}
                                    onChange={handleInputChange}
                                >
                                    {Object.values(NotificationDtoEventTypeEnum).map(eventType => (
                                        <option key={eventType} value={eventType}>{eventType}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="formGroup">
                                <label>To User ID</label>
                                <input
                                    type="number"
                                    name="toUserId"
                                    value={formData.toUserId}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="formGroup">
                                <label>Template ID</label>
                                <input
                                    type="number"
                                    name="templateId"
                                    value={formData.templateId}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="horizontalButtonContainer">
                                <button type="submit">Submit</button>
                            </div>
                        </form>
                    </div>
                </Modal>
            </div>
            {showErrorPopup && error && (
                <div className="errorModal">
                    <div className="errorModalContent">
                        <p>Error: {error}</p>
                        <button onClick={() => setShowErrorPopup(false)}>Close</button>
                    </div>
                </div>
            )}
            <table>
                <thead>
                <tr>
                    <th style={{width: '50px', textAlign: 'center'}}>ID</th>
                    <th>Channel Type</th>
                    <th>Event Type</th>
                    <th>To User ID</th>
                    <th>Subject</th>
                    <th>Status</th>
                    <th>Created At</th>
                    <th style={{width: '60px', textAlign: 'center'}}>Trial</th>
                    <th>Delete</th>
                </tr>
                </thead>
                <tbody>
                {notifications.map((notification, index) => (
                    <tr key={notification.id} className={`row ${index % 2 === 0 ? 'even' : 'odd'}`}>
                        <td style={{width: '50px', textAlign: 'center'}}>{notification.id}</td>
                        <td>{notification.channelType}</td>
                        <td>{notification.eventType}</td>
                        <td>{notification.toUserId}</td>
                        <td>{notification.subject}</td>
                        <td>{notification.status}</td>
                        <td>{notification.createdAt ? new Date(notification.createdAt).toLocaleString() : ''}</td>
                        <td style={{width: '60px', textAlign: 'center'}}>{notification.trial}</td>
                        <td>
                            <div className="icon-container">
                                <span onClick={() => handleDeleteClick(notification.id!)} className="deleteIcon">
                                    <MdOutlineDeleteForever/>
                                </span>
                            </div>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default NotificationForm;
