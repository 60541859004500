/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PermissionAccessDto,
} from '../models/index';
import {
    PermissionAccessDtoFromJSON,
    PermissionAccessDtoToJSON,
} from '../models/index';

export interface PermissionAccessDeleteRequest {
    permissionId: number;
}

export interface PermissionAccessReplaceRequest {
    permissionId: number;
    requestBody: Array<number>;
}

export interface PermissionAccessSelectByIdRequest {
    permissionId: number;
}

/**
 * 
 */
export class PermissionAccessManagementApi extends runtime.BaseAPI {

    /**
     */
    async permissionAccessDeleteRaw(requestParameters: PermissionAccessDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['permissionId'] == null) {
            throw new runtime.RequiredError(
                'permissionId',
                'Required parameter "permissionId" was null or undefined when calling permissionAccessDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/permission-access/{permissionId}`.replace(`{${"permissionId"}}`, encodeURIComponent(String(requestParameters['permissionId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async permissionAccessDelete(requestParameters: PermissionAccessDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.permissionAccessDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async permissionAccessReplaceRaw(requestParameters: PermissionAccessReplaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['permissionId'] == null) {
            throw new runtime.RequiredError(
                'permissionId',
                'Required parameter "permissionId" was null or undefined when calling permissionAccessReplace().'
            );
        }

        if (requestParameters['requestBody'] == null) {
            throw new runtime.RequiredError(
                'requestBody',
                'Required parameter "requestBody" was null or undefined when calling permissionAccessReplace().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/permission-access/{permissionId}`.replace(`{${"permissionId"}}`, encodeURIComponent(String(requestParameters['permissionId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['requestBody'],
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async permissionAccessReplace(requestParameters: PermissionAccessReplaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.permissionAccessReplaceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async permissionAccessSelectByIdRaw(requestParameters: PermissionAccessSelectByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PermissionAccessDto>>> {
        if (requestParameters['permissionId'] == null) {
            throw new runtime.RequiredError(
                'permissionId',
                'Required parameter "permissionId" was null or undefined when calling permissionAccessSelectById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/permission-access/{permissionId}`.replace(`{${"permissionId"}}`, encodeURIComponent(String(requestParameters['permissionId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PermissionAccessDtoFromJSON));
    }

    /**
     */
    async permissionAccessSelectById(requestParameters: PermissionAccessSelectByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PermissionAccessDto>> {
        const response = await this.permissionAccessSelectByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
