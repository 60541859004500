import './styles/global.css';
import React from 'react';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import Layout from './components/layout/Layout';
import Login from './components/Login';
import Dashboard from './components/layout/Dashboard';
import TenantForm from "./components/form/tenant/TenantForm";
import PrintPipelineForm from './components/form/pipeline/PrintPipelineForm';
import Logout from "./components/Logout";
import PermissionForm from "./components/form/permission/PermissionForm";
import UserForm from "./components/form/user/UserForm";
import TokenForm from "./components/form/token/TokenForm";
import TenantSetupForm from "./components/form/tenant/TenantSetupForm";
import EntityForm from "./components/form/entity/EntityForm";
import PipelineSchemaForm from "./components/form/schema/PipelineSchemaForm";
import CacheForm from "./components/form/cache/CacheForm";
import ResetPassword from "./components/ResetPassword";
import PipelineCreateForm from "./components/form/pipeline/PipelineCreateForm";
import PipelineSearchForm from "./components/form/pipeline/PipelineSearchForm";
import TraceSchemaForm from "./components/form/schema/TraceSchemaForm";
import HashAlgorithmForm from "./components/form/halgorithm/HashAlgorithmForm";
import NotificationForm from "./components/form/notification/NotificationForm";
import HostForm from "./components/form/host/HostForm";
import TranslationForm from "./components/form/translation/TranslationForm";
import TracePipelineForm from "./components/form/pipeline/TracePipelineForm";
import BundleForm from "./components/form/schema/BundleForm";
import TeamForm from "./components/form/team/TeamForm";
import CronForm from "./components/form/cron/CronForm";
import LogForm from "./components/form/log/LogForm";
import LoggerForm from "./components/form/log/LoggerForm";
import TemplateForm from "./components/form/template/TemplateForm";
import TraceSchemaLinkForm from "./components/form/schema/TraceSchemaLinkForm";
import TraceSchemaMetadataForm from "./components/form/schema/TraceSchemaMetadataForm";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Navigate replace to="/login"/>}/>
                <Route path="/login" element={<Login/>}/>
                <Route path="/dashboard" element={<Layout><Dashboard/></Layout>}/>
                <Route path="/pipeline/print" element={<Layout><PrintPipelineForm/></Layout>}/>
                <Route path="/tenant" element={<Layout><TenantForm/></Layout>}/>
                <Route path="/tenant-setup" element={<Layout><TenantSetupForm/></Layout>}/>
                <Route path="/permission" element={<Layout><PermissionForm/></Layout>}/>
                <Route path="/user" element={<Layout><UserForm/></Layout>}/>
                <Route path="/token" element={<Layout><TokenForm/></Layout>}/>
                <Route path="/entity" element={<Layout><EntityForm/></Layout>}/>
                <Route path="/pschema" element={<Layout><PipelineSchemaForm/></Layout>}/>
                <Route path="/cache-status" element={<Layout><CacheForm/></Layout>}/>
                <Route path="/logout" element={<Layout><Logout/></Layout>}/>
                <Route path="/forgot" element={<Layout><Logout/></Layout>}/>
                <Route path="/reset-handle/:token" element={<ResetPassword/>}/>
                <Route path="/pipeline-register" element={<Layout><PipelineCreateForm/></Layout>}/>
                <Route path="/trace-schema/:pschemaId" element={<Layout><TraceSchemaForm/></Layout>}/>
                <Route path="/pipeline-search" element={<Layout><PipelineSearchForm/></Layout>}/>
                <Route path="/halgorithm" element={<Layout><HashAlgorithmForm/></Layout>}/>
                <Route path="/tenant-notification" element={<Layout><NotificationForm/></Layout>}/>
                <Route path="/host" element={<Layout><HostForm/></Layout>}/>
                <Route path="/translation" element={<Layout><TranslationForm/></Layout>}/>
                <Route path="/trace-pipeline/:pipelineId" element={<Layout><TracePipelineForm/></Layout>}/>
                <Route path="/bundle/:pschemaId" element={<Layout><BundleForm/></Layout>}/>
                <Route path="/team" element={<Layout><TeamForm/></Layout>}/>
                <Route path="/cron" element={<Layout><CronForm/></Layout>}/>
                <Route path="/log" element={<Layout><LogForm/></Layout>}/>
                <Route path="/logger" element={<Layout><LoggerForm/></Layout>}/>
                <Route path="/template" element={<Layout><TemplateForm/></Layout>}/>
                <Route path="/manage-links/:pschemaId/:urn" element={<Layout><TraceSchemaLinkForm/></Layout>}/>
                <Route path="/trace-schema-metadata/:pschemaId/:urn"
                       element={<Layout><TraceSchemaMetadataForm/></Layout>}/>
            </Routes>
        </BrowserRouter>
    );
}

export default App;