import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {TraceDto, TraceSelectByPipelineRequest} from "../../../api/generated-react-client/src";
import {traceSelectByPipelineID} from "../../../api/TraceService";
import {FiCopy, FiList} from "react-icons/fi";

const TracePipelineForm: React.FC = () => {

    const {pipelineId: pipelineIdStr} = useParams<{ pipelineId: string }>();
    const pipelineId = Number(pipelineIdStr);
    const [traces, setTraces] = useState<Array<TraceDto>>([]);
    const [error, setError] = useState<string | null>(null);
    const [showErrorPopup, setShowErrorPopup] = useState<boolean>(false);


    useEffect(() => {
        fetchTraces();
    }, []);

    const fetchTraces = async () => {
        try {
            const req: TraceSelectByPipelineRequest = {
                pipelineId: pipelineId
            };
            const traceList_ = await traceSelectByPipelineID(req);
            setTraces(traceList_ || []);
        } catch (error) {
            setError((error as Error).message);
            setShowErrorPopup(true);
        }
    };

    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text).then(() => {
            alert('Hash copied to clipboard!');
        }).catch(error => {
            setError((error as Error).message);
            setShowErrorPopup(true);
        });
    };

    const truncateHash = (hash: string) => {
        const truncated = hash.length > 24 ? `${hash.substring(0, 24)}...` : hash;
        return (
            <span title={hash} style={{display: 'flex', alignItems: 'center'}}>
                {truncated}
                <FiCopy
                    style={{marginLeft: '8px', cursor: 'pointer'}}
                    onClick={() => copyToClipboard(hash)}
                />
            </span>
        );
    };

    return (
        <div className="formList">
            <div className="headerContainer">
                <b>
                    Traces for Pipeline ID {pipelineId}
                </b>
            </div>
            {showErrorPopup && error && (
                <div className="errorModal">
                    <div className="errorModalContent">
                        <p>Error: {error}</p>
                        <button onClick={() => setShowErrorPopup(false)}>Close</button>
                    </div>
                </div>
            )}
            <table>
                <thead>
                <tr>
                    <th style={{width: '200px'}}>URN</th>
                    <th>Step</th>
                    <th>URL</th>
                    <th>Hash Algorithm</th>
                    {/*<th style={{width: '245px'}}>Hash</th>*/}
                    <th>Title</th>
                    <th>Data</th>
                    <th style={{width: '170px', textAlign: 'center'}}>Created At</th>
                    <th>Creator</th>
                    <th style={{width: '70px', textAlign: 'center'}}>Manifest</th>
                    <th style={{width: '100px', textAlign: 'center'}}>Status</th>
                    <th>Shares</th>
                </tr>
                </thead>
                <tbody>
                {traces.map((trace, index) => (
                    <tr key={trace.id} className={`row ${index % 2 === 0 ? 'even' : 'odd'}`}>
                        <td style={{width: '200px', wordBreak: 'break-all'}}>{trace.urn}</td>
                        <td>{trace.batchAlias}</td>
                        <td>{trace.url}</td>
                        <td>{trace.hashList!.toLocaleString()}</td>
                        {/*<td style={{width: '245px'}}>{truncateHash(trace.hash!)}</td>*/}
                        <td>{trace.title}</td>
                        <td>{trace.data}</td>
                        <td style={{width: '170px', textAlign: 'center'}}>
                            {trace.createdAt ? new Date(trace.createdAt).toLocaleString() : 'Not Available'}
                        </td>
                        <td>{trace.creator ? `${trace.creator?.firstName} ${trace.creator?.lastName}` : 'Not Available'}</td>
                        <td style={{width: '70px', textAlign: 'center'}}>{trace.manifestId}</td>
                        <td style={{width: '100px', textAlign: 'center'}}>{trace.status}</td>
                        <td><FiList/> {trace.shareList?.length || 0}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default TracePipelineForm;
