import React, {ChangeEvent, useEffect, useState} from 'react';
import {teamDelete, teamInsert, teamSelect, teamUpdate} from '../../../api/TeamService';
import {userSearch} from '../../../api/UserService';
import {
    TeamDto,
    TeamDtoScopeEnum,
    TeamInsertRequest,
    UserDto,
    UserFilter,
    UserSearchRequest
} from "../../../api/generated-react-client/src";
import Modal from "../../common/Modal";
import {MdOutlineCreate, MdOutlineDeleteForever, MdOutlineMoreHoriz} from "react-icons/md";
import styles from "./TeamForm.module.css";

const TeamForm: React.FC = () => {
    const [teams, setTeams] = useState<TeamDto[]>([]);
    const [searchText, setSearchText] = useState('');
    const [isTeamModalOpen, setIsTeamModalOpen] = useState(false);
    const [createTeamFormData, setCreateTeamFormData] = useState<CreateTeamFormData>({
        alias: '',
        scope: TeamDtoScopeEnum.Private,
        title: '',
        eligibility: '',
        linkedId: 0,
        tenantId: 0
    });
    const [isLinkedIdModalOpen, setIsLinkedIdModalOpen] = useState(false);
    const [selectedTeam, setSelectedTeam] = useState<TeamDto | null>(null);
    const [isEligibilityModalOpen, setIsEligibilityModalOpen] = useState(false);
    const [eligibilityEdit, setEligibilityEdit] = useState('');
    const [linkedIdSearch, setLinkedIdSearch] = useState('');
    const [linkedIdOptions, setLinkedIdOptions] = useState<TeamDto[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [showPopup, setShowPopup] = useState<boolean>(false);

    const handleError = (error: unknown) => {
        setError((error as Error).message);
        setShowPopup(true);
    };

    const fetchTeams = async () => {
        try {
            const response = await teamSelect();
            setTeams(response || []);
        } catch (error) {
            handleError(error);
        }
    };

    useEffect(() => {
        fetchTeams();
    }, []);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {name, value} = event.currentTarget;
        setCreateTeamFormData(prevFormData => ({...prevFormData, [name]: value}));
    };

    const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const {name, value} = event.target;
        setCreateTeamFormData(prevFormData => ({...prevFormData, [name]: value}));
    };

    const handleEditClick = async (team: TeamDto, field: keyof TeamDto, value: any) => {
        try {
            const updatedTeam = {...team, [field]: value};
            await teamUpdate({teamDto: updatedTeam});
            setTeams(teams.map(t => (t.id === team.id ? updatedTeam : t)));
        } catch (error) {
            handleError(error);
        }
    };

    const handleDelete = async (teamId: number) => {
        if (window.confirm('Are you sure you want to delete this team?')) {
            try {
                await teamDelete({teamId});
                setTeams(teams.filter(team => team.id !== teamId));
            } catch (error) {
                handleError(error);
            }
        }
    };

    const handleCreate = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const teamDto: TeamDto = {...createTeamFormData};
        const requestParameters: TeamInsertRequest = {teamDto};
        try {
            await teamInsert(requestParameters);
            fetchTeams();
            setIsTeamModalOpen(false);
        } catch (error) {
            handleError(error);
        }
    };

    const handleLinkedIdClick = (team: TeamDto) => {
        setSelectedTeam(team);
        setIsLinkedIdModalOpen(true);
    };

    const handleEligibilityChangeClick = (team: TeamDto) => {
        setEligibilityEdit(team.eligibility || '');
        setSelectedTeam(team);
        setIsEligibilityModalOpen(true);
    };

    const handleEligibilitySave = async () => {
        if (selectedTeam) {
            try {
                const updatedTeam = {...selectedTeam, eligibility: eligibilityEdit};
                await teamUpdate({teamDto: updatedTeam});
                setTeams(teams.map(t => (t.id === selectedTeam.id ? updatedTeam : t)));
                setIsEligibilityModalOpen(false);
            } catch (error) {
                handleError(error);
            }
        }
    };

    const handleLinkedIdSearchChange = async (event: ChangeEvent<HTMLInputElement>) => {
        setLinkedIdSearch(event.target.value);

        // Mocking the API call - replace this with actual API call
        setTimeout(() => {
            // Mocking a response for the sake of example
            const mockOptions = [
                {id: 0, title: 'Not linked to...'},
                {id: 1, title: 'External Team Bob'},
                {id: 2, title: 'Team Fred'},
                {id: 3, title: 'Martin Team 3'},
                {id: 4, title: 'Factiven Team'},
                {id: 5, title: 'Intech'}
            ];

            // Filter mock options based on search text
            const filteredOptions = mockOptions.filter(option =>
                option.title.toLowerCase().includes(event.target.value.toLowerCase())
            );
            setLinkedIdOptions(filteredOptions);
        }, 1000);
    };

    const handleLinkedIdSave = async (selectedOption: TeamDto) => {
        if (selectedTeam) {
            try {
                const updatedTeam = {...selectedTeam, linkedId: selectedOption.id};
                await teamUpdate({teamDto: updatedTeam});
                setTeams(teams.map(t => (t.id === selectedTeam.id ? updatedTeam : t)));
                setIsLinkedIdModalOpen(false);
            } catch (error) {
                handleError(error);
            }
        }
    };

    return (
        <div className="formList">
            <div className="headerContainer">
                <b>Team list</b>
                <div className="formGroup">
                    <input
                        type="text"
                        placeholder="Search text..."
                        value={searchText}
                        onChange={e => setSearchText(e.target.value)}
                    />
                    <button onClick={() => setIsTeamModalOpen(true)}>Create</button>
                </div>
                <Modal isOpen={isTeamModalOpen} onClose={() => setIsTeamModalOpen(false)}>
                    <form onSubmit={handleCreate}>
                        <div className="formGroup">
                            <label>Alias</label>
                            <input
                                type="text"
                                name="alias"
                                value={createTeamFormData.alias}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="formGroup">
                            <label>Scope</label>
                            <select
                                name="scope"
                                value={createTeamFormData.scope}
                                onChange={handleSelectChange}
                            >
                                {Object.values(TeamDtoScopeEnum).map(scope => (
                                    <option key={scope} value={scope}>
                                        {scope}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="formGroup">
                            <label>Title</label>
                            <input
                                type="text"
                                name="title"
                                value={createTeamFormData.title}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="formGroup">
                            <label>Eligibility</label>
                            <textarea
                                name="eligibility"
                                value={createTeamFormData.eligibility}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="formGroup">
                            <label>Linked ID</label>
                            <select
                                name="linkedId"
                                value={createTeamFormData.linkedId}
                                onChange={handleSelectChange}
                            >
                                <option value={0}>Internal</option>
                                <option value={1}>External</option>
                            </select>
                            <span onClick={() => setIsLinkedIdModalOpen(true)} className="moreIcon">
                                <MdOutlineMoreHoriz/>
                            </span>
                        </div>
                        <div className="horizontalButtonContainer">
                            <button type="submit">Create</button>
                        </div>
                    </form>
                </Modal>
            </div>
            {showPopup && error !== null && (
                <div className="errorModal">
                    <div className="errorModalContent">
                        <p>Error: {error}</p>
                        <button onClick={() => setShowPopup(false)}>Close</button>
                    </div>
                </div>
            )}
            <table>
                <thead>
                <tr>
                    <th style={{width: '40px', textAlign: 'center'}}>ID</th>
                    <th style={{textAlign: 'left'}}>Alias</th>
                    <th style={{width: '120px', textAlign: 'center'}}>Scope</th>
                    <th style={{textAlign: 'left'}}>Title</th>
                    <th style={{textAlign: 'left'}}>Eligibility</th>
                    <th style={{width: '120px', textAlign: 'center'}}>Linked ID</th>
                    <th style={{width: '120px', textAlign: 'center'}}>Members</th>
                    <th style={{width: '170px', textAlign: 'center'}}>Created at</th>
                    <th style={{width: '100px', textAlign: 'center'}}>Actions</th>
                </tr>
                </thead>
                <tbody>
                {teams.map((team, index) => (
                    <tr key={team.id} className={`row ${index % 2 === 0 ? 'even' : 'odd'}`}>
                        <td style={{width: '40px', textAlign: 'center'}}>{team.id}</td>
                        <td style={{
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                            wordBreak: 'break-all',
                            textAlign: 'left'
                        }}>
                            <div className="td-vertical">
                                {team.alias}
                                <span className="editIcon" onClick={() => handleEditClick(team, 'alias', team.alias)}>
                                    <MdOutlineCreate/>
                                </span>
                            </div>
                        </td>
                        <td style={{width: '120px', textAlign: 'center'}}>
                            <select
                                value={team.scope}
                                onChange={(e) => handleEditClick(team, 'scope', e.target.value)}
                            >
                                {Object.values(TeamDtoScopeEnum).map(scope => (
                                    <option key={scope} value={scope}>
                                        {scope}
                                    </option>
                                ))}
                            </select>
                        </td>
                        <td style={{textAlign: 'left'}}>
                            <div className="td-vertical">
                                {team.title}
                                <span className="editIcon" onClick={() => handleEditClick(team, 'title', team.title)}>
                                    <MdOutlineCreate/>
                                </span>
                            </div>
                        </td>
                        <td style={{textAlign: 'left'}}>
                            <div className="td-vertical">
                                <span title={team.eligibility}>{team.eligibility?.split('\n')[0]}</span>
                                <span className="editIcon" onClick={() => handleEligibilityChangeClick(team)}>
                                    <MdOutlineCreate/>
                                </span>
                            </div>
                        </td>
                        <td style={{width: '120px', textAlign: 'center'}}>
                            <div className="td-vertical">
                                {team.linkedId === 0 ? 'Internal' : 'External'}
                                <span className="editIcon" onClick={() => handleLinkedIdClick(team)}>
                                    <MdOutlineMoreHoriz/>
                                </span>
                            </div>
                        </td>
                        <MembersColumn team={team} setError={setError} setShowPopup={setShowPopup}/>
                        <td style={{
                            width: '170px',
                            textAlign: 'center'
                        }}>{team.createdAt?.toLocaleString() ?? 'Not available'}</td>
                        <td style={{width: '100px'}}>
                            <div className="icon-container">
                                <span onClick={() => handleDelete(team.id!)} className="deleteIcon">
                                <MdOutlineDeleteForever/>
                                </span>
                            </div>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
            <Modal isOpen={isEligibilityModalOpen} onClose={() => setIsEligibilityModalOpen(false)}>
                <h3>Define eligibility rules</h3>
                <div className="formGroup">
                        <textarea
                            name="eligibilityEdit"
                            value={eligibilityEdit}
                            onChange={(e) => setEligibilityEdit(e.target.value)}
                            rows={5}
                        />
                </div>
                <div className="horizontalButtonContainer">
                    <button onClick={handleEligibilitySave}>Save</button>
                </div>
            </Modal>
            <Modal isOpen={isLinkedIdModalOpen} onClose={() => setIsLinkedIdModalOpen(false)}>
                <div style={{minWidth: '300px'}}>
                    <h3>Select External Team</h3>
                    <div className="formGroup">
                        <input
                            type="text"
                            placeholder="Type to search..."
                            value={linkedIdSearch}
                            onChange={handleLinkedIdSearchChange}
                        />
                    </div>
                    <div className="formGroup">
                        {linkedIdOptions.length > 0 && (
                            <div className={styles.radioList}>
                                {linkedIdOptions.map(option => (
                                    <label key={option.id} className={styles.radioItem}>
                                        <input
                                            type="radio"
                                            name="linkedIdOption"
                                            value={option.id}
                                            onClick={() => handleLinkedIdSave(option)}
                                        />
                                        {option.title} ({option.alias})
                                    </label>
                                ))}
                            </div>
                        )}
                    </div>
                    <div className="horizontalButtonContainer">
                    </div>
                </div>
            </Modal>
        </div>
    );
};

const MembersColumn: React.FC<MembersColumnProps> = ({team, setError, setShowPopup}) => {
    const [isMembersModalOpen, setIsMembersModalOpen] = useState(false);
    const [members, setMembers] = useState<UserDto[]>([]);
    const [memberSearch, setMemberSearch] = useState('');
    const [memberOptions, setMemberOptions] = useState<UserDto[]>([]);

    const fetchMembers = async () => {
        // Implement the logic to fetch members associated with the team
    };

    useEffect(() => {
        fetchMembers();
    }, []);

    const handleMemberSearchChange = async (event: ChangeEvent<HTMLInputElement>) => {
        setMemberSearch(event.target.value);

        const userFilter: UserFilter = {
            text: event.target.value,
            limit: 10,
            offset: 0
        };
        const requestParameters: UserSearchRequest = {userFilter};

        try {
            const response = await userSearch(requestParameters);
            setMemberOptions(response.list || []);
        } catch (error) {
            setError((error as Error).message);
            setShowPopup(true);
        }
    };

    const handleMemberSelect = (selectedUser: UserDto) => {
        // Implement the logic to add the selected user to the team's members list
        setMembers([...members, selectedUser]);
        setIsMembersModalOpen(false);
    };

    return (
        <td style={{width: '120px', textAlign: 'center'}}>
            <div className="td-vertical-centred">
                {members.map(member => member.firstName + ' ' + member.lastName).join(' | ')}
                <span onClick={() => setIsMembersModalOpen(true)}
                      className="notifyIcon"><MdOutlineMoreHoriz/>
                </span>
                {isMembersModalOpen && (
                    <Modal isOpen={isMembersModalOpen} onClose={() => setIsMembersModalOpen(false)}>
                        <div style={{minWidth: '300px'}}>
                            <h3>Select team members</h3>
                            <div className="formGroup">
                                <input
                                    type="text"
                                    placeholder="Type to search..."
                                    value={memberSearch}
                                    onChange={handleMemberSearchChange}
                                />
                            </div>
                            <div className="formGroup">
                                {memberOptions.length > 0 && (
                                    <div className={styles.radioList}>
                                        {memberOptions.map(option => (
                                            <label key={option.id} className={styles.radioItem} title={option.email}>
                                                <input
                                                    type="radio"
                                                    name="memberOption"
                                                    value={option.id}
                                                    onClick={() => handleMemberSelect(option)}
                                                />
                                                {option.firstName} {option.lastName}
                                            </label>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <div className="horizontalButtonContainer">
                            </div>
                        </div>
                    </Modal>
                )}
            </div>
        </td>
    );
};

interface MembersColumnProps {
    team: TeamDto;
    setError: (error: string | null) => void;
    setShowPopup: (show: boolean) => void;
}

interface CreateTeamFormData {
    alias: string;
    scope: TeamDtoScopeEnum;
    title: string;
    eligibility: string;
    linkedId: number;
    tenantId: number;
}

export default TeamForm;