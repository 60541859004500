import React, {ChangeEvent, useEffect, useState} from 'react';
import {cronDelete, cronInsert, cronSearch, cronUpdate} from '../../../api/CronService'; // Import cronSearch
import {CronDto, CronFilter, CronInsertRequest, CronSearchRequest} from '../../../api/generated-react-client/src';
import Modal from '../../common/Modal';
import {MdOutlineCreate, MdOutlineDeleteForever} from 'react-icons/md';

const CronForm: React.FC = () => {
    const [crons, setCrons] = useState<CronDto[]>([]);
    const [searchText, setSearchText] = useState('');
    const [isCronModalOpen, setIsCronModalOpen] = useState(false);
    const [createCronFormData, setCreateCronFormData] = useState<CronDto>({
        host: '',
        running: false,
        executeAt: new Date(),
        userId: 0,
        handler: '',
        jsonPayload: '',
        tenantId: 0,
        retryCount: 0,
        error: '',
        executedAt: undefined,
        retryMaxCount: 3,
        retryWaitTime: 60,
        runMaxTime: 60
    });
    const [selectedCron, setSelectedCron] = useState<CronDto | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [offset, setOffset] = useState<number>(0); // For pagination
    const [limit, setLimit] = useState<number>(10); // For pagination
    const [total, setTotal] = useState<number>(0); // For displaying total records

    const handleError = (error: unknown) => {
        setError((error as Error).message);
        setShowPopup(true);
    };

    const fetchCrons = async () => {
        const cronFilter: CronFilter = {
            offset,
            limit,
        };
        const requestParameters: CronSearchRequest = {cronFilter};

        try {
            const response = await cronSearch(requestParameters);
            setCrons(response.list || []);
            setTotal(response.count || 0); // Update total number of records
        } catch (error) {
            handleError(error);
        }
    };

    useEffect(() => {
        fetchCrons();
    }, [offset, limit]); // Re-fetch whenever offset or limit changes

    const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {name, value} = event.currentTarget;

        setCreateCronFormData(prevFormData => ({
            ...prevFormData,
            [name]: name === 'executeAt' ? new Date(value) : value
        }));
    };

    const handleEditClick = async (cron: CronDto, field: keyof CronDto, value: any) => {
        try {
            const updatedCron = {...cron, [field]: value};
            await cronUpdate({cronDto: updatedCron});
            setCrons(crons.map(c => (c.id === cron.id ? updatedCron : c)));
        } catch (error) {
            handleError(error);
        }
    };

    const handleDelete = async (cronId: number) => {
        if (window.confirm('Are you sure you want to delete this cron?')) {
            try {
                await cronDelete({cronId});
                setCrons(crons.filter(cron => cron.id !== cronId));
            } catch (error) {
                handleError(error);
            }
        }
    };

    const handleCreate = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const requestParameters: CronInsertRequest = {cronDto: createCronFormData};
        try {
            await cronInsert(requestParameters);
            fetchCrons();
            setIsCronModalOpen(false);
        } catch (error) {
            handleError(error);
        }
    };

    const handlePageChange = (newOffset: number) => {
        setOffset(newOffset);
    };

    return (
        <div className="formList">
            <div className="headerContainer">
                <b>Cron Job List</b>
                <div className="formGroup">
                    <input
                        type="text"
                        placeholder="Search text..."
                        value={searchText}
                        onChange={e => setSearchText(e.target.value)}
                    />
                    <button onClick={() => setIsCronModalOpen(true)}>Create</button>
                </div>
                <Modal isOpen={isCronModalOpen} onClose={() => setIsCronModalOpen(false)}>
                    <form onSubmit={handleCreate}>
                        <div className="formGroup">
                            <label>Host</label>
                            <input
                                type="text"
                                name="host"
                                value={createCronFormData.host}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="formGroup">
                            <label>Handler</label>
                            <input
                                type="text"
                                name="handler"
                                value={createCronFormData.handler}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="formGroup">
                            <label>User ID</label>
                            <input
                                type="number"
                                name="userId"
                                value={createCronFormData.userId}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="formGroup">
                            <label>Execute At</label>
                            <input
                                type="datetime-local"
                                name="executeAt"
                                value={
                                    createCronFormData.executeAt instanceof Date
                                        ? createCronFormData.executeAt.toISOString().substring(0, 16)
                                        : ''
                                }
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="formGroup">
                            <label>JSON Payload</label>
                            <textarea
                                name="jsonPayload"
                                value={createCronFormData.jsonPayload}
                                onChange={handleInputChange}
                                rows={5}
                            />
                        </div>
                        <div className="formGroup">
                            <label>Retry Max Count</label>
                            <input
                                type="number"
                                name="retryMaxCount"
                                value={createCronFormData.retryMaxCount}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="formGroup">
                            <label>Retry Wait Time (ms)</label>
                            <input
                                type="number"
                                name="retryWaitTime"
                                value={createCronFormData.retryWaitTime}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="formGroup">
                            <label>Run Max Time (ms)</label>
                            <input
                                type="number"
                                name="runMaxTime"
                                value={createCronFormData.runMaxTime}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="horizontalButtonContainer">
                            <button type="submit">Create</button>
                        </div>
                    </form>
                </Modal>
            </div>
            {showPopup && error !== null && (
                <div className="errorModal">
                    <div className="errorModalContent">
                        <p>Error: {error}</p>
                        <button onClick={() => setShowPopup(false)}>Close</button>
                    </div>
                </div>
            )}
            <table>
                <thead>
                <tr>
                    <th style={{width: '40px', textAlign: 'center'}}>ID</th>
                    <th style={{textAlign: 'left'}}>Host</th>
                    <th style={{textAlign: 'left'}}>Handler</th>
                    <th style={{width: '65px', textAlign: 'center'}}>Running</th>
                    <th style={{textAlign: 'center'}}>User ID</th>
                    <th style={{width: '160px', textAlign: 'center'}}>Execute At</th>
                    <th style={{width: '160px', textAlign: 'center'}}>Executed At</th>
                    <th style={{width: '70px', textAlign: 'center'}}>Tries</th>
                    <th style={{textAlign: 'center'}}>Error</th>
                    <th style={{width: '65px', textAlign: 'center'}}>Tenant</th>
                    <th style={{width: '70px', textAlign: 'center'}}>Actions</th>
                </tr>
                </thead>
                <tbody>
                {crons.map((cron, index) => (
                    <tr key={cron.id} className={`row ${index % 2 === 0 ? 'even' : 'odd'}`}>
                        <td style={{width: '40px', textAlign: 'center'}}>{cron.id}</td>
                        <td style={{
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                            wordBreak: 'break-all',
                            textAlign: 'left'
                        }}>
                            <div className="td-vertical">
                                {cron.host}
                                <span className="editIcon" onClick={() => handleEditClick(cron, 'host', cron.host)}>
                                    <MdOutlineCreate/>
                                </span>
                            </div>
                        </td>
                        <td style={{
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                            wordBreak: 'break-all',
                            textAlign: 'left'
                        }}>
                            <div className="td-vertical">
                                {cron.handler}
                                <span className="editIcon"
                                      onClick={() => handleEditClick(cron, 'handler', cron.handler)}>
                                    <MdOutlineCreate/>
                                </span>
                            </div>
                        </td>
                        <td style={{width: '65px', textAlign: 'center'}}>{cron.running ? 'Yes' : 'No'}</td>
                        <td style={{textAlign: 'center'}}>{cron.userId}</td>
                        <td style={{width: '160px', textAlign: 'center'}}>
                            {cron.executeAt instanceof Date ? cron.executeAt.toLocaleString() : 'Not available'}
                        </td>
                        <td style={{width: '160px', textAlign: 'center'}}>
                            {cron.executedAt instanceof Date ? cron.executedAt.toLocaleString() : 'Not executed yet'}
                        </td>
                        <td style={{width: '70px', textAlign: 'center'}}>{cron.retryCount}</td>
                        <td style={{textAlign: 'center'}}>{cron.error || 'No error'}</td>
                        <td style={{width: '65px', textAlign: 'center'}}>{cron.tenantId}</td>
                        <td style={{width: '70px'}}>
                            <div className="icon-container">
                                <span onClick={() => handleDelete(cron.id!)} className="deleteIcon">
                                    <MdOutlineDeleteForever/>
                                </span>
                            </div>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
            <div style={{textAlign: 'center'}}>
                <div className="pagination">
                    <button
                        className="pagination-button"
                        onClick={() => handlePageChange(Math.max(0, offset - limit))}
                        disabled={offset === 0}
                    >
                        Previous
                    </button>
                    <span
                        className="pagination-info">Showing {offset + 1} to {Math.min(offset + limit, total)} of {total} entries</span>
                    <button
                        className="pagination-button"
                        onClick={() => handlePageChange(offset + limit)}
                        disabled={offset + limit >= total}
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CronForm;
