/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  EntityDto,
} from '../models/index';
import {
    EntityDtoFromJSON,
    EntityDtoToJSON,
} from '../models/index';

export interface EntityDeleteRequest {
    entityId: number;
}

export interface EntityInsertRequest {
    entityDto: EntityDto;
}

export interface EntitySelectByAliasRequest {
    alias: string;
}

export interface EntitySelectByIdRequest {
    entityId: number;
}

export interface EntityUpdateRequest {
    entityDto: EntityDto;
}

/**
 * 
 */
export class EntityManagementApi extends runtime.BaseAPI {

    /**
     * Deletes a specific entity by its ID.
     * Delete a entity
     */
    async entityDeleteRaw(requestParameters: EntityDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['entityId'] == null) {
            throw new runtime.RequiredError(
                'entityId',
                'Required parameter "entityId" was null or undefined when calling entityDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/entity/{entityId}`.replace(`{${"entityId"}}`, encodeURIComponent(String(requestParameters['entityId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Deletes a specific entity by its ID.
     * Delete a entity
     */
    async entityDelete(requestParameters: EntityDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.entityDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates a new Entity and returns the created Entity with its ID.
     * Insert a new Entity
     */
    async entityInsertRaw(requestParameters: EntityInsertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EntityDto>> {
        if (requestParameters['entityDto'] == null) {
            throw new runtime.RequiredError(
                'entityDto',
                'Required parameter "entityDto" was null or undefined when calling entityInsert().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/entity`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EntityDtoToJSON(requestParameters['entityDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EntityDtoFromJSON(jsonValue));
    }

    /**
     * Creates a new Entity and returns the created Entity with its ID.
     * Insert a new Entity
     */
    async entityInsert(requestParameters: EntityInsertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EntityDto> {
        const response = await this.entityInsertRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a list of all entities within a tenant.
     * Get all entities
     */
    async entitySelectRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<EntityDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/entity`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EntityDtoFromJSON));
    }

    /**
     * Retrieves a list of all entities within a tenant.
     * Get all entities
     */
    async entitySelect(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<EntityDto>> {
        const response = await this.entitySelectRaw(initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a entity by its alias.
     * Get entity by name
     */
    async entitySelectByAliasRaw(requestParameters: EntitySelectByAliasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EntityDto>> {
        if (requestParameters['alias'] == null) {
            throw new runtime.RequiredError(
                'alias',
                'Required parameter "alias" was null or undefined when calling entitySelectByAlias().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/entity/by-alias/{alias}`.replace(`{${"alias"}}`, encodeURIComponent(String(requestParameters['alias']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EntityDtoFromJSON(jsonValue));
    }

    /**
     * Retrieves a entity by its alias.
     * Get entity by name
     */
    async entitySelectByAlias(requestParameters: EntitySelectByAliasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EntityDto> {
        const response = await this.entitySelectByAliasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a entity by its ID.
     * Get entity by ID
     */
    async entitySelectByIdRaw(requestParameters: EntitySelectByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EntityDto>> {
        if (requestParameters['entityId'] == null) {
            throw new runtime.RequiredError(
                'entityId',
                'Required parameter "entityId" was null or undefined when calling entitySelectById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/entity/{entityId}`.replace(`{${"entityId"}}`, encodeURIComponent(String(requestParameters['entityId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EntityDtoFromJSON(jsonValue));
    }

    /**
     * Retrieves a entity by its ID.
     * Get entity by ID
     */
    async entitySelectById(requestParameters: EntitySelectByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EntityDto> {
        const response = await this.entitySelectByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates selected information for a specific entity.
     * Update entity\'s attributes
     */
    async entityUpdateRaw(requestParameters: EntityUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EntityDto>> {
        if (requestParameters['entityDto'] == null) {
            throw new runtime.RequiredError(
                'entityDto',
                'Required parameter "entityDto" was null or undefined when calling entityUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/entity`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: EntityDtoToJSON(requestParameters['entityDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EntityDtoFromJSON(jsonValue));
    }

    /**
     * Updates selected information for a specific entity.
     * Update entity\'s attributes
     */
    async entityUpdate(requestParameters: EntityUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EntityDto> {
        const response = await this.entityUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
