/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UserDto } from './UserDto';
import {
    UserDtoFromJSON,
    UserDtoFromJSONTyped,
    UserDtoToJSON,
} from './UserDto';

/**
 * The new attributes for the entity.
 * @export
 * @interface EntityDto
 */
export interface EntityDto {
    /**
     * The unique identifier of the entity. Integer value.
     * @type {number}
     * @memberof EntityDto
     */
    id?: number;
    /**
     * The name of the entity. This is a human-readable alias.
     * @type {string}
     * @memberof EntityDto
     */
    alias?: string;
    /**
     * 
     * @type {UserDto}
     * @memberof EntityDto
     */
    creator?: UserDto;
    /**
     * Creation date
     * @type {Date}
     * @memberof EntityDto
     */
    createdAt?: Date;
    /**
     * A map of the entity's attributes, where each key-value pair represents an attribute name and its value.
     * @type {{ [key: string]: string; }}
     * @memberof EntityDto
     */
    attributeMap?: { [key: string]: string; };
}

/**
 * Check if a given object implements the EntityDto interface.
 */
export function instanceOfEntityDto(value: object): boolean {
    return true;
}

export function EntityDtoFromJSON(json: any): EntityDto {
    return EntityDtoFromJSONTyped(json, false);
}

export function EntityDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EntityDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'alias': json['alias'] == null ? undefined : json['alias'],
        'creator': json['creator'] == null ? undefined : UserDtoFromJSON(json['creator']),
        'createdAt': json['createdAt'] == null ? undefined : (new Date(json['createdAt'])),
        'attributeMap': json['attributeMap'] == null ? undefined : json['attributeMap'],
    };
}

export function EntityDtoToJSON(value?: EntityDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'alias': value['alias'],
        'creator': UserDtoToJSON(value['creator']),
        'createdAt': value['createdAt'] == null ? undefined : ((value['createdAt']).toISOString()),
        'attributeMap': value['attributeMap'],
    };
}

