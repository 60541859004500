import config from './clientConfig';
import {
    TraceManagementApi,
    TraceSelectByPipelineRequest
} from "./generated-react-client/src";
const api = new TraceManagementApi(config);

export const traceSelectByPipelineID = async(requestParameters: TraceSelectByPipelineRequest)=> {
    console.log("B");
    return await api.traceSelectByPipeline(requestParameters)
}