/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * The filter criteria used to search for pipelines
 * @export
 * @interface PipelineFilter
 */
export interface PipelineFilter {
    /**
     * The position of the first record to retrieve, used for pagination. It determines the number of initial rows to skip.
     * @type {number}
     * @memberof PipelineFilter
     */
    offset?: number;
    /**
     * The maximum number of records to return starting from the offset position. This is used to control the size of the result set.
     * @type {number}
     * @memberof PipelineFilter
     */
    limit?: number;
    /**
     * The identifier of the pipeline schema. This is a mandatory parameter.
     * @type {number}
     * @memberof PipelineFilter
     */
    pschemaId?: number;
    /**
     * The search text used to filter pipelines. This can include the pipeline title.
     * @type {string}
     * @memberof PipelineFilter
     */
    text?: string;
}

/**
 * Check if a given object implements the PipelineFilter interface.
 */
export function instanceOfPipelineFilter(value: object): boolean {
    return true;
}

export function PipelineFilterFromJSON(json: any): PipelineFilter {
    return PipelineFilterFromJSONTyped(json, false);
}

export function PipelineFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): PipelineFilter {
    if (json == null) {
        return json;
    }
    return {
        
        'offset': json['offset'] == null ? undefined : json['offset'],
        'limit': json['limit'] == null ? undefined : json['limit'],
        'pschemaId': json['pschemaId'] == null ? undefined : json['pschemaId'],
        'text': json['text'] == null ? undefined : json['text'],
    };
}

export function PipelineFilterToJSON(value?: PipelineFilter | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'offset': value['offset'],
        'limit': value['limit'],
        'pschemaId': value['pschemaId'],
        'text': value['text'],
    };
}

