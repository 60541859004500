import {
    TenantDeleteRequest, TenantHalgorithmDeleteRequest, TenantHalgorithmInsertRequest,
    TenantInsertRequest,
    TenantLinkSignerRequest,
    TenantManagementApi,
    TenantSelectByAliasRequest,
    TenantSelectByIdRequest,
    TenantUnlinkSignerRequest,
    TenantUpdateRequest,
} from './generated-react-client/src/apis/TenantManagementApi';
import config from './clientConfig';
import {apiCallWrapper} from '../utils/apiWrapper';

const api = new TenantManagementApi(config);

export const tenantInsert = async (requestParameters: TenantInsertRequest) => {
    return apiCallWrapper(() => api.tenantInsert(requestParameters));
};
export const tenantSelect = async () => {
    return apiCallWrapper(() => api.tenantSelect());
};

export const tenantUpdate = async (requestParameters: TenantUpdateRequest) => {
    return apiCallWrapper(() => api.tenantUpdate(requestParameters));
};

export const tenantDelete = async (requestParameters: TenantDeleteRequest) => {
    return apiCallWrapper(() => api.tenantDelete(requestParameters));
};

export const tenantHalgorithmInsert = async (requestParameters: TenantHalgorithmInsertRequest) => {
    return apiCallWrapper(() => api.tenantHalgorithmInsert(requestParameters));
};

export const tenantLinkSigner = async (requestParameters: TenantLinkSignerRequest) => {
    return apiCallWrapper(() => api.tenantLinkSigner(requestParameters));
};

export const tenantSelectByAlias = async (requestParameters: TenantSelectByAliasRequest) => {
    return apiCallWrapper(() => api.tenantSelectByAlias(requestParameters));
};

export const tenantSelectById = async (requestParameters: TenantSelectByIdRequest) => {
    return apiCallWrapper(() => api.tenantSelectById(requestParameters));
};

export const tenantHalgorithmDelete = async (requestParameters: TenantHalgorithmDeleteRequest) => {
    return apiCallWrapper(() => api.tenantHalgorithmDelete(requestParameters));
};

export const tenantUnlinkSigner = async (requestParameters: TenantUnlinkSignerRequest) => {
    return apiCallWrapper(() => api.tenantUnlinkSigner(requestParameters));
};