import React, {useEffect, useState} from 'react';
import {logDelete, logPurge, logSearch} from '../../../api/LogService';
import {LogDto, LogFilter, LogPurgeRequest, LogSearchRequest} from '../../../api/generated-react-client/src';
import {MdOutlineDeleteForever} from 'react-icons/md';
import Modal from '../../common/Modal';

const LogForm: React.FC = () => {
    const [logs, setLogs] = useState<LogDto[]>([]);
    const [searchText, setSearchText] = useState('');
    const [uuid, setUuid] = useState<string>('');
    const [isLogModalOpen, setIsLogModalOpen] = useState(false);
    const [selectedLogText, setSelectedLogText] = useState<string | null>(null);
    const [selectedLogField, setSelectedLogField] = useState<string | null>(null);
    const [fromDate, setFromDate] = useState<string>('');
    const [toDate, setToDate] = useState<string>('');
    const [error, setError] = useState<string | null>(null);
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [offset, setOffset] = useState<number>(0);
    const [limit, setLimit] = useState<number>(10);
    const [total, setTotal] = useState<number>(0);
    const [isPurgeModalOpen, setIsPurgeModalOpen] = useState(false);
    const [purgeDate, setPurgeDate] = useState<string>('');

    const handleError = (error: unknown) => {
        setError((error as Error).message);
        setShowPopup(true);
    };

    const fetchLogs = async () => {
        const logFilter: LogFilter = {
            offset,
            limit,
            uuid: uuid.trim() ? uuid : undefined,
            fromDate: fromDate ? new Date(fromDate) : undefined,
            toDate: toDate ? new Date(toDate) : undefined
        };
        const requestParameters: LogSearchRequest = {logFilter};

        try {
            const response = await logSearch(requestParameters);
            setLogs(response.list || []);
            setTotal(response.count || 0);
        } catch (error) {
            handleError(error);
        }
    };

    useEffect(() => {
        fetchLogs();
    }, [offset, limit, fromDate, toDate, uuid]);

    const handleDelete = async (uuid: string) => {
        if (window.confirm('Are you sure you want to delete this log?')) {
            try {
                await logDelete({uuid});
                setLogs(logs.filter(log => log.uuid !== uuid));
            } catch (error) {
                handleError(error);
            }
        }
    };

    const handlePageChange = (newOffset: number) => {
        setOffset(newOffset);
    };

    const handleUuidKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            fetchLogs();
        }
    };

    const truncateText = (text: string, maxLength: number) => {
        return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
    };

    const handleShowFullText = (text: string, field: string) => {
        setSelectedLogText(text);
        setSelectedLogField(field);
        setIsLogModalOpen(true);
    };

    const closeModal = () => {
        setIsLogModalOpen(false);
        setSelectedLogText(null);
        setSelectedLogField(null);
    };

    const handlePurge = async () => {
        if (purgeDate) {
            const requestParameters: LogPurgeRequest = {purgeTime: new Date(purgeDate).toISOString()};
            try {
                await logPurge(requestParameters);
                setIsPurgeModalOpen(false);
                fetchLogs();
            } catch (error) {
                handleError(error);
            }
        }
    };

    return (
        <div className="formList">
            <div className="headerContainer">
                <b>Log Entries</b>
                <div className="formGroup">
                    <input
                        type="text"
                        placeholder="Enter UUID"
                        value={uuid}
                        onChange={e => {
                            setUuid(e.target.value);
                            setOffset(0);  // Reset offset when UUID filter changes
                        }}
                        onKeyPress={handleUuidKeyPress}
                    />
                    <input
                        type="date"
                        value={fromDate}
                        onChange={e => {
                            setFromDate(e.target.value);
                            setOffset(0);  // Reset offset when fromDate filter changes
                        }}
                        placeholder="From"
                    />
                    <input
                        type="date"
                        value={toDate}
                        onChange={e => {
                            setToDate(e.target.value);
                            setOffset(0);  // Reset offset when toDate filter changes
                        }}
                        placeholder="To"
                    />
                    <input
                        type="text"
                        placeholder="Search logs..."
                        value={searchText}
                        onChange={e => {
                            setSearchText(e.target.value);
                            setOffset(0);  // Reset offset when search text changes
                        }}
                    />
                    <button onClick={() => setIsPurgeModalOpen(true)}>Purge</button>
                </div>
            </div>
            {showPopup && error !== null && (
                <div className="errorModal">
                    <div className="errorModalContent">
                        <p>Error: {error}</p>
                        <button onClick={() => setShowPopup(false)}>Close</button>
                    </div>
                </div>
            )}
            <div className="tableContainer">
                <table>
                    <thead>
                    <tr>
                        <th style={{width: '320px', textAlign: 'center'}}>UUID</th>
                        <th style={{width: '80px', textAlign: 'center'}}>User ID</th>
                        <th style={{width: '160px', textAlign: 'center'}}>Created At</th>
                        <th style={{textAlign: 'center'}}>Message</th>
                        <th style={{textAlign: 'center'}}>Context</th>
                        <th style={{textAlign: 'center'}}>Stacktrace</th>
                        <th style={{width: '70px', textAlign: 'center'}}>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {logs.map((log, index) => (
                        <tr key={log.uuid} className={`row ${index % 2 === 0 ? 'even' : 'odd'}`}>
                            <td style={{width: '320px', textAlign: 'center'}}>{log.uuid}</td>
                            <td style={{width: '80px', textAlign: 'center'}}>{log.userId}</td>
                            <td style={{width: '160px', textAlign: 'center'}}>
                                {log.createdAt instanceof Date ? log.createdAt.toLocaleString() : 'Not available'}
                            </td>
                            <td style={{textAlign: 'left', cursor: 'pointer'}}
                                onClick={() => handleShowFullText(log.message!, 'Message')}>
                                {truncateText(log.message || 'No message', 100)}
                            </td>
                            <td style={{textAlign: 'left', cursor: 'pointer'}}
                                onClick={() => handleShowFullText(log.context!, 'Context')}>
                                {truncateText(log.context || 'No context', 100)}
                            </td>
                            <td style={{textAlign: 'left', cursor: 'pointer'}}
                                onClick={() => handleShowFullText(log.stacktrace!, 'Stacktrace')}>
                                {truncateText(log.stacktrace || 'No stacktrace', 100)}
                            </td>
                            <td style={{width: '70px'}}>
                                <div className="icon-container">
                                <span onClick={() => handleDelete(log.uuid!)} className="deleteIcon">
                                    <MdOutlineDeleteForever/>
                                </span>
                                </div>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                <div className="headerContainer">
                    <div className="pagination">
                        <button
                            className="pagination-button"
                            onClick={() => handlePageChange(Math.max(0, offset - limit))}
                            disabled={offset === 0}
                        >
                            Previous
                        </button>
                        <span
                            className="pagination-info">Showing {offset + 1} to {Math.min(offset + limit, total)} of {total} entries</span>
                        <button
                            className="pagination-button"
                            onClick={() => handlePageChange(offset + limit)}
                            disabled={offset + limit >= total}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
            {isLogModalOpen && (
                <Modal isOpen={isLogModalOpen} onClose={closeModal}>
                    <div className="answerSection">
                        <h3>{selectedLogField}</h3>
                        <pre>{selectedLogText}</pre>
                    </div>
                </Modal>
            )}

            {isPurgeModalOpen && (
                <Modal isOpen={isPurgeModalOpen} onClose={() => setIsPurgeModalOpen(false)}>
                    <div className="formGroup">
                        <h3>Select the purge date</h3>
                        <input
                            type="date"
                            value={purgeDate}
                            onChange={e => setPurgeDate(e.target.value)}
                        />
                        <div className="horizontalButtonContainer">
                            <button onClick={handlePurge}>Purge</button>
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default LogForm;