import React, {useState} from 'react';
import {pipelinePrint} from '../../../api/PipelineService';
import styles from './PrintPipelineForm.module.css';

const PrintPipelineForm: React.FC = () => {
    const [hash, setHash] = useState<string>('');
    const [responseHTML, setResponseHTML] = useState<string>('');
    const [error, setError] = useState<string | null>(null);
    const [showErrorPopup, setShowErrorPopup] = useState<boolean>(false);

    const handleButtonClick = async () => {
        try {
            const status: string = await pipelinePrint({ hash });
            if (status === 'UNFOUND') {
                setResponseHTML(''); // Assuming 'status' contains the HTML for successful cases
                setError('The hash cannot be found. Please check if the hash value is correct, or try using the search screen.');
                setShowErrorPopup(true);
            } else {
                setResponseHTML(status); // Assuming 'status' contains the HTML for successful cases
            }
        } catch (error) {
            setError((error as Error).message);
            setShowErrorPopup(true);
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.formGroupE}>
                <label>Hash to find: </label>
                <input
                    id="hash"
                    type="text"
                    value={hash}
                    onChange={(e) => setHash(e.target.value)}
                    placeholder="Enter hash"
                />
                <button className={styles.buttonE} onClick={handleButtonClick}>Print</button>
            </div>
            <div className={styles.answerSection} dangerouslySetInnerHTML={{__html: responseHTML}}>
            </div>
            {showErrorPopup && error && (
                <div className="errorModal">
                    <div className="errorModalContent">
                        <p>Error: {error}</p>
                        <button onClick={() => setShowErrorPopup(false)}>Close</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PrintPipelineForm;