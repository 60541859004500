/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  HostDto,
} from '../models/index';
import {
    HostDtoFromJSON,
    HostDtoToJSON,
} from '../models/index';

export interface HostDeleteRequest {
    hostAlias: string;
}

export interface HostInsertRequest {
    hostDto: HostDto;
}

export interface HostSelectByAliasRequest {
    hostAlias: string;
}

export interface HostUpdateRequest {
    hostAlias: string;
    hostDto: HostDto;
}

/**
 * 
 */
export class HostManagementApi extends runtime.BaseAPI {

    /**
     * Deletes a specific host by its alias.
     * Delete a host
     */
    async hostDeleteRaw(requestParameters: HostDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['hostAlias'] == null) {
            throw new runtime.RequiredError(
                'hostAlias',
                'Required parameter "hostAlias" was null or undefined when calling hostDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/host/{hostAlias}`.replace(`{${"hostAlias"}}`, encodeURIComponent(String(requestParameters['hostAlias']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Deletes a specific host by its alias.
     * Delete a host
     */
    async hostDelete(requestParameters: HostDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.hostDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates a new host and returns the created host with its ID.
     * Insert a new host
     */
    async hostInsertRaw(requestParameters: HostInsertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HostDto>> {
        if (requestParameters['hostDto'] == null) {
            throw new runtime.RequiredError(
                'hostDto',
                'Required parameter "hostDto" was null or undefined when calling hostInsert().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/host`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HostDtoToJSON(requestParameters['hostDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HostDtoFromJSON(jsonValue));
    }

    /**
     * Creates a new host and returns the created host with its ID.
     * Insert a new host
     */
    async hostInsert(requestParameters: HostInsertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HostDto> {
        const response = await this.hostInsertRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a list of all host.
     * Get all host
     */
    async hostSelectAllRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<HostDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/host`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(HostDtoFromJSON));
    }

    /**
     * Retrieves a list of all host.
     * Get all host
     */
    async hostSelectAll(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<HostDto>> {
        const response = await this.hostSelectAllRaw(initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a host by its alias.
     * Get a host by alias
     */
    async hostSelectByAliasRaw(requestParameters: HostSelectByAliasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HostDto>> {
        if (requestParameters['hostAlias'] == null) {
            throw new runtime.RequiredError(
                'hostAlias',
                'Required parameter "hostAlias" was null or undefined when calling hostSelectByAlias().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/host/by-alias/{hostAlias}`.replace(`{${"hostAlias"}}`, encodeURIComponent(String(requestParameters['hostAlias']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HostDtoFromJSON(jsonValue));
    }

    /**
     * Retrieves a host by its alias.
     * Get a host by alias
     */
    async hostSelectByAlias(requestParameters: HostSelectByAliasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HostDto> {
        const response = await this.hostSelectByAliasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates selected information for a specific host.
     * Update host\'s attributes
     */
    async hostUpdateRaw(requestParameters: HostUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HostDto>> {
        if (requestParameters['hostAlias'] == null) {
            throw new runtime.RequiredError(
                'hostAlias',
                'Required parameter "hostAlias" was null or undefined when calling hostUpdate().'
            );
        }

        if (requestParameters['hostDto'] == null) {
            throw new runtime.RequiredError(
                'hostDto',
                'Required parameter "hostDto" was null or undefined when calling hostUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/host/{hostAlias}`.replace(`{${"hostAlias"}}`, encodeURIComponent(String(requestParameters['hostAlias']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: HostDtoToJSON(requestParameters['hostDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HostDtoFromJSON(jsonValue));
    }

    /**
     * Updates selected information for a specific host.
     * Update host\'s attributes
     */
    async hostUpdate(requestParameters: HostUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HostDto> {
        const response = await this.hostUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
