import {
    PipelineSchemaManagementApi,
    PschemaDeleteRequest,
    PschemaExportRequest,
    PschemaImportRequest,
    PschemaInsertRequest,
    PschemaLinkSignerRequest,
    PschemaSearchRequest,
    PschemaSelectByAliasAndVersionRequest,
    PschemaSelectByIdRequest,
    PschemaUnlinkSignerRequest,
    PschemaUpdateRequest,
} from './generated-react-client/src/apis';
import config from './clientConfig';
import {apiCallWrapper} from '../utils/apiWrapper';

const api = new PipelineSchemaManagementApi(config);

export const pschemaInsert = async (requestParameters: PschemaInsertRequest) => {
    return apiCallWrapper(() => api.pschemaInsert(requestParameters));
};

export const pschemaUpdate = async (requestParameters: PschemaUpdateRequest) => {
    return apiCallWrapper(() => api.pschemaUpdate(requestParameters));
};

export const pschemaDelete = async (requestParameters: PschemaDeleteRequest) => {
    return apiCallWrapper(() => api.pschemaDelete(requestParameters));
};

export const pschemaImport = async (requestParameters: PschemaImportRequest) => {
    return apiCallWrapper(() => api.pschemaImport(requestParameters));
};

export const pschemaExport = async (requestParameters: PschemaExportRequest) => {
    return apiCallWrapper(() => api.pschemaExport(requestParameters));
};

export const pschemaLinkSigner = async (requestParameters: PschemaLinkSignerRequest) => {
    return apiCallWrapper(() => api.pschemaLinkSigner(requestParameters));
};

export const pschemaSearch = async (requestParameters: PschemaSearchRequest) => {
    return apiCallWrapper(() => api.pschemaSearch(requestParameters));
};

export const pschemaSelectByAliasAndVersion = async (
    requestParameters: PschemaSelectByAliasAndVersionRequest
) => {
    return apiCallWrapper(() => api.pschemaSelectByAliasAndVersion(requestParameters));
};

export const pschemaSelectById = async (requestParameters: PschemaSelectByIdRequest) => {
    return apiCallWrapper(() => api.pschemaSelectById(requestParameters));
};

export const pschemaUnlinkSigner = async (requestParameters: PschemaUnlinkSignerRequest) => {
    return apiCallWrapper(() => api.pschemaUnlinkSigner(requestParameters));
};