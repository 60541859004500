/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AuthUserDto,
  BaRequest,
  SignupRequest,
  UserDto,
} from '../models/index';
import {
    AuthUserDtoFromJSON,
    AuthUserDtoToJSON,
    BaRequestFromJSON,
    BaRequestToJSON,
    SignupRequestFromJSON,
    SignupRequestToJSON,
    UserDtoFromJSON,
    UserDtoToJSON,
} from '../models/index';

export interface AuthenticateRequest {
    baRequest: BaRequest;
}

export interface ChooseTenantRequest {
    baRequest: BaRequest;
}

export interface HandleResetPasswordRequest {
    baRequest: BaRequest;
}

export interface ResetPasswordRequest {
    body: string;
}

export interface SignupOperationRequest {
    signupRequest: SignupRequest;
}

/**
 * 
 */
export class BasicAuthenticationManagementApi extends runtime.BaseAPI {

    /**
     * Authenticates a user based on credentials provided in the request body, and returns authentication details.
     * Authenticate User using Basic Authentication
     */
    async authenticateRaw(requestParameters: AuthenticateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthUserDto>> {
        if (requestParameters['baRequest'] == null) {
            throw new runtime.RequiredError(
                'baRequest',
                'Required parameter "baRequest" was null or undefined when calling authenticate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/ba/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BaRequestToJSON(requestParameters['baRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthUserDtoFromJSON(jsonValue));
    }

    /**
     * Authenticates a user based on credentials provided in the request body, and returns authentication details.
     * Authenticate User using Basic Authentication
     */
    async authenticate(requestParameters: AuthenticateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthUserDto> {
        const response = await this.authenticateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Authenticates a user based on credentials provided in the request body, and returns authentication details.
     * Authenticate User using Basic Authentication
     */
    async chooseTenantRaw(requestParameters: ChooseTenantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthUserDto>> {
        if (requestParameters['baRequest'] == null) {
            throw new runtime.RequiredError(
                'baRequest',
                'Required parameter "baRequest" was null or undefined when calling chooseTenant().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/ba/login/choose-tenant`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BaRequestToJSON(requestParameters['baRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthUserDtoFromJSON(jsonValue));
    }

    /**
     * Authenticates a user based on credentials provided in the request body, and returns authentication details.
     * Authenticate User using Basic Authentication
     */
    async chooseTenant(requestParameters: ChooseTenantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthUserDto> {
        const response = await this.chooseTenantRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Provide new password
     */
    async handleResetPasswordRaw(requestParameters: HandleResetPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['baRequest'] == null) {
            throw new runtime.RequiredError(
                'baRequest',
                'Required parameter "baRequest" was null or undefined when calling handleResetPassword().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/ba/reset-handle`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BaRequestToJSON(requestParameters['baRequest']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Provide new password
     */
    async handleResetPassword(requestParameters: HandleResetPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.handleResetPasswordRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async logoffRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ba/logoff`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async logoff(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.logoffRaw(initOverrides);
        return await response.value();
    }

    /**
     * Refresh a uuid token
     */
    async refreshTokenRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ba/refresh`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Refresh a uuid token
     */
    async refreshToken(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.refreshTokenRaw(initOverrides);
        return await response.value();
    }

    /**
     * Reset password
     */
    async resetPasswordRaw(requestParameters: ResetPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling resetPassword().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/ba/reset`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Reset password
     */
    async resetPassword(requestParameters: ResetPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.resetPasswordRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async signupRaw(requestParameters: SignupOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserDto>> {
        if (requestParameters['signupRequest'] == null) {
            throw new runtime.RequiredError(
                'signupRequest',
                'Required parameter "signupRequest" was null or undefined when calling signup().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/ba/signup`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SignupRequestToJSON(requestParameters['signupRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDtoFromJSON(jsonValue));
    }

    /**
     */
    async signup(requestParameters: SignupOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserDto> {
        const response = await this.signupRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
