/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AccessRightDto,
} from '../models/index';
import {
    AccessRightDtoFromJSON,
    AccessRightDtoToJSON,
} from '../models/index';

/**
 * 
 */
export class AccessManagementApi extends runtime.BaseAPI {

    /**
     * Retrieves a list of all access rights.
     * Get all access rights
     */
    async accessSelectRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AccessRightDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/access`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AccessRightDtoFromJSON));
    }

    /**
     * Retrieves a list of all access rights.
     * Get all access rights
     */
    async accessSelect(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AccessRightDto>> {
        const response = await this.accessSelectRaw(initOverrides);
        return await response.value();
    }

}
