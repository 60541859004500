/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * The new logging level for the logger.
 * @export
 * @interface LoggerDto
 */
export interface LoggerDto {
    /**
     * 
     * @type {string}
     * @memberof LoggerDto
     */
    alias?: string;
    /**
     * Defines the various log levels for logging purposes.
     * @type {string}
     * @memberof LoggerDto
     */
    level?: LoggerDtoLevelEnum;
    /**
     * 
     * @type {boolean}
     * @memberof LoggerDto
     */
    performance?: boolean;
}


/**
 * @export
 */
export const LoggerDtoLevelEnum = {
    All: 'ALL',
    Performance: 'PERFORMANCE',
    Error: 'ERROR',
    Warn: 'WARN',
    Info: 'INFO',
    Debug: 'DEBUG',
    Trace: 'TRACE'
} as const;
export type LoggerDtoLevelEnum = typeof LoggerDtoLevelEnum[keyof typeof LoggerDtoLevelEnum];


/**
 * Check if a given object implements the LoggerDto interface.
 */
export function instanceOfLoggerDto(value: object): boolean {
    return true;
}

export function LoggerDtoFromJSON(json: any): LoggerDto {
    return LoggerDtoFromJSONTyped(json, false);
}

export function LoggerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoggerDto {
    if (json == null) {
        return json;
    }
    return {
        
        'alias': json['alias'] == null ? undefined : json['alias'],
        'level': json['level'] == null ? undefined : json['level'],
        'performance': json['performance'] == null ? undefined : json['performance'],
    };
}

export function LoggerDtoToJSON(value?: LoggerDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'alias': value['alias'],
        'level': value['level'],
        'performance': value['performance'],
    };
}

