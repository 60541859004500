import config from './clientConfig';
import {
    LogDeleteRequest,
    LoggerUpdateLevelRequest,
    LoggerUpdatePerformanceRequest,
    LogManagementApi,
    LogPurgeRequest,
    LogSearchRequest,
} from './generated-react-client/src';
import {apiCallWrapper} from '../utils/apiWrapper';

const api = new LogManagementApi(config);

export const logDelete = async (requestParameter: LogDeleteRequest) => {
    return apiCallWrapper(() => api.logDelete(requestParameter));
};

export const logPurge = async (requestParameter: LogPurgeRequest) => {
    return apiCallWrapper(() => api.logPurge(requestParameter));
};

export const logSearch = async (requestParameter: LogSearchRequest) => {
    return apiCallWrapper(() => api.logSearch(requestParameter));
};

export const loggerSelect = async () => {
    return apiCallWrapper(() => api.loggerSelect());
};

export const loggerUpdateLevel = async (requestParameter: LoggerUpdateLevelRequest) => {
    return apiCallWrapper(() => api.loggerUpdateLevel(requestParameter));
};

export const loggerUpdatePerformance = async (requestParameter: LoggerUpdatePerformanceRequest) => {
    return apiCallWrapper(() => api.loggerUpdatePerformance(requestParameter));
};
