/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UserDto } from './UserDto';
import {
    UserDtoFromJSON,
    UserDtoFromJSONTyped,
    UserDtoToJSON,
} from './UserDto';

/**
 * The new attributes for the team.
 * @export
 * @interface TeamDto
 */
export interface TeamDto {
    /**
     * The unique identifier for the team.
     * @type {number}
     * @memberof TeamDto
     */
    id?: number;
    /**
     * The identifier of the tenant to which the team belongs.
     * @type {number}
     * @memberof TeamDto
     */
    tenantId?: number;
    /**
     * The identifier of an external public team maintained within the repository, if applicable.
     * @type {number}
     * @memberof TeamDto
     */
    linkedId?: number;
    /**
     * The alias or short name of the team.
     * @type {string}
     * @memberof TeamDto
     */
    alias?: string;
    /**
     * Defines the accessibility scope of a team within the SafeDataPath system.
     * @type {string}
     * @memberof TeamDto
     */
    scope?: TeamDtoScopeEnum;
    /**
     * The title or a short description of the team.
     * @type {string}
     * @memberof TeamDto
     */
    title?: string;
    /**
     * A set of regex patterns to determine eligibility for new members wanting to join the team.
     * @type {string}
     * @memberof TeamDto
     */
    eligibility?: string;
    /**
     * 
     * @type {UserDto}
     * @memberof TeamDto
     */
    creator?: UserDto;
    /**
     * The timestamp when the team was created.
     * @type {Date}
     * @memberof TeamDto
     */
    createdAt?: Date;
}


/**
 * @export
 */
export const TeamDtoScopeEnum = {
    Public: 'PUBLIC',
    Protected: 'PROTECTED',
    Private: 'PRIVATE'
} as const;
export type TeamDtoScopeEnum = typeof TeamDtoScopeEnum[keyof typeof TeamDtoScopeEnum];


/**
 * Check if a given object implements the TeamDto interface.
 */
export function instanceOfTeamDto(value: object): boolean {
    return true;
}

export function TeamDtoFromJSON(json: any): TeamDto {
    return TeamDtoFromJSONTyped(json, false);
}

export function TeamDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'tenantId': json['tenantId'] == null ? undefined : json['tenantId'],
        'linkedId': json['linkedId'] == null ? undefined : json['linkedId'],
        'alias': json['alias'] == null ? undefined : json['alias'],
        'scope': json['scope'] == null ? undefined : json['scope'],
        'title': json['title'] == null ? undefined : json['title'],
        'eligibility': json['eligibility'] == null ? undefined : json['eligibility'],
        'creator': json['creator'] == null ? undefined : UserDtoFromJSON(json['creator']),
        'createdAt': json['createdAt'] == null ? undefined : (new Date(json['createdAt'])),
    };
}

export function TeamDtoToJSON(value?: TeamDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'tenantId': value['tenantId'],
        'linkedId': value['linkedId'],
        'alias': value['alias'],
        'scope': value['scope'],
        'title': value['title'],
        'eligibility': value['eligibility'],
        'creator': UserDtoToJSON(value['creator']),
        'createdAt': value['createdAt'] == null ? undefined : ((value['createdAt']).toISOString()),
    };
}

