/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  SetupDto,
  SetupView,
  TenantSetupFilter,
} from '../models/index';
import {
    SetupDtoFromJSON,
    SetupDtoToJSON,
    SetupViewFromJSON,
    SetupViewToJSON,
    TenantSetupFilterFromJSON,
    TenantSetupFilterToJSON,
} from '../models/index';

export interface TenantSetupDeleteRequest {
    id: string;
}

export interface TenantSetupInsertRequest {
    setupDto: SetupDto;
}

export interface TenantSetupSearchRequest {
    filter: TenantSetupFilter;
}

export interface TenantSetupSelectByIdRequest {
    id: string;
}

export interface TenantSetupUpdateRequest {
    setupDto: SetupDto;
}

/**
 * 
 */
export class TenantSetupManagementApi extends runtime.BaseAPI {

    /**
     * Deletes a tenant configuration by their unique identifier (ID) and returns the count of deleted records.
     * Delete a tenant configuration
     */
    async tenantSetupDeleteRaw(requestParameters: TenantSetupDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling tenantSetupDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/tenant-setup/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Deletes a tenant configuration by their unique identifier (ID) and returns the count of deleted records.
     * Delete a tenant configuration
     */
    async tenantSetupDelete(requestParameters: TenantSetupDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.tenantSetupDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates a new tenant configuration for a tenant with the given details and returns the created configuration.
     * Create a new tenant configuration
     */
    async tenantSetupInsertRaw(requestParameters: TenantSetupInsertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SetupDto>> {
        if (requestParameters['setupDto'] == null) {
            throw new runtime.RequiredError(
                'setupDto',
                'Required parameter "setupDto" was null or undefined when calling tenantSetupInsert().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/tenant-setup`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetupDtoToJSON(requestParameters['setupDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SetupDtoFromJSON(jsonValue));
    }

    /**
     * Creates a new tenant configuration for a tenant with the given details and returns the created configuration.
     * Create a new tenant configuration
     */
    async tenantSetupInsert(requestParameters: TenantSetupInsertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SetupDto> {
        const response = await this.tenantSetupInsertRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async tenantSetupSearchRaw(requestParameters: TenantSetupSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SetupView>> {
        if (requestParameters['filter'] == null) {
            throw new runtime.RequiredError(
                'filter',
                'Required parameter "filter" was null or undefined when calling tenantSetupSearch().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/tenant-setup/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SetupViewFromJSON(jsonValue));
    }

    /**
     */
    async tenantSetupSearch(requestParameters: TenantSetupSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SetupView> {
        const response = await this.tenantSetupSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a list of all tenant configurations in the system.
     * Get all tenant configurations
     */
    async tenantSetupSelectRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SetupDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/tenant-setup`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SetupDtoFromJSON));
    }

    /**
     * Retrieves a list of all tenant configurations in the system.
     * Get all tenant configurations
     */
    async tenantSetupSelect(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SetupDto>> {
        const response = await this.tenantSetupSelectRaw(initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a single tenant configuration by their unique identifier (ID).
     * Get a tenant configuration by ID
     */
    async tenantSetupSelectByIdRaw(requestParameters: TenantSetupSelectByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SetupDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling tenantSetupSelectById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/tenant-setup/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SetupDtoFromJSON(jsonValue));
    }

    /**
     * Retrieves a single tenant configuration by their unique identifier (ID).
     * Get a tenant configuration by ID
     */
    async tenantSetupSelectById(requestParameters: TenantSetupSelectByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SetupDto> {
        const response = await this.tenantSetupSelectByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates an existing tenant configuration based on the provided information and returns the updated configuration.
     * Update an existing tenant configuration
     */
    async tenantSetupUpdateRaw(requestParameters: TenantSetupUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SetupDto>> {
        if (requestParameters['setupDto'] == null) {
            throw new runtime.RequiredError(
                'setupDto',
                'Required parameter "setupDto" was null or undefined when calling tenantSetupUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/tenant-setup`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: SetupDtoToJSON(requestParameters['setupDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SetupDtoFromJSON(jsonValue));
    }

    /**
     * Updates an existing tenant configuration based on the provided information and returns the updated configuration.
     * Update an existing tenant configuration
     */
    async tenantSetupUpdate(requestParameters: TenantSetupUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SetupDto> {
        const response = await this.tenantSetupUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
