import {
    BatchSchemaDeleteRequest,
    BatchSchemaInsertRequest,
    BatchSchemaManagementApi,
    BatchSchemaSelectByIdRequest,
    BatchSchemaSelectByPschemaIdRequest,
    BatchSchemaUpdateRequest
} from './generated-react-client/src/apis/BatchSchemaManagementApi';
import config from './clientConfig';

const api = new BatchSchemaManagementApi(config);

export const batchSchemaInsert = async (requestParameters: BatchSchemaInsertRequest) => {
    return await api.batchSchemaInsert(requestParameters);
};

export const batchSchemaUpdate = async (requestParameters: BatchSchemaUpdateRequest) => {
    return await api.batchSchemaUpdate(requestParameters);
};

export const batchSchemaDelete = async (requestParameters: BatchSchemaDeleteRequest) => {
    return await api.batchSchemaDelete(requestParameters);
};

export const batchSchemaSelectByPschemaId = async (requestParameters: BatchSchemaSelectByPschemaIdRequest) => {
    return await api.batchSchemaSelectByPschemaId(requestParameters);
};

export const batchSchemaSelectById = async (requestParameters: BatchSchemaSelectByIdRequest) => {
    return await api.batchSchemaSelectById(requestParameters);
};