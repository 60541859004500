import {
    NotificationDeleteRequest,
    NotificationInsertRequest,
    NotificationManagementApi,
    NotificationSelectByIdRequest,
    NotificationSelectByTenantRequest,
    NotificationSelectRequest,
    NotificationSendRequest,
    NotificationUpdateRequest,
} from './generated-react-client/src/apis/NotificationManagementApi';
import config from './clientConfig';
import {apiCallWrapper} from '../utils/apiWrapper';

const api = new NotificationManagementApi(config);

export const notificationInsert = async (requestParameters: NotificationInsertRequest) => {
    return apiCallWrapper(() => api.notificationInsert(requestParameters));
};

export const notificationSelect = async (requestParameters: NotificationSelectRequest) => {
    return apiCallWrapper(() => api.notificationSelect(requestParameters));
};

export const notificationSelectById = async (requestParameters: NotificationSelectByIdRequest) => {
    return apiCallWrapper(() => api.notificationSelectById(requestParameters));
};

export const notificationSelectByTenant = async (requestParameters: NotificationSelectByTenantRequest) => {
    return apiCallWrapper(() => api.notificationSelectByTenant(requestParameters));
};

export const notificationSend = async (requestParameters: NotificationSendRequest) => {
    return apiCallWrapper(() => api.notificationSend(requestParameters));
};

export const notificationUpdate = async (requestParameters: NotificationUpdateRequest) => {
    return apiCallWrapper(() => api.notificationUpdate(requestParameters));
};

export const notificationDelete = async (requestParameters: NotificationDeleteRequest) => {
    return apiCallWrapper(() => api.notificationDelete(requestParameters));
};