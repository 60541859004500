import React, {useEffect, useState} from 'react';
import {
    PipelineDto,
    PipelineSchemaDto,
    PipelineSearchRequest,
    PipelineView,
} from "../../../api/generated-react-client/src";
import {pipelineSearch} from "../../../api/PipelineService";
import {pschemaSearch} from "../../../api/PipelineSchemaService";
import {MdOutlineVisibility} from "react-icons/md";
import {generatePath, useNavigate} from "react-router-dom";

interface FormState {
    pschemaId: number;
    text: string;
}

const PipelineSearchForm: React.FC = () => {
    const [pschemas, setPschemas] = useState<PipelineSchemaDto[]>([]);
    const [formData, setFormData] = useState<FormState>({pschemaId: 0, text: ''});
    const [pipelines, setPipelines] = useState<(PipelineDto & { pschemaAlias?: string })[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [showErrorPopup, setShowErrorPopup] = useState<boolean>(false);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const req = {
                    pipelineFilter: {
                        pschemaId: 0,
                        limit: 10,
                        offset: 0,
                        text: '',
                    },
                };

                const research = await pipelineSearch(req);

                // Replace pschemaSelect() with pschemaSearch using an empty filter
                const searchFilter = {pipelineSchemaFilter: {}}; // Empty filter for retrieving all schemas
                const pschemaResult = await pschemaSearch(searchFilter);

                const pschemasFromApi = pschemaResult.list || []; // Extract list from the search result
                setPschemas(pschemasFromApi);

                const updatedPipelines = research.list!.map(pipeline => {
                    const pschemaAlias = pschemasFromApi.find(pschema => pschema.id === pipeline.pschemaId)?.alias || 'Unknown';
                    return {
                        ...pipeline,
                        pschemaAlias,
                    };
                });

                setPipelines(updatedPipelines);
            } catch (error) {
                setError((error as Error).message);
                setShowErrorPopup(true);
            }
        };

        fetchData();
    }, []);

    const handleInputChange = (field: keyof FormState, value: string) => {
        setFormData((prevFormData) => ({...prevFormData, [field]: value}));
    };

    const handleSchemaChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = parseInt(event.target.value);
        setFormData((prevFormData) => ({
            ...prevFormData,
            pschemaId: value,
        }));
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        try {
            const req: PipelineSearchRequest = {
                pipelineFilter: {
                    pschemaId: formData.pschemaId,
                    limit: 10,
                    offset: 0,
                    text: formData.text,
                },
            };
            const result: PipelineView = await pipelineSearch(req);
            setPipelines(result.list!.map(pipeline => ({
                ...pipeline,
                pschemaAlias: pschemas.find(pschema => pschema.id === pipeline.pschemaId)?.alias || 'Unknown'
            })) || []);
        } catch (error) {
            setError((error as Error).message);
            setShowErrorPopup(true);
        }
    };

    const handleShowTraces = (pschemaId: number) => {
        const path = generatePath('/trace-pipeline/:id', {id: '' + pschemaId});
        navigate(path);

    };

    return (
        <div className="formList">
            <div className="headerContainer" style={{justifyContent: 'flex-end'}}>
                <form onSubmit={handleSubmit}>
                    <div className="formGroup">
                        <label htmlFor="selectorpschema">Pipeline schema:</label>
                        <select
                            id="selectorpschema"
                            value={formData.pschemaId}
                            onChange={handleSchemaChange}
                        >
                            <option value={0}>Select a pschema</option>
                            {pschemas.map((pschema) => (
                                <option key={pschema.id} value={pschema.id}>
                                    {pschema.alias}
                                </option>
                            ))}
                        </select>
                        <label htmlFor="title">Title :</label>
                        <input
                            id="title"
                            type="text"
                            placeholder="Search..."
                            value={formData.text}
                            onChange={(e) => handleInputChange('text', e.target.value)}
                        />
                        <button type="submit">Search</button>
                    </div>
                </form>
            </div>
            {showErrorPopup && error && (
                <div className="errorModal">
                    <div className="errorModalContent">
                        <p>Error: {error}</p>
                        <button onClick={() => setShowErrorPopup(false)}>Close</button>
                    </div>
                </div>
            )}
            <table>
                <thead>
                <tr>
                    <th style={{width: '50px', textAlign: 'center'}}>Id</th>
                    <th>External Id</th>
                    <th>Title</th>
                    <th style={{width: '170px', textAlign: 'center'}}>Created At</th>
                    <th>Creator</th>
                    <th>Traces</th>
                    <th style={{textAlign: 'center'}}>Schema alias</th>
                </tr>
                </thead>
                <tbody>
                {pipelines.map((pipeline, index) => (
                    <tr key={pipeline.id} className={`row ${index % 2 === 0 ? 'even' : 'odd'}`}>
                        <td style={{width: '50px', textAlign: 'center'}}>{pipeline.id}</td>
                        <td>{pipeline.externalId}</td>
                        <td>{pipeline.title}</td>
                        <td style={{
                            width: '170px',
                            textAlign: 'center'
                        }}>{pipeline.createdAt ? new Date(pipeline.createdAt).toLocaleString() : 'Not Available'}</td>
                        <td>{pipeline.creator ? `${pipeline.creator?.firstName} ${pipeline.creator?.lastName}` : 'Not Available'}</td>
                        <td>
                            <div className="icon-container">
                                <span onClick={() => handleShowTraces(pipeline.id!)}
                                      className="notifyIcon"><MdOutlineVisibility/></span>
                                <span onClick={() => handleShowTraces(pipeline.id!)}
                                      className="notifyText">({pipeline.traceList?.length || 0})</span>
                            </div>
                        </td>
                        <td style={{textAlign: 'center'}}>{pipeline.pschemaAlias}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default PipelineSearchForm;
