import {
    TschemaDeleteByPschemaRequest,
    TschemaDeleteRequest,
    TschemaInsertRequest,
    TschemaLinkDeleteRequest,
    TschemaLinkInsertRequest,
    TschemaLinkSelectByPschemaRequest,
    TschemaSelectByPschemaRequest,
    TschemaSelectByRequest,
    TschemaUpdateRequest
} from './generated-react-client/src/apis/TraceSchemaManagementApi';
import config from './clientConfig';
import {TraceSchemaManagementApi} from "./generated-react-client/src";
import {apiCallWrapper} from '../utils/apiWrapper';

const api = new TraceSchemaManagementApi(config);

export const tschemaSelectByPschema = async (pschemaId: TschemaSelectByPschemaRequest) => {
    return apiCallWrapper(() => api.tschemaSelectByPschema(pschemaId));
};

export const tschemaUpdate = async (requestParameters: TschemaUpdateRequest) => {
    return apiCallWrapper(() => api.tschemaUpdate(requestParameters));
};

export const tschemaDelete = async (requestParameters: TschemaDeleteRequest) => {
    return apiCallWrapper(() => api.tschemaDelete(requestParameters));
};

export const tschemaDeleteByPschema = async (requestParameters: TschemaDeleteByPschemaRequest) => {
    return apiCallWrapper(() => api.tschemaDeleteByPschema(requestParameters));
};

export const tschemaInsert = async (requestParameters: TschemaInsertRequest) => {
    return apiCallWrapper(() => api.tschemaInsert(requestParameters));
};

export const tschemaSelectBy = async (requestParameters: TschemaSelectByRequest) => {
    return apiCallWrapper(() => api.tschemaSelectBy(requestParameters));
};

export const tschemaLinkInsert = async (requestParameters: TschemaLinkInsertRequest) => {
    return apiCallWrapper(() => api.tschemaLinkInsert(requestParameters));
};

export const tschemaLinkDelete = async (requestParameters: TschemaLinkDeleteRequest) => {
    return apiCallWrapper(() => api.tschemaLinkDelete(requestParameters));
};

export const tschemaLinkSelectByPschema = async (pschemaId: TschemaLinkSelectByPschemaRequest) => {
    return apiCallWrapper(() => api.tschemaLinkSelectByPschema(pschemaId));
};