import React, {useEffect, useState} from 'react';
import {pipelineRegister} from '../../../api/PipelineService';
import {
    HashDto,
    HashDtoEncodingEnum,
    PipelineRegisterRequest,
    PipelineSchemaDto,
    TraceSchemaDto,
    TschemaSelectByPschemaRequest
} from '../../../api/generated-react-client/src';
import styles from './PipelineCreateForm.module.css';
import {pschemaSearch} from '../../../api/PipelineSchemaService';
import {tschemaSelectByPschema} from '../../../api/TraceSchemaService';
import {halgorithmSelect} from '../../../api/HashAlgorithmService';

interface Trace {
    urn: string;
    batchId: number;
    url: string;
    title: string;
    data: string;
    hashList?: Array<HashDto>;
}

interface Pipeline {
    id?: number;
    pschema: string;
    pschemaVersion: string;
    title: string;
    traceList: Trace[];
}

const initialHash: HashDto = {
    algorithm: 'SHA-256',
    encoding: HashDtoEncodingEnum.Base64,
    hash: ''
};

const initialTrace: Trace = {
    urn: '',
    batchId: 0,
    url: '',
    title: '',
    data: '',
    hashList: [initialHash]
};

const PipelineCreateForm: React.FC = () => {
    const [pschemaList, setPschemaList] = useState<PipelineSchemaDto[]>([]);
    const [hashAlgorithmList, setHashAlgorithmList] = useState<string[]>([]);
    const [pipeline, setPipeline] = useState<Pipeline>({
        id: undefined,
        pschema: '',
        pschemaVersion: '',
        title: '',
        traceList: []
    });
    const [traceSchemas, setTraceSchemas] = useState<TraceSchemaDto[]>([]);
    const [resultId, setResultId] = useState<number | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [showPopup, setShowPopup] = useState<boolean>(false);

    useEffect(() => {
        const fetchInitialData = async () => {
            try {
                const [pschemaResult, hashAlgorithmList] = await Promise.all([
                    pschemaSearch({pipelineSchemaFilter: {}}),
                    halgorithmSelect()
                ]);

                const pschemasFromApi = pschemaResult.list || [];
                const hashAlgorithmAliasList = hashAlgorithmList.map(halgorithm => halgorithm.alias || '');

                setPschemaList(pschemasFromApi);
                setHashAlgorithmList(hashAlgorithmAliasList);
            } catch (error) {
                console.error('Failed to fetch initial data:', error);
            }
        };

        fetchInitialData();
    }, []);

    const addItem = () => {
        setPipeline(prevState => ({
            ...prevState,
            traceList: [...prevState.traceList, initialTrace]
        }));
    };

    const removeItem = (index: number) => {
        setPipeline(prevState => ({
            ...prevState,
            traceList: prevState.traceList.filter((_, i) => i !== index)
        }));
    };

    const updateItem = (index: number, field: keyof Trace, value: any) => {
        setPipeline(prevState => ({
            ...prevState,
            traceList: prevState.traceList.map((item, i) =>
                i === index ? {...item, [field]: value} : item
            )
        }));
    };

    const handleInputChange = async (field: keyof Pipeline, value: any) => {
        setPipeline(prevState => ({...prevState, [field]: value}));

        if (field === 'pschema' && value) {
            try {
                const selectedPschema = JSON.parse(value);
                const request: TschemaSelectByPschemaRequest = {pschemaId: selectedPschema.id};
                const traceSchemaList = await tschemaSelectByPschema(request);
                setTraceSchemas(traceSchemaList);
            } catch (error) {
                console.error('Failed to fetch trace schemas:', error);
            }
        }
    };

    const addHash = (traceIndex: number) => {
        setPipeline(prevState => {
            const newHash: HashDto = {
                algorithm: 'SHA-256',
                encoding: HashDtoEncodingEnum.Base64,
                hash: ''
            };
            const updatedTraceList = prevState.traceList.map((trace, index) => {
                if (index === traceIndex) {
                    return {
                        ...trace,
                        hashList: [...trace.hashList!, newHash]
                    };
                } else {
                    return trace;
                }
            });
            return {
                ...prevState,
                traceList: updatedTraceList
            };
        });
    };

    const removeHash = (traceIndex: number, hashIndex: number) => {
        setPipeline(prevState => {
            const updatedTraceList = prevState.traceList.map((trace, index) => {
                if (index === traceIndex) {
                    const newHashList = trace.hashList!.filter((_, idx) => idx !== hashIndex);
                    return {
                        ...trace,
                        hashList: newHashList
                    };
                } else {
                    return trace;
                }
            });
            return {
                ...prevState,
                traceList: updatedTraceList
            };
        });
    };

    const updateHash = <K extends keyof HashDto>(
        traceIndex: number,
        hashIndex: number,
        field: K,
        value: HashDto[K]
    ) => {
        setPipeline(prevState => {
            const updatedTraceList = prevState.traceList.map((trace, index) => {
                if (index === traceIndex) {
                    const updatedHashList = trace.hashList!.map((hash, idx) => {
                        if (idx === hashIndex) {
                            return {
                                ...hash,
                                [field]: value
                            };
                        } else {
                            return hash;
                        }
                    });
                    return {
                        ...trace,
                        hashList: updatedHashList
                    };
                } else {
                    return trace;
                }
            });
            return {
                ...prevState,
                traceList: updatedTraceList
            };
        });
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setError(null);
        try {
            const selectedPschema = JSON.parse(pipeline.pschema);

            // Transform pipeline.traceList into an array of TraceDto
            const traceDtoList = pipeline.traceList.map(trace => ({
                urn: trace.urn,
                batchId: trace.batchId,
                url: trace.url,
                title: trace.title,
                data: trace.data,
                hashList: trace.hashList
            }));

            const req: PipelineRegisterRequest = {
                pschemaAlias: selectedPschema.alias,
                pschemaVersion: pipeline.pschemaVersion,
                pipelineDto: {
                    id: pipeline.id,
                    title: pipeline.title,
                    traceList: traceDtoList
                }
            };
            const result = await pipelineRegister(req);
            setResultId(result);
            setShowPopup(true);
        } catch (error) {
            setError((error as Error).message);
            setShowPopup(true);
        }
    };

    return (
        <div className={styles.formContainer}>
            <div className={styles.formContent}>
                <form onSubmit={handleSubmit} className={styles.form}>
                    <div className={styles.formGroup}>
                        <label style={{textAlign: 'center', flex: '0 0 460px'}}>
                            <hr/>
                            Pipeline creation
                            <hr/>
                        </label>
                    </div>
                    <div className={styles.formGroup}>
                        <label htmlFor="pschemaselector">Schema:</label>
                        <select
                            id="pschemaselector"
                            value={pipeline.pschema}
                            onChange={e => handleInputChange('pschema', e.target.value)}
                        >
                            <option value="">Select a pipeline schema</option>
                            {pschemaList.map(pschema => (
                                <option key={pschema.id}
                                        value={JSON.stringify({id: pschema.id, alias: pschema.alias})}>
                                    {pschema.alias}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className={styles.formGroup}>
                        <label htmlFor="pschemaVersion">Schema Version:</label>
                        <input
                            id="pschemaVersion"
                            type="text"
                            value={pipeline.pschemaVersion}
                            onChange={e => handleInputChange('pschemaVersion', e.target.value)}
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <label htmlFor="pipelineId">ID:</label>
                        <input
                            id="pipelineId"
                            type="number"
                            value={pipeline.id ?? ''}
                            onChange={e => handleInputChange('id', e.target.value ? parseInt(e.target.value, 10) : undefined)}
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <label htmlFor="pipelineTitle">Title:</label>
                        <input
                            id="pipelineTitle"
                            type="text"
                            value={pipeline.title}
                            onChange={e => handleInputChange('title', e.target.value)}
                        />
                    </div>
                    {pipeline.traceList.map((item, traceIndex) => (
                        <div key={traceIndex} className={styles.subFormContainer}>
                            <h4>Item {traceIndex + 1}
                                <hr/>
                            </h4>
                            <div className={styles.formGroup}>
                                <label>URN:</label>
                                <select value={item.urn} onChange={e => updateItem(traceIndex, 'urn', e.target.value)}>
                                    <option value="">Select a URN</option>
                                    {traceSchemas.map((schema, i) => (
                                        <option key={i} value={schema.urn}>
                                            {schema.urn}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className={styles.formGroup}>
                                <label>Step ID:</label>
                                <input
                                    type="number"
                                    value={item.batchId}
                                    onChange={e => updateItem(traceIndex, 'batchId', e.target.value)}
                                />
                            </div>
                            <div className={styles.formGroup}>
                                <label>URL:</label>
                                <input
                                    type="text"
                                    value={item.url}
                                    onChange={e => updateItem(traceIndex, 'url', e.target.value)}
                                />
                            </div>
                            <div className={styles.formGroup}>
                                <label>Title:</label>
                                <input
                                    type="text"
                                    value={item.title}
                                    onChange={e => updateItem(traceIndex, 'title', e.target.value)}
                                />
                            </div>
                            <div className={styles.formGroup}>
                                <label>Data:</label>
                                <input
                                    type="text"
                                    value={item.data}
                                    onChange={e => updateItem(traceIndex, 'data', e.target.value)}
                                />
                            </div>
                            <div className={styles.hashListContainer}>
                                <h5>Hashes</h5>
                                {item.hashList!.map((hashItem, hashIndex) => (
                                    <div key={hashIndex} className={styles.hashItem}>
                                        <div className={styles.formGroup}>
                                            <label>Hash Algorithm:</label>
                                            <select
                                                value={hashItem.algorithm}
                                                onChange={e => updateHash(traceIndex, hashIndex, 'algorithm', e.target.value)}
                                            >
                                                <option value="">Select a hash algorithm</option>
                                                {hashAlgorithmList.map((algo, i) => (
                                                    <option key={i} value={algo}>
                                                        {algo}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className={styles.formGroup}>
                                            <label>Encoding:</label>
                                            <select
                                                value={hashItem.encoding}
                                                onChange={e => updateHash(traceIndex, hashIndex, 'encoding', e.target.value as HashDtoEncodingEnum)}
                                            >
                                                <option
                                                    value={HashDtoEncodingEnum.Base64}>{HashDtoEncodingEnum.Base64}</option>
                                                <option
                                                    value={HashDtoEncodingEnum.Hex}>{HashDtoEncodingEnum.Hex}</option>
                                            </select>
                                        </div>
                                        <div className={styles.formGroup}>
                                            <label>Hash:</label>
                                            <input
                                                type="text"
                                                value={hashItem.hash}
                                                onChange={e => updateHash(traceIndex, hashIndex, 'hash', e.target.value)}
                                            />
                                        </div>
                                        <div className={styles.formActions}>
                                            <button type="button"
                                                    onClick={() => removeHash(traceIndex, hashIndex)}>Remove Hash
                                            </button>
                                        </div>
                                    </div>
                                ))}
                                <div className={styles.formActions}>
                                    <button type="button" onClick={() => addHash(traceIndex)}>Add Hash</button>
                                    <hr/>
                                </div>
                            </div>

                            <div className={styles.formActions}>
                                <button type="button" onClick={() => removeItem(traceIndex)}>Remove Trace</button>
                            </div>
                        </div>
                    ))}
                    <div className={styles.formActions}>
                        <button type="button" onClick={addItem}>Add trace</button>
                        <button type="submit">Submit</button>
                    </div>
                </form>
                {showPopup && resultId !== null && (
                    <div className={styles.modal}>
                        <div className={styles.modalContent}>
                            <p>Registration successful. ID: {resultId}</p>
                            <button onClick={() => setShowPopup(false)}>Close</button>
                        </div>
                    </div>
                )}
                {showPopup && error !== null && (
                    <div className="errorModal">
                        <div className="errorModalContent">
                            <p>Error: {error}</p>
                            <button onClick={() => setShowPopup(false)}>Close</button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PipelineCreateForm;