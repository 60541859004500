import React, {useEffect, useState} from 'react';
import {permissionDelete, permissionInsert, permissionSelect, permissionUpdate} from '../../../api/PermissionService';
import {
    AccessRightDto,
    PermissionAccessDto,
    PermissionAccessReplaceRequest,
    PermissionDto,
    PermissionInsertRequest,
    PermissionUpdateRequest
} from '../../../api/generated-react-client/src';
import {permissionAccessReplace, permissionAccessSelectById} from '../../../api/PermissionAccessService';
import styles from './PermissionForm.module.css';
import Modal from '../../common/Modal';
import {accessSelect} from '../../../api/AccessRightService';
import {MdOutlineCreate, MdOutlineDeleteForever, MdOutlinePlaylistAdd} from 'react-icons/md';
import Tooltip from '@mui/material/Tooltip';

interface TableRowProps {
    permission: PermissionDto;
    setError: (error: string | null) => void;
    setShowPopup: (show: boolean) => void;
    updatePermission: (updatedPermission: PermissionDto) => void;
}

const PermissionForm: React.FC = () => {
    const [permissions, setPermissions] = useState<PermissionDto[]>([]);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [formData, setFormData] = useState<{ alias: string }>({alias: ''});
    const [error, setError] = useState<string | null>(null);
    const [showPopup, setShowPopup] = useState<boolean>(false);

    const fetchPermissions = async () => {
        try {
            const result = await permissionSelect();
            setPermissions(result);
        } catch (error) {
            setError((error as Error).message);
            setShowPopup(true);
        }
    };

    useEffect(() => {
        fetchPermissions();
    }, []);

    const handleEditClick = async (permission: PermissionDto, field: 'alias') => {
        const currentValue = permission[field]?.toString() || '';
        const newValue = prompt(`Edit ${field}`, currentValue);
        if (newValue !== null) {
            try {
                const requestParameters: PermissionUpdateRequest = {
                    permissionDto: {...permission, alias: newValue}
                };
                await permissionUpdate(requestParameters);
                setPermissions(prevPermissions =>
                    prevPermissions.map(t => t.id === permission.id ? {...t, alias: newValue} : t)
                );
            } catch (error) {
                setError((error as Error).message);
                setShowPopup(true);
            }
        }
    };

    const handleDeleteClick = async (permissionId: number) => {
        if (window.confirm('Are you sure you want to delete this permission?')) {
            try {
                await permissionDelete({permissionId});
                setPermissions(prevPermissions => prevPermissions.filter(t => t.id !== permissionId));
            } catch (error) {
                setError((error as Error).message);
                setShowPopup(true);
            }
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {name, value} = event.currentTarget;
        setFormData(prevFormData => ({...prevFormData, [name]: value}));
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const requestParameters: PermissionInsertRequest = {
            permissionDto: {alias: formData.alias},
        };

        try {
            await permissionInsert(requestParameters);
            await fetchPermissions();
            setIsModalOpen(false);
        } catch (error) {
            setError((error as Error).message);
            setShowPopup(true);
        }
    };

    return (
        <div className="formList">
            <div className="headerContainer">
                <b>Permission list</b>
                <div className="formGroup">
                    <button onClick={() => setIsModalOpen(true)}>Create</button>
                </div>
                <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                    <form onSubmit={handleSubmit}>
                        <div className="formGroup">
                            <label>Alias</label>
                            <input
                                type="text"
                                name="alias"
                                value={formData.alias}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div>
                            <button type="submit">Submit</button>
                        </div>
                    </form>
                </Modal>
            </div>
            {showPopup && error !== null && (
                <div className="errorModal">
                    <div className="errorModalContent">
                        <p>Error: {error}</p>
                        <button onClick={() => setShowPopup(false)}>Close</button>
                    </div>
                </div>
            )}
            <table>
                <thead>
                <tr>
                    <th style={{width: '40px', textAlign: 'center'}}>ID</th>
                    <th style={{width: '150px'}}>Alias</th>
                    <th>Access rights</th>
                    <th style={{width: '100px'}}>Actions</th>
                </tr>
                </thead>
                <tbody>

                {permissions.map((permission, index) => (

                    <tr key={permission.id} className={`row ${index % 2 === 0 ? 'even' : 'odd'}`}>
                        <td style={{width: '40px', textAlign: 'center'}}>{permission.id}</td>
                        <td style={{width: '150px'}}>
                            <div className="td-vertical">
                                {permission.alias}
                                <span className="editIcon" onClick={() => handleEditClick(permission, 'alias')}>
                                    <MdOutlineCreate/>
                                </span>
                            </div>
                        </td>
                        <TableRow key={permission.id!} permission={permission} setError={setError}
                                  setShowPopup={setShowPopup}
                                  updatePermission={(updatedPermission) => setPermissions(prevPermissions => prevPermissions.map(t => t.id === updatedPermission.id ? updatedPermission : t))}/>
                        <td style={{width: '100px'}}>
                            <div className="icon-container">
                                <span onClick={() => handleDeleteClick(permission.id!)}
                                      className="deleteIcon"><MdOutlineDeleteForever/></span>
                            </div>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

const TableRow: React.FC<TableRowProps> = ({permission, setError, setShowPopup, updatePermission}) => {
    const [paList, setPaList] = useState<PermissionAccessDto[]>([]);
    const [isAccessModalOpen, setIsAccessModalOpen] = useState<boolean>(false);
    const [accessRights, setAccessRights] = useState<AccessRightDto[]>([]);
    const [selectedAccessRight, setSelectedAccessRight] = useState<{ [key: string]: boolean }>({});

    useEffect(() => {
        const getAccessRights = async () => {
            const rights = await accessSelect();
            rights.sort((a, b) => a.alias!.localeCompare(b.alias!));
            setAccessRights(rights);
        };
        getAccessRights();
    }, []);

    useEffect(() => {
        const loadData = async () => {
            const paList = await permissionAccessSelectById({permissionId: permission.id!});
            setPaList(paList);
        };

        loadData();
    }, [permission.id]);

    useEffect(() => {
        if (isAccessModalOpen) {
            const paListIds = new Set(paList.map(pa => String(pa.accessId)));
            const initialSelections = accessRights.reduce<{ [key: string]: boolean }>((acc, right) => {
                const key = String(right.id);
                acc[key] = paListIds.has(key);
                return acc;
            }, {});

            setSelectedAccessRight(initialSelections);
        }
    }, [isAccessModalOpen, accessRights, paList]);

    const handleCheckboxChange = (id: number) => {
        setSelectedAccessRight(prev => ({...prev, [id]: !prev[id]}));
    };

    const handleSubmitSelectedRights = async (permissionId: number) => {
        const selectedIds = Object.keys(selectedAccessRight)
            .filter(key => selectedAccessRight[key])
            .map(id => Number(id));
        const request: PermissionAccessReplaceRequest = {
            permissionId,
            requestBody: selectedIds,
        };
        try {
            await permissionAccessReplace(request);
            setIsAccessModalOpen(false);
            const updatedPaList = await permissionAccessSelectById({permissionId});
            setPaList(updatedPaList);
            const updatedPermission = {...permission, accessRights: selectedIds} as PermissionDto;
            updatePermission(updatedPermission);
        } catch (error) {
            setError((error as Error).message);
            setShowPopup(true);
        }
    };

    const displayedAccessRights = paList.slice(0, 10).map(paDto => paDto.accessId).join(' | ');
    const fullAccessRights = paList.map(paDto => paDto.accessId).join(' | ');

    return (
        <td>
            <div className="td-vertical-centred">
                <Tooltip title={fullAccessRights}>
                    <span>
                        {displayedAccessRights}{paList.length > 10 && '...'}
                    </span>
                </Tooltip>
                <span onClick={() => setIsAccessModalOpen(true)}
                      className="editIcon"><MdOutlinePlaylistAdd/></span>
                {isAccessModalOpen && (
                    <Modal isOpen={isAccessModalOpen} onClose={() => setIsAccessModalOpen(false)}>
                        <form onSubmit={e => {
                            e.preventDefault();
                            handleSubmitSelectedRights(permission.id!);
                        }}>
                            <div className={`${styles.modalContent} ${styles.leftAlign}`}>
                                {accessRights.map(right => (
                                    <div key={right.id}>
                                        <label>
                                            <input
                                                type="checkbox"
                                                checked={selectedAccessRight[right.id!]}
                                                onChange={() => handleCheckboxChange(right.id!)}
                                            />
                                            {right.alias}
                                        </label>
                                    </div>
                                ))}
                            </div>
                            <div>
                                <button type="submit">Submit</button>
                            </div>
                        </form>
                    </Modal>
                )}
            </div>
        </td>);
};
export default PermissionForm;