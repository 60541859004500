/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LogDto } from './LogDto';
import {
    LogDtoFromJSON,
    LogDtoFromJSONTyped,
    LogDtoToJSON,
} from './LogDto';

/**
 * Represents the search result with pagination for log entries.
 * @export
 * @interface LogView
 */
export interface LogView {
    /**
     * The total count of log entries matching the filter.
     * @type {number}
     * @memberof LogView
     */
    count?: number;
    /**
     * Offset for pagination.
     * @type {number}
     * @memberof LogView
     */
    offset?: number;
    /**
     * Limit for pagination.
     * @type {number}
     * @memberof LogView
     */
    limit?: number;
    /**
     * The list of log entries returned for the current page.
     * @type {Array<LogDto>}
     * @memberof LogView
     */
    list?: Array<LogDto>;
}

/**
 * Check if a given object implements the LogView interface.
 */
export function instanceOfLogView(value: object): boolean {
    return true;
}

export function LogViewFromJSON(json: any): LogView {
    return LogViewFromJSONTyped(json, false);
}

export function LogViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): LogView {
    if (json == null) {
        return json;
    }
    return {
        
        'count': json['count'] == null ? undefined : json['count'],
        'offset': json['offset'] == null ? undefined : json['offset'],
        'limit': json['limit'] == null ? undefined : json['limit'],
        'list': json['list'] == null ? undefined : ((json['list'] as Array<any>).map(LogDtoFromJSON)),
    };
}

export function LogViewToJSON(value?: LogView | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'count': value['count'],
        'offset': value['offset'],
        'limit': value['limit'],
        'list': value['list'] == null ? undefined : ((value['list'] as Array<any>).map(LogDtoToJSON)),
    };
}

