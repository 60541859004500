import {
    HalgorithmDeleteRequest,
    HalgorithmInsertRequest,
    HalgorithmSelectByAliasRequest,
    HalgorithmSelectByIdRequest,
    HalgorithmUpdateRequest,
    HashAlgorithmManagementApi
} from './generated-react-client/src/apis/HashAlgorithmManagementApi';
import config from './clientConfig';
import {apiCallWrapper} from '../utils/apiWrapper';

const api = new HashAlgorithmManagementApi(config);

export const halgorithmInsert = async (requestParameters: HalgorithmInsertRequest) => {
    return apiCallWrapper(() => api.halgorithmInsert(requestParameters));
};

export const halgorithmSelect = async () => {
    return apiCallWrapper(() => api.halgorithmSelect());
};

export const halgorithmUpdate = async (requestParameters: HalgorithmUpdateRequest) => {
    return apiCallWrapper(() => api.halgorithmUpdate(requestParameters));
};

export const halgorithmDelete = async (requestParameters: HalgorithmDeleteRequest) => {
    return apiCallWrapper(() => api.halgorithmDelete(requestParameters));
};

export const halgorithmSelectByAlias = async (requestParameters: HalgorithmSelectByAliasRequest) => {
    return apiCallWrapper(() => api.halgorithmSelectByAlias(requestParameters));
};

export const halgorithmSelectById = async (requestParameters: HalgorithmSelectByIdRequest) => {
    return apiCallWrapper(() => api.halgorithmSelectById(requestParameters));
};