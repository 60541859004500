import React, {ChangeEvent, useEffect, useState} from 'react';
import Modal from "../../common/Modal";
import {SetupDto, SetupDtoDataTypeEnum} from "../../../api/generated-react-client/src";
import {MdOutlineCreate, MdOutlineDeleteForever} from "react-icons/md";
import {
    tenantSetupDelete,
    tenantSetupInsert,
    tenantSetupSelect,
    tenantSetupUpdate
} from "../../../api/TenantSetupService";

const TenantSetupForm: React.FC = () => {
    const [setups, setSetups] = useState<SetupDto[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [showErrorPopup, setShowErrorPopup] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [formData, setFormData] = useState<Partial<SetupDto>>({
        id: 'name.sub',
        active: false,
        value: '',
        defaultValue: '',
        validationRule: '',
        valueList: [''],
        mandatory: true,
        dataType: SetupDtoDataTypeEnum.String
    });

    const fetchSetups = async () => {
        try {
            const result = await tenantSetupSelect();
            setSetups(result);
        } catch (error) {
            setError((error as Error).message);
            setShowErrorPopup(true);
        }
    };

    useEffect(() => {
        fetchSetups();
    }, []);

    const updateSetup = async (setup: Partial<SetupDto>) => {
        try {
            await tenantSetupUpdate({setupDto: setup});
            fetchSetups();
        } catch (error) {
            setError((error as Error).message);
            setShowErrorPopup(true);
        }
    };

    const handleEditClick = async (setup: SetupDto, field: keyof SetupDto) => {
        const currentValue = setup[field]?.toString() || '';
        const newValue = prompt(`Edit ${field}`, currentValue);
        if (newValue !== null) {
            updateSetup({id: setup.id, [field]: newValue});
        }
    };

    const handleDelete = async (id: string) => {
        if (window.confirm('Are you sure you want to delete this setup?')) {
            try {
                await tenantSetupDelete(id);
                fetchSetups();
            } catch (error) {
                setError((error as Error).message);
                setShowErrorPopup(true);
            }
        }
    };

    const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const {name, value, type} = event.currentTarget;
        if (type === 'checkbox') {
            const {checked} = event.currentTarget as HTMLInputElement;
            setFormData(prevFormData => ({
                ...prevFormData,
                [name]: checked,
            }));
        } else {
            setFormData(prevFormData => ({
                ...prevFormData,
                [name]: value,
            }));
        }
    };

    const handleCreate = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            await tenantSetupInsert({setupDto: formData as SetupDto});
            setIsModalOpen(false);
            fetchSetups();
        } catch (error) {
            setError((error as Error).message);
            setShowErrorPopup(true);
        }
    };

    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const filteredSetups = setups.filter(setup => setup.id!.toLowerCase().includes(searchTerm.toLowerCase()));

    return (
        <div className="formList">
            <div className="headerContainer">
                <b>Tenant configuration</b>
                <div className="formGroup">
                    <input
                        type="text"
                        placeholder="Search by ID"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        style={{marginRight: '10px'}}
                    />
                    <button onClick={() => setIsModalOpen(true)}>Create</button>
                </div>
                <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                    <form onSubmit={handleCreate}>
                        <div className="formGroup">
                            <label>Active</label>
                            <input type="checkbox" name="active" checked={formData.active}
                                   onChange={handleInputChange}/>
                        </div>
                        <div className="formGroup">
                            <label>Id</label>
                            <input type="text" name="id" value={formData.id} onChange={handleInputChange}/>
                        </div>
                        <div className="formGroup">
                            <label>Data type</label>
                            <select name="dataType" value={formData.dataType} onChange={handleInputChange}>
                                {Object.values(SetupDtoDataTypeEnum).map(type => (
                                    <option key={type} value={type}>
                                        {type}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="formGroup">
                            <label>Value</label>
                            <input type="text" name="value" value={formData.value} onChange={handleInputChange}/>
                        </div>
                        <div className="horizontalButtonContainer">
                            <button type="submit">Create</button>
                        </div>
                    </form>
                </Modal>
            </div>
            {showErrorPopup && error && (
                <div className="errorModal">
                    <div className="errorModalContent">
                        <p>Error: {error}</p>
                        <button onClick={() => setShowErrorPopup(false)}>Close</button>
                    </div>
                </div>
            )}
            <table>
                <thead>
                <tr>
                    <th style={{width: '60px', textAlign: 'center'}}>Active</th>
                    <th>Id</th>
                    <th style={{width: '100px', textAlign: 'center'}}>Data type</th>
                    <th>Value</th>
                    <th>Validation rule</th>
                    <th style={{width: '80px', textAlign: 'center'}}>Actions</th>
                </tr>
                </thead>
                <tbody>
                {filteredSetups.map((setup, index) => (
                    <tr key={setup.id} className={`row ${index % 2 === 0 ? 'even' : 'odd'}`}>
                        <td style={{width: '60px', textAlign: 'center'}}>
                            <input
                                type="checkbox"
                                checked={setup.active}
                                onChange={() => updateSetup({id: setup.id, active: !setup.active})}
                            />
                        </td>
                        <td style={{whiteSpace: 'normal', wordWrap: 'break-word'}}>{setup.id}</td>
                        <td style={{width: '100px', textAlign: 'center'}}>
                            <select
                                value={setup.dataType}
                                onChange={(e) => updateSetup({
                                    id: setup.id,
                                    dataType: e.target.value as SetupDtoDataTypeEnum
                                })}
                            >
                                {Object.values(SetupDtoDataTypeEnum).map(type => (
                                    <option key={type} value={type}>
                                        {type}
                                    </option>
                                ))}
                            </select>
                        </td>
                        <td style={{whiteSpace: 'normal', wordWrap: 'break-word', wordBreak: 'break-all'}}>
                            <div className="td-vertical">
                                {setup.value}
                                <span className="editIcon" onClick={() => handleEditClick(setup, 'value')}>
                            <MdOutlineCreate/></span>
                            </div>
                        </td>
                        <td>
                            <div className="td-vertical">
                                {setup.validationRule}
                                <span className="editIcon"
                                      onClick={() => handleEditClick(setup, 'validationRule')}><MdOutlineCreate/></span>
                            </div>
                        </td>
                        <td style={{width: '80px', textAlign: 'center'}}>
                            <div className="icon-container">
                                <span onClick={() => handleDelete(setup.id!)}
                                      className="deleteIcon"><MdOutlineDeleteForever/></span>
                            </div>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default TenantSetupForm;