import {
    UserPermissionDeleteRequest,
    UserPermissionInsertRequest,
    UserPermissionManagementApi,
    UserPermissionSelectByUserIdRequest
} from './generated-react-client/src/apis/UserPermissionManagementApi';
import config from './clientConfig';
import { apiCallWrapper } from '../utils/apiWrapper';

const api = new UserPermissionManagementApi(config);

export const userPermissionInsert = async (requestParameters: UserPermissionInsertRequest) => {
    return apiCallWrapper(() => api.userPermissionInsert(requestParameters));
};

export const userPermissionSelectByUserId = async (requestParameters: UserPermissionSelectByUserIdRequest) => {
    return apiCallWrapper(() => api.userPermissionSelectByUserId(requestParameters));
};

export const userPermissionDelete = async (requestParameters: UserPermissionDeleteRequest) => {
    return apiCallWrapper(() => api.userPermissionDelete(requestParameters));
};