/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  NotificationDto,
} from '../models/index';
import {
    NotificationDtoFromJSON,
    NotificationDtoToJSON,
} from '../models/index';

export interface NotificationDeleteRequest {
    notificationId: number;
}

export interface NotificationInsertRequest {
    notificationDto: NotificationDto;
}

export interface NotificationSelectRequest {
    min: Date;
    max: Date;
}

export interface NotificationSelectByIdRequest {
    notificationId: number;
}

export interface NotificationSelectByTenantRequest {
    tenantId: number;
    min: Date;
    max: Date;
}

export interface NotificationSendRequest {
    notificationId: number;
}

export interface NotificationUpdateRequest {
    notificationDto: NotificationDto;
}

/**
 * 
 */
export class NotificationManagementApi extends runtime.BaseAPI {

    /**
     * Deletes a notification by its unique identifier (ID) and returns the count of deleted records.
     * Delete a notification
     */
    async notificationDeleteRaw(requestParameters: NotificationDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['notificationId'] == null) {
            throw new runtime.RequiredError(
                'notificationId',
                'Required parameter "notificationId" was null or undefined when calling notificationDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/notification/{notificationId}`.replace(`{${"notificationId"}}`, encodeURIComponent(String(requestParameters['notificationId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Deletes a notification by its unique identifier (ID) and returns the count of deleted records.
     * Delete a notification
     */
    async notificationDelete(requestParameters: NotificationDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.notificationDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates a new notification with the given details and returns the newly created notification.
     * Create a new notification
     */
    async notificationInsertRaw(requestParameters: NotificationInsertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotificationDto>> {
        if (requestParameters['notificationDto'] == null) {
            throw new runtime.RequiredError(
                'notificationDto',
                'Required parameter "notificationDto" was null or undefined when calling notificationInsert().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/notification`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NotificationDtoToJSON(requestParameters['notificationDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationDtoFromJSON(jsonValue));
    }

    /**
     * Creates a new notification with the given details and returns the newly created notification.
     * Create a new notification
     */
    async notificationInsert(requestParameters: NotificationInsertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotificationDto> {
        const response = await this.notificationInsertRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a list of notifications.
     * Get all notifications between date-min and date-max
     */
    async notificationSelectRaw(requestParameters: NotificationSelectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<NotificationDto>>> {
        if (requestParameters['min'] == null) {
            throw new runtime.RequiredError(
                'min',
                'Required parameter "min" was null or undefined when calling notificationSelect().'
            );
        }

        if (requestParameters['max'] == null) {
            throw new runtime.RequiredError(
                'max',
                'Required parameter "max" was null or undefined when calling notificationSelect().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['min'] != null) {
            queryParameters['min'] = (requestParameters['min'] as any).toISOString();
        }

        if (requestParameters['max'] != null) {
            queryParameters['max'] = (requestParameters['max'] as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/notification`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NotificationDtoFromJSON));
    }

    /**
     * Retrieves a list of notifications.
     * Get all notifications between date-min and date-max
     */
    async notificationSelect(requestParameters: NotificationSelectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<NotificationDto>> {
        const response = await this.notificationSelectRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a notification.
     * Get a notification by ID
     */
    async notificationSelectByIdRaw(requestParameters: NotificationSelectByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotificationDto>> {
        if (requestParameters['notificationId'] == null) {
            throw new runtime.RequiredError(
                'notificationId',
                'Required parameter "notificationId" was null or undefined when calling notificationSelectById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/notification/{notificationId}`.replace(`{${"notificationId"}}`, encodeURIComponent(String(requestParameters['notificationId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationDtoFromJSON(jsonValue));
    }

    /**
     * Retrieves a notification.
     * Get a notification by ID
     */
    async notificationSelectById(requestParameters: NotificationSelectByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotificationDto> {
        const response = await this.notificationSelectByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a list of notifications.
     * Get all notifications between minimum and maximum date
     */
    async notificationSelectByTenantRaw(requestParameters: NotificationSelectByTenantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<NotificationDto>>> {
        if (requestParameters['tenantId'] == null) {
            throw new runtime.RequiredError(
                'tenantId',
                'Required parameter "tenantId" was null or undefined when calling notificationSelectByTenant().'
            );
        }

        if (requestParameters['min'] == null) {
            throw new runtime.RequiredError(
                'min',
                'Required parameter "min" was null or undefined when calling notificationSelectByTenant().'
            );
        }

        if (requestParameters['max'] == null) {
            throw new runtime.RequiredError(
                'max',
                'Required parameter "max" was null or undefined when calling notificationSelectByTenant().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['min'] != null) {
            queryParameters['min'] = (requestParameters['min'] as any).toISOString();
        }

        if (requestParameters['max'] != null) {
            queryParameters['max'] = (requestParameters['max'] as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/notification/by-tenant/{tenantId}`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters['tenantId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NotificationDtoFromJSON));
    }

    /**
     * Retrieves a list of notifications.
     * Get all notifications between minimum and maximum date
     */
    async notificationSelectByTenant(requestParameters: NotificationSelectByTenantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<NotificationDto>> {
        const response = await this.notificationSelectByTenantRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Sends and retrieves a notification.
     * Send a notification by ID
     */
    async notificationSendRaw(requestParameters: NotificationSendRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotificationDto>> {
        if (requestParameters['notificationId'] == null) {
            throw new runtime.RequiredError(
                'notificationId',
                'Required parameter "notificationId" was null or undefined when calling notificationSend().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/notification/send/{notificationId}`.replace(`{${"notificationId"}}`, encodeURIComponent(String(requestParameters['notificationId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationDtoFromJSON(jsonValue));
    }

    /**
     * Sends and retrieves a notification.
     * Send a notification by ID
     */
    async notificationSend(requestParameters: NotificationSendRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotificationDto> {
        const response = await this.notificationSendRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates selected information for a specific notification.
     * Update notification\'s attributes
     */
    async notificationUpdateRaw(requestParameters: NotificationUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotificationDto>> {
        if (requestParameters['notificationDto'] == null) {
            throw new runtime.RequiredError(
                'notificationDto',
                'Required parameter "notificationDto" was null or undefined when calling notificationUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/notification`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: NotificationDtoToJSON(requestParameters['notificationDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationDtoFromJSON(jsonValue));
    }

    /**
     * Updates selected information for a specific notification.
     * Update notification\'s attributes
     */
    async notificationUpdate(requestParameters: NotificationUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotificationDto> {
        const response = await this.notificationUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
