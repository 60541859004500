import {
    ExportCSVFileRequest,
    ImportCSVFileRequest,
    TranslationDeleteAllLanguagesRequest,
    TranslationDeleteRequest,
    TranslationInsertRequest,
    TranslationManagementApi,
    TranslationSelectByCategoryRequest,
    TranslationSelectByLabelRequest,
    TranslationSelectByLanguageRequest,
    TranslationSelectByPkRequest,
    TranslationSelectWithTenant0Request,
    TranslationUpdateRequest,
} from './generated-react-client/src/apis/TranslationManagementApi';
import config from './clientConfig';
import {apiCallWrapper} from '../utils/apiWrapper';

const api = new TranslationManagementApi(config);

export const translationInsert = async (requestParameters: TranslationInsertRequest) => {
    return apiCallWrapper(() => api.translationInsert(requestParameters));
};

export const translationSelectByCategory = async (requestParameters: TranslationSelectByCategoryRequest) => {
    return apiCallWrapper(() => api.translationSelectByCategory(requestParameters));
};

export const translationSelectByLabel = async (requestParameters: TranslationSelectByLabelRequest) => {
    return apiCallWrapper(() => api.translationSelectByLabel(requestParameters));
};

export const translationSelectByLanguage = async (requestParameters: TranslationSelectByLanguageRequest) => {
    return apiCallWrapper(() => api.translationSelectByLanguage(requestParameters));
};

export const translationSelectByPk = async (requestParameters: TranslationSelectByPkRequest) => {
    return apiCallWrapper(() => api.translationSelectByPk(requestParameters));
};

export const translationSelectWithTenant0 = async (requestParameters: TranslationSelectWithTenant0Request) => {
    return apiCallWrapper(() => api.translationSelectWithTenant0(requestParameters));
};

export const translationUpdate = async (requestParameters: TranslationUpdateRequest) => {
    return apiCallWrapper(() => api.translationUpdate(requestParameters));
};

export const translationDelete = async (requestParameters: TranslationDeleteRequest) => {
    return apiCallWrapper(() => api.translationDelete(requestParameters));
};

export const translationDeleteAllLanguages = async (requestParameters: TranslationDeleteAllLanguagesRequest) => {
    return apiCallWrapper(() => api.translationDeleteAllLanguages(requestParameters));
};

export const exportCSVFile = async (requestParameters: ExportCSVFileRequest) => {
    return apiCallWrapper(() => api.exportCSVFile(requestParameters));
};

export const importCSVFile = async (requestParameters: ImportCSVFileRequest) => {
    return apiCallWrapper(() => api.importCSVFile(requestParameters));
};