export const apiCallWrapper = async <T>(apiCall: () => Promise<T>): Promise<T> => {
    try {
        const response = await apiCall();
        return response;
    } catch (error: any) {
        // console.log("error:", error);
        // console.log("error.response:", error.response);
        // console.log("error.response.status:", error.response.status);
        // console.log("error.response.status:", error.response.json);
        if (error.response && error.response.status) {
            const responseData = await error.response.json();
            const errorMessage = responseData.message || 'An unexpected error occurred';
            throw new Error(errorMessage);
        }
        throw error;
    }
};