/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  TranslationDto,
} from '../models/index';
import {
    TranslationDtoFromJSON,
    TranslationDtoToJSON,
} from '../models/index';

export interface ExportCSVFileRequest {
    templateAlias: string;
    languageId: ExportCSVFileLanguageIdEnum;
}

export interface ImportCSVFileRequest {
    templateAlias: string;
    importMode: ImportCSVFileImportModeEnum;
    file: Blob;
}

export interface TranslationDeleteRequest {
    category: TranslationDeleteCategoryEnum;
    language: TranslationDeleteLanguageEnum;
    labelId: number;
}

export interface TranslationDeleteAllLanguagesRequest {
    category: TranslationDeleteAllLanguagesCategoryEnum;
    labelId: number;
}

export interface TranslationInsertRequest {
    translationDto: TranslationDto;
}

export interface TranslationSelectByCategoryRequest {
    category: TranslationSelectByCategoryCategoryEnum;
}

export interface TranslationSelectByLabelRequest {
    labelId: number;
}

export interface TranslationSelectByLanguageRequest {
    language: TranslationSelectByLanguageLanguageEnum;
}

export interface TranslationSelectByPkRequest {
    category: TranslationSelectByPkCategoryEnum;
    language: TranslationSelectByPkLanguageEnum;
    labelId: number;
}

export interface TranslationSelectWithTenant0Request {
    category: TranslationSelectWithTenant0CategoryEnum;
    language: TranslationSelectWithTenant0LanguageEnum;
}

export interface TranslationUpdateRequest {
    translationDto: TranslationDto;
}

/**
 * 
 */
export class TranslationManagementApi extends runtime.BaseAPI {

    /**
     * Exports all translations for the specified tenant and language in a CSV file.
     * Export a CSV file with all the translations
     */
    async exportCSVFileRaw(requestParameters: ExportCSVFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters['templateAlias'] == null) {
            throw new runtime.RequiredError(
                'templateAlias',
                'Required parameter "templateAlias" was null or undefined when calling exportCSVFile().'
            );
        }

        if (requestParameters['languageId'] == null) {
            throw new runtime.RequiredError(
                'languageId',
                'Required parameter "languageId" was null or undefined when calling exportCSVFile().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['templateAlias'] != null) {
            queryParameters['templateAlias'] = requestParameters['templateAlias'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/translation/csv-export/{languageId}`.replace(`{${"languageId"}}`, encodeURIComponent(String(requestParameters['languageId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Exports all translations for the specified tenant and language in a CSV file.
     * Export a CSV file with all the translations
     */
    async exportCSVFile(requestParameters: ExportCSVFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.exportCSVFileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Uploads a CSV file for processing. The file must be associated with a specified template alias.
     * Upload a CSV file for a translation in a certain language
     */
    async importCSVFileRaw(requestParameters: ImportCSVFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['templateAlias'] == null) {
            throw new runtime.RequiredError(
                'templateAlias',
                'Required parameter "templateAlias" was null or undefined when calling importCSVFile().'
            );
        }

        if (requestParameters['importMode'] == null) {
            throw new runtime.RequiredError(
                'importMode',
                'Required parameter "importMode" was null or undefined when calling importCSVFile().'
            );
        }

        if (requestParameters['file'] == null) {
            throw new runtime.RequiredError(
                'file',
                'Required parameter "file" was null or undefined when calling importCSVFile().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['templateAlias'] != null) {
            queryParameters['templateAlias'] = requestParameters['templateAlias'];
        }

        if (requestParameters['importMode'] != null) {
            queryParameters['importMode'] = requestParameters['importMode'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['file'] != null) {
            formParams.append('file', requestParameters['file'] as any);
        }

        const response = await this.request({
            path: `/api/translation/csv-import`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Uploads a CSV file for processing. The file must be associated with a specified template alias.
     * Upload a CSV file for a translation in a certain language
     */
    async importCSVFile(requestParameters: ImportCSVFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.importCSVFileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes a translation by its primary key and returns the count of deleted records.
     * Delete a translation
     */
    async translationDeleteRaw(requestParameters: TranslationDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['category'] == null) {
            throw new runtime.RequiredError(
                'category',
                'Required parameter "category" was null or undefined when calling translationDelete().'
            );
        }

        if (requestParameters['language'] == null) {
            throw new runtime.RequiredError(
                'language',
                'Required parameter "language" was null or undefined when calling translationDelete().'
            );
        }

        if (requestParameters['labelId'] == null) {
            throw new runtime.RequiredError(
                'labelId',
                'Required parameter "labelId" was null or undefined when calling translationDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/translation/{category}/{language}/{labelId}`.replace(`{${"category"}}`, encodeURIComponent(String(requestParameters['category']))).replace(`{${"language"}}`, encodeURIComponent(String(requestParameters['language']))).replace(`{${"labelId"}}`, encodeURIComponent(String(requestParameters['labelId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Deletes a translation by its primary key and returns the count of deleted records.
     * Delete a translation
     */
    async translationDelete(requestParameters: TranslationDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.translationDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes translations in all supported languages for the given category and label ID, and returns the count of deleted records.
     * Delete a translation in all supported languages
     */
    async translationDeleteAllLanguagesRaw(requestParameters: TranslationDeleteAllLanguagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['category'] == null) {
            throw new runtime.RequiredError(
                'category',
                'Required parameter "category" was null or undefined when calling translationDeleteAllLanguages().'
            );
        }

        if (requestParameters['labelId'] == null) {
            throw new runtime.RequiredError(
                'labelId',
                'Required parameter "labelId" was null or undefined when calling translationDeleteAllLanguages().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/translation/{category}/{labelId}`.replace(`{${"category"}}`, encodeURIComponent(String(requestParameters['category']))).replace(`{${"labelId"}}`, encodeURIComponent(String(requestParameters['labelId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Deletes translations in all supported languages for the given category and label ID, and returns the count of deleted records.
     * Delete a translation in all supported languages
     */
    async translationDeleteAllLanguages(requestParameters: TranslationDeleteAllLanguagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.translationDeleteAllLanguagesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates a new translation with the given details and returns the created translation.
     * Create a new translation
     */
    async translationInsertRaw(requestParameters: TranslationInsertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TranslationDto>> {
        if (requestParameters['translationDto'] == null) {
            throw new runtime.RequiredError(
                'translationDto',
                'Required parameter "translationDto" was null or undefined when calling translationInsert().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/translation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TranslationDtoToJSON(requestParameters['translationDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TranslationDtoFromJSON(jsonValue));
    }

    /**
     * Creates a new translation with the given details and returns the created translation.
     * Create a new translation
     */
    async translationInsert(requestParameters: TranslationInsertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TranslationDto> {
        const response = await this.translationInsertRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a list of all translations.
     * Get all translations
     */
    async translationListSelectRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TranslationDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/translation`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TranslationDtoFromJSON));
    }

    /**
     * Retrieves a list of all translations.
     * Get all translations
     */
    async translationListSelect(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TranslationDto>> {
        const response = await this.translationListSelectRaw(initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a list of all translations for a category.
     * Get translation by category
     */
    async translationSelectByCategoryRaw(requestParameters: TranslationSelectByCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TranslationDto>>> {
        if (requestParameters['category'] == null) {
            throw new runtime.RequiredError(
                'category',
                'Required parameter "category" was null or undefined when calling translationSelectByCategory().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/translation/by-category/{category}`.replace(`{${"category"}}`, encodeURIComponent(String(requestParameters['category']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TranslationDtoFromJSON));
    }

    /**
     * Retrieves a list of all translations for a category.
     * Get translation by category
     */
    async translationSelectByCategory(requestParameters: TranslationSelectByCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TranslationDto>> {
        const response = await this.translationSelectByCategoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a list of all translations for a label id.
     * Get translation by labelId
     */
    async translationSelectByLabelRaw(requestParameters: TranslationSelectByLabelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TranslationDto>>> {
        if (requestParameters['labelId'] == null) {
            throw new runtime.RequiredError(
                'labelId',
                'Required parameter "labelId" was null or undefined when calling translationSelectByLabel().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/translation/by-label/{labelId}`.replace(`{${"labelId"}}`, encodeURIComponent(String(requestParameters['labelId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TranslationDtoFromJSON));
    }

    /**
     * Retrieves a list of all translations for a label id.
     * Get translation by labelId
     */
    async translationSelectByLabel(requestParameters: TranslationSelectByLabelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TranslationDto>> {
        const response = await this.translationSelectByLabelRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a list of all translations for a language.
     * Get translation by language
     */
    async translationSelectByLanguageRaw(requestParameters: TranslationSelectByLanguageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TranslationDto>>> {
        if (requestParameters['language'] == null) {
            throw new runtime.RequiredError(
                'language',
                'Required parameter "language" was null or undefined when calling translationSelectByLanguage().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/translation/by-language/{language}`.replace(`{${"language"}}`, encodeURIComponent(String(requestParameters['language']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TranslationDtoFromJSON));
    }

    /**
     * Retrieves a list of all translations for a language.
     * Get translation by language
     */
    async translationSelectByLanguage(requestParameters: TranslationSelectByLanguageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TranslationDto>> {
        const response = await this.translationSelectByLanguageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a translations by its primary key.
     * Get translation by primary key
     */
    async translationSelectByPkRaw(requestParameters: TranslationSelectByPkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TranslationDto>> {
        if (requestParameters['category'] == null) {
            throw new runtime.RequiredError(
                'category',
                'Required parameter "category" was null or undefined when calling translationSelectByPk().'
            );
        }

        if (requestParameters['language'] == null) {
            throw new runtime.RequiredError(
                'language',
                'Required parameter "language" was null or undefined when calling translationSelectByPk().'
            );
        }

        if (requestParameters['labelId'] == null) {
            throw new runtime.RequiredError(
                'labelId',
                'Required parameter "labelId" was null or undefined when calling translationSelectByPk().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/translation/by-pk/{category}/{language}/{labelId}`.replace(`{${"category"}}`, encodeURIComponent(String(requestParameters['category']))).replace(`{${"language"}}`, encodeURIComponent(String(requestParameters['language']))).replace(`{${"labelId"}}`, encodeURIComponent(String(requestParameters['labelId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TranslationDtoFromJSON(jsonValue));
    }

    /**
     * Retrieves a translations by its primary key.
     * Get translation by primary key
     */
    async translationSelectByPk(requestParameters: TranslationSelectByPkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TranslationDto> {
        const response = await this.translationSelectByPkRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a list of all translations for a category, language, for current tenant and tenant 0.
     * Get translation by category and language in current tenant and tenant 0
     */
    async translationSelectWithTenant0Raw(requestParameters: TranslationSelectWithTenant0Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TranslationDto>>> {
        if (requestParameters['category'] == null) {
            throw new runtime.RequiredError(
                'category',
                'Required parameter "category" was null or undefined when calling translationSelectWithTenant0().'
            );
        }

        if (requestParameters['language'] == null) {
            throw new runtime.RequiredError(
                'language',
                'Required parameter "language" was null or undefined when calling translationSelectWithTenant0().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/translation/with-tenant-zero/{category}/{language}`.replace(`{${"category"}}`, encodeURIComponent(String(requestParameters['category']))).replace(`{${"language"}}`, encodeURIComponent(String(requestParameters['language']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TranslationDtoFromJSON));
    }

    /**
     * Retrieves a list of all translations for a category, language, for current tenant and tenant 0.
     * Get translation by category and language in current tenant and tenant 0
     */
    async translationSelectWithTenant0(requestParameters: TranslationSelectWithTenant0Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TranslationDto>> {
        const response = await this.translationSelectWithTenant0Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a list of all translations for a specified category and language, including both the current tenant and the default tenant (tenant 0). The process involves first retrieving the default translations associated with the virtual tenant \'0\'. Subsequently, any specific translations for the current tenant are retrieved and used to override the default translations from tenant 0.
     * Get translation by category and language in current tenant and tenant 0
     */
    async translationUpdateRaw(requestParameters: TranslationUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TranslationDto>> {
        if (requestParameters['translationDto'] == null) {
            throw new runtime.RequiredError(
                'translationDto',
                'Required parameter "translationDto" was null or undefined when calling translationUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/translation`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: TranslationDtoToJSON(requestParameters['translationDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TranslationDtoFromJSON(jsonValue));
    }

    /**
     * Retrieves a list of all translations for a specified category and language, including both the current tenant and the default tenant (tenant 0). The process involves first retrieving the default translations associated with the virtual tenant \'0\'. Subsequently, any specific translations for the current tenant are retrieved and used to override the default translations from tenant 0.
     * Get translation by category and language in current tenant and tenant 0
     */
    async translationUpdate(requestParameters: TranslationUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TranslationDto> {
        const response = await this.translationUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const ExportCSVFileLanguageIdEnum = {
    En: 'EN',
    Fr: 'FR',
    De: 'DE',
    Pl: 'PL',
    Sv: 'SV'
} as const;
export type ExportCSVFileLanguageIdEnum = typeof ExportCSVFileLanguageIdEnum[keyof typeof ExportCSVFileLanguageIdEnum];
/**
 * @export
 */
export const ImportCSVFileImportModeEnum = {
    Throw: 'THROW',
    Replace: 'REPLACE'
} as const;
export type ImportCSVFileImportModeEnum = typeof ImportCSVFileImportModeEnum[keyof typeof ImportCSVFileImportModeEnum];
/**
 * @export
 */
export const TranslationDeleteCategoryEnum = {
    Gui: 'GUI',
    Data: 'DATA',
    Enumeration: 'ENUMERATION',
    Anomaly: 'ANOMALY'
} as const;
export type TranslationDeleteCategoryEnum = typeof TranslationDeleteCategoryEnum[keyof typeof TranslationDeleteCategoryEnum];
/**
 * @export
 */
export const TranslationDeleteLanguageEnum = {
    En: 'EN',
    Fr: 'FR',
    De: 'DE',
    Pl: 'PL',
    Sv: 'SV'
} as const;
export type TranslationDeleteLanguageEnum = typeof TranslationDeleteLanguageEnum[keyof typeof TranslationDeleteLanguageEnum];
/**
 * @export
 */
export const TranslationDeleteAllLanguagesCategoryEnum = {
    Gui: 'GUI',
    Data: 'DATA',
    Enumeration: 'ENUMERATION',
    Anomaly: 'ANOMALY'
} as const;
export type TranslationDeleteAllLanguagesCategoryEnum = typeof TranslationDeleteAllLanguagesCategoryEnum[keyof typeof TranslationDeleteAllLanguagesCategoryEnum];
/**
 * @export
 */
export const TranslationSelectByCategoryCategoryEnum = {
    Gui: 'GUI',
    Data: 'DATA',
    Enumeration: 'ENUMERATION',
    Anomaly: 'ANOMALY'
} as const;
export type TranslationSelectByCategoryCategoryEnum = typeof TranslationSelectByCategoryCategoryEnum[keyof typeof TranslationSelectByCategoryCategoryEnum];
/**
 * @export
 */
export const TranslationSelectByLanguageLanguageEnum = {
    En: 'EN',
    Fr: 'FR',
    De: 'DE',
    Pl: 'PL',
    Sv: 'SV'
} as const;
export type TranslationSelectByLanguageLanguageEnum = typeof TranslationSelectByLanguageLanguageEnum[keyof typeof TranslationSelectByLanguageLanguageEnum];
/**
 * @export
 */
export const TranslationSelectByPkCategoryEnum = {
    Gui: 'GUI',
    Data: 'DATA',
    Enumeration: 'ENUMERATION',
    Anomaly: 'ANOMALY'
} as const;
export type TranslationSelectByPkCategoryEnum = typeof TranslationSelectByPkCategoryEnum[keyof typeof TranslationSelectByPkCategoryEnum];
/**
 * @export
 */
export const TranslationSelectByPkLanguageEnum = {
    En: 'EN',
    Fr: 'FR',
    De: 'DE',
    Pl: 'PL',
    Sv: 'SV'
} as const;
export type TranslationSelectByPkLanguageEnum = typeof TranslationSelectByPkLanguageEnum[keyof typeof TranslationSelectByPkLanguageEnum];
/**
 * @export
 */
export const TranslationSelectWithTenant0CategoryEnum = {
    Gui: 'GUI',
    Data: 'DATA',
    Enumeration: 'ENUMERATION',
    Anomaly: 'ANOMALY'
} as const;
export type TranslationSelectWithTenant0CategoryEnum = typeof TranslationSelectWithTenant0CategoryEnum[keyof typeof TranslationSelectWithTenant0CategoryEnum];
/**
 * @export
 */
export const TranslationSelectWithTenant0LanguageEnum = {
    En: 'EN',
    Fr: 'FR',
    De: 'DE',
    Pl: 'PL',
    Sv: 'SV'
} as const;
export type TranslationSelectWithTenant0LanguageEnum = typeof TranslationSelectWithTenant0LanguageEnum[keyof typeof TranslationSelectWithTenant0LanguageEnum];
