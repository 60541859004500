import React, {useEffect, useState} from 'react';
import {HostDto, HostDtoStatusEnum} from '../../../api/generated-react-client/src/models/HostDto';
import {hostDelete, hostInsert, hostSelectAll, hostUpdate} from "../../../api/HostService";
import {MdOutlineCreate, MdOutlineDeleteForever} from "react-icons/md";
import Modal from "../../common/Modal";
import {HostInsertRequest} from "../../../api/generated-react-client/src";

const HostForm: React.FC = () => {

    const [hosts, setHosts] = useState<Array<HostDto>>([]);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
    const [createFormData, setCreateFormData] = useState<HostDto>({
        alias: '',
        name: '',
        ip: '',
        port: 0,
        status: HostDtoStatusEnum.Stopped
    });
    const [error, setError] = useState<string | null>(null);
    const [showErrorPopup, setShowErrorPopup] = useState<boolean>(false);

    const handleError = (error: unknown) => {
        setError((error as Error).message);
        setShowErrorPopup(true);
    };

    useEffect(() => {
        const fetchHosts = async () => {
            try {
                const result = await hostSelectAll();
                setHosts(result);
            } catch (error) {
                handleError(error);
            }
        };

        fetchHosts();
    }, []);

    const handleDeleteClick = async (hostAlias: string) => {
        if (window.confirm('Are you sure you want to delete this host?')) {
            try {
                await hostDelete({hostAlias});
                setHosts(prevHosts => prevHosts.filter(host => host.alias !== hostAlias));
            } catch (error) {
                handleError(error);
            }
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.currentTarget;
        setCreateFormData(prevFormData => ({...prevFormData, [name]: value}));
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const requestParameters: HostInsertRequest = {hostDto: createFormData};

        try {
            await hostInsert(requestParameters);
            setIsCreateModalOpen(false);
            const result = await hostSelectAll();
            setHosts(result);
        } catch (error) {
            handleError(error);
        }
    };

    const handleEditClick = async (host: HostDto, field: 'alias' | 'name' | 'ip' | 'port') => {
        const currentValue = host[field]?.toString() || '';
        const newValue = prompt(`Edit ${field}`, currentValue);
        if (newValue !== null) {
            const updatedHost = {...host, [field]: field === 'port' ? Number(newValue) : newValue};
            try {
                await hostUpdate({hostAlias: host.alias || '', hostDto: updatedHost});
                setHosts(prevHosts => prevHosts.map(h => h.alias === host.alias ? updatedHost : h));
            } catch (error) {
                handleError(error);
            }
        }
    };

    return (
        <div className="formList">
            <div className="headerContainer">
                <b>All Hosts</b>
                <div className="formGroup">
                    <button className="createButton" onClick={() => setIsCreateModalOpen(true)}>Create</button>
                </div>
            </div>
            <Modal isOpen={isCreateModalOpen} onClose={() => setIsCreateModalOpen(false)}>
                <form onSubmit={handleSubmit} className="hostForm">
                    <div className="formGroup">
                        <label>Alias</label>
                        <input
                            type="text"
                            name="alias"
                            value={createFormData.alias}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="formGroup">
                        <label>Host name</label>
                        <input
                            type="text"
                            name="name"
                            value={createFormData.name}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="formGroup">
                        <label>IP Address</label>
                        <input
                            type="text"
                            name="ip"
                            value={createFormData.ip}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="formGroup">
                        <label>Port</label>
                        <input
                            type="number"
                            name="port"
                            value={createFormData.port}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="horizontalButtonContainer">
                        <button type="submit">Submit</button>
                    </div>
                </form>
            </Modal>

            {showErrorPopup && error && (
                <div className="errorModal">
                    <div className="errorModalContent">
                        <p>Error: {error}</p>
                        <button onClick={() => setShowErrorPopup(false)}>Close</button>
                    </div>
                </div>
            )}
            <table>
                <thead>
                <tr>
                    <th style={{width: '150px', textAlign: 'center'}}>Alias</th>
                    <th>Host name</th>
                    <th>IP Address</th>
                    <th style={{width: '80px', textAlign: 'center'}}>Port</th>
                    <th style={{width: '80px', textAlign: 'center'}}>Status</th>
                    <th style={{width: '170px', textAlign: 'center'}}>Started At</th>
                    <th style={{width: '170px', textAlign: 'center'}}>Stopped At</th>
                    <th style={{width: '170px', textAlign: 'center'}}>Alive At</th>
                    <th style={{width: '80px', textAlign: 'center'}}>Actions</th>
                </tr>
                </thead>
                <tbody>
                {hosts.map((host, index) => (
                    <tr key={host.alias} className={`row ${index % 2 === 0 ? 'even' : 'odd'}`}>
                        <td style={{width: '150px', textAlign: 'center'}}>
                            {host.alias}
                            <span className="editIcon"
                                  onClick={() => handleEditClick(host, 'alias')}><MdOutlineCreate/></span>
                        </td>
                        <td>
                            {host.name}
                            <span className="editIcon"
                                  onClick={() => handleEditClick(host, 'name')}><MdOutlineCreate/></span>
                        </td>
                        <td>
                            {host.ip}
                            <span className="editIcon"
                                  onClick={() => handleEditClick(host, 'ip')}><MdOutlineCreate/></span>
                        </td>
                        <td style={{width: '80px', textAlign: 'center'}}>
                            {host.port}
                            <span className="editIcon" onClick={() => handleEditClick(host, 'port')}>
                                    <MdOutlineCreate/>
                                </span>
                        </td>
                        <td style={{width: '80px', textAlign: 'center'}}>{host.status}</td>
                        <td style={{width: '170px', textAlign: 'center'}}>
                            {host.startedAt ? new Date(host.startedAt).toLocaleString() : ''}
                        </td>
                        <td style={{width: '170px', textAlign: 'center'}}>
                            {host.stoppedAt ? new Date(host.stoppedAt).toLocaleString() : ''}
                        </td>
                        <td style={{width: '170px', textAlign: 'center'}}>
                            {host.aliveAt ? new Date(host.aliveAt).toLocaleString() : ''}
                        </td>
                        <td style={{width: '80px', textAlign: 'center'}}>
                                    <span onClick={() => handleDeleteClick(host.alias!)} className="deleteIcon">
                                        <MdOutlineDeleteForever/>
                                    </span>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default HostForm;