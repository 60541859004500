/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Translation data to update an existing translation
 * @export
 * @interface TranslationDto
 */
export interface TranslationDto {
    /**
     * The identifier for the tenant to which the translation belongs.
     * @type {number}
     * @memberof TranslationDto
     */
    tenantId?: number;
    /**
     * Defines the categories of translations used in the application.
     * @type {string}
     * @memberof TranslationDto
     */
    category?: TranslationDtoCategoryEnum;
    /**
     * Defines the set of supported languages for translation.
     * @type {string}
     * @memberof TranslationDto
     */
    language?: TranslationDtoLanguageEnum;
    /**
     * Identifier necessary to uniquely identify a translation.
     * @type {number}
     * @memberof TranslationDto
     */
    labelId?: number;
    /**
     * The label of the translation
     * @type {string}
     * @memberof TranslationDto
     */
    label?: string;
}


/**
 * @export
 */
export const TranslationDtoCategoryEnum = {
    Gui: 'GUI',
    Data: 'DATA',
    Enumeration: 'ENUMERATION',
    Anomaly: 'ANOMALY'
} as const;
export type TranslationDtoCategoryEnum = typeof TranslationDtoCategoryEnum[keyof typeof TranslationDtoCategoryEnum];

/**
 * @export
 */
export const TranslationDtoLanguageEnum = {
    En: 'EN',
    Fr: 'FR',
    De: 'DE',
    Pl: 'PL',
    Sv: 'SV'
} as const;
export type TranslationDtoLanguageEnum = typeof TranslationDtoLanguageEnum[keyof typeof TranslationDtoLanguageEnum];


/**
 * Check if a given object implements the TranslationDto interface.
 */
export function instanceOfTranslationDto(value: object): boolean {
    return true;
}

export function TranslationDtoFromJSON(json: any): TranslationDto {
    return TranslationDtoFromJSONTyped(json, false);
}

export function TranslationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TranslationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'tenantId': json['tenantId'] == null ? undefined : json['tenantId'],
        'category': json['category'] == null ? undefined : json['category'],
        'language': json['language'] == null ? undefined : json['language'],
        'labelId': json['labelId'] == null ? undefined : json['labelId'],
        'label': json['label'] == null ? undefined : json['label'],
    };
}

export function TranslationDtoToJSON(value?: TranslationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'tenantId': value['tenantId'],
        'category': value['category'],
        'language': value['language'],
        'labelId': value['labelId'],
        'label': value['label'],
    };
}

