/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Configuration details to update an existing tenant configuration
 * @export
 * @interface SetupDto
 */
export interface SetupDto {
    /**
     * The unique identifier of the setup parameter.
     * @type {string}
     * @memberof SetupDto
     */
    id?: string;
    /**
     * Indicates whether the setup parameter is currently active.
     * @type {boolean}
     * @memberof SetupDto
     */
    active?: boolean;
    /**
     * The current value of the setup parameter.
     * @type {string}
     * @memberof SetupDto
     */
    value?: string;
    /**
     * The default value of the setup parameter if no value is specified.
     * @type {string}
     * @memberof SetupDto
     */
    defaultValue?: string;
    /**
     * Validation rule expressed as a regular expression to validate the setup parameter value.
     * @type {string}
     * @memberof SetupDto
     */
    validationRule?: string;
    /**
     * A list of acceptable values for the setup parameter if it is restricted to specific options.
     * @type {Array<string>}
     * @memberof SetupDto
     */
    valueList?: Array<string>;
    /**
     * Indicates whether the setup parameter is mandatory.
     * @type {boolean}
     * @memberof SetupDto
     */
    mandatory?: boolean;
    /**
     * Enumerates the various types of data that a field can hold, providing specific context for the data handling requirements.
     * @type {string}
     * @memberof SetupDto
     */
    dataType?: SetupDtoDataTypeEnum;
}


/**
 * @export
 */
export const SetupDtoDataTypeEnum = {
    Boolean: 'BOOLEAN',
    Integer: 'INTEGER',
    String: 'STRING',
    Memo: 'MEMO',
    Double: 'DOUBLE',
    Date: 'DATE',
    Time: 'TIME',
    Datetime: 'DATETIME',
    List: 'LIST',
    Json: 'JSON',
    Xml: 'XML'
} as const;
export type SetupDtoDataTypeEnum = typeof SetupDtoDataTypeEnum[keyof typeof SetupDtoDataTypeEnum];


/**
 * Check if a given object implements the SetupDto interface.
 */
export function instanceOfSetupDto(value: object): boolean {
    return true;
}

export function SetupDtoFromJSON(json: any): SetupDto {
    return SetupDtoFromJSONTyped(json, false);
}

export function SetupDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetupDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'active': json['active'] == null ? undefined : json['active'],
        'value': json['value'] == null ? undefined : json['value'],
        'defaultValue': json['defaultValue'] == null ? undefined : json['defaultValue'],
        'validationRule': json['validationRule'] == null ? undefined : json['validationRule'],
        'valueList': json['valueList'] == null ? undefined : json['valueList'],
        'mandatory': json['mandatory'] == null ? undefined : json['mandatory'],
        'dataType': json['dataType'] == null ? undefined : json['dataType'],
    };
}

export function SetupDtoToJSON(value?: SetupDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'active': value['active'],
        'value': value['value'],
        'defaultValue': value['defaultValue'],
        'validationRule': value['validationRule'],
        'valueList': value['valueList'],
        'mandatory': value['mandatory'],
        'dataType': value['dataType'],
    };
}

