/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PipelineSchemaDto,
  PipelineSchemaFilter,
  PipelineSchemaMemberDto,
  PipelineSchemaSignerDto,
  PipelineSchemaView,
} from '../models/index';
import {
    PipelineSchemaDtoFromJSON,
    PipelineSchemaDtoToJSON,
    PipelineSchemaFilterFromJSON,
    PipelineSchemaFilterToJSON,
    PipelineSchemaMemberDtoFromJSON,
    PipelineSchemaMemberDtoToJSON,
    PipelineSchemaSignerDtoFromJSON,
    PipelineSchemaSignerDtoToJSON,
    PipelineSchemaViewFromJSON,
    PipelineSchemaViewToJSON,
} from '../models/index';

export interface PipelineSchemaMemberDeleteRequest {
    pipelineSchemaMemberDto: PipelineSchemaMemberDto;
}

export interface PipelineSchemaMemberInsertRequest {
    pipelineSchemaMemberDto: PipelineSchemaMemberDto;
}

export interface PipelineSchemaMemberSelectByMemberIdRequest {
    memberType: PipelineSchemaMemberSelectByMemberIdMemberTypeEnum;
    memberId: number;
}

export interface PipelineSchemaMemberSelectByPkRequest {
    pschemaId: number;
    memberType: PipelineSchemaMemberSelectByPkMemberTypeEnum;
    memberId: number;
    roleType: PipelineSchemaMemberSelectByPkRoleTypeEnum;
}

export interface PipelineSchemaMemberSelectByPschemaIdRequest {
    pschemaId: number;
}

export interface PschemaDeleteRequest {
    pschemaId: number;
}

export interface PschemaExportRequest {
    pschemaId: number;
}

export interface PschemaImportRequest {
    createUrn: boolean;
    file: Blob;
}

export interface PschemaInsertRequest {
    pipelineSchemaDto: PipelineSchemaDto;
}

export interface PschemaLinkSignerRequest {
    pschemaId: number;
    body: number;
}

export interface PschemaSearchRequest {
    pipelineSchemaFilter: PipelineSchemaFilter;
}

export interface PschemaSelectByAliasAndVersionRequest {
    alias: string;
    version: string;
}

export interface PschemaSelectByIdRequest {
    pschemaId: number;
}

export interface PschemaUnlinkSignerRequest {
    pschemaId: number;
    body: number;
}

export interface PschemaUpdateRequest {
    pipelineSchemaDto: PipelineSchemaDto;
}

/**
 * 
 */
export class PipelineSchemaManagementApi extends runtime.BaseAPI {

    /**
     * Deletes a specific pipeline schema member relationship by its details.
     * Delete a pipeline schema member relationship
     */
    async pipelineSchemaMemberDeleteRaw(requestParameters: PipelineSchemaMemberDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['pipelineSchemaMemberDto'] == null) {
            throw new runtime.RequiredError(
                'pipelineSchemaMemberDto',
                'Required parameter "pipelineSchemaMemberDto" was null or undefined when calling pipelineSchemaMemberDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/pschema/member`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: PipelineSchemaMemberDtoToJSON(requestParameters['pipelineSchemaMemberDto']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Deletes a specific pipeline schema member relationship by its details.
     * Delete a pipeline schema member relationship
     */
    async pipelineSchemaMemberDelete(requestParameters: PipelineSchemaMemberDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.pipelineSchemaMemberDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates a new pipeline schema member relationship and returns all its details.
     * Insert a new pipeline schema member relationship
     */
    async pipelineSchemaMemberInsertRaw(requestParameters: PipelineSchemaMemberInsertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PipelineSchemaMemberDto>> {
        if (requestParameters['pipelineSchemaMemberDto'] == null) {
            throw new runtime.RequiredError(
                'pipelineSchemaMemberDto',
                'Required parameter "pipelineSchemaMemberDto" was null or undefined when calling pipelineSchemaMemberInsert().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/pschema/member`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PipelineSchemaMemberDtoToJSON(requestParameters['pipelineSchemaMemberDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PipelineSchemaMemberDtoFromJSON(jsonValue));
    }

    /**
     * Creates a new pipeline schema member relationship and returns all its details.
     * Insert a new pipeline schema member relationship
     */
    async pipelineSchemaMemberInsert(requestParameters: PipelineSchemaMemberInsertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PipelineSchemaMemberDto> {
        const response = await this.pipelineSchemaMemberInsertRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves all pipeline schema members associated with a specific member type and member ID.
     * Retrieve pipeline schema member relationships by member type and its ID
     */
    async pipelineSchemaMemberSelectByMemberIdRaw(requestParameters: PipelineSchemaMemberSelectByMemberIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PipelineSchemaMemberDto>>> {
        if (requestParameters['memberType'] == null) {
            throw new runtime.RequiredError(
                'memberType',
                'Required parameter "memberType" was null or undefined when calling pipelineSchemaMemberSelectByMemberId().'
            );
        }

        if (requestParameters['memberId'] == null) {
            throw new runtime.RequiredError(
                'memberId',
                'Required parameter "memberId" was null or undefined when calling pipelineSchemaMemberSelectByMemberId().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/pschema/member/{memberType}/{memberId}`.replace(`{${"memberType"}}`, encodeURIComponent(String(requestParameters['memberType']))).replace(`{${"memberId"}}`, encodeURIComponent(String(requestParameters['memberId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PipelineSchemaMemberDtoFromJSON));
    }

    /**
     * Retrieves all pipeline schema members associated with a specific member type and member ID.
     * Retrieve pipeline schema member relationships by member type and its ID
     */
    async pipelineSchemaMemberSelectByMemberId(requestParameters: PipelineSchemaMemberSelectByMemberIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PipelineSchemaMemberDto>> {
        const response = await this.pipelineSchemaMemberSelectByMemberIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a single pipeline schema member by its schema ID, member type, member ID, and role type.
     * Retrieve a pipeline schema member relationship by composite key
     */
    async pipelineSchemaMemberSelectByPkRaw(requestParameters: PipelineSchemaMemberSelectByPkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PipelineSchemaMemberDto>> {
        if (requestParameters['pschemaId'] == null) {
            throw new runtime.RequiredError(
                'pschemaId',
                'Required parameter "pschemaId" was null or undefined when calling pipelineSchemaMemberSelectByPk().'
            );
        }

        if (requestParameters['memberType'] == null) {
            throw new runtime.RequiredError(
                'memberType',
                'Required parameter "memberType" was null or undefined when calling pipelineSchemaMemberSelectByPk().'
            );
        }

        if (requestParameters['memberId'] == null) {
            throw new runtime.RequiredError(
                'memberId',
                'Required parameter "memberId" was null or undefined when calling pipelineSchemaMemberSelectByPk().'
            );
        }

        if (requestParameters['roleType'] == null) {
            throw new runtime.RequiredError(
                'roleType',
                'Required parameter "roleType" was null or undefined when calling pipelineSchemaMemberSelectByPk().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/pschema/member/{pschemaId}/{memberType}/{memberId}/{roleType}`.replace(`{${"pschemaId"}}`, encodeURIComponent(String(requestParameters['pschemaId']))).replace(`{${"memberType"}}`, encodeURIComponent(String(requestParameters['memberType']))).replace(`{${"memberId"}}`, encodeURIComponent(String(requestParameters['memberId']))).replace(`{${"roleType"}}`, encodeURIComponent(String(requestParameters['roleType']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PipelineSchemaMemberDtoFromJSON(jsonValue));
    }

    /**
     * Retrieves a single pipeline schema member by its schema ID, member type, member ID, and role type.
     * Retrieve a pipeline schema member relationship by composite key
     */
    async pipelineSchemaMemberSelectByPk(requestParameters: PipelineSchemaMemberSelectByPkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PipelineSchemaMemberDto> {
        const response = await this.pipelineSchemaMemberSelectByPkRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves all members associated with a specific pipeline schema by its unique identifier (ID).
     * Retrieve Pipeline schema member relationships by Schema ID
     */
    async pipelineSchemaMemberSelectByPschemaIdRaw(requestParameters: PipelineSchemaMemberSelectByPschemaIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PipelineSchemaMemberDto>>> {
        if (requestParameters['pschemaId'] == null) {
            throw new runtime.RequiredError(
                'pschemaId',
                'Required parameter "pschemaId" was null or undefined when calling pipelineSchemaMemberSelectByPschemaId().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/pschema/member/{pschemaId}`.replace(`{${"pschemaId"}}`, encodeURIComponent(String(requestParameters['pschemaId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PipelineSchemaMemberDtoFromJSON));
    }

    /**
     * Retrieves all members associated with a specific pipeline schema by its unique identifier (ID).
     * Retrieve Pipeline schema member relationships by Schema ID
     */
    async pipelineSchemaMemberSelectByPschemaId(requestParameters: PipelineSchemaMemberSelectByPschemaIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PipelineSchemaMemberDto>> {
        const response = await this.pipelineSchemaMemberSelectByPschemaIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes a pipeline schema by its unique identifier (ID) and returns the count of deleted records.
     * Delete a pipeline schema
     */
    async pschemaDeleteRaw(requestParameters: PschemaDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['pschemaId'] == null) {
            throw new runtime.RequiredError(
                'pschemaId',
                'Required parameter "pschemaId" was null or undefined when calling pschemaDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/pschema/{pschemaId}`.replace(`{${"pschemaId"}}`, encodeURIComponent(String(requestParameters['pschemaId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Deletes a pipeline schema by its unique identifier (ID) and returns the count of deleted records.
     * Delete a pipeline schema
     */
    async pschemaDelete(requestParameters: PschemaDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.pschemaDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetches and exports a pipeline schema by its ID, returning it as a JSON file.
     * Export a Pipeline Schema
     */
    async pschemaExportRaw(requestParameters: PschemaExportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['pschemaId'] == null) {
            throw new runtime.RequiredError(
                'pschemaId',
                'Required parameter "pschemaId" was null or undefined when calling pschemaExport().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/pschema/export/{pschemaId}`.replace(`{${"pschemaId"}}`, encodeURIComponent(String(requestParameters['pschemaId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Fetches and exports a pipeline schema by its ID, returning it as a JSON file.
     * Export a Pipeline Schema
     */
    async pschemaExport(requestParameters: PschemaExportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.pschemaExportRaw(requestParameters, initOverrides);
    }

    /**
     * Uploads and processes a JSON file representing a pipeline schema, with a boolean flag indicating whether to create a URN.
     * Import a Pipeline Schema
     */
    async pschemaImportRaw(requestParameters: PschemaImportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['createUrn'] == null) {
            throw new runtime.RequiredError(
                'createUrn',
                'Required parameter "createUrn" was null or undefined when calling pschemaImport().'
            );
        }

        if (requestParameters['file'] == null) {
            throw new runtime.RequiredError(
                'file',
                'Required parameter "file" was null or undefined when calling pschemaImport().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['createUrn'] != null) {
            queryParameters['createUrn'] = requestParameters['createUrn'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['file'] != null) {
            formParams.append('file', requestParameters['file'] as any);
        }

        const response = await this.request({
            path: `/api/pschema/import`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Uploads and processes a JSON file representing a pipeline schema, with a boolean flag indicating whether to create a URN.
     * Import a Pipeline Schema
     */
    async pschemaImport(requestParameters: PschemaImportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.pschemaImportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates a new pipeline schema with the given details and returns the created pipeline schema.
     * Create a new pipeline schema
     */
    async pschemaInsertRaw(requestParameters: PschemaInsertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PipelineSchemaDto>> {
        if (requestParameters['pipelineSchemaDto'] == null) {
            throw new runtime.RequiredError(
                'pipelineSchemaDto',
                'Required parameter "pipelineSchemaDto" was null or undefined when calling pschemaInsert().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/pschema`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PipelineSchemaDtoToJSON(requestParameters['pipelineSchemaDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PipelineSchemaDtoFromJSON(jsonValue));
    }

    /**
     * Creates a new pipeline schema with the given details and returns the created pipeline schema.
     * Create a new pipeline schema
     */
    async pschemaInsert(requestParameters: PschemaInsertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PipelineSchemaDto> {
        const response = await this.pschemaInsertRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Link the signer and the pipeline schema and return the newly created relation
     * Link signer with pipeline schema
     */
    async pschemaLinkSignerRaw(requestParameters: PschemaLinkSignerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PipelineSchemaSignerDto>> {
        if (requestParameters['pschemaId'] == null) {
            throw new runtime.RequiredError(
                'pschemaId',
                'Required parameter "pschemaId" was null or undefined when calling pschemaLinkSigner().'
            );
        }

        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling pschemaLinkSigner().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/pschema/signer/{pschemaId}`.replace(`{${"pschemaId"}}`, encodeURIComponent(String(requestParameters['pschemaId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PipelineSchemaSignerDtoFromJSON(jsonValue));
    }

    /**
     * Link the signer and the pipeline schema and return the newly created relation
     * Link signer with pipeline schema
     */
    async pschemaLinkSigner(requestParameters: PschemaLinkSignerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PipelineSchemaSignerDto> {
        const response = await this.pschemaLinkSignerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Searches for pipeline schemas based on a set of filters provided in the request body.
     * Search for pipeline schemas
     */
    async pschemaSearchRaw(requestParameters: PschemaSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PipelineSchemaView>> {
        if (requestParameters['pipelineSchemaFilter'] == null) {
            throw new runtime.RequiredError(
                'pipelineSchemaFilter',
                'Required parameter "pipelineSchemaFilter" was null or undefined when calling pschemaSearch().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/pschema/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PipelineSchemaFilterToJSON(requestParameters['pipelineSchemaFilter']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PipelineSchemaViewFromJSON(jsonValue));
    }

    /**
     * Searches for pipeline schemas based on a set of filters provided in the request body.
     * Search for pipeline schemas
     */
    async pschemaSearch(requestParameters: PschemaSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PipelineSchemaView> {
        const response = await this.pschemaSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a single pschema by their alias.
     * Get pschema by alias
     */
    async pschemaSelectByAliasAndVersionRaw(requestParameters: PschemaSelectByAliasAndVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PipelineSchemaDto>> {
        if (requestParameters['alias'] == null) {
            throw new runtime.RequiredError(
                'alias',
                'Required parameter "alias" was null or undefined when calling pschemaSelectByAliasAndVersion().'
            );
        }

        if (requestParameters['version'] == null) {
            throw new runtime.RequiredError(
                'version',
                'Required parameter "version" was null or undefined when calling pschemaSelectByAliasAndVersion().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/pschema/by-alias/{alias}/by-version/{version}`.replace(`{${"alias"}}`, encodeURIComponent(String(requestParameters['alias']))).replace(`{${"version"}}`, encodeURIComponent(String(requestParameters['version']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PipelineSchemaDtoFromJSON(jsonValue));
    }

    /**
     * Retrieves a single pschema by their alias.
     * Get pschema by alias
     */
    async pschemaSelectByAliasAndVersion(requestParameters: PschemaSelectByAliasAndVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PipelineSchemaDto> {
        const response = await this.pschemaSelectByAliasAndVersionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a single pipeline schema by its unique identifier (ID).
     * Get pipeline schema by ID
     */
    async pschemaSelectByIdRaw(requestParameters: PschemaSelectByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PipelineSchemaDto>> {
        if (requestParameters['pschemaId'] == null) {
            throw new runtime.RequiredError(
                'pschemaId',
                'Required parameter "pschemaId" was null or undefined when calling pschemaSelectById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/pschema/{pschemaId}`.replace(`{${"pschemaId"}}`, encodeURIComponent(String(requestParameters['pschemaId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PipelineSchemaDtoFromJSON(jsonValue));
    }

    /**
     * Retrieves a single pipeline schema by its unique identifier (ID).
     * Get pipeline schema by ID
     */
    async pschemaSelectById(requestParameters: PschemaSelectByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PipelineSchemaDto> {
        const response = await this.pschemaSelectByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes the link between signer and pipeline schema and returns the count of deleted records.
     * Unlink signer and pipeline schema
     */
    async pschemaUnlinkSignerRaw(requestParameters: PschemaUnlinkSignerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['pschemaId'] == null) {
            throw new runtime.RequiredError(
                'pschemaId',
                'Required parameter "pschemaId" was null or undefined when calling pschemaUnlinkSigner().'
            );
        }

        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling pschemaUnlinkSigner().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/pschema/signer/{pschemaId}`.replace(`{${"pschemaId"}}`, encodeURIComponent(String(requestParameters['pschemaId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Deletes the link between signer and pipeline schema and returns the count of deleted records.
     * Unlink signer and pipeline schema
     */
    async pschemaUnlinkSigner(requestParameters: PschemaUnlinkSignerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.pschemaUnlinkSignerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates an existing pipeline schema\'s details based on the provided information and returns the updated pipeline schema information.
     * Update an existing pipeline schema
     */
    async pschemaUpdateRaw(requestParameters: PschemaUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PipelineSchemaDto>> {
        if (requestParameters['pipelineSchemaDto'] == null) {
            throw new runtime.RequiredError(
                'pipelineSchemaDto',
                'Required parameter "pipelineSchemaDto" was null or undefined when calling pschemaUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/pschema`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PipelineSchemaDtoToJSON(requestParameters['pipelineSchemaDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PipelineSchemaDtoFromJSON(jsonValue));
    }

    /**
     * Updates an existing pipeline schema\'s details based on the provided information and returns the updated pipeline schema information.
     * Update an existing pipeline schema
     */
    async pschemaUpdate(requestParameters: PschemaUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PipelineSchemaDto> {
        const response = await this.pschemaUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const PipelineSchemaMemberSelectByMemberIdMemberTypeEnum = {
    User: 'USER',
    Entity: 'ENTITY',
    Tenant: 'TENANT',
    Team: 'TEAM'
} as const;
export type PipelineSchemaMemberSelectByMemberIdMemberTypeEnum = typeof PipelineSchemaMemberSelectByMemberIdMemberTypeEnum[keyof typeof PipelineSchemaMemberSelectByMemberIdMemberTypeEnum];
/**
 * @export
 */
export const PipelineSchemaMemberSelectByPkMemberTypeEnum = {
    User: 'USER',
    Entity: 'ENTITY',
    Tenant: 'TENANT',
    Team: 'TEAM'
} as const;
export type PipelineSchemaMemberSelectByPkMemberTypeEnum = typeof PipelineSchemaMemberSelectByPkMemberTypeEnum[keyof typeof PipelineSchemaMemberSelectByPkMemberTypeEnum];
/**
 * @export
 */
export const PipelineSchemaMemberSelectByPkRoleTypeEnum = {
    Viewer: 'VIEWER',
    Manager: 'MANAGER',
    Administrator: 'ADMINISTRATOR'
} as const;
export type PipelineSchemaMemberSelectByPkRoleTypeEnum = typeof PipelineSchemaMemberSelectByPkRoleTypeEnum[keyof typeof PipelineSchemaMemberSelectByPkRoleTypeEnum];
