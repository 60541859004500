/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  LogFilter,
  LogView,
  LoggerDto,
} from '../models/index';
import {
    LogFilterFromJSON,
    LogFilterToJSON,
    LogViewFromJSON,
    LogViewToJSON,
    LoggerDtoFromJSON,
    LoggerDtoToJSON,
} from '../models/index';

export interface LogDeleteRequest {
    uuid: string;
}

export interface LogPurgeRequest {
    purgeTime: string;
}

export interface LogSearchRequest {
    logFilter: LogFilter;
}

export interface LoggerUpdateLevelRequest {
    loggerDto: LoggerDto;
}

export interface LoggerUpdatePerformanceRequest {
    loggerDto: LoggerDto;
}

/**
 * 
 */
export class LogManagementApi extends runtime.BaseAPI {

    /**
     * Deletes a specific log by its UUID.
     * Delete a log
     */
    async logDeleteRaw(requestParameters: LogDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling logDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/log/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Deletes a specific log by its UUID.
     * Delete a log
     */
    async logDelete(requestParameters: LogDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.logDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes all logs created before a specific date.
     * Purge logs
     */
    async logPurgeRaw(requestParameters: LogPurgeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['purgeTime'] == null) {
            throw new runtime.RequiredError(
                'purgeTime',
                'Required parameter "purgeTime" was null or undefined when calling logPurge().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/log/purge/{purgeTime}`.replace(`{${"purgeTime"}}`, encodeURIComponent(String(requestParameters['purgeTime']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Deletes all logs created before a specific date.
     * Purge logs
     */
    async logPurge(requestParameters: LogPurgeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.logPurgeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Searches for log records based on a set of filters provided in the request body.
     * Search for log records
     */
    async logSearchRaw(requestParameters: LogSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LogView>> {
        if (requestParameters['logFilter'] == null) {
            throw new runtime.RequiredError(
                'logFilter',
                'Required parameter "logFilter" was null or undefined when calling logSearch().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/log/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LogFilterToJSON(requestParameters['logFilter']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LogViewFromJSON(jsonValue));
    }

    /**
     * Searches for log records based on a set of filters provided in the request body.
     * Search for log records
     */
    async logSearch(requestParameters: LogSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LogView> {
        const response = await this.logSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetches a list of all loggers and their configurations within the system.
     * Retrieve all loggers
     */
    async loggerSelectRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<LoggerDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/log/logger`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LoggerDtoFromJSON));
    }

    /**
     * Fetches a list of all loggers and their configurations within the system.
     * Retrieve all loggers
     */
    async loggerSelect(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<LoggerDto>> {
        const response = await this.loggerSelectRaw(initOverrides);
        return await response.value();
    }

    /**
     * Updates the logging level for a specific logger.
     * Update logger level
     */
    async loggerUpdateLevelRaw(requestParameters: LoggerUpdateLevelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoggerDto>> {
        if (requestParameters['loggerDto'] == null) {
            throw new runtime.RequiredError(
                'loggerDto',
                'Required parameter "loggerDto" was null or undefined when calling loggerUpdateLevel().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/log/logger/level`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: LoggerDtoToJSON(requestParameters['loggerDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoggerDtoFromJSON(jsonValue));
    }

    /**
     * Updates the logging level for a specific logger.
     * Update logger level
     */
    async loggerUpdateLevel(requestParameters: LoggerUpdateLevelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoggerDto> {
        const response = await this.loggerUpdateLevelRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Enables or disables performance logging for a specific logger.
     * Update logger performance logging
     */
    async loggerUpdatePerformanceRaw(requestParameters: LoggerUpdatePerformanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoggerDto>> {
        if (requestParameters['loggerDto'] == null) {
            throw new runtime.RequiredError(
                'loggerDto',
                'Required parameter "loggerDto" was null or undefined when calling loggerUpdatePerformance().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/log/logger/performance`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: LoggerDtoToJSON(requestParameters['loggerDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoggerDtoFromJSON(jsonValue));
    }

    /**
     * Enables or disables performance logging for a specific logger.
     * Update logger performance logging
     */
    async loggerUpdatePerformance(requestParameters: LoggerUpdatePerformanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoggerDto> {
        const response = await this.loggerUpdatePerformanceRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
