import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {logoff} from '../api/AuthenticationService';

const Logout: React.FC = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const performLogout = async () => {
            try {
                await logoff();
                // Perform any additional cleanup (e.g., clearing local storage or state)
                console.log("Successfully logged out");
            } catch (error) {
                console.error("Error during logout:", error);
            }
            navigate('/login'); // Redirect to login page
        };

        performLogout();
    }, [navigate]);

    return (
        <div>Logging out...</div> // Show a message or spinner while logging out
    );
};

export default Logout;
