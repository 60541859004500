import React from 'react';
import styles from './Footer.module.css'; // Assuming styles are defined as before

const Footer: React.FC = () => {
    const version: string = '1.0.0'; // Replace with actual version or fetch dynamically
    const gitCommitId: string = process.env.REACT_APP_GIT_COMMIT_ID || 'unknown';
    return (
        <footer className={styles.footer}>
            <p>Version: {version} (build:<a href={`https://gitlab.com/traceability1/test/-/commit/${gitCommitId}`}
                                            style={{textDecoration: 'none', color: 'inherit'}}
                                            target="_blank"
                                            rel="noopener noreferrer">{gitCommitId}</a>)
            </p>
        </footer>
    );
};

export default Footer;