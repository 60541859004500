/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UserDto } from './UserDto';
import {
    UserDtoFromJSON,
    UserDtoFromJSONTyped,
    UserDtoToJSON,
} from './UserDto';

/**
 * The new attributes for the tenant.
 * @export
 * @interface TenantDto
 */
export interface TenantDto {
    /**
     * Unique identifier for the tenant. Used to distinguish between different tenants.
     * @type {number}
     * @memberof TenantDto
     */
    id?: number;
    /**
     * The tenant's alias or name. It's a human-readable string identifying the tenant.
     * @type {string}
     * @memberof TenantDto
     */
    alias?: string;
    /**
     * Represents the tenant's current budget in terms of available credits.
     * @type {number}
     * @memberof TenantDto
     */
    budget?: number;
    /**
     * JSON string containing the tenant's configuration parameters. It allows for flexible tenant-specific settings.
     * @type {string}
     * @memberof TenantDto
     */
    setup?: string;
    /**
     * 
     * @type {UserDto}
     * @memberof TenantDto
     */
    creator?: UserDto;
    /**
     * The date of creation of the tenant
     * @type {Date}
     * @memberof TenantDto
     */
    createdAt?: Date;
}

/**
 * Check if a given object implements the TenantDto interface.
 */
export function instanceOfTenantDto(value: object): boolean {
    return true;
}

export function TenantDtoFromJSON(json: any): TenantDto {
    return TenantDtoFromJSONTyped(json, false);
}

export function TenantDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TenantDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'alias': json['alias'] == null ? undefined : json['alias'],
        'budget': json['budget'] == null ? undefined : json['budget'],
        'setup': json['setup'] == null ? undefined : json['setup'],
        'creator': json['creator'] == null ? undefined : UserDtoFromJSON(json['creator']),
        'createdAt': json['createdAt'] == null ? undefined : (new Date(json['createdAt'])),
    };
}

export function TenantDtoToJSON(value?: TenantDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'alias': value['alias'],
        'budget': value['budget'],
        'setup': value['setup'],
        'creator': UserDtoToJSON(value['creator']),
        'createdAt': value['createdAt'] == null ? undefined : ((value['createdAt']).toISOString()),
    };
}

