/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  TeamDto,
  TeamMemberDto,
} from '../models/index';
import {
    TeamDtoFromJSON,
    TeamDtoToJSON,
    TeamMemberDtoFromJSON,
    TeamMemberDtoToJSON,
} from '../models/index';

export interface TeamDeleteRequest {
    teamId: number;
}

export interface TeamInsertRequest {
    teamDto: TeamDto;
}

export interface TeamLinkMemberRequest {
    teamMemberDto: TeamMemberDto;
}

export interface TeamMemberSelectByTeamRequest {
    teamId: number;
}

export interface TeamSelectByAliasRequest {
    alias: string;
}

export interface TeamSelectByIdRequest {
    teamId: number;
}

export interface TeamUnlinkMemberRequest {
    teamMemberDto: TeamMemberDto;
}

export interface TeamUpdateRequest {
    teamDto: TeamDto;
}

/**
 * 
 */
export class TeamManagementApi extends runtime.BaseAPI {

    /**
     * Deletes a team entry from the system
     * Delete a team
     */
    async teamDeleteRaw(requestParameters: TeamDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['teamId'] == null) {
            throw new runtime.RequiredError(
                'teamId',
                'Required parameter "teamId" was null or undefined when calling teamDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/team/{teamId}`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters['teamId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Deletes a team entry from the system
     * Delete a team
     */
    async teamDelete(requestParameters: TeamDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.teamDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates a new team entry in the system
     * Insert a new team
     */
    async teamInsertRaw(requestParameters: TeamInsertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TeamDto>> {
        if (requestParameters['teamDto'] == null) {
            throw new runtime.RequiredError(
                'teamDto',
                'Required parameter "teamDto" was null or undefined when calling teamInsert().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/team`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TeamDtoToJSON(requestParameters['teamDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamDtoFromJSON(jsonValue));
    }

    /**
     * Creates a new team entry in the system
     * Insert a new team
     */
    async teamInsert(requestParameters: TeamInsertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TeamDto> {
        const response = await this.teamInsertRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates a new team member entry in the system
     * Insert a new team member
     */
    async teamLinkMemberRaw(requestParameters: TeamLinkMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TeamMemberDto>> {
        if (requestParameters['teamMemberDto'] == null) {
            throw new runtime.RequiredError(
                'teamMemberDto',
                'Required parameter "teamMemberDto" was null or undefined when calling teamLinkMember().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/team/member`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TeamMemberDtoToJSON(requestParameters['teamMemberDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamMemberDtoFromJSON(jsonValue));
    }

    /**
     * Creates a new team member entry in the system
     * Insert a new team member
     */
    async teamLinkMember(requestParameters: TeamLinkMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TeamMemberDto> {
        const response = await this.teamLinkMemberRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetches a list of all members associated with a specific team, based on the team\'s unique identifier.
     * Retrieve all team members in a team
     */
    async teamMemberSelectByTeamRaw(requestParameters: TeamMemberSelectByTeamRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TeamMemberDto>>> {
        if (requestParameters['teamId'] == null) {
            throw new runtime.RequiredError(
                'teamId',
                'Required parameter "teamId" was null or undefined when calling teamMemberSelectByTeam().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/team/member/by-team/{teamId}`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters['teamId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TeamMemberDtoFromJSON));
    }

    /**
     * Fetches a list of all members associated with a specific team, based on the team\'s unique identifier.
     * Retrieve all team members in a team
     */
    async teamMemberSelectByTeam(requestParameters: TeamMemberSelectByTeamRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TeamMemberDto>> {
        const response = await this.teamMemberSelectByTeamRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetches a list of all teams available within the tenant\'s context.
     * Retrieve all teams
     */
    async teamSelectRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TeamDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/team`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TeamDtoFromJSON));
    }

    /**
     * Fetches a list of all teams available within the tenant\'s context.
     * Retrieve all teams
     */
    async teamSelect(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TeamDto>> {
        const response = await this.teamSelectRaw(initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a team object based on its alias.
     * Get team by alias
     */
    async teamSelectByAliasRaw(requestParameters: TeamSelectByAliasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TeamDto>> {
        if (requestParameters['alias'] == null) {
            throw new runtime.RequiredError(
                'alias',
                'Required parameter "alias" was null or undefined when calling teamSelectByAlias().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/team/by-alias/{alias}`.replace(`{${"alias"}}`, encodeURIComponent(String(requestParameters['alias']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamDtoFromJSON(jsonValue));
    }

    /**
     * Retrieves a team object based on its alias.
     * Get team by alias
     */
    async teamSelectByAlias(requestParameters: TeamSelectByAliasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TeamDto> {
        const response = await this.teamSelectByAliasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetches a team detail based on the given team ID. This operation is secure and can only be performed within the context of the tenant to which the team belongs.
     * Retrieve a specific team by its ID
     */
    async teamSelectByIdRaw(requestParameters: TeamSelectByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TeamDto>> {
        if (requestParameters['teamId'] == null) {
            throw new runtime.RequiredError(
                'teamId',
                'Required parameter "teamId" was null or undefined when calling teamSelectById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/team/{teamId}`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters['teamId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamDtoFromJSON(jsonValue));
    }

    /**
     * Fetches a team detail based on the given team ID. This operation is secure and can only be performed within the context of the tenant to which the team belongs.
     * Retrieve a specific team by its ID
     */
    async teamSelectById(requestParameters: TeamSelectByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TeamDto> {
        const response = await this.teamSelectByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes a team member entry from the system
     * Delete a team member
     */
    async teamUnlinkMemberRaw(requestParameters: TeamUnlinkMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['teamMemberDto'] == null) {
            throw new runtime.RequiredError(
                'teamMemberDto',
                'Required parameter "teamMemberDto" was null or undefined when calling teamUnlinkMember().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/team/member`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: TeamMemberDtoToJSON(requestParameters['teamMemberDto']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Deletes a team member entry from the system
     * Delete a team member
     */
    async teamUnlinkMember(requestParameters: TeamUnlinkMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.teamUnlinkMemberRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates selected information for a specific team.
     * Update team\'s attributes
     */
    async teamUpdateRaw(requestParameters: TeamUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TeamDto>> {
        if (requestParameters['teamDto'] == null) {
            throw new runtime.RequiredError(
                'teamDto',
                'Required parameter "teamDto" was null or undefined when calling teamUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/team`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: TeamDtoToJSON(requestParameters['teamDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamDtoFromJSON(jsonValue));
    }

    /**
     * Updates selected information for a specific team.
     * Update team\'s attributes
     */
    async teamUpdate(requestParameters: TeamUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TeamDto> {
        const response = await this.teamUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
