import {
    TemplateDeleteRequest,
    TemplateInsertRequest,
    TemplateManagementApi,
    TemplateSelectByIdRequest,
    TemplateSelectByPkRequest,
    TemplateUpdateRequest,
} from './generated-react-client/src';
import config from './clientConfig';
import {apiCallWrapper} from '../utils/apiWrapper';

const api = new TemplateManagementApi(config);

export const templateInsert = async (requestParameters: TemplateInsertRequest) => {
    return apiCallWrapper(() => api.templateInsert(requestParameters));
};

export const templateUpdate = async (requestParameters: TemplateUpdateRequest) => {
    return apiCallWrapper(() => api.templateUpdate(requestParameters));
};

export const templateDelete = async (requestParameters: TemplateDeleteRequest) => {
    return apiCallWrapper(() => api.templateDelete(requestParameters));
};

export const templateSelectById = async (requestParameters: TemplateSelectByIdRequest) => {
    return apiCallWrapper(() => api.templateSelectById(requestParameters));
};

export const templateSelectByPk = async (requestParameters: TemplateSelectByPkRequest) => {
    return apiCallWrapper(() => api.templateSelectByPk(requestParameters));
};