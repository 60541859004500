import {
    HostDeleteRequest,
    HostInsertRequest,
    HostManagementApi,
    HostSelectByAliasRequest,
    HostUpdateRequest
} from './generated-react-client/src/apis/HostManagementApi';
import config from './clientConfig';
import {apiCallWrapper} from '../utils/apiWrapper';

const api = new HostManagementApi(config);

export const hostInsert = async (requestParameters: HostInsertRequest) => {
    return apiCallWrapper(() => api.hostInsert(requestParameters));
};

export const hostSelectAll = async () => {
    return apiCallWrapper(() => api.hostSelectAll());
};

export const hostUpdate = async (requestParameters: HostUpdateRequest) => {
    return apiCallWrapper(() => api.hostUpdate(requestParameters));
};

export const hostDelete = async (requestParameters: HostDeleteRequest) => {
    return apiCallWrapper(() => api.hostDelete(requestParameters));
};

export const hostSelectByAlias = async (requestParameters: HostSelectByAliasRequest) => {
    return apiCallWrapper(() => api.hostSelectByAlias(requestParameters));
};