/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * The filter criteria used to search the log records
 * @export
 * @interface LogFilter
 */
export interface LogFilter {
    /**
     * The position of the first record to retrieve, used for pagination. It determines the number of initial rows to skip.
     * @type {number}
     * @memberof LogFilter
     */
    offset?: number;
    /**
     * The maximum number of records to return starting from the offset position. This is used to control the size of the result set.
     * @type {number}
     * @memberof LogFilter
     */
    limit?: number;
    /**
     * Filter by user ID.
     * @type {number}
     * @memberof LogFilter
     */
    userId?: number;
    /**
     * Filter by UUID.
     * @type {string}
     * @memberof LogFilter
     */
    uuid?: string;
    /**
     * Filter log entries created after this date.
     * @type {Date}
     * @memberof LogFilter
     */
    fromDate?: Date;
    /**
     * Filter log entries created before this date.
     * @type {Date}
     * @memberof LogFilter
     */
    toDate?: Date;
}

/**
 * Check if a given object implements the LogFilter interface.
 */
export function instanceOfLogFilter(value: object): boolean {
    return true;
}

export function LogFilterFromJSON(json: any): LogFilter {
    return LogFilterFromJSONTyped(json, false);
}

export function LogFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): LogFilter {
    if (json == null) {
        return json;
    }
    return {
        
        'offset': json['offset'] == null ? undefined : json['offset'],
        'limit': json['limit'] == null ? undefined : json['limit'],
        'userId': json['userId'] == null ? undefined : json['userId'],
        'uuid': json['uuid'] == null ? undefined : json['uuid'],
        'fromDate': json['fromDate'] == null ? undefined : (new Date(json['fromDate'])),
        'toDate': json['toDate'] == null ? undefined : (new Date(json['toDate'])),
    };
}

export function LogFilterToJSON(value?: LogFilter | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'offset': value['offset'],
        'limit': value['limit'],
        'userId': value['userId'],
        'uuid': value['uuid'],
        'fromDate': value['fromDate'] == null ? undefined : ((value['fromDate']).toISOString()),
        'toDate': value['toDate'] == null ? undefined : ((value['toDate']).toISOString()),
    };
}

