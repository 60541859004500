import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {authenticate, resetPassword} from '../api/AuthenticationService';
import { useTranslation } from 'react-i18next';
import styles from './Login.module.css';

const LoginForm: React.FC = () => {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [loginError, setLoginError] = useState<string>('');
    const navigate = useNavigate(); // This is already correctly typed by react-router-dom
    const { t } = useTranslation(); // `t` function for translating

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        try {
            await authenticate(email, password);
            navigate('/dashboard'); // Navigate to the dashboard on success
        } catch (error) {
            console.error('Authentication failed:', error);
            setLoginError('Authentication failed. Please check your credentials.');
        }
    };

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [forgotEmail, setForgotEmail] = useState<string>('');

    const handleOpenModal = (e: React.MouseEvent<HTMLSpanElement>) => {
        e.preventDefault(); // This will stop the form from submitting
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setForgotEmail(event.target.value);
    };

    const handleForgotSubmit = async () => {
        // Call your backend API with the email
        console.log('Submitting email:', forgotEmail);
        await resetPassword(forgotEmail);
        handleCloseModal();
        navigate('/login'); // Redirect to login page
        // Optionally, handle response and notify user
    };

    return (
        <div className={styles.container}>
            <div className={styles.loginSection}>
                <form onSubmit={handleSubmit} className={styles.loginForm}>
                    <div className={styles.inputGroup}>
                        <label>Email:</label>
                        <input type="text" value={email} onChange={(e) => setEmail(e.target.value)}/>
                    </div>
                    <div className={styles.inputGroup}>
                        <label>{t('password')}:</label>
                        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)}/>
                    </div>
                    <button type="submit">Login</button>
                    {loginError && <div className={styles.errorMessage}>{loginError}</div>}
                    &nbsp;
                    <div className={styles.simpleCenteredText}>
                        Forgot password ?
                        <span onClick={(e: React.MouseEvent<HTMLSpanElement>) => handleOpenModal(e)}
                              style={{cursor: 'pointer', color: 'blue', textDecoration: 'underline'}}>click here</span>
                        {isModalOpen && (
                            <div className={styles.modalStyle}>
                                <h2>Reset Password</h2>
                                <input type="forgotEmail" value={forgotEmail} onChange={handleEmailChange}
                                       placeholder="Enter your email"/>
                                <button onClick={handleForgotSubmit}>Submit</button>
                                <button onClick={handleCloseModal}>Cancel</button>
                            </div>
                        )}
                    </div>
                </form>
            </div>
            <div className={styles.backgroundWithText}>
                <div className={styles.backgroundImage}></div>
                <div className={styles.centeredText}>Building a space<br/>we can all trust</div>
            </div>
        </div>
    );
};

export default LoginForm;