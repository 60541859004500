import config from './clientConfig';
import {apiCallWrapper} from '../utils/apiWrapper';
import {
    TraceSchemaMetadataDeleteRequest,
    TraceSchemaMetadataInsertRequest,
    TraceSchemaMetadataManagementApi,
    TraceSchemaMetadataSelectByTschemaIdRequest,
    TraceSchemaMetadataUpdateRequest
} from "./generated-react-client/src";

const api = new TraceSchemaMetadataManagementApi(config);

export const traceSchemaMetadataInsert = async (requestParameters: TraceSchemaMetadataInsertRequest) => {
    return apiCallWrapper(() => api.traceSchemaMetadataInsert(requestParameters))
}

export const traceSchemaMetadataDelete = async (requestParameters: TraceSchemaMetadataDeleteRequest) => {
    return apiCallWrapper(() => api.traceSchemaMetadataDelete(requestParameters))
}

export const traceSchemaMetadataUpdate = async (requestParameters: TraceSchemaMetadataUpdateRequest) => {
    return apiCallWrapper(() => api.traceSchemaMetadataUpdate(requestParameters))
}

export const traceSchemaMetadataSelectByTSchema = async (requestParameters: TraceSchemaMetadataSelectByTschemaIdRequest) => {
    return apiCallWrapper(() => api.traceSchemaMetadataSelectByTschemaId(requestParameters));
}
