/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  HalgorithmDto,
} from '../models/index';
import {
    HalgorithmDtoFromJSON,
    HalgorithmDtoToJSON,
} from '../models/index';

export interface HalgorithmDeleteRequest {
    halgorithmId: number;
}

export interface HalgorithmInsertRequest {
    halgorithmDto: HalgorithmDto;
}

export interface HalgorithmSelectByAliasRequest {
    alias: string;
}

export interface HalgorithmSelectByIdRequest {
    halgorithmId: number;
}

export interface HalgorithmUpdateRequest {
    halgorithmDto: HalgorithmDto;
}

/**
 * 
 */
export class HashAlgorithmManagementApi extends runtime.BaseAPI {

    /**
     * Deletes a specific hash algorithm by its ID.
     * Delete a hash algorithm
     */
    async halgorithmDeleteRaw(requestParameters: HalgorithmDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['halgorithmId'] == null) {
            throw new runtime.RequiredError(
                'halgorithmId',
                'Required parameter "halgorithmId" was null or undefined when calling halgorithmDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/halgorithm/{halgorithmId}`.replace(`{${"halgorithmId"}}`, encodeURIComponent(String(requestParameters['halgorithmId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Deletes a specific hash algorithm by its ID.
     * Delete a hash algorithm
     */
    async halgorithmDelete(requestParameters: HalgorithmDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.halgorithmDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates a new hash algorithm and returns the created hash algorithm with its ID.
     * Insert a new hash algorithm
     */
    async halgorithmInsertRaw(requestParameters: HalgorithmInsertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HalgorithmDto>> {
        if (requestParameters['halgorithmDto'] == null) {
            throw new runtime.RequiredError(
                'halgorithmDto',
                'Required parameter "halgorithmDto" was null or undefined when calling halgorithmInsert().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/halgorithm`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HalgorithmDtoToJSON(requestParameters['halgorithmDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HalgorithmDtoFromJSON(jsonValue));
    }

    /**
     * Creates a new hash algorithm and returns the created hash algorithm with its ID.
     * Insert a new hash algorithm
     */
    async halgorithmInsert(requestParameters: HalgorithmInsertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HalgorithmDto> {
        const response = await this.halgorithmInsertRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a list of all hash algorithms.
     * Get all hash algorithms
     */
    async halgorithmSelectRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<HalgorithmDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/halgorithm`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(HalgorithmDtoFromJSON));
    }

    /**
     * Retrieves a list of all hash algorithms.
     * Get all hash algorithms
     */
    async halgorithmSelect(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<HalgorithmDto>> {
        const response = await this.halgorithmSelectRaw(initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a hash algorithm by its alias.
     * Get hash algorithm by alias
     */
    async halgorithmSelectByAliasRaw(requestParameters: HalgorithmSelectByAliasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HalgorithmDto>> {
        if (requestParameters['alias'] == null) {
            throw new runtime.RequiredError(
                'alias',
                'Required parameter "alias" was null or undefined when calling halgorithmSelectByAlias().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/halgorithm/by-alias/{alias}`.replace(`{${"alias"}}`, encodeURIComponent(String(requestParameters['alias']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HalgorithmDtoFromJSON(jsonValue));
    }

    /**
     * Retrieves a hash algorithm by its alias.
     * Get hash algorithm by alias
     */
    async halgorithmSelectByAlias(requestParameters: HalgorithmSelectByAliasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HalgorithmDto> {
        const response = await this.halgorithmSelectByAliasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a hash algorithm by its ID.
     * Get hash algorithm by ID
     */
    async halgorithmSelectByIdRaw(requestParameters: HalgorithmSelectByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HalgorithmDto>> {
        if (requestParameters['halgorithmId'] == null) {
            throw new runtime.RequiredError(
                'halgorithmId',
                'Required parameter "halgorithmId" was null or undefined when calling halgorithmSelectById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/halgorithm/{halgorithmId}`.replace(`{${"halgorithmId"}}`, encodeURIComponent(String(requestParameters['halgorithmId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HalgorithmDtoFromJSON(jsonValue));
    }

    /**
     * Retrieves a hash algorithm by its ID.
     * Get hash algorithm by ID
     */
    async halgorithmSelectById(requestParameters: HalgorithmSelectByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HalgorithmDto> {
        const response = await this.halgorithmSelectByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates selected information for a specific hash algorithm.
     * Update hash algorithm\'s attributes
     */
    async halgorithmUpdateRaw(requestParameters: HalgorithmUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['halgorithmDto'] == null) {
            throw new runtime.RequiredError(
                'halgorithmDto',
                'Required parameter "halgorithmDto" was null or undefined when calling halgorithmUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/halgorithm`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: HalgorithmDtoToJSON(requestParameters['halgorithmDto']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Updates selected information for a specific hash algorithm.
     * Update hash algorithm\'s attributes
     */
    async halgorithmUpdate(requestParameters: HalgorithmUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.halgorithmUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
