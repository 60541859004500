/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PipelineDto } from './PipelineDto';
import {
    PipelineDtoFromJSON,
    PipelineDtoFromJSONTyped,
    PipelineDtoToJSON,
} from './PipelineDto';

/**
 * Carries the search result with pagination details.
 * @export
 * @interface PipelineView
 */
export interface PipelineView {
    /**
     * The total number of elements available for the query, which may be greater than the number of elements in the list.
     * @type {number}
     * @memberof PipelineView
     */
    count?: number;
    /**
     * The position of the first element in the list within the overall query results.
     * @type {number}
     * @memberof PipelineView
     */
    offset?: number;
    /**
     * The maximum number of elements that can be returned in a single response.
     * @type {number}
     * @memberof PipelineView
     */
    limit?: number;
    /**
     * The list of pipelines representing the actual data returned for the current page of results.
     * @type {Array<PipelineDto>}
     * @memberof PipelineView
     */
    list?: Array<PipelineDto>;
}

/**
 * Check if a given object implements the PipelineView interface.
 */
export function instanceOfPipelineView(value: object): boolean {
    return true;
}

export function PipelineViewFromJSON(json: any): PipelineView {
    return PipelineViewFromJSONTyped(json, false);
}

export function PipelineViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): PipelineView {
    if (json == null) {
        return json;
    }
    return {
        
        'count': json['count'] == null ? undefined : json['count'],
        'offset': json['offset'] == null ? undefined : json['offset'],
        'limit': json['limit'] == null ? undefined : json['limit'],
        'list': json['list'] == null ? undefined : ((json['list'] as Array<any>).map(PipelineDtoFromJSON)),
    };
}

export function PipelineViewToJSON(value?: PipelineView | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'count': value['count'],
        'offset': value['offset'],
        'limit': value['limit'],
        'list': value['list'] == null ? undefined : ((value['list'] as Array<any>).map(PipelineDtoToJSON)),
    };
}

