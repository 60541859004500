import config from './clientConfig';
import {
    ShareDeleteRequest,
    ShareInsertRequest,
    ShareManagementApi,
    ShareSelectByIdRequest,
    ShareSelectByPschemaRequest,
    ShareSelectByTschemaRequest,
    ShareTeamDeleteRequest,
    ShareTeamInsertRequest,
    ShareTeamSelectByShareRequest,
    ShareTeamSelectByTeamRequest,
    ShareTeamSelectRequest,
    ShareTraceSchemaDeleteByShareRequest,
    ShareTraceSchemaDeleteByTraceSchemaRequest,
    ShareTraceSchemaDeleteRequest,
    ShareTraceSchemaInsertRequest,
    ShareTraceSchemaSelectRequest,
    ShareUpdateRequest
} from "./generated-react-client/src";
import {apiCallWrapper} from '../utils/apiWrapper';

const api = new ShareManagementApi(config);

export const shareInsert = async (requestParameter: ShareInsertRequest) => {
    return apiCallWrapper(() => api.shareInsert(requestParameter));
};

export const shareDelete = async (requestParameter: ShareDeleteRequest) => {
    return apiCallWrapper(() => api.shareDelete(requestParameter));
};

export const shareUpdate = async (requestParameter: ShareUpdateRequest) => {
    return apiCallWrapper(() => api.shareUpdate(requestParameter));
};

export const shareSelectByPschema = async (requestParameter: ShareSelectByPschemaRequest) => {
    return apiCallWrapper(() => api.shareSelectByPschema(requestParameter));
};

export const shareSelectById = async (requestParameter: ShareSelectByIdRequest) => {
    return apiCallWrapper(() => api.shareSelectById(requestParameter));
};

export const shareSelectByTschema = async (requestParameter: ShareSelectByTschemaRequest) => {
    return apiCallWrapper(() => api.shareSelectByTschema(requestParameter));
};

export const shareTeamInsert = async (requestParameter: ShareTeamInsertRequest) => {
    return apiCallWrapper(() => api.shareTeamInsert(requestParameter));
};

export const shareTeamDelete = async (requestParameter: ShareTeamDeleteRequest) => {
    return apiCallWrapper(() => api.shareTeamDelete(requestParameter));
};

export const shareTeamSelect = async (requestParameter: ShareTeamSelectRequest) => {
    return apiCallWrapper(() => api.shareTeamSelect(requestParameter));
};

export const shareTeamSelectByShare = async (requestParameter: ShareTeamSelectByShareRequest) => {
    return apiCallWrapper(() => api.shareTeamSelectByShare(requestParameter));
};

export const shareTeamSelectByTeam = async (requestParameter: ShareTeamSelectByTeamRequest) => {
    return apiCallWrapper(() => api.shareTeamSelectByTeam(requestParameter));
};

export const shareTraceSchemaInsert = async (requestParameter: ShareTraceSchemaInsertRequest) => {
    return apiCallWrapper(() => api.shareTraceSchemaInsert(requestParameter));
};

export const shareTraceSchemaDelete = async (requestParameter: ShareTraceSchemaDeleteRequest) => {
    return apiCallWrapper(() => api.shareTraceSchemaDelete(requestParameter));
};

export const shareTraceSchemaSelect = async (requestParameter: ShareTraceSchemaSelectRequest) => {
    return apiCallWrapper(() => api.shareTraceSchemaSelect(requestParameter));
};

export const shareTraceSchemaDeleteByShare = async (requestParameter: ShareTraceSchemaDeleteByShareRequest) => {
    return apiCallWrapper(() => api.shareTraceSchemaDeleteByShare(requestParameter));
};

export const shareTraceSchemaDeleteByTraceSchema = async (requestParameter: ShareTraceSchemaDeleteByTraceSchemaRequest) => {
    return apiCallWrapper(() => api.shareTraceSchemaDeleteByTraceSchema(requestParameter));
};
