/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PipelineDto,
  PipelineFilter,
  PipelineUploadRequest,
  PipelineView,
  ProtectedPipeline,
} from '../models/index';
import {
    PipelineDtoFromJSON,
    PipelineDtoToJSON,
    PipelineFilterFromJSON,
    PipelineFilterToJSON,
    PipelineUploadRequestFromJSON,
    PipelineUploadRequestToJSON,
    PipelineViewFromJSON,
    PipelineViewToJSON,
    ProtectedPipelineFromJSON,
    ProtectedPipelineToJSON,
} from '../models/index';

export interface PipelineExportRequest {
    pipelineId: number;
    exportFormat: PipelineExportExportFormatEnum;
}

export interface PipelineGetRequest {
    hash: string;
}

export interface PipelinePrintRequest {
    hash: string;
}

export interface PipelineRegisterRequest {
    pschemaAlias: string;
    pschemaVersion: string;
    pipelineDto: PipelineDto;
}

export interface PipelineSearchRequest {
    pipelineFilter: PipelineFilter;
}

export interface PipelineSelectByIdRequest {
    pipelineId: number;
}

export interface PipelineUpdateStatusRequest {
    pipelineId: number;
    body: string;
}

export interface PipelineUploadOperationRequest {
    pschema: string;
    pipelineUploadRequest?: PipelineUploadRequest;
}

export interface PipelineValidateRequest {
    hash: string;
}

/**
 * 
 */
export class PipelineManagementApi extends runtime.BaseAPI {

    /**
     * Export a pipeline in the chosen export format.
     */
    async pipelineExportRaw(requestParameters: PipelineExportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters['pipelineId'] == null) {
            throw new runtime.RequiredError(
                'pipelineId',
                'Required parameter "pipelineId" was null or undefined when calling pipelineExport().'
            );
        }

        if (requestParameters['exportFormat'] == null) {
            throw new runtime.RequiredError(
                'exportFormat',
                'Required parameter "exportFormat" was null or undefined when calling pipelineExport().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['exportFormat'] != null) {
            queryParameters['exportFormat'] = requestParameters['exportFormat'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/pipeline/export/{pipelineId}`.replace(`{${"pipelineId"}}`, encodeURIComponent(String(requestParameters['pipelineId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Export a pipeline in the chosen export format.
     */
    async pipelineExport(requestParameters: PipelineExportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.pipelineExportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a protected item by its hash.
     * Retrieve protected item
     */
    async pipelineGetRaw(requestParameters: PipelineGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProtectedPipeline>> {
        if (requestParameters['hash'] == null) {
            throw new runtime.RequiredError(
                'hash',
                'Required parameter "hash" was null or undefined when calling pipelineGet().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['hash'] != null) {
            queryParameters['hash'] = requestParameters['hash'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/pipeline`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProtectedPipelineFromJSON(jsonValue));
    }

    /**
     * Retrieves a protected item by its hash.
     * Retrieve protected item
     */
    async pipelineGet(requestParameters: PipelineGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProtectedPipeline> {
        const response = await this.pipelineGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Prints the status of a pipeline related to the hash.
     * Print status by hash
     */
    async pipelinePrintRaw(requestParameters: PipelinePrintRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['hash'] == null) {
            throw new runtime.RequiredError(
                'hash',
                'Required parameter "hash" was null or undefined when calling pipelinePrint().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['hash'] != null) {
            queryParameters['hash'] = requestParameters['hash'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/pipeline/print`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Prints the status of a pipeline related to the hash.
     * Print status by hash
     */
    async pipelinePrint(requestParameters: PipelinePrintRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.pipelinePrintRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Registers a new pipeline using related pipeline configuration schema and returns the pipeline ID.
     * Register a new pipeline
     */
    async pipelineRegisterRaw(requestParameters: PipelineRegisterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['pschemaAlias'] == null) {
            throw new runtime.RequiredError(
                'pschemaAlias',
                'Required parameter "pschemaAlias" was null or undefined when calling pipelineRegister().'
            );
        }

        if (requestParameters['pschemaVersion'] == null) {
            throw new runtime.RequiredError(
                'pschemaVersion',
                'Required parameter "pschemaVersion" was null or undefined when calling pipelineRegister().'
            );
        }

        if (requestParameters['pipelineDto'] == null) {
            throw new runtime.RequiredError(
                'pipelineDto',
                'Required parameter "pipelineDto" was null or undefined when calling pipelineRegister().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pschemaAlias'] != null) {
            queryParameters['pschemaAlias'] = requestParameters['pschemaAlias'];
        }

        if (requestParameters['pschemaVersion'] != null) {
            queryParameters['pschemaVersion'] = requestParameters['pschemaVersion'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/pipeline/register`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PipelineDtoToJSON(requestParameters['pipelineDto']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Registers a new pipeline using related pipeline configuration schema and returns the pipeline ID.
     * Register a new pipeline
     */
    async pipelineRegister(requestParameters: PipelineRegisterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.pipelineRegisterRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Searches for pipelines based on a set of filters provided in the request body.
     * Search for pipelines
     */
    async pipelineSearchRaw(requestParameters: PipelineSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PipelineView>> {
        if (requestParameters['pipelineFilter'] == null) {
            throw new runtime.RequiredError(
                'pipelineFilter',
                'Required parameter "pipelineFilter" was null or undefined when calling pipelineSearch().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/pipeline/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PipelineFilterToJSON(requestParameters['pipelineFilter']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PipelineViewFromJSON(jsonValue));
    }

    /**
     * Searches for pipelines based on a set of filters provided in the request body.
     * Search for pipelines
     */
    async pipelineSearch(requestParameters: PipelineSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PipelineView> {
        const response = await this.pipelineSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a pipeline.
     * Get a pipeline by ID
     */
    async pipelineSelectByIdRaw(requestParameters: PipelineSelectByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PipelineDto>> {
        if (requestParameters['pipelineId'] == null) {
            throw new runtime.RequiredError(
                'pipelineId',
                'Required parameter "pipelineId" was null or undefined when calling pipelineSelectById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/pipeline/by-id/{pipelineId}`.replace(`{${"pipelineId"}}`, encodeURIComponent(String(requestParameters['pipelineId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PipelineDtoFromJSON(jsonValue));
    }

    /**
     * Retrieves a pipeline.
     * Get a pipeline by ID
     */
    async pipelineSelectById(requestParameters: PipelineSelectByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PipelineDto> {
        const response = await this.pipelineSelectByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates selected status for a specific pipeline.
     * Update pipeline\'s status
     */
    async pipelineUpdateStatusRaw(requestParameters: PipelineUpdateStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['pipelineId'] == null) {
            throw new runtime.RequiredError(
                'pipelineId',
                'Required parameter "pipelineId" was null or undefined when calling pipelineUpdateStatus().'
            );
        }

        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling pipelineUpdateStatus().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/pipeline/status/{pipelineId}`.replace(`{${"pipelineId"}}`, encodeURIComponent(String(requestParameters['pipelineId']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Updates selected status for a specific pipeline.
     * Update pipeline\'s status
     */
    async pipelineUpdateStatus(requestParameters: PipelineUpdateStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.pipelineUpdateStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Uploads a file and calculates its hash using related pipeline configuration schema.
     * Upload a file to a pipeline
     */
    async pipelineUploadRaw(requestParameters: PipelineUploadOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['pschema'] == null) {
            throw new runtime.RequiredError(
                'pschema',
                'Required parameter "pschema" was null or undefined when calling pipelineUpload().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pschema'] != null) {
            queryParameters['pschema'] = requestParameters['pschema'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/pipeline/upload`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PipelineUploadRequestToJSON(requestParameters['pipelineUploadRequest']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Uploads a file and calculates its hash using related pipeline configuration schema.
     * Upload a file to a pipeline
     */
    async pipelineUpload(requestParameters: PipelineUploadOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.pipelineUploadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Identifies the pipeline related to the hash and validates it.
     * Validate hash
     */
    async pipelineValidateRaw(requestParameters: PipelineValidateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['hash'] == null) {
            throw new runtime.RequiredError(
                'hash',
                'Required parameter "hash" was null or undefined when calling pipelineValidate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['hash'] != null) {
            queryParameters['hash'] = requestParameters['hash'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/pipeline/validate`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Identifies the pipeline related to the hash and validates it.
     * Validate hash
     */
    async pipelineValidate(requestParameters: PipelineValidateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.pipelineValidateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const PipelineExportExportFormatEnum = {
    W3CProvJson: 'W3C_PROV_JSON'
} as const;
export type PipelineExportExportFormatEnum = typeof PipelineExportExportFormatEnum[keyof typeof PipelineExportExportFormatEnum];
