/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * the id of the halgorithm
 * @export
 * @interface TenantHalgorithmDto
 */
export interface TenantHalgorithmDto {
    /**
     * The ID of the tenant
     * @type {number}
     * @memberof TenantHalgorithmDto
     */
    tenantId?: number;
    /**
     * The ID of the halgorithm
     * @type {number}
     * @memberof TenantHalgorithmDto
     */
    halgorithmId?: number;
}

/**
 * Check if a given object implements the TenantHalgorithmDto interface.
 */
export function instanceOfTenantHalgorithmDto(value: object): boolean {
    return true;
}

export function TenantHalgorithmDtoFromJSON(json: any): TenantHalgorithmDto {
    return TenantHalgorithmDtoFromJSONTyped(json, false);
}

export function TenantHalgorithmDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TenantHalgorithmDto {
    if (json == null) {
        return json;
    }
    return {
        
        'tenantId': json['tenantId'] == null ? undefined : json['tenantId'],
        'halgorithmId': json['halgorithmId'] == null ? undefined : json['halgorithmId'],
    };
}

export function TenantHalgorithmDtoToJSON(value?: TenantHalgorithmDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'tenantId': value['tenantId'],
        'halgorithmId': value['halgorithmId'],
    };
}

