import React, {ChangeEvent, FormEvent, useEffect, useState} from 'react';
import {MdOutlineCreate, MdOutlineDeleteForever} from 'react-icons/md';
import Modal from '../../common/Modal';
import {TenantDto} from '../../../api/generated-react-client/src/models/TenantDto';
import {tenantDelete, tenantInsert, tenantSelect, tenantUpdate} from '../../../api/TenantService';
import {TenantInsertRequest} from "../../../api/generated-react-client/src";

const TenantForm: React.FC = () => {

    const [tenants, setTenants] = useState<Array<TenantDto>>([]);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
    const [createFormData, setCreateFormData] = useState<CreateFormData>({
        alias: '',
        budget: 0,
        setup: ''
    });
    const [error, setError] = useState<string | null>(null);
    const [showErrorPopup, setShowErrorPopup] = useState<boolean>(false);

    const handleError = (error: unknown) => {
        setError((error as Error).message);
        setShowErrorPopup(true);
    };

    useEffect(() => {
        const fetchTenants = async () => {
            try {
                const result = await tenantSelect();
                setTenants(result);
            } catch (error) {
                handleError(error);
            }
        };
        fetchTenants();
    }, []);

    const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {name, value} = event.currentTarget;
        setCreateFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleCreate = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const requestParameters: TenantInsertRequest = {tenantDto: createFormData};

        try {
            await tenantInsert(requestParameters);
            setIsCreateModalOpen(false);
            const result = await tenantSelect();
            setTenants(result);
        } catch (error) {
            handleError(error);
        }
    };

    const handleEditClick = async (tenant: TenantDto, field: keyof TenantDto) => {
        const currentValue = tenant[field]?.toString() || '';
        const newValue = prompt(`Edit ${field}`, currentValue);

        if (newValue !== null && newValue !== currentValue) {
            const updatedTenant = {...tenant, [field]: field === 'budget' ? parseInt(newValue, 10) : newValue};
            try {
                await tenantUpdate({tenantDto: updatedTenant});
                setTenants((prevTenants) =>
                    prevTenants.map((t) => (t.id === tenant.id ? updatedTenant : t))
                );
            } catch (error) {
                handleError(error);
            }
        }
    };

    const handleDeleteClick = async (tenantId: number) => {
        if (window.confirm('Are you sure you want to delete this tenant?')) {
            try {
                await tenantDelete({tenantId});
                setTenants((prevTenants) => prevTenants.filter((t) => t.id !== tenantId));
            } catch (error) {
                handleError(error);
            }
        }
    };

    return (
        <div className="formList">
            <div className="headerContainer">
                <b>Tenant list</b>
                <div className="formGroup">
                    <button onClick={() => setIsCreateModalOpen(true)}>Create</button>
                </div>
            </div>

            <Modal isOpen={isCreateModalOpen} onClose={() => setIsCreateModalOpen(false)}>
                <form onSubmit={handleCreate}>
                    <div className="formGroup">
                        <label>Alias</label>
                        <input
                            type="text"
                            name="alias"
                            value={createFormData.alias}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="formGroup">
                        <label>Budget</label>
                        <input
                            type="number"
                            name="budget"
                            value={createFormData.budget}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="formGroup">
                        <label>Setup</label>
                        <input
                            type="text"
                            name="setup"
                            value={createFormData.setup}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="horizontalButtonContainer">
                        <button type="submit">Create</button>
                    </div>
                </form>
            </Modal>

            {showErrorPopup && error && (
                <div className="errorModal">
                    <div className="errorModalContent">
                        <p>Error: {error}</p>
                        <button onClick={() => setShowErrorPopup(false)}>Close</button>
                    </div>
                </div>
            )}
            <table>
                <thead>
                <tr>
                    <th style={{width: '40px', textAlign: 'center'}}>ID</th>
                    <th>Alias</th>
                    <th>Budget</th>
                    <th>Setup</th>
                    <th>Creator</th>
                    <th style={{width: '170px', textAlign: 'center'}}>Created at</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {tenants.map((tenant, index) => (
                    <tr key={tenant.id} className={`row ${index % 2 === 0 ? 'even' : 'odd'}`}>
                        <td style={{width: '40px', textAlign: 'center'}}>{tenant.id}</td>
                        <td>
                            <div className="td-vertical">
                                {tenant.alias}
                                <span className="editIcon" onClick={() => handleEditClick(tenant, 'alias')}>
                                    <MdOutlineCreate/>
                                </span>
                            </div>
                        </td>
                        <td>
                            <div className="td-vertical">
                                {tenant.budget}
                                <span className="editIcon" onClick={() => handleEditClick(tenant, 'budget')}>
                                    <MdOutlineCreate/>
                                </span>
                            </div>
                        </td>
                        <td>
                            <div className="td-vertical">
                                {tenant.setup}
                                <span className="editIcon" onClick={() => handleEditClick(tenant, 'setup')}>
                                    <MdOutlineCreate/>
                                </span>
                            </div>
                        </td>
                        <td>
                            {tenant.creator ? `${tenant.creator.firstName} ${tenant.creator.lastName}` : ''}
                        </td>
                        <td style={{width: '170px', textAlign: 'center'}}>
                            {new Date(tenant.createdAt!).toLocaleString()}
                        </td>
                        <td>
                            <div className="icon-container">
                                    <span onClick={() => handleDeleteClick(tenant.id!)} className="deleteIcon">
                                        <MdOutlineDeleteForever/>
                                    </span>
                            </div>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

interface CreateFormData {
    alias: string;
    budget: number;
    setup: string;
}

export default TenantForm;