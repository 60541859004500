/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ShareDto,
  ShareTeamDto,
  ShareTraceSchemaDto,
} from '../models/index';
import {
    ShareDtoFromJSON,
    ShareDtoToJSON,
    ShareTeamDtoFromJSON,
    ShareTeamDtoToJSON,
    ShareTraceSchemaDtoFromJSON,
    ShareTraceSchemaDtoToJSON,
} from '../models/index';

export interface ShareDeleteRequest {
    shareId: number;
}

export interface ShareInsertRequest {
    shareDto: ShareDto;
}

export interface ShareSelectByIdRequest {
    shareId: number;
}

export interface ShareSelectByPschemaRequest {
    pschemaId: number;
}

export interface ShareSelectByTschemaRequest {
    tschemaId: number;
}

export interface ShareTeamDeleteRequest {
    shareId: number;
    teamId: number;
}

export interface ShareTeamInsertRequest {
    shareTeamDto: ShareTeamDto;
}

export interface ShareTeamSelectRequest {
    shareId: number;
    teamId: number;
}

export interface ShareTeamSelectByShareRequest {
    shareId: number;
}

export interface ShareTeamSelectByTeamRequest {
    teamId: number;
}

export interface ShareTraceSchemaDeleteRequest {
    shareId: number;
    tschemaId: number;
}

export interface ShareTraceSchemaDeleteByShareRequest {
    shareId: number;
}

export interface ShareTraceSchemaDeleteByTraceSchemaRequest {
    tschemaId: number;
}

export interface ShareTraceSchemaInsertRequest {
    shareTraceSchemaDto: ShareTraceSchemaDto;
}

export interface ShareTraceSchemaSelectRequest {
    shareId: number;
    tschemaId: number;
}

export interface ShareTraceSchemaSelectByBundleRequest {
    shareId: number;
}

export interface ShareTraceSchemaSelectByTraceSchemaRequest {
    tschemaId: number;
}

export interface ShareUpdateRequest {
    shareDto: ShareDto;
}

/**
 * 
 */
export class ShareManagementApi extends runtime.BaseAPI {

    /**
     * Deletes a specific Share Bundle by its ID.
     * Delete a Share Bundle
     */
    async shareDeleteRaw(requestParameters: ShareDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['shareId'] == null) {
            throw new runtime.RequiredError(
                'shareId',
                'Required parameter "shareId" was null or undefined when calling shareDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/share/{shareId}`.replace(`{${"shareId"}}`, encodeURIComponent(String(requestParameters['shareId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Deletes a specific Share Bundle by its ID.
     * Delete a Share Bundle
     */
    async shareDelete(requestParameters: ShareDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.shareDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates a new Share Bundle and returns the created Share Bundle with its ID.
     * Insert a new Share Bundle
     */
    async shareInsertRaw(requestParameters: ShareInsertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShareDto>> {
        if (requestParameters['shareDto'] == null) {
            throw new runtime.RequiredError(
                'shareDto',
                'Required parameter "shareDto" was null or undefined when calling shareInsert().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/share`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ShareDtoToJSON(requestParameters['shareDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShareDtoFromJSON(jsonValue));
    }

    /**
     * Creates a new Share Bundle and returns the created Share Bundle with its ID.
     * Insert a new Share Bundle
     */
    async shareInsert(requestParameters: ShareInsertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShareDto> {
        const response = await this.shareInsertRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a Share Bundle by its ID.
     * Get Share Bundle by ID
     */
    async shareSelectByIdRaw(requestParameters: ShareSelectByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShareDto>> {
        if (requestParameters['shareId'] == null) {
            throw new runtime.RequiredError(
                'shareId',
                'Required parameter "shareId" was null or undefined when calling shareSelectById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/share/{shareId}`.replace(`{${"shareId"}}`, encodeURIComponent(String(requestParameters['shareId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShareDtoFromJSON(jsonValue));
    }

    /**
     * Retrieves a Share Bundle by its ID.
     * Get Share Bundle by ID
     */
    async shareSelectById(requestParameters: ShareSelectByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShareDto> {
        const response = await this.shareSelectByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a list of all Share Bundles within a Pipeline Schema.
     * Get all Share Bundles in a Pipeline Schema
     */
    async shareSelectByPschemaRaw(requestParameters: ShareSelectByPschemaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ShareDto>>> {
        if (requestParameters['pschemaId'] == null) {
            throw new runtime.RequiredError(
                'pschemaId',
                'Required parameter "pschemaId" was null or undefined when calling shareSelectByPschema().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/share/by-pschema/{pschemaId}`.replace(`{${"pschemaId"}}`, encodeURIComponent(String(requestParameters['pschemaId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ShareDtoFromJSON));
    }

    /**
     * Retrieves a list of all Share Bundles within a Pipeline Schema.
     * Get all Share Bundles in a Pipeline Schema
     */
    async shareSelectByPschema(requestParameters: ShareSelectByPschemaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ShareDto>> {
        const response = await this.shareSelectByPschemaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a list of all Share Bundles associated with the specified Trace Schema.
     * Get all Share Bundles linked to a Trace Schema
     */
    async shareSelectByTschemaRaw(requestParameters: ShareSelectByTschemaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ShareDto>>> {
        if (requestParameters['tschemaId'] == null) {
            throw new runtime.RequiredError(
                'tschemaId',
                'Required parameter "tschemaId" was null or undefined when calling shareSelectByTschema().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/share/by-tschema/{tschemaId}`.replace(`{${"tschemaId"}}`, encodeURIComponent(String(requestParameters['tschemaId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ShareDtoFromJSON));
    }

    /**
     * Retrieves a list of all Share Bundles associated with the specified Trace Schema.
     * Get all Share Bundles linked to a Trace Schema
     */
    async shareSelectByTschema(requestParameters: ShareSelectByTschemaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ShareDto>> {
        const response = await this.shareSelectByTschemaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes a specific link between a Share Bundle and a Team, identified by their IDs.
     * Delete a link between Share Bundle and Team
     */
    async shareTeamDeleteRaw(requestParameters: ShareTeamDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['shareId'] == null) {
            throw new runtime.RequiredError(
                'shareId',
                'Required parameter "shareId" was null or undefined when calling shareTeamDelete().'
            );
        }

        if (requestParameters['teamId'] == null) {
            throw new runtime.RequiredError(
                'teamId',
                'Required parameter "teamId" was null or undefined when calling shareTeamDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/share/team/{shareId}/{teamId}`.replace(`{${"shareId"}}`, encodeURIComponent(String(requestParameters['shareId']))).replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters['teamId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Deletes a specific link between a Share Bundle and a Team, identified by their IDs.
     * Delete a link between Share Bundle and Team
     */
    async shareTeamDelete(requestParameters: ShareTeamDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.shareTeamDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates a new link between a Share Bundle and a Team, and returns the created link record.
     * Create a link between Share Bundle and Team
     */
    async shareTeamInsertRaw(requestParameters: ShareTeamInsertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShareTeamDto>> {
        if (requestParameters['shareTeamDto'] == null) {
            throw new runtime.RequiredError(
                'shareTeamDto',
                'Required parameter "shareTeamDto" was null or undefined when calling shareTeamInsert().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/share/team`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ShareTeamDtoToJSON(requestParameters['shareTeamDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShareTeamDtoFromJSON(jsonValue));
    }

    /**
     * Creates a new link between a Share Bundle and a Team, and returns the created link record.
     * Create a link between Share Bundle and Team
     */
    async shareTeamInsert(requestParameters: ShareTeamInsertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShareTeamDto> {
        const response = await this.shareTeamInsertRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetches a specific link between a Share Bundle and a Team using their respective IDs.
     * Retrieve a link between Share Bundle and Team
     */
    async shareTeamSelectRaw(requestParameters: ShareTeamSelectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShareTeamDto>> {
        if (requestParameters['shareId'] == null) {
            throw new runtime.RequiredError(
                'shareId',
                'Required parameter "shareId" was null or undefined when calling shareTeamSelect().'
            );
        }

        if (requestParameters['teamId'] == null) {
            throw new runtime.RequiredError(
                'teamId',
                'Required parameter "teamId" was null or undefined when calling shareTeamSelect().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/share/team/{shareId}/{teamId}`.replace(`{${"shareId"}}`, encodeURIComponent(String(requestParameters['shareId']))).replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters['teamId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShareTeamDtoFromJSON(jsonValue));
    }

    /**
     * Fetches a specific link between a Share Bundle and a Team using their respective IDs.
     * Retrieve a link between Share Bundle and Team
     */
    async shareTeamSelect(requestParameters: ShareTeamSelectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShareTeamDto> {
        const response = await this.shareTeamSelectRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a list of all links between the specified Share Bundle and its associated Teams.
     * Get all links between a Share Bundle and Teams
     */
    async shareTeamSelectByShareRaw(requestParameters: ShareTeamSelectByShareRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ShareTeamDto>>> {
        if (requestParameters['shareId'] == null) {
            throw new runtime.RequiredError(
                'shareId',
                'Required parameter "shareId" was null or undefined when calling shareTeamSelectByShare().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/share/team/by-share/{shareId}`.replace(`{${"shareId"}}`, encodeURIComponent(String(requestParameters['shareId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ShareTeamDtoFromJSON));
    }

    /**
     * Retrieves a list of all links between the specified Share Bundle and its associated Teams.
     * Get all links between a Share Bundle and Teams
     */
    async shareTeamSelectByShare(requestParameters: ShareTeamSelectByShareRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ShareTeamDto>> {
        const response = await this.shareTeamSelectByShareRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a list of all Share Bundles linked to the specified Team.
     * Get all links between Teams and Share Bundles
     */
    async shareTeamSelectByTeamRaw(requestParameters: ShareTeamSelectByTeamRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ShareTeamDto>>> {
        if (requestParameters['teamId'] == null) {
            throw new runtime.RequiredError(
                'teamId',
                'Required parameter "teamId" was null or undefined when calling shareTeamSelectByTeam().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/share/team/by-team/{teamId}`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters['teamId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ShareTeamDtoFromJSON));
    }

    /**
     * Retrieves a list of all Share Bundles linked to the specified Team.
     * Get all links between Teams and Share Bundles
     */
    async shareTeamSelectByTeam(requestParameters: ShareTeamSelectByTeamRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ShareTeamDto>> {
        const response = await this.shareTeamSelectByTeamRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes a specific association between a Share Bundle and a Trace Schema by their IDs.
     * Delete a Share Bundle-Trace Schema association
     */
    async shareTraceSchemaDeleteRaw(requestParameters: ShareTraceSchemaDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['shareId'] == null) {
            throw new runtime.RequiredError(
                'shareId',
                'Required parameter "shareId" was null or undefined when calling shareTraceSchemaDelete().'
            );
        }

        if (requestParameters['tschemaId'] == null) {
            throw new runtime.RequiredError(
                'tschemaId',
                'Required parameter "tschemaId" was null or undefined when calling shareTraceSchemaDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/share/tschema/{shareId}/{tschemaId}`.replace(`{${"shareId"}}`, encodeURIComponent(String(requestParameters['shareId']))).replace(`{${"tschemaId"}}`, encodeURIComponent(String(requestParameters['tschemaId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Deletes a specific association between a Share Bundle and a Trace Schema by their IDs.
     * Delete a Share Bundle-Trace Schema association
     */
    async shareTraceSchemaDelete(requestParameters: ShareTraceSchemaDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.shareTraceSchemaDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes all associations between the specified Share Bundle and any TraceSchemas.
     * Delete all Trace Schema associations for a Share Bundle
     */
    async shareTraceSchemaDeleteByShareRaw(requestParameters: ShareTraceSchemaDeleteByShareRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['shareId'] == null) {
            throw new runtime.RequiredError(
                'shareId',
                'Required parameter "shareId" was null or undefined when calling shareTraceSchemaDeleteByShare().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/share/tschema/by-share/{shareId}`.replace(`{${"shareId"}}`, encodeURIComponent(String(requestParameters['shareId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Deletes all associations between the specified Share Bundle and any TraceSchemas.
     * Delete all Trace Schema associations for a Share Bundle
     */
    async shareTraceSchemaDeleteByShare(requestParameters: ShareTraceSchemaDeleteByShareRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.shareTraceSchemaDeleteByShareRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes all associations between the specified Trace Schema and any Share Bundles.
     * Delete all Share Bundle associations for a Trace Schema
     */
    async shareTraceSchemaDeleteByTraceSchemaRaw(requestParameters: ShareTraceSchemaDeleteByTraceSchemaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['tschemaId'] == null) {
            throw new runtime.RequiredError(
                'tschemaId',
                'Required parameter "tschemaId" was null or undefined when calling shareTraceSchemaDeleteByTraceSchema().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/share/tschema/by-tschema/{tschemaId}`.replace(`{${"tschemaId"}}`, encodeURIComponent(String(requestParameters['tschemaId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Deletes all associations between the specified Trace Schema and any Share Bundles.
     * Delete all Share Bundle associations for a Trace Schema
     */
    async shareTraceSchemaDeleteByTraceSchema(requestParameters: ShareTraceSchemaDeleteByTraceSchemaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.shareTraceSchemaDeleteByTraceSchemaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates a new association between a Share Bundle and a Trace Schema, returning the created Share Bundle-Trace Schema record.
     * Link a Share Bundle to a Trace Schema
     */
    async shareTraceSchemaInsertRaw(requestParameters: ShareTraceSchemaInsertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShareTraceSchemaDto>> {
        if (requestParameters['shareTraceSchemaDto'] == null) {
            throw new runtime.RequiredError(
                'shareTraceSchemaDto',
                'Required parameter "shareTraceSchemaDto" was null or undefined when calling shareTraceSchemaInsert().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/share/tschema`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ShareTraceSchemaDtoToJSON(requestParameters['shareTraceSchemaDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShareTraceSchemaDtoFromJSON(jsonValue));
    }

    /**
     * Creates a new association between a Share Bundle and a Trace Schema, returning the created Share Bundle-Trace Schema record.
     * Link a Share Bundle to a Trace Schema
     */
    async shareTraceSchemaInsert(requestParameters: ShareTraceSchemaInsertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShareTraceSchemaDto> {
        const response = await this.shareTraceSchemaInsertRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a specific association between a Share Bundle and a  by their IDs.
     * Get a specific Share Bundle-Trace Schema association
     */
    async shareTraceSchemaSelectRaw(requestParameters: ShareTraceSchemaSelectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShareTraceSchemaDto>> {
        if (requestParameters['shareId'] == null) {
            throw new runtime.RequiredError(
                'shareId',
                'Required parameter "shareId" was null or undefined when calling shareTraceSchemaSelect().'
            );
        }

        if (requestParameters['tschemaId'] == null) {
            throw new runtime.RequiredError(
                'tschemaId',
                'Required parameter "tschemaId" was null or undefined when calling shareTraceSchemaSelect().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/share/tschema/{shareId}/{tschemaId}`.replace(`{${"shareId"}}`, encodeURIComponent(String(requestParameters['shareId']))).replace(`{${"tschemaId"}}`, encodeURIComponent(String(requestParameters['tschemaId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShareTraceSchemaDtoFromJSON(jsonValue));
    }

    /**
     * Retrieves a specific association between a Share Bundle and a  by their IDs.
     * Get a specific Share Bundle-Trace Schema association
     */
    async shareTraceSchemaSelect(requestParameters: ShareTraceSchemaSelectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShareTraceSchemaDto> {
        const response = await this.shareTraceSchemaSelectRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a list of all Trace Schemas associated with the specified Share Bundle.
     * Get all TraceSchemas for a Share Bundle
     */
    async shareTraceSchemaSelectByBundleRaw(requestParameters: ShareTraceSchemaSelectByBundleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ShareTraceSchemaDto>>> {
        if (requestParameters['shareId'] == null) {
            throw new runtime.RequiredError(
                'shareId',
                'Required parameter "shareId" was null or undefined when calling shareTraceSchemaSelectByBundle().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/share/tschema/by-share/{shareId}`.replace(`{${"shareId"}}`, encodeURIComponent(String(requestParameters['shareId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ShareTraceSchemaDtoFromJSON));
    }

    /**
     * Retrieves a list of all Trace Schemas associated with the specified Share Bundle.
     * Get all TraceSchemas for a Share Bundle
     */
    async shareTraceSchemaSelectByBundle(requestParameters: ShareTraceSchemaSelectByBundleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ShareTraceSchemaDto>> {
        const response = await this.shareTraceSchemaSelectByBundleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a list of all Share Bundles associated with the specified Trace Schema.
     * Get all Share Bundles for a Trace Schema
     */
    async shareTraceSchemaSelectByTraceSchemaRaw(requestParameters: ShareTraceSchemaSelectByTraceSchemaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ShareTraceSchemaDto>>> {
        if (requestParameters['tschemaId'] == null) {
            throw new runtime.RequiredError(
                'tschemaId',
                'Required parameter "tschemaId" was null or undefined when calling shareTraceSchemaSelectByTraceSchema().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/share/tschema/by-tschema/{tschemaId}`.replace(`{${"tschemaId"}}`, encodeURIComponent(String(requestParameters['tschemaId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ShareTraceSchemaDtoFromJSON));
    }

    /**
     * Retrieves a list of all Share Bundles associated with the specified Trace Schema.
     * Get all Share Bundles for a Trace Schema
     */
    async shareTraceSchemaSelectByTraceSchema(requestParameters: ShareTraceSchemaSelectByTraceSchemaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ShareTraceSchemaDto>> {
        const response = await this.shareTraceSchemaSelectByTraceSchemaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates selected information for a specific Share Bundle.
     * Update Share Bundle\'s attributes
     */
    async shareUpdateRaw(requestParameters: ShareUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShareDto>> {
        if (requestParameters['shareDto'] == null) {
            throw new runtime.RequiredError(
                'shareDto',
                'Required parameter "shareDto" was null or undefined when calling shareUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/share`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ShareDtoToJSON(requestParameters['shareDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShareDtoFromJSON(jsonValue));
    }

    /**
     * Updates selected information for a specific Share Bundle.
     * Update Share Bundle\'s attributes
     */
    async shareUpdate(requestParameters: ShareUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShareDto> {
        const response = await this.shareUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
