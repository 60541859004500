import {
    TenantSetupInsertRequest,
    TenantSetupManagementApi,
    TenantSetupSearchRequest,
    TenantSetupUpdateRequest
} from './generated-react-client/src/apis';
import config from './clientConfig';
import {apiCallWrapper} from '../utils/apiWrapper';

const tenantSetupManagementApi = new TenantSetupManagementApi(config);

export const tenantSetupInsert = async (requestParameters: TenantSetupInsertRequest) => {
    return apiCallWrapper(() => tenantSetupManagementApi.tenantSetupInsert(requestParameters));
};

export const tenantSetupSelect = async () => {
    return apiCallWrapper(() => tenantSetupManagementApi.tenantSetupSelect());
};

export const tenantSetupUpdate = async (requestParameters: TenantSetupUpdateRequest) => {
    return apiCallWrapper(() => tenantSetupManagementApi.tenantSetupUpdate(requestParameters));
};

export const tenantSetupDelete = async (id: string) => {
    return apiCallWrapper(() => tenantSetupManagementApi.tenantSetupDelete({id}));
};

export const tenantSetupSearch = async (requestParameters: TenantSetupSearchRequest) => {
    return apiCallWrapper(() => tenantSetupManagementApi.tenantSetupSearch(requestParameters));
};