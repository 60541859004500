import React, {ChangeEvent, FormEvent, useState} from 'react';
import {userUploadCSVFile} from "../../api/UserService";
import {UploadCSVFileRequest} from "../../api/generated-react-client/src";

interface FileUploadButtonProps {
    closeModal: () => void;
    onUploadSuccess: () => void;
}

const FileUploadButton: React.FC<FileUploadButtonProps> = ({closeModal, onUploadSuccess}) => {
    const [file, setFile] = useState<File | null>(null);
    const [templateAlias, setTemplateAlias] = useState<string>('');

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files) {
            setFile(files[0]);
        }
    };

    const handleTemplateAliasChange = (event: ChangeEvent<HTMLInputElement>) => {
        setTemplateAlias(event.target.value);
    };

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        if (file) {
            try {
                const request: UploadCSVFileRequest = {
                    templateAlias: templateAlias,
                    file: file
                };
                const responseText = await userUploadCSVFile(request);
                alert('Server responded: ' + responseText);
                onUploadSuccess(); // Call the callback to refresh the user list
                closeModal();
            } catch (error) {
                console.error('Error uploading file:', error);
                alert('Failed to upload file.');
            }
        } else {
            alert('Please select a file to upload.');
        }
    };

    return (
        <form onSubmit={handleSubmit} encType="multipart/form-data">
            <div>
                <label>
                    Template Alias:
                    <input type="text" value={templateAlias} onChange={handleTemplateAliasChange}/>
                </label>
            </div>
            <div>
                <label>
                    Upload CSV:
                    <input type="file" accept=".csv" onChange={handleFileChange}/>
                </label>
            </div>
            <button type="submit">Upload CSV</button>
        </form>
    );
};

export default FileUploadButton;