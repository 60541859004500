/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShareDto } from './ShareDto';
import {
    ShareDtoFromJSON,
    ShareDtoFromJSONTyped,
    ShareDtoToJSON,
} from './ShareDto';

/**
 * Trace schema data to update an existing record
 * @export
 * @interface TraceSchemaDto
 */
export interface TraceSchemaDto {
    /**
     * Identifier of the Pipeline schema associated with this trace schema.
     * @type {number}
     * @memberof TraceSchemaDto
     */
    pschemaId?: number;
    /**
     * Uniform Resource Name (URN) of the trace schema, providing a unique reference.
     * @type {string}
     * @memberof TraceSchemaDto
     */
    urn?: string;
    /**
     * The ID of the URN. This field is used to reference the URN string.
     * @type {number}
     * @memberof TraceSchemaDto
     */
    urnId?: number;
    /**
     * If true, it means that a specific trace can be created. If false, it means that this trace is identical to the tschema.
     * @type {boolean}
     * @memberof TraceSchemaDto
     */
    mutable?: boolean;
    /**
     * Identifier of the batch within the trace schema.
     * @type {number}
     * @memberof TraceSchemaDto
     */
    batchId?: number;
    /**
     * Alias of the batch within the trace schema, providing an alternative name.
     * @type {string}
     * @memberof TraceSchemaDto
     */
    batchAlias?: string;
    /**
     * Enum representing different types of traces according to the W3C PROV standard, which defines a model for provenance information.
     * @type {string}
     * @memberof TraceSchemaDto
     */
    variant?: TraceSchemaDtoVariantEnum;
    /**
     * Title of the trace schema, summarizing its content or purpose.
     * @type {string}
     * @memberof TraceSchemaDto
     */
    title?: string;
    /**
     * The hash algorithm used (e.g., SHA, BLAKE), specifying the method of hash calculation.
     * @type {string}
     * @memberof TraceSchemaDto
     */
    hashAlgorithm?: string;
    /**
     * Indicates if the trace schema is required; defaults to false.
     * @type {boolean}
     * @memberof TraceSchemaDto
     */
    required?: boolean;
    /**
     * X-coordinate of the trace schema on the global graphical representation.
     * @type {number}
     * @memberof TraceSchemaDto
     */
    x?: number;
    /**
     * Y-coordinate of the trace schema on the global graphical representation.
     * @type {number}
     * @memberof TraceSchemaDto
     */
    y?: number;
    /**
     * A list of share bundles that this item belongs to, which can be empty if the item is not associated with any share bundles.
     * @type {Array<ShareDto>}
     * @memberof TraceSchemaDto
     */
    shareList?: Array<ShareDto>;
}


/**
 * @export
 */
export const TraceSchemaDtoVariantEnum = {
    Hash: 'HASH',
    Entity: 'ENTITY',
    Activity: 'ACTIVITY',
    Agent: 'AGENT',
    Note: 'NOTE'
} as const;
export type TraceSchemaDtoVariantEnum = typeof TraceSchemaDtoVariantEnum[keyof typeof TraceSchemaDtoVariantEnum];


/**
 * Check if a given object implements the TraceSchemaDto interface.
 */
export function instanceOfTraceSchemaDto(value: object): boolean {
    return true;
}

export function TraceSchemaDtoFromJSON(json: any): TraceSchemaDto {
    return TraceSchemaDtoFromJSONTyped(json, false);
}

export function TraceSchemaDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TraceSchemaDto {
    if (json == null) {
        return json;
    }
    return {
        
        'pschemaId': json['pschemaId'] == null ? undefined : json['pschemaId'],
        'urn': json['urn'] == null ? undefined : json['urn'],
        'urnId': json['urnId'] == null ? undefined : json['urnId'],
        'mutable': json['mutable'] == null ? undefined : json['mutable'],
        'batchId': json['batchId'] == null ? undefined : json['batchId'],
        'batchAlias': json['batchAlias'] == null ? undefined : json['batchAlias'],
        'variant': json['variant'] == null ? undefined : json['variant'],
        'title': json['title'] == null ? undefined : json['title'],
        'hashAlgorithm': json['hashAlgorithm'] == null ? undefined : json['hashAlgorithm'],
        'required': json['required'] == null ? undefined : json['required'],
        'x': json['x'] == null ? undefined : json['x'],
        'y': json['y'] == null ? undefined : json['y'],
        'shareList': json['shareList'] == null ? undefined : ((json['shareList'] as Array<any>).map(ShareDtoFromJSON)),
    };
}

export function TraceSchemaDtoToJSON(value?: TraceSchemaDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'pschemaId': value['pschemaId'],
        'urn': value['urn'],
        'urnId': value['urnId'],
        'mutable': value['mutable'],
        'batchId': value['batchId'],
        'batchAlias': value['batchAlias'],
        'variant': value['variant'],
        'title': value['title'],
        'hashAlgorithm': value['hashAlgorithm'],
        'required': value['required'],
        'x': value['x'],
        'y': value['y'],
        'shareList': value['shareList'] == null ? undefined : ((value['shareList'] as Array<any>).map(ShareDtoToJSON)),
    };
}

