import React, {ChangeEvent, useEffect, useState} from 'react';
import {MdOutlineCreate, MdOutlineDeleteForever} from 'react-icons/md';
import Modal from '../../common/Modal';
import {
    TokenSetupDto,
    TokenSetupDtoPurposeEnum,
    TokenSetupInsertRequest,
} from '../../../api/generated-react-client/src';
import {tokenDelete, tokenInsert, tokenSelect, tokenUpdate} from '../../../api/TokenService';

const TokenForm: React.FC = () => {
    const [tokens, setTokens] = useState<Array<TokenSetupDto>>([]);
    const [error, setError] = useState<string | null>(null);
    const [showErrorPopup, setShowErrorPopup] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [formData, setFormData] = useState<FormData>({
        alias: '',
        purpose: TokenSetupDtoPurposeEnum.Notification,
        lifespan: 3600,
        canRefresh: false
    });

    useEffect(() => {
        const fetchTokens = async () => {
            try {
                const result = await tokenSelect();
                setTokens(result);
            } catch (err) {
                setError((err as Error).message);
            }
        };

        fetchTokens();
    }, []);

    const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {name, value} = event.currentTarget;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: name === 'lifespan' ? +value : value,
        }));
    };

    const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const {name, value} = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        const {name, checked} = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: checked
        }));
    };

    const handleCreate = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const requestParameters: TokenSetupInsertRequest = {tokenSetupDto: formData};

        try {
            await tokenInsert(requestParameters);
            setIsModalOpen(false);
            const result = await tokenSelect();
            setTokens(result);
        } catch (error) {
            setError((error as Error).message);
            setShowErrorPopup(true);
        }
    };

    const handleEditClick = async (token: TokenSetupDto, field: keyof TokenSetupDto) => {
        const currentValue = token[field]?.toString() || '';
        const newValue = prompt(`Edit ${field}`, currentValue);

        if (newValue !== null) {
            const updatedToken = {
                ...token,
                [field]: field === 'lifespan' ? +newValue : newValue
            };
            try {
                await tokenUpdate({tokenSetupDto: updatedToken});
                setTokens((prevTokens) =>
                    prevTokens.map((t) => (t.id === token.id ? updatedToken : t))
                );
            } catch (error) {
                setError((error as Error).message);
                setShowErrorPopup(true);
            }
        }
    };

    const handleDelete = async (tokenId: number) => {
        if (window.confirm('Are you sure you want to delete this token?')) {
            try {
                await tokenDelete(tokenId);
                setTokens((prevTokens) => prevTokens.filter((t) => t.id !== tokenId));
            } catch (error) {
                setError((error as Error).message);
                setShowErrorPopup(true);
            }
        }
    };

    const handlePurposeChange = async (token: TokenSetupDto, newPurpose: TokenSetupDtoPurposeEnum) => {
        try {
            const updatedToken = {...token, purpose: newPurpose};
            await tokenUpdate({tokenSetupDto: updatedToken});
            setTokens((prevTokens) =>
                prevTokens.map((t) => (t.id === token.id ? updatedToken : t))
            );
        } catch (error) {
            setError((error as Error).message);
            setShowErrorPopup(true);
        }
    };

    return (
        <div className="formList">
            <div className="headerContainer">
                <b>Token list</b>
                <div className="formGroup">
                    <button onClick={() => setIsModalOpen(true)}>Create</button>
                </div>
                <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                    <form onSubmit={handleCreate}>
                        <div className="formGroup">
                            <label>Alias</label>
                            <input
                                type="text"
                                name="alias"
                                value={formData.alias}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="formGroup">
                            <label>Purpose</label>
                            <select
                                name="purpose"
                                value={formData.purpose}
                                onChange={handleSelectChange}
                            >
                                {Object.values(TokenSetupDtoPurposeEnum).map((purpose) => (
                                    <option key={purpose} value={purpose}>
                                        {purpose}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="formGroup">
                            <label>Lifespan</label>
                            <input
                                type="number"
                                name="lifespan"
                                value={formData.lifespan}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="formGroup">
                            <label>Can Refresh</label>
                            <input
                                type="checkbox"
                                name="canRefresh"
                                checked={formData.canRefresh}
                                onChange={handleCheckboxChange}
                            />
                        </div>
                        <div className="horizontalButtonContainer">
                            <button type="submit">Create</button>
                        </div>
                    </form>
                </Modal>
            </div>
            {showErrorPopup && error !== null && (
                <div className="errorModal">
                    <div className="errorModalContent">
                        <p>Error: {error}</p>
                        <button onClick={() => setShowErrorPopup(false)}>Close</button>
                    </div>
                </div>
            )}
            <table>
                <thead>
                <tr>
                    <th style={{width: '40px', textAlign: 'center'}}>ID</th>
                    <th>Alias</th>
                    <th style={{width: '120px', textAlign: 'center'}}>Purpose</th>
                    <th style={{width: '100px', textAlign: 'center'}}>Lifespan</th>
                    <th style={{width: '100px', textAlign: 'center'}}>Can Refresh</th>
                    <th style={{width: '170px', textAlign: 'center'}}>Created at</th>
                    <th>Creator</th>
                    <th style={{width: '100px'}}>Actions</th>
                </tr>
                </thead>
                <tbody>
                {tokens.map((token, index) => (
                    <tr key={token.id} className={`row ${index % 2 === 0 ? 'even' : 'odd'}`}>
                        <td style={{width: '40px', textAlign: 'center'}}>{token.id}</td>
                        <td>
                            <div className="td-vertical">
                                {token.alias}
                                <span
                                    className="editIcon"
                                    onClick={() => handleEditClick(token, 'alias')}
                                >
                                    <MdOutlineCreate/>
                                </span>
                            </div>
                        </td>
                        <td style={{width: '120px', textAlign: 'center'}}>
                            <select
                                value={token.purpose}
                                onChange={(e) =>
                                    handlePurposeChange(token, e.target.value as TokenSetupDtoPurposeEnum)
                                }
                            >
                                {Object.values(TokenSetupDtoPurposeEnum).map((purpose) => (
                                    <option key={purpose} value={purpose}>
                                        {purpose}
                                    </option>
                                ))}
                            </select>
                        </td>
                        <td style={{width: '100px', textAlign: 'center'}}>
                            <div className="td-vertical">
                                {token.lifespan}
                                <span
                                    className="editIcon"
                                    onClick={() => handleEditClick(token, 'lifespan')}
                                >
                                    <MdOutlineCreate/>
                                </span>
                            </div>
                        </td>
                        <td style={{width: '100px', textAlign: 'center'}}>
                            <input
                                type="checkbox"
                                checked={token.canRefresh}
                                readOnly
                                onClick={() => handleEditClick(token, 'canRefresh')}
                            />
                        </td>
                        <td style={{
                            width: '170px',
                            textAlign: 'center'
                        }}>{token.createdAt?.toLocaleString() ?? 'Not available'}</td>
                        <td>{token.creator!.firstName} {token.creator!.lastName}</td>
                        <td style={{width: '100px'}}>
                            <div className="icon-container">
                                    <span
                                        onClick={() => handleDelete(token.id!)}
                                        className="deleteIcon"
                                    >
                                        <MdOutlineDeleteForever/>
                                    </span>
                            </div>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

interface FormData {
    alias: string;
    purpose: TokenSetupDtoPurposeEnum;
    lifespan: number;
    canRefresh: boolean;
}

export default TokenForm;