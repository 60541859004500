/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * The new attributes for the permission.
 * @export
 * @interface PermissionDto
 */
export interface PermissionDto {
    /**
     * The unique identifier for the permission.
     * @type {number}
     * @memberof PermissionDto
     */
    id?: number;
    /**
     * The identifier for the tenant to which the permission belongs.
     * @type {number}
     * @memberof PermissionDto
     */
    tenantId?: number;
    /**
     * A human-readable alias for the permission, describing its purpose or scope.
     * @type {string}
     * @memberof PermissionDto
     */
    alias?: string;
}

/**
 * Check if a given object implements the PermissionDto interface.
 */
export function instanceOfPermissionDto(value: object): boolean {
    return true;
}

export function PermissionDtoFromJSON(json: any): PermissionDto {
    return PermissionDtoFromJSONTyped(json, false);
}

export function PermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'tenantId': json['tenantId'] == null ? undefined : json['tenantId'],
        'alias': json['alias'] == null ? undefined : json['alias'],
    };
}

export function PermissionDtoToJSON(value?: PermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'tenantId': value['tenantId'],
        'alias': value['alias'],
    };
}

