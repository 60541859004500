/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CronDto } from './CronDto';
import {
    CronDtoFromJSON,
    CronDtoFromJSONTyped,
    CronDtoToJSON,
} from './CronDto';

/**
 * Carries the search result with pagination details for cron tasks.
 * @export
 * @interface CronView
 */
export interface CronView {
    /**
     * The total number of elements available for the query, which may be greater than the number of elements in the list.
     * @type {number}
     * @memberof CronView
     */
    count?: number;
    /**
     * The position of the first element in the list within the overall query results.
     * @type {number}
     * @memberof CronView
     */
    offset?: number;
    /**
     * The maximum number of elements that can be returned in a single response.
     * @type {number}
     * @memberof CronView
     */
    limit?: number;
    /**
     * The list of cron tasks representing the actual data returned for the current page of results.
     * @type {Array<CronDto>}
     * @memberof CronView
     */
    list?: Array<CronDto>;
}

/**
 * Check if a given object implements the CronView interface.
 */
export function instanceOfCronView(value: object): boolean {
    return true;
}

export function CronViewFromJSON(json: any): CronView {
    return CronViewFromJSONTyped(json, false);
}

export function CronViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): CronView {
    if (json == null) {
        return json;
    }
    return {
        
        'count': json['count'] == null ? undefined : json['count'],
        'offset': json['offset'] == null ? undefined : json['offset'],
        'limit': json['limit'] == null ? undefined : json['limit'],
        'list': json['list'] == null ? undefined : ((json['list'] as Array<any>).map(CronDtoFromJSON)),
    };
}

export function CronViewToJSON(value?: CronView | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'count': value['count'],
        'offset': value['offset'],
        'limit': value['limit'],
        'list': value['list'] == null ? undefined : ((value['list'] as Array<any>).map(CronDtoToJSON)),
    };
}

