/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * The UserPermissionDto object containing the identifiers. If permissionId is omitted, all permissions for the userId are revoked.
 * @export
 * @interface UserPermissionDto
 */
export interface UserPermissionDto {
    /**
     * The unique identifier of the user.
     * @type {number}
     * @memberof UserPermissionDto
     */
    userId?: number;
    /**
     * The unique identifier of the permission, representing a group of privileges.
     * @type {number}
     * @memberof UserPermissionDto
     */
    permissionId?: number;
}

/**
 * Check if a given object implements the UserPermissionDto interface.
 */
export function instanceOfUserPermissionDto(value: object): boolean {
    return true;
}

export function UserPermissionDtoFromJSON(json: any): UserPermissionDto {
    return UserPermissionDtoFromJSONTyped(json, false);
}

export function UserPermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserPermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'userId': json['userId'] == null ? undefined : json['userId'],
        'permissionId': json['permissionId'] == null ? undefined : json['permissionId'],
    };
}

export function UserPermissionDtoToJSON(value?: UserPermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'userId': value['userId'],
        'permissionId': value['permissionId'],
    };
}

