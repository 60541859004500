/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Urn information for update a record
 * @export
 * @interface UrnDto
 */
export interface UrnDto {
    /**
     * The unique identifier of the URN.
     * @type {number}
     * @memberof UrnDto
     */
    id?: number;
    /**
     * The ID of the related pipeline schema. The schema must already exist.
     * @type {number}
     * @memberof UrnDto
     */
    pschemaId?: number;
    /**
     * The value of the URN.
     * @type {string}
     * @memberof UrnDto
     */
    value?: string;
}

/**
 * Check if a given object implements the UrnDto interface.
 */
export function instanceOfUrnDto(value: object): boolean {
    return true;
}

export function UrnDtoFromJSON(json: any): UrnDto {
    return UrnDtoFromJSONTyped(json, false);
}

export function UrnDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UrnDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'pschemaId': json['pschemaId'] == null ? undefined : json['pschemaId'],
        'value': json['value'] == null ? undefined : json['value'],
    };
}

export function UrnDtoToJSON(value?: UrnDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'pschemaId': value['pschemaId'],
        'value': value['value'],
    };
}

