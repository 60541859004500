/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  TraceDto,
} from '../models/index';
import {
    TraceDtoFromJSON,
    TraceDtoToJSON,
} from '../models/index';

export interface TraceInsertRequest {
    traceDto: TraceDto;
}

export interface TraceSelectByHashRequest {
    hash: string;
}

export interface TraceSelectByIdRequest {
    traceId: number;
}

export interface TraceSelectByPipelineRequest {
    pipelineId: number;
}

export interface TraceSelectByPipelineAndUrnRequest {
    pipelineId: number;
    urnId: number;
}

export interface TraceUpdateManifestRequest {
    traceId: number;
    body: number;
}

export interface TraceUpdateStatusRequest {
    traceId: number;
    body: string;
}

/**
 * 
 */
export class TraceManagementApi extends runtime.BaseAPI {

    /**
     * Creates a new trace with the given details and returns the created trace.
     * Create a new trace
     */
    async traceInsertRaw(requestParameters: TraceInsertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TraceDto>> {
        if (requestParameters['traceDto'] == null) {
            throw new runtime.RequiredError(
                'traceDto',
                'Required parameter "traceDto" was null or undefined when calling traceInsert().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/trace`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TraceDtoToJSON(requestParameters['traceDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TraceDtoFromJSON(jsonValue));
    }

    /**
     * Creates a new trace with the given details and returns the created trace.
     * Create a new trace
     */
    async traceInsert(requestParameters: TraceInsertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TraceDto> {
        const response = await this.traceInsertRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a list of all traces related to the specified hash.
     * Get all traces related to the specific hash.
     */
    async traceSelectByHashRaw(requestParameters: TraceSelectByHashRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TraceDto>>> {
        if (requestParameters['hash'] == null) {
            throw new runtime.RequiredError(
                'hash',
                'Required parameter "hash" was null or undefined when calling traceSelectByHash().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/trace/by-hash/{hash}`.replace(`{${"hash"}}`, encodeURIComponent(String(requestParameters['hash']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TraceDtoFromJSON));
    }

    /**
     * Retrieves a list of all traces related to the specified hash.
     * Get all traces related to the specific hash.
     */
    async traceSelectByHash(requestParameters: TraceSelectByHashRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TraceDto>> {
        const response = await this.traceSelectByHashRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a single trace by its ID.
     * Get trace  by ID
     */
    async traceSelectByIdRaw(requestParameters: TraceSelectByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TraceDto>> {
        if (requestParameters['traceId'] == null) {
            throw new runtime.RequiredError(
                'traceId',
                'Required parameter "traceId" was null or undefined when calling traceSelectById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/trace/{traceId}`.replace(`{${"traceId"}}`, encodeURIComponent(String(requestParameters['traceId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TraceDtoFromJSON(jsonValue));
    }

    /**
     * Retrieves a single trace by its ID.
     * Get trace  by ID
     */
    async traceSelectById(requestParameters: TraceSelectByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TraceDto> {
        const response = await this.traceSelectByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a list of all traces related to the specified pipeline.
     * Get all traces related to the specific pipeline.
     */
    async traceSelectByPipelineRaw(requestParameters: TraceSelectByPipelineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TraceDto>>> {
        if (requestParameters['pipelineId'] == null) {
            throw new runtime.RequiredError(
                'pipelineId',
                'Required parameter "pipelineId" was null or undefined when calling traceSelectByPipeline().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/trace/by-pipeline/{pipelineId}`.replace(`{${"pipelineId"}}`, encodeURIComponent(String(requestParameters['pipelineId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TraceDtoFromJSON));
    }

    /**
     * Retrieves a list of all traces related to the specified pipeline.
     * Get all traces related to the specific pipeline.
     */
    async traceSelectByPipeline(requestParameters: TraceSelectByPipelineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TraceDto>> {
        const response = await this.traceSelectByPipelineRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a list of all traces related to the specified pipeline and URN.
     * Get all traces related to the specific pipeline and URN.
     */
    async traceSelectByPipelineAndUrnRaw(requestParameters: TraceSelectByPipelineAndUrnRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TraceDto>>> {
        if (requestParameters['pipelineId'] == null) {
            throw new runtime.RequiredError(
                'pipelineId',
                'Required parameter "pipelineId" was null or undefined when calling traceSelectByPipelineAndUrn().'
            );
        }

        if (requestParameters['urnId'] == null) {
            throw new runtime.RequiredError(
                'urnId',
                'Required parameter "urnId" was null or undefined when calling traceSelectByPipelineAndUrn().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['urnId'] != null) {
            queryParameters['urnId'] = requestParameters['urnId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/trace/by-pipeline-urn/{pipelineId}`.replace(`{${"pipelineId"}}`, encodeURIComponent(String(requestParameters['pipelineId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TraceDtoFromJSON));
    }

    /**
     * Retrieves a list of all traces related to the specified pipeline and URN.
     * Get all traces related to the specific pipeline and URN.
     */
    async traceSelectByPipelineAndUrn(requestParameters: TraceSelectByPipelineAndUrnRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TraceDto>> {
        const response = await this.traceSelectByPipelineAndUrnRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates the manifest details of an existing trace based on the provided information and returns the updated trace information.
     * Update an existing trace with a manifest
     */
    async traceUpdateManifestRaw(requestParameters: TraceUpdateManifestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['traceId'] == null) {
            throw new runtime.RequiredError(
                'traceId',
                'Required parameter "traceId" was null or undefined when calling traceUpdateManifest().'
            );
        }

        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling traceUpdateManifest().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/trace/update-manifest/{traceId}`.replace(`{${"traceId"}}`, encodeURIComponent(String(requestParameters['traceId']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Updates the manifest details of an existing trace based on the provided information and returns the updated trace information.
     * Update an existing trace with a manifest
     */
    async traceUpdateManifest(requestParameters: TraceUpdateManifestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.traceUpdateManifestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates the status of an existing trace and returns the number of updated records.
     * Update an existing trace -
     */
    async traceUpdateStatusRaw(requestParameters: TraceUpdateStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['traceId'] == null) {
            throw new runtime.RequiredError(
                'traceId',
                'Required parameter "traceId" was null or undefined when calling traceUpdateStatus().'
            );
        }

        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling traceUpdateStatus().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/trace/update-status/{traceId}`.replace(`{${"traceId"}}`, encodeURIComponent(String(requestParameters['traceId']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Updates the status of an existing trace and returns the number of updated records.
     * Update an existing trace -
     */
    async traceUpdateStatus(requestParameters: TraceUpdateStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.traceUpdateStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
