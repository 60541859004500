/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TraceDto } from './TraceDto';
import {
    TraceDtoFromJSON,
    TraceDtoFromJSONTyped,
    TraceDtoToJSON,
} from './TraceDto';
import type { UserDto } from './UserDto';
import {
    UserDtoFromJSON,
    UserDtoFromJSONTyped,
    UserDtoToJSON,
} from './UserDto';

/**
 * Pipeline configuration data.
 * @export
 * @interface PipelineDto
 */
export interface PipelineDto {
    /**
     * The unique identifier for the pipeline instance.
     * @type {number}
     * @memberof PipelineDto
     */
    id?: number;
    /**
     * The unique identifier of the related pipeline schema.
     * @type {number}
     * @memberof PipelineDto
     */
    pschemaId?: number;
    /**
     * An external or additional identifier that links this pipeline to external systems or databases.
     * @type {string}
     * @memberof PipelineDto
     */
    externalId?: string;
    /**
     * The title of the pipeline, providing a human-readable name that describes its purpose or function.
     * @type {string}
     * @memberof PipelineDto
     */
    title?: string;
    /**
     * Creation date.
     * @type {Date}
     * @memberof PipelineDto
     */
    createdAt?: Date;
    /**
     * 
     * @type {UserDto}
     * @memberof PipelineDto
     */
    creator?: UserDto;
    /**
     * The status of the pipeline.
     * @type {string}
     * @memberof PipelineDto
     */
    status?: PipelineDtoStatusEnum;
    /**
     * A list of items associated with this pipeline, representing various trace elements or data points recorded during the business process execution.
     * @type {Array<TraceDto>}
     * @memberof PipelineDto
     */
    traceList?: Array<TraceDto>;
}


/**
 * @export
 */
export const PipelineDtoStatusEnum = {
    Created: 'CREATED',
    Started: 'STARTED',
    Suspended: 'SUSPENDED',
    Canceled: 'CANCELED',
    Eronous: 'ERONOUS',
    Completed: 'COMPLETED',
    Deleted: 'DELETED'
} as const;
export type PipelineDtoStatusEnum = typeof PipelineDtoStatusEnum[keyof typeof PipelineDtoStatusEnum];


/**
 * Check if a given object implements the PipelineDto interface.
 */
export function instanceOfPipelineDto(value: object): boolean {
    return true;
}

export function PipelineDtoFromJSON(json: any): PipelineDto {
    return PipelineDtoFromJSONTyped(json, false);
}

export function PipelineDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PipelineDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'pschemaId': json['pschemaId'] == null ? undefined : json['pschemaId'],
        'externalId': json['externalId'] == null ? undefined : json['externalId'],
        'title': json['title'] == null ? undefined : json['title'],
        'createdAt': json['createdAt'] == null ? undefined : (new Date(json['createdAt'])),
        'creator': json['creator'] == null ? undefined : UserDtoFromJSON(json['creator']),
        'status': json['status'] == null ? undefined : json['status'],
        'traceList': json['traceList'] == null ? undefined : ((json['traceList'] as Array<any>).map(TraceDtoFromJSON)),
    };
}

export function PipelineDtoToJSON(value?: PipelineDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'pschemaId': value['pschemaId'],
        'externalId': value['externalId'],
        'title': value['title'],
        'createdAt': value['createdAt'] == null ? undefined : ((value['createdAt']).toISOString()),
        'creator': UserDtoToJSON(value['creator']),
        'status': value['status'],
        'traceList': value['traceList'] == null ? undefined : ((value['traceList'] as Array<any>).map(TraceDtoToJSON)),
    };
}

