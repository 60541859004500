// Dashboard.tsx
import React from 'react';
import styles from './Dashboard.module.css';

const Dashboard: React.FC = () => {
    return (
        <div>
            <div className={styles.backgroundContainer}></div>
            <div className={styles.textContainer}>
                <div className={styles.textBackground}>
                    Choose an option from the top menu.
                </div>
            </div>
        </div>
    );
};

export default Dashboard;