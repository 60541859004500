import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {tschemaLinkInsert, tschemaLinkSelectByPschema, tschemaSelectByPschema} from "../../../api/TraceSchemaService"; // Adjust the path based on your project
import Modal from "../../common/Modal";
import {MdOutlineDeleteForever} from "react-icons/md";
import {TraceSchemaDto, TraceSchemaLinkDto, TraceSchemaLinkDtoLinkEnum} from "../../../api/generated-react-client/src";

const TraceSchemaLinkForm: React.FC = () => {
    const {pschemaId} = useParams<{ pschemaId: string }>();
    const {urn} = useParams<{ urn: string }>();
    const navigate = useNavigate();
    const [links, setLinks] = useState<TraceSchemaLinkDto[]>([]);
    const [filteredLinks, setFilteredLinks] = useState<TraceSchemaLinkDto[]>([]);
    const [availableSchemas, setAvailableSchemas] = useState<TraceSchemaDto[]>([]); // Holds the available schemas for dropdown
    const [createFormData, setCreateFormData] = useState({
        pschemaId: 0,
        ancestorUrn: '',
        descendantUrn: '',
        link: TraceSchemaLinkDtoLinkEnum.UsedBy,
        multi: false
    });
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [filterApplied, setFilterApplied] = useState(true); // To track if the filter is applied

    const handleError = (error: unknown) => {
        setError((error as Error).message);
        setShowErrorPopup(true);
    };

    useEffect(() => {
        const fetchLinks = async () => {
            try {
                const fetchedLinks = await tschemaLinkSelectByPschema({pschemaId: Number(pschemaId)});
                setLinks(fetchedLinks);
                if (urn) {
                    const filtered = fetchedLinks.filter(
                        link => link.ancestorUrn === urn || link.descendantUrn === urn
                    );
                    setFilteredLinks(filtered);
                } else {
                    setFilteredLinks(fetchedLinks);
                }
            } catch (error) {
                handleError(error);
            }
        };
        fetchLinks();
    }, [pschemaId, urn]);

    // Fetch available trace schemas for dropdown options
    useEffect(() => {
        const fetchAvailableSchemas = async () => {
            try {
                const schemas = await tschemaSelectByPschema({pschemaId: Number(pschemaId)});
                setAvailableSchemas(schemas);
            } catch (error) {
                handleError(error);
            }
        };
        fetchAvailableSchemas();
    }, [pschemaId]);

    const handleCreate = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const traceSchemaLinkDto: TraceSchemaLinkDto = {
            pschemaId: Number(pschemaId),
            ancestorUrn: createFormData.ancestorUrn,
            descendantUrn: createFormData.descendantUrn,
            link: createFormData.link,
            multi: createFormData.multi,
        };

        try {
            await tschemaLinkInsert({traceSchemaLinkDto});
            setIsCreateModalOpen(false); // Close the modal after creating the link
            const updatedLinks = await tschemaLinkSelectByPschema({pschemaId: Number(pschemaId)}); // Refresh the links list
            setLinks(updatedLinks); // Update the state with the new list of links
            if (urn) {
                const filtered = updatedLinks.filter(
                    link => link.ancestorUrn === urn || link.descendantUrn === urn
                );
                setFilteredLinks(filtered);
            } else {
                setFilteredLinks(updatedLinks);
            }
        } catch (error) {
            handleError(error);
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
        const {name, value} = event.target;
        setCreateFormData(prevFormData => ({...prevFormData, [name]: value}));
    };

    const handleDelete = async (link: TraceSchemaLinkDto) => {
        // Logic to delete the link
    };

    const resetFilter = () => {
        setFilteredLinks(links); // Reset the links to the original list
        setFilterApplied(false); // Disable the filter
    };

    return (
        <div className="formList">
            <div className="headerContainer">
                <b>Manage Links</b>
                <div className="formGroup">
                    <button onClick={() => navigate(-1)}>Return</button>
                    <button onClick={() => setIsCreateModalOpen(true)}>Create Link</button>
                    {filterApplied && (
                        <button onClick={resetFilter} style={{ marginLeft: '10px' }}>
                            Reset Filter
                        </button>
                    )}
                </div>
            </div>
            <Modal isOpen={isCreateModalOpen} onClose={() => setIsCreateModalOpen(false)}>
                <form onSubmit={handleCreate}>
                    <div className="formGroup">
                        <label>Ancestor URN</label>
                        <select
                            name="ancestorUrn"
                            value={createFormData.ancestorUrn}
                            onChange={handleInputChange}
                        >
                            <option value="">Select Ancestor URN</option>
                            {availableSchemas.map(schema => (
                                <option key={schema.urn} value={schema.urn}>
                                    {schema.urn}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="formGroup">
                        <label>Descendant URN</label>
                        <select
                            name="descendantUrn"
                            value={createFormData.descendantUrn}
                            onChange={handleInputChange}
                        >
                            <option value="">Select Descendant URN</option>
                            {availableSchemas.map(schema => (
                                <option key={schema.urn} value={schema.urn}>
                                    {schema.urn}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="formGroup">
                        <label>Link Type</label>
                        <select
                            name="link"
                            value={createFormData.link}
                            onChange={handleInputChange}
                        >
                            <option value="">Select Link Type</option>
                            {Object.values(TraceSchemaLinkDtoLinkEnum).map(linkType => (
                                <option key={linkType} value={linkType}>
                                    {linkType}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="formGroup">
                        <label>Multiple</label>
                        <input
                            type="checkbox"
                            name="multi"
                            checked={createFormData.multi}
                            onChange={(e) => setCreateFormData(prev => ({...prev, multi: e.target.checked}))}
                        />
                    </div>
                    <div className="horizontalButtonContainer">
                        <button type="submit">Create Link</button>
                    </div>
                </form>
            </Modal>
            {showErrorPopup && error !== null && (
                <div className="errorModal">
                    <div className="errorModalContent">
                        <p>Error: {error}</p>
                        <button onClick={() => setShowErrorPopup(false)}>Close</button>
                    </div>
                </div>
            )}
            <table>
                <thead>
                <tr>
                    <th>Ancestor URN</th>
                    <th>Descendant URN</th>
                    <th>Link</th>
                    <th>Multiple</th>
                    <th>Action</th>
                </tr>
                </thead>
                <tbody>
                {filteredLinks.map((link, index) => (
                    <tr key={index}>
                        <td>{link.ancestorUrn}</td>
                        <td>{link.descendantUrn}</td>
                        <td>{link.link}</td>
                        <td>{link.multi ? 'Yes' : 'No'}</td>
                        <td>
                                <span onClick={() => handleDelete(link)} className="deleteIcon">
                                    <MdOutlineDeleteForever/>
                                </span>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default TraceSchemaLinkForm;
